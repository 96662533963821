import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'

import { withBasketType } from '@thg-commerce/enterprise-components/Header'
import { i18n, Routes, useSiteConfig } from '@thg-commerce/enterprise-core'
import { addToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'

import { useBasket } from '../Basket/hooks/useBasketId'

interface BasketItem {
  quantity: number
  standardPricePerUnit: {
    amount: string
    displayValue: string
  }
  chargePricePerUnit?: {
    amount: string
    displayValue: string
  }
  freeGift: boolean
  product: {
    title: string
    externalIdentifier?: string | null
    product?: {
      sku?: string
      url?: string
      basketImage?: string
    } | null
    price?: {
      rrp?: {
        amount: string
        displayValue: string
      }
    } | null
  }
  subscriptionContract?: {
    initialPrice?: {
      price?: {
        amount: string
        displayValue: string
      }
    }
  }
}

const getPriceDetails = (
  item: BasketItem,
  enableVipPrice: boolean | undefined,
) => {
  const defaultPrice = { amount: '0.00', displayValue: '0.00' }

  if (enableVipPrice && item.chargePricePerUnit) {
    return {
      amount: item.chargePricePerUnit.amount,
      price: item.chargePricePerUnit.displayValue,
    }
  }

  const priceObject = item.subscriptionContract
    ? item.chargePricePerUnit
    : item.standardPricePerUnit

  return {
    amount: priceObject?.amount ?? defaultPrice.amount,
    price: priceObject?.displayValue ?? defaultPrice.displayValue,
  }
}

const MiniBasketRenderer = ({ Component }) => {
  const { basket } = useBasket()
  const { useExternalIdentifier, qubit, enableVipPrice } = useSiteConfig()

  const earnableLoyaltyPoints = basket?.earnableLoyaltyPoints

  const qubitBasketItemEventsEmittedRef = React.useRef<boolean>(false)

  const miniBasketI18nText = {
    basketSummarySingular: i18n('header.basket.summary.singular.text'),
    basketSummary: i18n('header.basket.summary.text'),
    basketView: i18n('header.basket.viewbasket.text'),
    basketEmpty: i18n('header.basket.noitems.text'),
    continueShopping: i18n('header.basket.button.continueshopping.text'),
    itemListAriaLabel: i18n('header.basket.items.arialabel'),
    quantityText: i18n('header.basket.qty.text'),
    freePriceText: i18n('product.freeprice.text'),
    loyaltyText: i18n('basket.loyalty.text', String(earnableLoyaltyPoints)),
  }

  const basketItems = basket?.items?.map((item: BasketItem) => {
    const priceDetails = getPriceDetails(item, enableVipPrice)
    return {
      name: item?.product?.title || '',
      sku: item?.product?.product?.sku,
      externalIdentifier: item?.product?.externalIdentifier || '',
      quantity: item?.quantity || 0,
      price: priceDetails,
      rrpPrice: item.product?.price?.rrp,
      imageUrl: item?.product?.product?.basketImage,
      productUrl: item?.product?.product?.url,
      freeGift: item?.freeGift,
    }
  })

  const miniBasketLinks = {
    viewBasket: Routes.Basket,
  }

  const basketOverview = {
    earnableLoyaltyPoints,
    items: basketItems,
    totalPrice: basket?.chargePrice?.displayValue || '0.00',
    totalQuantity: basket?.totalQuantity || 0,
  }

  React.useEffect(() => {
    if (basket) {
      addToDataLayer({
        key: 'basketTotal',
        value: basket.chargePrice?.amount || '0.00',
      })
      addToDataLayer({
        key: 'basketProducts',
        value: basketOverview.items?.map((item) => {
          return {
            id:
              useExternalIdentifier && item.externalIdentifier
                ? item.externalIdentifier
                : item.sku?.toString(),
            sku: item.sku?.toString(),
            name: item.name,
            price: item.price.amount.toString(),
            quantity: item.quantity.toString(),
          }
        }),
      })
    }

    if (basket && qubit?.enabled && window.uv?.['events']) {
      if (!qubitBasketItemEventsEmittedRef.current) {
        const alreadyEmittedEvents: any[] = window.uv?.['events'].filter(
          (event) => event?.meta.type === 'ecBasketItem',
        )

        if (alreadyEmittedEvents.length > 0) {
          basket.qubitBasketItems?.forEach((product) => {
            const eventEmitted = alreadyEmittedEvents.find(
              (item) =>
                item.product.productId === product.product?.productId &&
                item.product.sku === product.product?.sku,
            )
            !eventEmitted && window.uv?.emit('ecBasketItem', product)
          })
        } else {
          basket.qubitBasketItems?.forEach((product) => {
            window.uv?.emit('ecBasketItem', product)
          })
        }

        qubitBasketItemEventsEmittedRef.current = true
      }

      const basketSummaryEvents: any[] = window.uv?.['events'].filter(
        (event) => event.meta.type === 'ecBasketSummary',
      )

      if (basketSummaryEvents?.length > 0) {
        const lastEvent = basketSummaryEvents[basketSummaryEvents.length - 1]

        if (
          lastEvent.basket.quantity === basketOverview.totalQuantity &&
          lastEvent.basket.total.value ===
            parseFloat(basket.chargePrice.amount) &&
          lastEvent.basket.total.currency === basket.chargePrice.currency
        ) {
          return
        }
      }

      if (basketOverview.totalQuantity > 0) {
        window.uv?.emit('ecBasketSummary', {
          basket: {
            total: {
              value: parseFloat(basket.chargePrice.amount),
              currency: basket.chargePrice.currency,
            },
            quantity: basketOverview.totalQuantity,
          },
        })
      }
    }
  }, [
    basket,
    basketOverview.items,
    basketOverview.totalQuantity,
    qubit?.enabled,
    useExternalIdentifier,
  ])

  return (
    <Component
      basket={{
        basketData: basketOverview,
        links: miniBasketLinks,
        i18nText: miniBasketI18nText,
      }}
    />
  )
}

const MiniBasketRendererComponent = withHydrationOnDemand({
  on: [['idle']],
})(MiniBasketRenderer)

export const withBasket: withBasketType = (props) => {
  return () => (
    <MiniBasketRendererComponent
      {...props}
      wrapperProps={{
        style: { display: 'flex', justifyContent: 'center' },
      }}
    />
  )
}
