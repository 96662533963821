import * as React from 'react'
import loadable from '@loadable/component'

import { KeyboardKeys } from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

const ChevronDown = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronDown'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { Dropdown, DropdownButton, OptionsContainer } from '../styles'

import {
  BlankDropdownButtonContent,
  ButtonWrapper,
  Quantifier,
  StyledButton,
} from './styles'

export interface BlankDropdownProps {
  children: JSX.Element
  disabledClearAll: boolean
  optionsLength: number
  buttonText: string
  className?: string
  disabled?: boolean
  zIndex?: number
  width?: string
  iconOverride?: {
    svgPath: string
    viewBox: string
    width: string
    height: string
  }
  dropdownWidth?: string
  useDefaultDropdownWidth?: boolean
  onCustomButtonClick?: () => void
  quantifier?: number
  i18nButtonText?: {
    customButton?: string
    close?: string
  }
  ariaHasPopup?:
    | 'dialog'
    | 'menu'
    | 'listbox'
    | 'true'
    | 'false'
    | 'grid'
    | 'tree'
  hasBrandSearch?: boolean
  badgePosition?: HorizontalAlignment
  focusIndex?: number
  setFocusIndex?: React.Dispatch<React.SetStateAction<number>>
  selectDropdownOption?: () => void
  facetName?: string
}
interface DropdownOptionButtonsProps {
  i18nButtonText?: {
    customButton?: string
    close?: string
  }
  onCustomButtonClick?: () => void
  disabledClearAll: boolean
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>
  setFocusIndex?: React.Dispatch<React.SetStateAction<number>>
  dropDownRef?: React.RefObject<HTMLButtonElement>
}

const handleTabKeyOnCustomButton = (
  shiftKey: boolean,
  props: DropdownOptionButtonsProps,
) => {
  if (shiftKey) {
    props.setFocusIndex?.(0)
    const newFocusElement = document.getElementById(`facet-option-0`)
    newFocusElement?.focus()
  } else {
    document.getElementById('blank-dropdown-close-button')?.focus()
  }
}

const customButtonOnKeyDown = (
  event: React.KeyboardEvent,
  props: DropdownOptionButtonsProps,
) => {
  switch (event.key) {
    case KeyboardKeys.Spacebar:
    case KeyboardKeys.Enter:
      event.preventDefault()
      props.onCustomButtonClick?.()
      props.setFocusIndex?.(0)
      break
    case KeyboardKeys.Tab:
      event.preventDefault()
      handleTabKeyOnCustomButton(event.shiftKey, props)
      break
    case KeyboardKeys.Esc:
    case KeyboardKeys.Escape:
      props.setFocusIndex?.(0)
      props.setShowDropdown?.(false)
      break
    default:
  }
}

const handleTabKeyOnCloseButton = (
  shiftKey: boolean,
  props: DropdownOptionButtonsProps,
) => {
  if (shiftKey) {
    if (props.disabledClearAll) {
      props.setFocusIndex?.(0)
      const newFocusElement = document.getElementById(`facet-option-0`)

      newFocusElement?.focus()
    } else {
      document.getElementById('blank-dropdown-clear-button')?.focus()
    }
  } else {
    props.setShowDropdown?.(false)
    props.dropDownRef?.current?.focus()
  }
}

const closeButtonOnKeyDown = (
  event: React.KeyboardEvent,
  props: DropdownOptionButtonsProps,
) => {
  switch (event.key) {
    case KeyboardKeys.Spacebar:
    case KeyboardKeys.Enter:
      event.preventDefault()
      props.setShowDropdown(false)
      props.dropDownRef?.current?.focus()
      break
    case KeyboardKeys.Tab:
      event.preventDefault()
      handleTabKeyOnCloseButton(event.shiftKey, props)
      break
    case KeyboardKeys.Esc:
    case KeyboardKeys.Escape:
      props.setFocusIndex?.(0)
      props.setShowDropdown(false)
      break
  }
}

const DropdownOptionButtons = (props: DropdownOptionButtonsProps) => {
  return (
    <React.Fragment>
      {(props.i18nButtonText?.close || props.i18nButtonText?.customButton) && (
        <ButtonWrapper>
          {props.i18nButtonText.customButton && (
            <StyledButton
              emphasis="low"
              id="blank-dropdown-clear-button"
              data-testid={`blank-dropdown-custom-button-${props.i18nButtonText.customButton}`}
              sizing="regular"
              onClick={() => props.onCustomButtonClick?.()}
              disabled={props.disabledClearAll}
              onKeyDown={(event: React.KeyboardEvent) => {
                customButtonOnKeyDown(event, props)
              }}
            >
              {props.i18nButtonText.customButton}
            </StyledButton>
          )}
          {props.i18nButtonText.close && (
            <StyledButton
              id="blank-dropdown-close-button"
              emphasis="low"
              sizing="regular"
              onClick={() => {
                props.setShowDropdown(false)
                props.setFocusIndex?.(0)
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                closeButtonOnKeyDown(event, props)
              }}
              alignRight
            >
              {props.i18nButtonText.close}
            </StyledButton>
          )}
        </ButtonWrapper>
      )}
    </React.Fragment>
  )
}

export const BlankDropdown = (props: BlankDropdownProps) => {
  const [showDropdown, setShowDropdown] = React.useState(false)

  const containerRef = React.useRef<HTMLDivElement>(null)
  const dropDownRef = React.useRef<HTMLButtonElement>(null)

  const { setFocusIndex } = props

  const handleKeyPress = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case KeyboardKeys.Enter:
        event.preventDefault()
        event.stopPropagation()
        setShowDropdown((prevState) => !prevState)
        break
      case KeyboardKeys.Esc:
      case KeyboardKeys.Escape:
        setShowDropdown(false)
        break
      default:
    }
  }

  const handleArrowDown = () => {
    if (
      (props.focusIndex || props.focusIndex === 0) &&
      props.optionsLength > props.focusIndex + 1
    ) {
      setFocusIndex?.((focusIndex) => focusIndex + 1)
      const newFocusElement = document.getElementById(
        `facet-option-${props.focusIndex + 1}`,
      )
      const elementBelow = document.getElementById(
        `facet-option-${props.focusIndex + 2}`,
      )
      newFocusElement?.focus()
      elementBelow?.scrollIntoView({ block: 'nearest' })
    }
  }

  const handleArrowUp = () => {
    if (props.focusIndex) {
      setFocusIndex?.((focusIndex) => focusIndex - 1)
      const newFocusElement = document.getElementById(
        `facet-option-${props.focusIndex - 1}`,
      )
      const elementAbove = document.getElementById(
        `facet-option-${props.focusIndex - 2}`,
      )
      newFocusElement?.focus()
      elementAbove?.scrollIntoView({ block: 'nearest' })
    }
  }

  const handleTab = (shiftKey: boolean) => {
    if (shiftKey) {
      dropDownRef?.current?.focus()
      props.setFocusIndex?.(0)
      setShowDropdown(false)
    } else {
      props.disabledClearAll
        ? document.getElementById('blank-dropdown-close-button')?.focus()
        : document.getElementById('blank-dropdown-clear-button')?.focus()
    }
  }

  const handleOptionContainerKeyPress = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case KeyboardKeys.Esc:
      case KeyboardKeys.Escape:
        setFocusIndex?.(0)
        setShowDropdown(false)
        break
      case KeyboardKeys.ArrowDown:
        handleArrowDown()
        event.preventDefault()
        break
      case KeyboardKeys.ArrowUp:
        handleArrowUp()
        event.preventDefault()
        break
      case KeyboardKeys.Tab:
        event.preventDefault()
        handleTab(event.shiftKey)
        break
      case KeyboardKeys.Enter:
      case KeyboardKeys.Spacebar:
        event.preventDefault()
        props.selectDropdownOption?.()
        break
      default:
    }
  }

  React.useEffect(() => {
    if (showDropdown) {
      const firstElement = document.getElementById(
        `facet-option-${props.focusIndex}`,
      )
      firstElement?.focus()
    }
  }, [showDropdown, props.focusIndex])

  React.useEffect(() => {
    if (!showDropdown) {
      setFocusIndex?.(0)
    }
  }, [showDropdown, setFocusIndex])

  const disableButton = props.disabled

  return (
    <div className={props.className}>
      <Dropdown
        {...(props.width && { width: props.width })}
        onBlur={(event: React.FocusEvent) => {
          if (!event.currentTarget.contains(event.relatedTarget as Node)) {
            setShowDropdown(false)
          }
        }}
      >
        <DropdownButton
          data-testid="dropdown-button"
          aria-expanded={showDropdown}
          aria-haspopup={props.ariaHasPopup}
          aria-label={props.buttonText}
          dropdownOpen={showDropdown}
          ref={dropDownRef}
          onClick={() => {
            !disableButton && setShowDropdown((prevState) => !prevState)
          }}
          onKeyDown={(event: React.KeyboardEvent) => {
            !disableButton && handleKeyPress(event)
          }}
        >
          <span>{props.buttonText}</span>
          <BlankDropdownButtonContent quantifier={props.quantifier}>
            {props.quantifier && (
              <Quantifier badgePosition={props.badgePosition}>
                {props.quantifier.toString()}
              </Quantifier>
            )}
            {!disableButton && props.iconOverride?.svgPath ? (
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={props.iconOverride.viewBox}
                width={props.iconOverride.width}
                height={props.iconOverride.height}
              >
                <path d={props.iconOverride.svgPath} fillRule="evenodd" />
              </SvgIcon>
            ) : (
              <ChevronDown />
            )}
          </BlankDropdownButtonContent>
        </DropdownButton>
        {showDropdown && (
          <OptionsContainer
            ref={containerRef}
            data-testid="options-container"
            tabIndex={-1}
            zIndex={props.zIndex}
            dropdownWidth={props.dropdownWidth}
            useDefaultWidth={props.useDefaultDropdownWidth}
            stickyPosition
            removePadding
            onKeyDown={(event: React.KeyboardEvent) => {
              if (
                !(
                  props.facetName?.includes('price') ||
                  props.facetName?.includes('brand_content')
                ) &&
                document.activeElement?.id.includes('facet-option')
              ) {
                handleOptionContainerKeyPress(event)
              }
            }}
          >
            {props.children}
            <DropdownOptionButtons
              i18nButtonText={props.i18nButtonText}
              onCustomButtonClick={props.onCustomButtonClick}
              disabledClearAll={props.disabledClearAll}
              setShowDropdown={setShowDropdown}
              setFocusIndex={setFocusIndex}
              dropDownRef={dropDownRef}
            />
          </OptionsContainer>
        )}
      </Dropdown>
    </div>
  )
}
