import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { ImageThumbnailsProps } from '../types'

import {
  ProductImageThumbnailsWrapper,
  ProductImageWrapperListItem,
  StyledButton,
  StyledImage,
  StyledThumbnailList,
} from './styles'

export const ImageThumbnails = (props: ImageThumbnailsProps) => {
  const productThumbnails = props.urls.map((url, index) => {
    if (!url.url) {
      return null
    }

    // TODO: REBUILD-4597: change to use replaceAll after upgrade to node version > 15
    const escapedThumbnailButtonAriaLabel = props.thumbnailButtonAriaLabel.replace(
      /%(?![s])/g,
      '%%',
    )

    const ariaLabel = vsprintf(escapedThumbnailButtonAriaLabel, [
      `${index + 1}`,
    ])

    const videoThumbnailSVG = `
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="circle-shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow dx="0" dy="4" stdDeviation="6" flood-color="#000000" flood-opacity="0.4" />
          </filter>
        </defs>

        <rect width="100" height="100" fill="#ffffff" />
        <circle cx="50" cy="50" r="25" fill="#222222" filter="url(#circle-shadow)" />
        <path d="M46 40L58 50L46 60Z" fill="#ffffff" />
      </svg>
    `

    const videoThumbnailPlaceholder = `data:image/svg+xml,${encodeURIComponent(
      videoThumbnailSVG,
    )}`

    return (
      <ProductImageWrapperListItem
        key={url.url}
        direction={props.direction}
        fullScreen={props.fullScreen}
        size={props.size}
        aspectRatio={props.aspectRatio}
      >
        <StyledButton
          aria-label={ariaLabel}
          selected={index === props.selectedImageIndex}
          data-testid={`image-thumbnails-button-${index}`}
          onClick={() => {
            props.onSelect?.(index)
          }}
        >
          {url.isVideoUrl ? (
            <StyledImage
              src={[
                {
                  url: videoThumbnailPlaceholder,
                  width: 70,
                  imageSize: 70,
                },
              ]}
              alt={'Product Video Thumbnail'}
              width="70"
              fullScreen={props.fullScreen}
              aspectRatio={props.aspectRatio}
              isAmp={false}
            />
          ) : (
            <StyledImage
              src={[
                {
                  url: url.url,
                  width: 70,
                  imageSize: 70,
                },
              ]}
              alt={''}
              width="70"
              fullScreen={props.fullScreen}
              aspectRatio={props.aspectRatio}
              isAmp={false}
            />
          )}
        </StyledButton>
      </ProductImageWrapperListItem>
    )
  })

  return (
    <ProductImageThumbnailsWrapper
      direction={props.direction}
      fullScreen={props.fullScreen}
    >
      <StyledThumbnailList
        direction={props.direction}
        fullScreen={props.fullScreen}
      >
        {productThumbnails}
      </StyledThumbnailList>
    </ProductImageThumbnailsWrapper>
  )
}
