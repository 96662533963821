import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { RecommendationTrackingContext } from '@thg-commerce/enterprise-metrics/src/backend_event/types/tracking'
import { Modal, ModalProps } from '@thg-commerce/gravity-elements'
import {
  AddedToBasketModalInterface,
  LoyaltyPoints,
  ProductBlock,
} from '@thg-commerce/gravity-patterns'

import { AddedToBasketModalSkeleton } from './AddedToBasketModalSkeleton'
import {
  AddedToBasketModalButton,
  BasketInformation,
  ButtonContainer,
  ItemInformation,
  ModalTitle,
  PriceLabel,
  RecommendationsContainer,
  RecommendationsItem,
  RecommendationsTitle,
  SubtotalContainer,
  SubtotalLabel,
} from './styles'
import { ModalProduct, Recommendation } from './types'

export interface AddedToBasketModalProps {
  product: ModalProduct
  removeFocusTrap: boolean
  loading: boolean
  addToBasketModalTheme: AddedToBasketModalInterface
  modal: Omit<ModalProps, 'children' | 'onClose' | 'open'>
  basket: {
    totalValue: string
    quantity: number
    link: string
    earnableLoyaltyPoints: number | null
  }
  i18nText: {
    continueToBasketButtonText: string
    continueShoppingButtonText: string
    modalTitle: string
    basketCountLabel: string
    singularBasketSizeText: string
    pluralBasketSizeText: string
    recommendedProductsTitle: string
    loyaltyText: string
    saveTextLabel: string
    savingTextLabel: string
  }
  rendering: {
    open: boolean
    onClose: () => void
  }
  userInteractionCallbacks?: {
    closeClicked?: () => void
    continueShoppingClicked?: () => void
    checkoutClicked?: () => void
  }
  recommendations?: Recommendation[]
  enableSavePrice?: boolean
}

const Recommendations = (props: {
  recommendedProductsTitle: string
  recommendations?: Recommendation[]
  addToBasketModalTheme: AddedToBasketModalInterface
}) => {
  if (!props.recommendations?.length) {
    return null
  }

  return (
    <React.Fragment>
      <RecommendationsTitle>
        {props.recommendedProductsTitle || ''}
      </RecommendationsTitle>
      <RecommendationsContainer
        showMobileRecs={
          props.addToBasketModalTheme.recommendations?.mobile?.show
        }
      >
        {props.recommendations.map((recommendation, index) => {
          return (
            <RecommendationsItem
              colStart={[
                Math.floor(index % 2) + 1,
                Math.floor(index % 2) + 1,
                Math.floor(index % 4) + 1,
                Math.floor(index % 4) + 1,
              ]}
              rowStart={[
                Math.floor(index / 2) + 1,
                Math.floor(index / 2) + 1,
                Math.floor(index / 4) + 1,
                Math.floor(index / 4) + 1,
              ]}
              data-testid="recommendation-block"
              key={index}
            >
              <ProductBlock
                {...recommendation}
                image={{
                  ...recommendation.image,
                  width: ['100%'],
                }}
                url={`${recommendation.url}?rctxt=${RecommendationTrackingContext.POST_ADD_TO_BASKET}`}
                customStyling={props.addToBasketModalTheme.content}
              />
            </RecommendationsItem>
          )
        })}
      </RecommendationsContainer>
    </React.Fragment>
  )
}

export const AddedToBasketModal = ({
  product,
  basket,
  i18nText,
  recommendations,
  rendering,
  modal,
  userInteractionCallbacks,
  loading,
  removeFocusTrap,
  addToBasketModalTheme,
  enableSavePrice,
}: AddedToBasketModalProps) => {
  const basketSizeI18nText =
    basket.quantity === 1
      ? i18nText.singularBasketSizeText
      : i18nText.pluralBasketSizeText

  const handleOnClick = (callback?: () => void) =>
    typeof callback === 'function' && callback()

  return (
    <Modal
      {...modal}
      open={rendering.open}
      onClose={() => {
        rendering.onClose()
        handleOnClick(userInteractionCallbacks?.closeClicked)
      }}
      data-testid="added-to-basket-modal"
      removeFocusTrap={removeFocusTrap}
      contentPadding={addToBasketModalTheme?.padding}
      gridItemHeight={addToBasketModalTheme?.height}
      verticalAlignment={addToBasketModalTheme?.verticalAlignment}
      isAutoHeightMobile={addToBasketModalTheme?.autoHeightMobile}
    >
      {loading ? (
        <AddedToBasketModalSkeleton />
      ) : (
        <React.Fragment>
          <ModalTitle data-testid="add-to-basket-modal-title">
            {i18nText.modalTitle || 'Added to your basket'}
          </ModalTitle>
          <ProductBlock
            {...product}
            image={{
              ...product.image,
              width: [93, 117, 174, 174],
            }}
            directions={['row']}
            attributes={{
              justifyContent: 'start',
            }}
            customStyling={{
              ...addToBasketModalTheme.content,
              containerHeight: 'auto',
            }}
            i18nText={i18nText}
            enableSavePrice={enableSavePrice}
          />
          <BasketInformation>
            <SubtotalContainer>
              <SubtotalLabel>
                {i18nText.basketCountLabel || 'Subtotal:'}
              </SubtotalLabel>
              <PriceLabel data-testid="add-to-basket-price-label">
                {basket.totalValue}
              </PriceLabel>
            </SubtotalContainer>
            <ItemInformation data-testid="add-to-basket-item-information">
              {`(${
                basketSizeI18nText
                  ? vsprintf(basketSizeI18nText, [basket.quantity])
                  : vsprintf('(%s items in your basket)', [
                      basket.quantity || 0,
                    ])
              })`}
            </ItemInformation>
            {basket.earnableLoyaltyPoints && (
              <LoyaltyPoints pointsText={i18nText.loyaltyText} />
            )}
          </BasketInformation>
          <ButtonContainer>
            <AddedToBasketModalButton
              data-testid="add-to-basket-view-basket"
              emphasis="high"
              href={basket.link}
              renderedAs="a"
              onClick={() => {
                handleOnClick(userInteractionCallbacks?.checkoutClicked)
                rendering.onClose()
              }}
            >
              {i18nText.continueToBasketButtonText || 'View Basket'}
            </AddedToBasketModalButton>
            <AddedToBasketModalButton
              data-testid="add-to-basket-continue-shopping"
              emphasis="medium"
              onClick={() => {
                rendering.onClose()
                handleOnClick(userInteractionCallbacks?.continueShoppingClicked)
              }}
              type="button"
            >
              {i18nText.continueShoppingButtonText || 'Continue Shopping'}
            </AddedToBasketModalButton>
          </ButtonContainer>
          <Recommendations
            recommendedProductsTitle={i18nText.recommendedProductsTitle}
            recommendations={recommendations}
            addToBasketModalTheme={addToBasketModalTheme}
          />
        </React.Fragment>
      )}
    </Modal>
  )
}
