import * as React from 'react'

export const SvgHangerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={26} height={26} {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(0 .263)">
      <path
        fill="#FFFFFF"
        d="M24.25 17.1562C25.0312 17.625 25.5 18.4844 25.5 19.3828C25.5 20.8281 24.2891 22 22.8438 22H3.11719C1.67188 22 0.5 20.8281 0.5 19.3828C0.5 18.4453 0.929688 17.625 1.67188 17.1562L14.3672 8.67969C15.0703 8.21094 15.5 7.42969 15.5 6.60938V5.75C15.5 4.38281 14.3672 3.25 13 3.25C11.5938 3.25 10.5 4.38281 10.5 5.75C10.5 6.10156 10.1875 6.375 9.875 6.375C9.52344 6.375 9.25 6.10156 9.25 5.75C9.25 3.71875 10.9297 2 13 2C15.0312 2 16.75 3.71875 16.75 5.75V6.60938C16.75 7.85938 16.125 9.03125 15.0703 9.73438L13.8203 10.5547L24.25 17.1562ZM22.8438 20.75C23.625 20.75 24.25 20.125 24.25 19.3828C24.25 18.9141 23.9766 18.4453 23.5859 18.2109L12.6875 11.2969L2.33594 18.2109C1.94531 18.4453 1.75 18.875 1.75 19.3438C1.75 20.125 2.33594 20.75 3.11719 20.75H22.8438Z"
      />
    </g>
  </svg>
)
export default SvgHangerIcon
