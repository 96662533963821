import * as React from 'react'

const SvgWhiteOutlineYoutube = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h18a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4H4Zm6 13.673 8.32-4.834L10 8.005v9.668Z"
      fill="#fff"
    />
  </svg>
)

export default SvgWhiteOutlineYoutube
