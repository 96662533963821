import * as React from 'react'
export const SvgWhiteOutlineInstagram = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={26}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={26}
      height={26}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .052h25.992v25.94H0V.052Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.996.052c-3.53 0-3.972.014-5.358.078C6.254.193 5.31.412 4.483.732a6.371 6.371 0 0 0-2.302 1.496A6.356 6.356 0 0 0 .682 4.526C.361 5.35.142 6.294.078 7.674.015 9.057 0 9.5 0 13.022c0 3.522.015 3.964.078 5.347.063 1.38.283 2.324.604 3.149a6.356 6.356 0 0 0 1.5 2.297 6.373 6.373 0 0 0 2.301 1.496c.827.32 1.771.54 3.155.603 1.386.063 1.828.078 5.358.078 3.53 0 3.972-.015 5.358-.078 1.383-.063 2.328-.283 3.155-.603a6.372 6.372 0 0 0 2.302-1.496 6.355 6.355 0 0 0 1.498-2.297c.322-.825.541-1.768.605-3.149.063-1.383.078-1.825.078-5.347 0-3.523-.015-3.965-.078-5.348-.064-1.38-.283-2.323-.605-3.148a6.355 6.355 0 0 0-1.498-2.298A6.37 6.37 0 0 0 21.509.732c-.827-.32-1.772-.54-3.155-.602-1.386-.064-1.829-.078-5.358-.078Zm0 2.336c3.47 0 3.88.014 5.251.076 1.267.058 1.956.27 2.413.447.607.235 1.04.516 1.495.97.454.454.736.886.972 1.491.178.457.39 1.144.447 2.409.063 1.367.076 1.778.076 5.24 0 3.464-.013 3.874-.076 5.242-.057 1.264-.27 1.951-.447 2.408a4.019 4.019 0 0 1-.972 1.491 4.028 4.028 0 0 1-1.495.97c-.457.178-1.146.39-2.413.447-1.37.063-1.78.076-5.251.076-3.47 0-3.881-.013-5.252-.076-1.267-.057-1.955-.269-2.413-.446a4.027 4.027 0 0 1-1.494-.97 4.018 4.018 0 0 1-.972-1.492c-.178-.457-.39-1.144-.448-2.408-.062-1.368-.075-1.778-.075-5.241 0-3.463.013-3.874.075-5.241.058-1.265.27-1.952.448-2.409a4.018 4.018 0 0 1 .972-1.491 4.027 4.027 0 0 1 1.494-.97c.458-.178 1.146-.39 2.413-.447 1.37-.062 1.782-.076 5.252-.076Z"
        fill="#fff"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.996 17.345a4.328 4.328 0 0 1-4.332-4.323 4.328 4.328 0 0 1 4.332-4.324 4.328 4.328 0 0 1 4.332 4.324 4.328 4.328 0 0 1-4.332 4.323Zm0-10.984c-3.686 0-6.674 2.982-6.674 6.66 0 3.68 2.988 6.661 6.674 6.661 3.686 0 6.673-2.982 6.673-6.66 0-3.679-2.987-6.66-6.673-6.66ZM21.493 6.098c0 .86-.699 1.557-1.56 1.557-.861 0-1.56-.697-1.56-1.557s.699-1.556 1.56-1.556c.861 0 1.56.697 1.56 1.556Z"
      fill="#fff"
    />
  </svg>
)
export default SvgWhiteOutlineInstagram
