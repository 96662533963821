import { Property } from 'csstype'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { css, mq, spacing, Text } from '@thg-commerce/gravity-theme'

export const StyledEmptyNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) =>
    props.theme.patterns.header.navigation.backgroundColor.inactive ||
    props.theme.colors.palette.brand.base};
  height: ${spacing(5)};
`

export const StyledNavigation = styled.nav<{ fullWidthSeparator?: boolean }>`
  display: none;
  flex-direction: column;
  width: 100%;
  position: relative;
  ${(props) =>
    props.fullWidthSeparator
      ? `border: none;`
      : `border-bottom: solid ${props.theme.patterns.header.navigation.bottomSeparator.color} ${props.theme.patterns.header.navigation.bottomSeparator.width};`}
  background: ${(props) =>
    props.theme.patterns.header.navigation.backgroundColor.inactive ||
    props.theme.colors.palette.brand.base};

  &::before {
    content: '';
    width: 100%;
    ${(props) => props.fullWidthSeparator && 'position: absolute;'}
    border-bottom: solid ${(props) =>
      `${props.theme.patterns.header.bottomSeparator.color} ${props.theme.patterns.header.bottomSeparator.width}`};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: flex;
  }
`

export const NavigationWrapper = styled.div<{
  truncateNavigationItems?: boolean
  isOpen?: boolean
  fullWidthSeparator?: boolean
  enableThreeTierNav?: boolean
  headerWidth?: number
}>`
  width: ${(props) => (props.headerWidth ? `${props.headerWidth}px` : '100%')};
  display: flex;
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    position: ${(props) =>
      props.enableThreeTierNav ? 'absolute' : 'relative'};
    overflow: ${(props) =>
      props.truncateNavigationItems && !props.isOpen ? 'hidden' : 'visible'};
    flex-wrap: ${(props) =>
      props.truncateNavigationItems ? 'wrap' : 'nowrap'};
    row-gap: ${(props) => props.theme.patterns.header.navigation.height};
    height: ${(props) =>
      props.enableThreeTierNav
        ? props.theme.patterns.header.navigation.threeTierNav.secondTier.height
        : props.theme.patterns.header.navigation.height};
    justify-content: ${(props) =>
      props.theme.patterns.header.navigation.alignItems};
    max-width: ${(props) => props.theme.site.siteWidth};
    margin: 0 auto;
    padding: 0 ${spacing(2)};
    ${(props) =>
      props.fullWidthSeparator &&
      !props.enableThreeTierNav &&
      `border-bottom: ${props.theme.patterns.header.navigation.borderBottom};`}
    ${(props) =>
      props.enableThreeTierNav &&
      `
        left: -${spacing(25)};
        top: ${props.theme.patterns.header.navigation.height};
      `};
  }
`

export const ContentPanelWrapper = styled.div<{
  isOpen: boolean
  positionTopOffset?: string
  type: string
  enableThreeTierNav?: boolean
}>`
  position: absolute;
  top: ${(props) =>
    `${
      props.enableThreeTierNav
        ? props.theme.patterns.header.navigation.threeTierNav.secondTier.height
        : props.positionTopOffset
    }`};
  left: 0;
  overflow: hidden;
  width: 100%;
  max-width: ${(props) => `${props.theme.site.siteWidth}`};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
`

export const TabPanelWrapper = styled.div`
  position: absolute;
  left: ${spacing(2)};
  right: ${spacing(2)};
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: calc(100% - ${spacing(4)});
  height: 100vh;
  min-height: 100%;
`

const dynamicStyles = css<{
  offset: number
  width: string
}>`
  width: ${(props) => props.width};
  display: block;
  ${(props) =>
    props.offset === 0
      ? `margin: 0 16px`
      : `margin: 0 16px 0 ${props.offset}px`};
`

export const StyledTabPanel = styled.div<{
  isVariableWidth: boolean | undefined
  width: string
  offset: number
  type: string
  navHeaderHeight?: number
}>`
  background-color: ${(props) =>
    props.type === 'TEXT_CARD' ||
    props.type === 'IMAGE_CARD' ||
    props.type === 'THUMBNAIL_CARD'
      ? props.theme.colors.palette.greys.lighter
      : props.theme.colors.palette.greys.white};
  margin: ${(props) =>
      props.theme.patterns.header.navigation.tabPanelVerticalMargin}
    16px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    max-width: ${(props) => props.theme.grid.maxWidth}px;
    overflow: auto;
    height: 100%;
    max-height: ${(props) =>
      props.navHeaderHeight
        ? `calc(100vh - ${props.navHeaderHeight}px)`
        : '100%'};
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.palette.greys.grey};
    }
  }

  ${(props) => props.isVariableWidth && dynamicStyles}
`

export const NavItemContainer = styled.div<{ enableThreeTierNav?: boolean }>`
  ${Text('bodyText', 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  height: ${(props) =>
    props.enableThreeTierNav
      ? props.theme.patterns.header.navigation.threeTierNav.secondTier.height
      : props.theme.patterns.header.navigation.height};
  border-left: ${(props) =>
    `${props.theme.patterns.header.navigation.verticalSeparator.width} solid ${props.theme.patterns.header.navigation.verticalSeparator.color}`};
`

export const A11yButton = styled.button<{
  hasOpenSubmenu: boolean
}>`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  right: 0;
  bottom: 0;

  svg {
    width: 14px;
    height: 14px;
  }

  &:focus {
    width: 14px;
    height: 14px;
    margin: 0;
    clip: auto;
    outline: ${(props) =>
      props.theme.patterns.header.navigation.link?.focus.outline ||
      `2px solid ${
        props.theme.patterns.header.navigation.link?.focus.outlineColor ||
        props.theme.colors.palette.brand.base
      }`};
    outline-offset: -4px;
    ${(props) =>
      `background-color: ${
        props.theme.patterns.header.navigation.link.active.backgroundColor ||
        props.theme.patterns.header.navigation.backgroundColor.active ||
        props.theme.colors.palette.greys.lighter
      }`};
    ${(props) =>
      props.hasOpenSubmenu && 'transform: rotate(180deg); overflow: visible;'}
  }
`

export const StyledLink = styled.a<{
  isActive: boolean
  enableThreeTierNav?: boolean
  firstTier?: boolean
  promotionBackgroundColour?: Property.Color
  promotionTextColour?: Property.Color
  promotionTextStyle?: string
}>`
  ${(props) =>
    props.enableThreeTierNav && !props.firstTier
      ? Text(
          props.theme.patterns.header.navigation.threeTierNav.secondTier.link
            .entry,
          props.theme.patterns.header.navigation.threeTierNav.secondTier.link
            .style,
        )
      : Text(
          props.theme.patterns.header.navigation.font.inactive.entry,
          props.promotionTextStyle
            ? props.promotionTextStyle
            : props.theme.patterns.header.navigation.font.inactive.style,
        )};
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.font.inactive.transform};
  align-items: center;
  justify-content: center;

  max-width: ${(props) => props.theme.patterns.header.navigation.itemWidth.max};
  min-width: ${(props) => props.theme.patterns.header.navigation.itemWidth.min};
  padding: ${(props) =>
    `${spacing(1)} ${
      props.theme.patterns.header.navigation.breakLine === true
        ? spacing(1)
        : spacing(
            props.theme.patterns.header.navigation.itemHorizontalSpacing || 2,
          )
    }`};
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.font.inactive.textDecoration ||
    'none'};
  transition: background-color 0.3s, color 0.3s;
  white-space: ${(props) =>
    props.theme.patterns.header.navigation.breakLine
      ? 'break-space'
      : 'nowrap'};
  ${(props) =>
    props.theme.patterns.header.navigation.breakLine && 'text-align: center;'}
  vertical-align: middle;
  display: flex;
  outline: 0;
  ${(props) => props.enableThreeTierNav && 'cursor: pointer;'};

  ${(props) =>
    props.promotionBackgroundColour &&
    `background-color: ${props.promotionBackgroundColour};`}

  color: ${(props) =>
    props.promotionTextColour ||
    props.theme.patterns.header.navigation.font.inactive.textColor};

  ${(props) =>
    props.isActive &&
    `
      color: ${
        props.theme.patterns.header.navigation.font.active.textColor ||
        props.theme.colors.palette.brand.base
      };
      background-color: ${
        props.theme.patterns.header.navigation.backgroundColor.active ||
        props.theme.colors.palette.greys.lighter
      };
    `}

  ${(props) =>
    props.isActive &&
    props.promotionBackgroundColour &&
    `background-color: ${props.promotionBackgroundColour};`}

  ${(props) =>
    props.isActive &&
    props.promotionTextColour &&
    `color: ${props.promotionTextColour};`}

  ${(props) =>
    props.firstTier &&
    `
      border-top: ${props.theme.patterns.header.navigation.link?.border.top.width}
        ${props.theme.patterns.header.navigation.link?.border.top.style}
        ${props.theme.patterns.header.navigation.link?.border.top.color};
    `}

  ${(props) =>
    props.isActive &&
    props.firstTier &&
    `
      border-top: ${props.theme.patterns.header.navigation.link?.active.border.top.width}
        ${props.theme.patterns.header.navigation.link?.active.border.top.style}
        ${props.theme.patterns.header.navigation.link?.active.border.top.color};
    `}

  &:focus,
  &:hover {
    ${(props) =>
      props.enableThreeTierNav && !props.firstTier
        ? Text(
            props.theme.patterns.header.navigation.threeTierNav.secondTier.link
              .entry,
            props.theme.patterns.header.navigation.threeTierNav.secondTier.link
              .style,
          )
        : Text(
            props.theme.patterns.header.navigation.font.active.entry,
            props.promotionTextStyle ||
              props.theme.patterns.header.navigation.font.active.style,
          )};
    text-transform: ${(props) =>
      props.theme.patterns.header.navigation.font.active.transform};
    text-decoration: ${(props) =>
      props.theme.patterns.header.navigation.font.active.textDecoration ||
      'none'};
    align-items: center;
    color: ${(props) =>
      props.theme.patterns.header.navigation.font.active.textColor ||
      props.theme.colors.palette.brand.base};

    background-color: ${(props) =>
      props.enableThreeTierNav && !props.firstTier
        ? props.theme.colors.palette.greys.white
        : props.theme.patterns.header.navigation.backgroundColor.active ||
          props.theme.colors.palette.greys.lighter};
    cursor: pointer;
    ${(props) =>
      props.promotionBackgroundColour &&
      `background-color: ${props.promotionBackgroundColour};`}

    ${(props) =>
      props.promotionTextColour && `color: ${props.promotionTextColour};`}
  }

  &:active {
    outline: ${(props) =>
      props.theme.patterns.header.navigation.link?.active.outline ||
      `2px solid ${props.theme.colors.palette.brand.base}`};
    outline-offset: -4px;
    ${(props) =>
      props.theme.patterns.header.navigation.link?.active.backgroundColor &&
      `background-color: ${props.theme.patterns.header.navigation.link.active.backgroundColor}`};
  }

  &:focus {
    outline: ${(props) =>
      props.theme.patterns.header.navigation.link?.focus.outline ||
      `2px solid ${
        props.theme.patterns.header.navigation.link?.focus.outlineColor ||
        props.theme.colors.palette.brand.base
      }`};
    outline-offset: -4px;
    ${(props) =>
      props.theme.patterns.header.navigation.link?.focus.backgroundColor &&
      `background-color: ${props.theme.patterns.header.navigation.link.focus.backgroundColor}`};
  }
`
