import { Button } from '@thg-commerce/gravity-elements'
import { styled } from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  position: relative;
`

export const TranscriptTriggerButton = styled(Button)<{
  isVisible: boolean
  left?: string
  bottom?: string
}>`
  position: absolute;
  z-index: 1;
  bottom: ${(props) => (props.bottom ? props.bottom : '40px')};
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-color: transparent;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out,
    background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  pointerevents: auto;

  left: ${(props) => (props.left ? props.left : '130px')};

  @media (min-width: 600px) and (max-width: 630px) {
    left: 90px;
  }

  @media (min-width: 630px) and (max-width: 750px) {
    left: 110px;
  }

  @media (min-width: 900px) and (max-width: 1055px) {
    left: 75px;
  }

  &:hover {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: #242020;
  }
  &:focus {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: #242020;
    outline: 2px solid ${(props) => props.theme.colors.palette.greys.darker};
  }
  /* Firefox */
  @supports (-moz-appearance: none) {
    left: auto;
    right: 55px;
    bottom: 45px;
  }
  /* Safari */
  @supports (-webkit-backdrop-filter: none) {
    left: 15px;
    bottom: 45px;
  }
`
