import { css, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { ButtonState } from '@thg-commerce/gravity-elements/Button'
import { TextEntry, TextStyle } from '@thg-commerce/gravity-theme/'
import { IconStyling } from '@thg-commerce/gravity-theme/icons'

import { StartLiveChatCTABasketStyleInterface } from '../LiveChat/LiveChat'


export const Container = styled.div`
  margin: ${spacing(4)} 0;
  border: solid 1px ${(props) => props.theme.colors.palette.greys.light};
  width: 100%;
`

const PaddingAttribute = css`
  padding: ${spacing(2)} ${spacing(2)};
`

export const TopContainer = styled.div`
  ${PaddingAttribute};
  background-color: #eee;
  display: flex;
  flex-direction: row;
`

const liveChatButtonStateProperties = (state: ButtonState) => (props) => css<{
  startChatCTAStyle?: StartLiveChatCTABasketStyleInterface
}>`
  ${Text('bodyText', props.startChatCTAStyle[state].button.text.style)}
  background-color: ${props.startChatCTAStyle[state].button.backgroundColor};
  border: ${props.startChatCTAStyle[state].button.border.width} solid
    ${props.startChatCTAStyle[state].button.border.color};
  color: ${props.startChatCTAStyle[state].button.text.color};
  text-decoration: ${props.startChatCTAStyle[state].button.text.textDecoration};
  box-shadow: ${props.startChatCTAStyle[state].button.boxShadow.shadowX}
    ${props.startChatCTAStyle[state].button.boxShadow.shadowY}
    ${props.startChatCTAStyle[state].button.boxShadow.shadowBlur}
    ${props.startChatCTAStyle[state].button.boxShadow.shadowSpread}
    ${props.startChatCTAStyle[state].button.boxShadow.shadowColor};
`

const customStateStyle = css`
  ${liveChatButtonStateProperties(ButtonState.Default)}

  &:hover {
    ${liveChatButtonStateProperties(ButtonState.Hover)}
  }

  &:focus {
    ${liveChatButtonStateProperties(ButtonState.Focus)}
  }

  &:active {
    ${liveChatButtonStateProperties(ButtonState.Active)}
  }
`

export const StyledLiveChatButton = styled(Button)<{
  startChatCTAStyle?: StartLiveChatCTABasketStyleInterface
}>`
  > button {
    ${(props) => props.startChatCTAStyle && customStateStyle}
  }
`

export const BottomContainer = styled.div<{
  containerStyleOverride?: {
    border: string
    backgroundColor: string
    boxShadow: string
  }
}>`
  ${PaddingAttribute}

  > div {
    border: ${(props) =>
      props.containerStyleOverride && props.containerStyleOverride.border};
    box-shadow: ${(props) =>
      props.containerStyleOverride && props.containerStyleOverride.boxShadow};
    background-color: ${(props) =>
      props.containerStyleOverride &&
      props.containerStyleOverride.backgroundColor};
    &:focus,
    &:focus-within,
    &:hover,
    &:disabled {
      border: ${(props) =>
        props.containerStyleOverride && props.containerStyleOverride.border};
    }
  }
`

export const SubtitleLine = styled.p<{ liveChatTextEntry?: TextEntry }>`
  ${(props) =>
    Text(
      (props.liveChatTextEntry && props.liveChatTextEntry) || 'medium1',
      'alternate',
    )};

  margin-bottom: ${spacing(0.5)};
`

export const ActiveSearchText = styled.p`
  ${Text('bodyText', 'default')};

  margin-bottom: ${spacing(2)};
`

export const IconContainer = styled.div<{
  iconContainerStyleOverride?: IconStyling
}>`
  margin-right: ${spacing(2)};
  width: ${(props) =>
    props.iconContainerStyleOverride
      ? `${props.iconContainerStyleOverride.width}`
      : '48px'};
  height: ${(props) =>
    props.iconContainerStyleOverride
      ? `${props.iconContainerStyleOverride.height}`
      : '48px'};
  background-color: ${(props) =>
    props.iconContainerStyleOverride
      ? `${props.iconContainerStyleOverride.color}`
      : 'unset'};
  border-radius: 50%;
  overflow: hidden;
`

const additionalChatIconStyle = css<{
  chatIconStyleOverride?: {
    position?: string
    margin?: string
    top?: string
  }
}>`
  ${(props) =>
    props.chatIconStyleOverride &&
    `position: ${props.chatIconStyleOverride.position};
     margin: ${props.chatIconStyleOverride.margin};
     top: ${props.chatIconStyleOverride.top};`}
`
export const ChatIcon = styled.div<{
  chatIconStyleOverride?: {
    width?: string
    height?: string
    position?: string
    margin?: string
    top?: string
    backgroundSize?: string
    backgroundPosition?: string
  }
}>`
  width: ${(props) =>
    props.chatIconStyleOverride
      ? `${props.chatIconStyleOverride.width}`
      : '100%'};
  height: ${(props) =>
    props.chatIconStyleOverride
      ? `${props.chatIconStyleOverride.height}`
      : '100%'};
  background-size: ${(props) =>
    props.chatIconStyleOverride?.backgroundSize || 'contain'};
  background-position: ${(props) =>
    props.chatIconStyleOverride?.backgroundPosition || 'unset'};
  background-repeat: no-repeat;
  background-image: url(${(props) => props.theme.site.favicon});
  ${additionalChatIconStyle};
`

export const OnlineText = styled.p<{ onlineTextStyle?: TextStyle }>`
  ${(props) => Text('bodyText', props.onlineTextStyle || 'default')};
  color: #007831;
  margin-top: ${spacing(-0.55)};
  margin-left: ${spacing(0.5)};
`

export const OnlineTextContainer = styled.div`
  display: flex;
`

export const ChatTextContainer = styled.div`
  display: flex;
  align-items: center;
`
