import * as React from 'react'

import { Close, SvgIcon } from '@thg-commerce/gravity-icons/src'
import { Orientation } from '@thg-commerce/gravity-patterns/Header/types'
import { css, styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text, zIndex } from '@thg-commerce/gravity-theme'

import { DropdownOptions, HeaderZIndexLevel } from '../../types'

export interface HeaderDropdownTriggerProps {
  isMobile: boolean
  i18nAriaLabels: { trigger: string; close: string }
  isShowing: boolean
  toggle: () => void
  open: () => void
  icon: React.ReactElement
  href: string
  text: string
  tabIndex?: number
  dropdownType: DropdownOptions
  inSubmenu?: boolean
}

const TriggerWrapper = styled.div<{ inSubmenu?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.theme.patterns.header.headerSlots.orientation ===
      Orientation.HORIZONTAL && !props.inSubmenu
      ? 'row'
      : props.theme.patterns.header.headerSlots.orientation ===
          Orientation.VERTICAL && !props.inSubmenu
      ? 'column'
      : ''};
`

export const triggerSubmenuText = css`
  ${(props) =>
    Text(
      props.theme.patterns.header.subNavigation.desktop.hoverFont.entry,
      props.theme.patterns.header.subNavigation.desktop.hoverFont.style,
    )};
  text-transform: ${(props) =>
    props.theme.patterns.header.subNavigation.desktop.hoverFont.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.header.subNavigation.desktop.hoverFont.textDecoration};
  ${(props) =>
    props.theme.patterns.header.subNavigation.desktop.hoverFont.textColor &&
    `color: ${props.theme.patterns.header.subNavigation.desktop.hoverFont.textColor}`};
`

export const TriggerTextStyle = css<{ dropdownType: DropdownOptions }>`
  ${(props) =>
    props.dropdownType !== DropdownOptions.SEARCH &&
    Text(
      props.theme.patterns.header.dropdowns[props.dropdownType]?.font.trigger
        .entry,
      props.theme.patterns.header.dropdowns[props.dropdownType]?.font.trigger
        .style,
    )};
  text-transform: ${(props) =>
    props.dropdownType !== DropdownOptions.SEARCH &&
    props.theme.patterns.header.dropdowns[props.dropdownType]?.font.trigger
      .transform};
  text-decoration: ${(props) =>
    props.dropdownType !== DropdownOptions.SEARCH &&
    props.theme.patterns.header.dropdowns[props.dropdownType]?.font.trigger
      .textDecoration};
`

export const TriggerText = styled.div<{
  isShowing: boolean
  dropdownType: DropdownOptions
  inSubmenu?: boolean
}>`
  ${(props) => (props.inSubmenu ? triggerSubmenuText : TriggerTextStyle)}

  padding-left: ${spacing(1)};
  overflow-wrap: break-word;
  display: none;
  white-space: nowrap;
  color: ${(props) =>
    props.isShowing
      ? props.theme.colors.palette.greys.darker
      : props.theme.patterns.header.dropdowns[props.dropdownType]?.font.trigger
          .textColor};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      props.theme.patterns.header.dropdowns[props.dropdownType]?.hideText}
    display:  ${(props) =>
      props.theme.patterns.header.dropdowns[props.dropdownType]?.hideText
        ? 'none'
        : 'inline-block'};
  }
`

export const TriggerStyles = css<{
  isShowing?: boolean
  dropdownType: DropdownOptions
  inSubmenu?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  width: 100%;
  height: 48px;
  flex: 0;
  border: 2px solid transparent;

  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
    border: 2px solid ${(props) => props.theme.colors.palette.greys.lighter};
    color: ${(props) => props.theme.colors.palette.greys.darker};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: 100%;
    min-width: initial;
    padding: ${(props) =>
      `${
        props.theme.patterns.header.dropdowns[props.dropdownType]?.hideText
          ? spacing(1.25)
          : '0'
      } ${
        props.inSubmenu
          ? '16px'
          : props.theme.patterns.header.dropdowns[props.dropdownType]?.hideText
          ? spacing(1.25)
          : props.theme.patterns.header.mobileSearch
          ? spacing(1.25)
          : spacing(3)
      }`};
    text-decoration: none;
  }

  ${(props) =>
    props.isShowing &&
    `background-color: ${props.theme.colors.palette.greys.lighter}; 
    ${zIndex(HeaderZIndexLevel.Dropdown)};`}

  svg {
    fill: ${(props) =>
      props.dropdownType !== DropdownOptions.SEARCH &&
      props.theme.patterns.header.dropdowns[props.dropdownType]
        .mobileIconColor};

    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      fill: ${(props) =>
        props.isShowing
          ? props.theme.colors.palette.greys.darker
          : props.theme.patterns.header.dropdowns[props.dropdownType]
              ?.iconColor};
    }
  }

  &:hover {
    svg {
      fill: ${(props) =>
        props.dropdownType !== DropdownOptions.SEARCH &&
        props.theme.patterns.header.dropdowns[props.dropdownType]
          .hoverIconColor};
    }
  }

  &:focus {
    outline: ${(props) =>
      props.theme.patterns.header.dropdowns[props.dropdownType]?.focusOutline ||
      'none'};
    outline-offset: ${(props) =>
      props.theme.patterns.header.dropdowns[props.dropdownType]
        ?.outlineOffset || 'none'};
    border-color: ${(props) =>
      props.theme.patterns.header.dropdowns[props.dropdownType]?.focusOutline
        ? 'transparent'
        : (props.dropdownType !== DropdownOptions.SEARCH &&
            props.theme.patterns.header.dropdowns[props.dropdownType]
              .focusBorderColor) ||
          props.theme.colors.palette.brand.base};
    svg {
      fill: ${(props) => props.theme.colors.palette.greys.darker};
    }
  }

  &:hover,
  &:focus {
    svg {
      ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
        fill: ${(props) =>
          props.dropdownType !== DropdownOptions.SEARCH &&
          props.theme.patterns.header.dropdowns[props.dropdownType]
            .hoverIconColor};
      }
    }
  }
`

export const MobileDropdownTrigger = styled.button<{
  isShowing: boolean
  dropdownType: DropdownOptions
}>`
  ${TriggerStyles}

  &:hover {
    background-color: ${(props) =>
      props.theme.patterns.header.dropdowns.headerButton?.backgroundHoverColor
        ? props.theme.patterns.header.dropdowns.headerButton
            ?.backgroundHoverColor
        : ''};

    svg {
      fill: ${(props) =>
        props.theme.patterns.header.dropdowns.headerButton?.iconHoverColor
          ? props.theme.patterns.header.dropdowns.headerButton?.iconHoverColor
          : ''};
    }
  }
`

export const DesktopDropdownTrigger = styled.a<{
  isShowing: boolean
  dropdownType: DropdownOptions
  inSubmenu?: boolean
}>`
  ${TriggerStyles} {
    height: ${(props) =>
      props.inSubmenu
        ? props.theme.patterns.header.subNavigation.dropdowns.triggerHeight
        : props.theme.patterns.header.dropdowns.triggerHeight};
  }
`

const CloseWrapper = styled.div<{ dropdownType: DropdownOptions }>`
  ${(props) =>
    `
  svg {
    path {
      fill: ${
        props.dropdownType === DropdownOptions.SEARCH
          ? props.theme.patterns.header.search.mobileCloseIconColor
          : props.theme.patterns.header.dropdowns[props.dropdownType]
              .closeIconColor
      };
    }
  }`}
`

export const HeaderDropdownTrigger = (props: HeaderDropdownTriggerProps) => {
  const theme = useTheme()
  return props.isMobile ? (
    <MobileDropdownTrigger
      dropdownType={props.dropdownType}
      tabIndex={0}
      data-testid={`header-trigger-${props.isShowing ? 'open' : 'closed'}`}
      isShowing={props.isShowing}
      aria-label={
        props.isShowing
          ? props.i18nAriaLabels.close
          : props.i18nAriaLabels.trigger
      }
      onClick={() => props.toggle()}
    >
      {props.isShowing ? (
        <CloseWrapper dropdownType={props.dropdownType}>
          {theme.patterns.header.flyout.closeIcon.svgPath ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.patterns.header.flyout.closeIcon.viewBox}
              width={theme.patterns.header.flyout.closeIcon.width}
              height={theme.patterns.header.flyout.closeIcon.height}
            >
              <path
                d={theme.patterns.header.flyout.closeIcon.svgPath}
                fill-rule="evenodd"
              ></path>
            </SvgIcon>
          ) : (
            <Close />
          )}
        </CloseWrapper>
      ) : (
        <React.Fragment>{props.icon}</React.Fragment>
      )}
    </MobileDropdownTrigger>
  ) : (
    <DesktopDropdownTrigger
      dropdownType={props.dropdownType}
      data-testid={`header-trigger-${props.isShowing ? 'open' : 'closed'}`}
      href={props.href}
      aria-label={
        props.isShowing
          ? props.i18nAriaLabels.close
          : props.i18nAriaLabels.trigger
      }
      isShowing={props.isShowing}
      onMouseEnter={() => props.open()}
      inSubmenu={props.inSubmenu}
    >
      <TriggerWrapper inSubmenu={props.inSubmenu}>
        {props.icon}
        <TriggerText
          isShowing={props.isShowing}
          dropdownType={props.dropdownType}
          inSubmenu={props.inSubmenu}
        >
          {props.text}
        </TriggerText>
      </TriggerWrapper>
    </DesktopDropdownTrigger>
  )
}
