import * as React from 'react'
import loadable from '@loadable/component'

import SvgIcon from '@thg-commerce/gravity-icons/src/components/SvgIcon'
import { BreakpointArray, KeyboardKeys } from '@thg-commerce/gravity-theme'

const ZoomIn = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ZoomIn'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ZoomOut = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ZoomOut'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { Scale, useImageGalleryContext } from './ImageGalleryContext'
import {
  Control,
  ControlsWrapper,
  OpenZoomControl,
  ZoomWrapper,
} from './styles'
import { Position } from './types'

interface ControlsProps {
  i18nText: {
    zoomInLabel: string
    zoomOutLabel: string
    enterFullScreenText: string
  }
  controlIcons?: {
    zoomIn?: string
    zoomOut?: string
    fill: string
  }
  controlSize: BreakpointArray<number>
  showCarouselControls: BreakpointArray<boolean>
  zoomControlPosition: BreakpointArray<Position>
  location: Position
  zoomOpen?: boolean
  zoomInOnClick?: () => void
  zoomOutOnClick?: () => void
  selectedImageIndex?: number
  desktopZoomEnabled?: boolean
}

const positionsToApplyPadding = [Position.TOP_RIGHT]

export const Controls: React.FunctionComponent<ControlsProps> = (props) => {
  const {
    scale: [scale, _],
    showZoomButtonsAccessibilityLabel: [, setShowZoomButtonsAccessibilityLabel],
    zoomButtonClickHandler,
  } = useImageGalleryContext()

  const keyboardTab = (event: React.KeyboardEvent, disableShift?: boolean) => {
    if (event.key === KeyboardKeys.Tab) {
      setShowZoomButtonsAccessibilityLabel(
        event.shiftKey ? !disableShift : true,
      )
    }
  }

  return (
    <ControlsWrapper
      fullscreen={false}
      fullscreenButton={props.zoomOpen}
      location={props.location}
      applyPadding={positionsToApplyPadding.includes(props.location)}
    >
      {props.zoomOpen && (
        <ZoomWrapper>
          <Control
            disabled={scale !== Scale.MIN && scale >= Scale.MAX}
            onClick={() => {
              props.zoomInOnClick && props.zoomInOnClick()
            }}
            aria-label={props.i18nText.zoomInLabel}
            aria-describedby="image-gallery-accessibility-label"
            zoomEnabled={true}
            data-testid="carousel-zoom-in"
            size={props.controlSize}
            location={props.location}
            controlPosition={props.zoomControlPosition}
          >
            {props.controlIcons?.zoomIn ? (
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                style={{ margin: '0 auto' }}
              >
                <path
                  d={props.controlIcons?.zoomIn}
                  fillRule="evenodd"
                  fill={props.controlIcons?.fill}
                />
              </SvgIcon>
            ) : (
              <ZoomIn width="24" height="24" />
            )}
          </Control>
          <Control
            disabled={scale === Scale.MIN}
            onClick={() => {
              props.zoomOutOnClick && props.zoomOutOnClick()
            }}
            aria-label={props.i18nText.zoomOutLabel}
            aria-describedby="image-gallery-accessibility-label"
            zoomEnabled={true}
            data-testid="carousel-zoom-out"
            size={props.controlSize}
            location={props.location}
            controlPosition={props.zoomControlPosition}
          >
            {props.controlIcons?.zoomOut ? (
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                style={{ margin: '0 auto' }}
              >
                <path
                  d={props.controlIcons?.zoomOut}
                  fillRule="evenodd"
                  fill={props.controlIcons?.fill}
                />
              </SvgIcon>
            ) : (
              <ZoomOut width="24" height="24" />
            )}
          </Control>
        </ZoomWrapper>
      )}
      {!props.zoomOpen && zoomButtonClickHandler && (
        <OpenZoomControl
          onClick={() => zoomButtonClickHandler(props.selectedImageIndex || 0)}
          onKeyDown={(e) => keyboardTab(e, true)}
          onFocus={() => setShowZoomButtonsAccessibilityLabel(false)}
          aria-label={props.i18nText.zoomInLabel}
          data-testid="fullscreen-view-button"
          size={props.controlSize}
          controlPosition={props.zoomControlPosition || [Position.TOP_RIGHT]}
          location={props.location || Position.TOP_RIGHT}
          desktopZoomEnabled={props.desktopZoomEnabled}
          tabIndex={0}
        >
          {props.controlIcons?.zoomIn ? (
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              style={{ margin: '0 auto' }}
            >
              <path
                d={props.controlIcons?.zoomIn}
                fillRule="evenodd"
                fill={props.controlIcons?.fill}
              />
            </SvgIcon>
          ) : (
            <ZoomIn width="24" height="24" />
          )}
        </OpenZoomControl>
      )}
    </ControlsWrapper>
  )
}
