import { DrawFocus } from '@thg-commerce/gravity-elements'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

import { css, styled } from '../theme'

import { TabBorderStyle } from './Tabs'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TabList = styled.div<{
  enableThreeTierNav?: boolean
  alternateStyling?: boolean
  enableSubscribeAndSaveInlinePricing?: boolean
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.enableSubscribeAndSaveInlinePricing ? 'column' : 'row'};
  border-bottom: ${(props) =>
      props.enableThreeTierNav
        ? '0px'
        : props.theme.patterns.tabs.tabList.border.bottom.width}
    solid ${(props) => props.theme.patterns.tabs.tabList.border.bottom.color};
  ${(props) =>
    props.enableThreeTierNav &&
    `
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      padding: 0 ${spacing(1)};
      &::-webkit-scrollbar {
        display: none;
      }
    `}
  ${(props) => props.alternateStyling && 'width:100%;'}
`

export const Tab = styled.button<{
  borderStyle: TabBorderStyle
  isSelected?: boolean
  enableThreeTierNav?: boolean
  alternateStyling?: boolean
}>`
  ${(props) =>
    props.enableThreeTierNav
      ? Text('bodyText', 'alternate')
      : Text(
          props.theme.patterns.tabs.inactive.textStyling.entry,
          props.theme.patterns.tabs.inactive.textStyling.style,
        )}
  color: ${(props) =>
    props.isSelected
      ? props.theme.patterns.tabs.inactive.textColor.darker
        ? props.theme.patterns.tabs.inactive.textColor.darker
        : props.theme.colors.palette.greys.darker
      : props.theme.patterns.tabs.inactive.textColor.light
      ? props.theme.patterns.tabs.inactive.textColor.light
      : props.theme.colors.palette.brand.light};
  border: ${(props) => props.theme.patterns.tabs.inactive.border.width} solid;
  border-color: transparent transparent
    ${(props) => props.theme.patterns.tabs.inactive.border.bottom.color}
    ${(props) => props.theme.patterns.tabs.inactive.border.left.color};
  ${(props) =>
    props.enableThreeTierNav
      ? 'min-width: fit-content'
      : props.alternateStyling
      ? 'width:50%'
      : `width: ${props.theme.patterns.tabs.width.lg}`};
  min-height: ${(props) => props.theme.patterns.tabs.height};
  padding: ${(props) =>
    props.enableThreeTierNav ? `0 0 ${spacing(1.5)}` : `${spacing(1)}`};

  ${(props) => props.enableThreeTierNav && `margin: 0 ${spacing(1)}`};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: ${(props) =>
      props.enableThreeTierNav
        ? 'fit-content'
        : props.alternateStyling
        ? '50%'
        : props.theme.patterns.tabs.width.sm};
  }

  ${(props) =>
    props.borderStyle === 'MultiTab' &&
    `border-bottom: 1px solid ${props.theme.colors.palette.brand.lightest}`};

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.enableThreeTierNav
        ? 'none'
        : props.theme.patterns.tabs.inactive.backgroundColor
        ? props.theme.patterns.tabs.inactive.backgroundColor
        : props.theme.colors.palette.brand.lightest};
    border-color: transparent transparent
      ${(props) => props.theme.patterns.tabs.inactive.border.bottom.color}
      ${(props) => props.theme.patterns.tabs.inactive.border.left.color};
    border-bottom: ${(props) =>
        props.theme.patterns.tabs.inactive.border.bottom.width}
      solid
      ${(props) =>
        props.enableThreeTierNav
          ? 'none'
          : props.theme.colors.palette.brand.lightest};
  }

  &:focus {
    border: ${(props) => props.theme.patterns.tabs.inactive.border.bottom.width}
      solid
      ${(props) =>
        props.enableThreeTierNav
          ? 'none'
          : props.theme.patterns.tabs.inactive.border.color
          ? props.theme.patterns.tabs.inactive.border.color
          : props.theme.colors.palette.brand.base};
    outline: none;
  }

  &[aria-selected='true'] {
    font-weight: ${(props) => props.theme.patterns.tabs.active.fontWeight};
    color: ${(props) =>
      props.theme.patterns.tabs.active.textColor
        ? props.theme.patterns.tabs.active.textColor
        : props.theme.colors.palette.brand.base};
    border-bottom: ${(props) =>
        props.theme.patterns.tabs.active.border.bottom.width}
      solid
      ${(props) =>
        props.theme.patterns.tabs.active.border.bottom.color
          ? props.theme.patterns.tabs.active.border.bottom.color
          : props.theme.colors.palette.brand.base};
    background-color: ${(props) =>
      props.theme.patterns.tabs.active.backgroundColor};
  }

  &[aria-selected='false'] {
    font-weight: ${(props) => props.theme.patterns.tabs.inactive.fontWeight};
    background-color: ${(props) =>
      props.theme.patterns.tabs.inactive.backgroundColor};
  }

  &:first-of-type {
    border-left-color: transparent;
    ${(props) =>
      !props.enableThreeTierNav &&
      `&:focus {
      border-left-color: ${
        props.theme.patterns.tabs.inactive.border.color
          ? props.theme.patterns.tabs.inactive.border.color
          : props.theme.colors.palette.brand.base
      };
        }`};
  }
`
export const RadioTab = styled.button<{
  borderStyle: TabBorderStyle
  isSelected?: boolean
  enableThreeTierNav?: boolean
  alternateStyling?: boolean
}>`
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.palette.brand.base};

  &::before {
    content: '';
    position: absolute;
    left: ${spacing(1)};
    width: 16px;
    height: 16px;
    border: 2px solid
      ${(props) =>
        props.isSelected
          ? props.theme.colors.palette.brand.base
          : props.theme.colors.palette.greys.light};
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  &[aria-selected='true'] {
    color: ${(props) =>
      props.theme.patterns.tabs.active.textColor
        ? props.theme.patterns.tabs.active.textColor
        : props.theme.colors.palette.brand.base};

    &::after {
      content: '';
      position: absolute;
      left: ${spacing(1.5)};
      width: 8px;
      height: 8px;
      background-color: ${(props) => props.theme.colors.palette.brand.base};
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
    }
  }

  ${(props) =>
    props.enableThreeTierNav
      ? Text('bodyText', 'alternate')
      : Text(
          props.theme.patterns.tabs.inactive.textStyling.entry,
          props.theme.patterns.tabs.inactive.textStyling.style,
        )}

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:first-of-type {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }
`

export const TabPrice = styled.span`
  font-weight: bold;
  margin-right: ${spacing(1)};
`

const AlternateTabPanelStyle = css`
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  gap: ${spacing(3)};
  padding-top: ${spacing(5)};
`

const TabPanelFocusStyles = (props) =>
  props.theme.patterns.tabsPanel?.focus &&
  css`
    &:focus {
      border-color: ${props.theme.patterns.tabsPanel.focus.border.color};
    }
  `

export const TabPanel = styled.div<{ alternateStyling?: boolean }>`
  ${DrawFocus()};
  ${(props) =>
    props.alternateStyling ? AlternateTabPanelStyle : 'min-height: 400px;'}
  width: 100%;
  section > :first-child {
    margin-bottom: 0;
  }

  ${TabPanelFocusStyles}
`
