import * as React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { PapBadges } from '@thg-commerce/enterprise-widget-product-list-page/theme'
import {
  css,
  padding,
  spacing,
  styled,
  Text,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

interface ProductContainerInterface {
  badgeStyle?: PapBadges
}

interface ProductCardInterface extends ProductContainerInterface {
  useAlternate: boolean
}

const ProductCardContainer = styled.div<ProductContainerInterface>`
  position: absolute;
  top: ${(props) => spacing(props.badgeStyle?.top ?? 1)};
  left: ${(props) => spacing(props.badgeStyle?.left ?? 0)};
  z-index: ${ZIndexLevel.Low};
`

const ProductCardAlternate = ({
  useAlternate,
  badgeStyle,
}: ProductCardInterface) =>
  useAlternate &&
  badgeStyle?.alternate &&
  css`
    background: ${badgeStyle.alternate.backgroundColor};
    color: ${badgeStyle.alternate.textStyle.textColor};
  `

const ProductCard = styled.div<ProductCardInterface>`
  ${Text('xsmall', 'alternate')};
  background: ${(props) => props.badgeStyle?.base.backgroundColor};
  color: ${(props) => props.badgeStyle?.base.textStyle.textColor};
  border-radius: ${(props) => props.badgeStyle?.borderRadius};
  position: absolute;
  vertical-align: middle;
  width: max-content;
  left: -0.375rem;
  ${(props) =>
    padding(
      props.badgeStyle?.padding ?? {
        top: 0.5,
        bottom: 0.5,
        left: 0.5,
        right: 0.5,
      },
    )};

  ${(props) =>
    props.badgeStyle?.fontSize &&
    css`
      & {
        font-size: ${props.badgeStyle?.fontSize};
      }
    `}

  ${ProductCardAlternate}
`

export const PapBadgeRenderer = ({
  marketedSpecialOfferTitleText,
  badgeStyle,
}: {
  marketedSpecialOfferTitleText: string
  badgeStyle?: PapBadges
}) => {
  const {
    enablePapOverlay,
    papProductItemHighlightedTitleList,
  } = useSiteConfig()

  if (
    !enablePapOverlay ||
    !papProductItemHighlightedTitleList ||
    !marketedSpecialOfferTitleText
  ) {
    return null
  }

  return (
    <ProductCardContainer badgeStyle={badgeStyle}>
      <ProductCard
        badgeStyle={badgeStyle}
        useAlternate={papProductItemHighlightedTitleList.includes(
          marketedSpecialOfferTitleText,
        )}
      >
        {marketedSpecialOfferTitleText}
      </ProductCard>
    </ProductCardContainer>
  )
}
