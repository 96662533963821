import React from 'react'
import dynamic from 'next/dynamic'

import { StyledVideo } from '../ImageCarousel/styles'
import { Container, TranscriptTriggerButton } from '../VideoTranscript/styles'

const SvgVideoTranscript = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/VideoTranscript'),
)

interface VideoPlayerProps {
  url: {
    url: string
    alt: string
    tag?: string
    isVideoUrl?: boolean
    videoTitle?: string
    videoTranscript?: string
  }
  onTranscriptClick: () => void
  transcriptionButtonAlignment?: {
    left?: string
    bottom?: string
  }
}

interface TranscriptTriggerProps {
  onClick: (e?: React.SyntheticEvent) => void
  isVisible: boolean
  left?: string
  bottom?: string
}

// Start of reference to Iris's transcription ongoing ticket on branch INGBEA-479
const useVideoControls = (
  videoRef: React.RefObject<HTMLVideoElement>,
  containerRef: React.RefObject<HTMLDivElement>,
) => {
  const [
    transcriptTriggerVisible,
    setTranscriptTriggerVisible,
  ] = React.useState(false)

  React.useEffect(() => {
    const video = videoRef.current
    const container = containerRef.current

    if (!video || !container) return

    let hideTimeout: NodeJS.Timeout

    const showControls = () => {
      setTranscriptTriggerVisible(true)
      clearTimeout(hideTimeout)
    }

    const startHideTimer = () => {
      clearTimeout(hideTimeout)
      hideTimeout = setTimeout(() => {
        setTranscriptTriggerVisible(false)
      }, 2700)
    }

    const handleMouseMove = () => {
      showControls()
      startHideTimer()
    }

    const handleMouseLeave = () => {
      clearTimeout(hideTimeout)
      if (!video.paused) {
        setTimeout(() => {
          setTranscriptTriggerVisible(false)
        }, 100)
      }
    }

    const handleVideoEnded = () => {
      setTranscriptTriggerVisible(true)
    }

    container.addEventListener('mousemove', handleMouseMove)
    container.addEventListener('mouseleave', handleMouseLeave)
    video.addEventListener('ended', handleVideoEnded)

    return () => {
      container.removeEventListener('mousemove', handleMouseMove)
      container.removeEventListener('mouseleave', handleMouseLeave)
      video.removeEventListener('ended', handleVideoEnded)
      clearTimeout(hideTimeout)
    }
  }, [videoRef, containerRef])

  return { transcriptTriggerVisible }
}
// End of reference

const TranscriptTrigger = (props: TranscriptTriggerProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    props.onClick(e)
  }

  return (
    <TranscriptTriggerButton
      sizing="regular"
      emphasis="low"
      isVisible={props.isVisible}
      aria-label="Transcript"
      onClick={handleClick}
      data-testid="transcript-trigger"
      left={props.left}
      bottom={props.bottom}
    >
      <SvgVideoTranscript />
    </TranscriptTriggerButton>
  )
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { transcriptTriggerVisible } = useVideoControls(videoRef, containerRef)

  return (
    <Container ref={containerRef}>
      <StyledVideo
        ref={videoRef}
        controls
        autoPlay
        muted
        loop
        controlsList="nodownload"
        aria-label={props.url.videoTitle}
      >
        <source src={props.url.url} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
      {props.url.videoTranscript && (
        <TranscriptTrigger
          onClick={props.onTranscriptClick}
          isVisible={transcriptTriggerVisible}
          left={props.transcriptionButtonAlignment?.left}
          bottom={props.transcriptionButtonAlignment?.bottom}
        />
      )}
    </Container>
  )
}

export default VideoPlayer
