import dynamic from 'next/dynamic'

import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'

const DefaultCheckmark = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Checkmark'),
)

export const Paragraph = styled.p`
  ${Text('small', 'default')};
`

export const TitleContainer = styled.div`
  ${Text('bodyText', 'alternate')};
  display: flex;
  margin-bottom: ${spacing(1)};

  svg {
    width: 24px;
    height: 24px;
    margin-left: ${spacing(1)};
  }
`

export const StyledCheckmark = styled(DefaultCheckmark)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  path {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const SubscriptionInfoBoxWrapper = styled.div<{
  additionalStyle?: { marginTop: number; padding: number }
}>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => spacing(props.additionalStyle?.padding || 3)};
  margin-top: ${(props) => spacing(props.additionalStyle?.marginTop || 3)};
  background-color: ${(props) =>
    props.theme.patterns.tabs?.subscribePanel?.background
      ? props.theme.patterns.tabs.subscribePanel.background
      : props.theme.colors.palette.greys.lighter};
`
