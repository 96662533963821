import { Image } from '@thg-commerce/gravity-elements'
import { css, mq, styled } from '@thg-commerce/gravity-theme'

export const StyledImage = styled(Image)<{
  scale?: number
  zoomEnabled?: boolean
}>`
  width: 100%;
  height: 100%;
  cursor: ${(props) =>
    props.zoomEnabled && props.scale === 1 ? 'zoom-in' : 'move'};
  user-drag: none;
  user-select: none;
  pointer-events: none;
`

export const StyledVideo = styled.video<{
  aspectRatio?: string
}>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: ${(props) => props.aspectRatio || '1/1'};
`

export const CarouselWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`

export const CarouselInnerWrapper = styled.div<{
  fullscreen?: boolean
}>`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.fullscreen &&
    css`
      flex-shrink: 0;
      ${(props) =>
        mq(props.theme.breakpointUtils.map, 'md')}, (orientation: landscape) {
        width: calc(100vh - 96px);
      }
    `}
`

export const MotionDivBounds = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`

export const ImageWrapper = styled.div`
  height: 100%;
`
