import dynamic from 'next/dynamic'

import {
  margin,
  mq,
  spacing,
  styled,
  Text,
} from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Picture } from '@thg-commerce/gravity-system'

const Tick = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Tick'),
)

interface ListItemProps {
  index?: number
}

export const Title = styled.h1`
  ${(props) =>
    Text(
      props.theme.widget.emailEngagementModal?.title?.font?.entry || 'large1',
      props.theme.widget.emailEngagementModal?.title?.font?.style ||
        'alternate',
    )};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: 0 0 ${spacing(3)};
  }
  color: ${(props) =>
    props.theme.widget?.emailEngagementModal?.title?.font?.textColor};
  text-align: ${(props) =>
    props.theme.widget?.emailEngagementModal?.title?.textAlign};
`

export const ListItem = styled.li<ListItemProps>`
  ${Text('bodyText', 'default')};
  list-style: none;
  ${(props) =>
    props.theme.widget.emailEngagementModal?.listItems?.textStyle?.margin &&
    margin(props.theme.widget.emailEngagementModal.listItems.textStyle.margin)}

  ${(props) =>
    props.index === 0 &&
    props.theme.widget?.emailEngagementModal?.listItems?.textStyle?.bulletOne &&
    Text(
      props.theme.widget.emailEngagementModal.listItems.textStyle.bulletOne
        .entry,
      props.theme.widget.emailEngagementModal.listItems.textStyle.bulletOne
        .style,
    )}

  ${(props) =>
    (props.index === 1 || props.index === 2) &&
    props.theme.widget?.emailEngagementModal?.listItems?.textStyle?.bulletTwo &&
    Text(
      props.theme.widget.emailEngagementModal.listItems.textStyle.bulletTwo
        .entry,
      props.theme.widget.emailEngagementModal.listItems.textStyle.bulletTwo
        .style,
    )}

  color: ${(props) =>
    props.theme.widget?.emailEngagementModal?.title?.font?.textColor};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const TickIcon = styled(Tick)`
  margin-right: ${spacing(1)};
  stroke: ${(props) => props.theme.colors.palette.greys.darker};
`

export const StyledPicture = styled(Picture)`
  height: 150px;
  width: 100%;
  aspect-ratio: auto 3 / 4;
  object-fit: cover;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 286px;
    height: 100%;
  }
`

export const ListContainer = styled.div`
  overflow: auto;
  overscroll-behavior: contain;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: 0 0 ${spacing(3)};
  }
`

export const TextContainer = styled.ul`
  display: ${(props) =>
    props.theme.widget?.emailEngagementModal?.listItems?.alignments?.display};
  flex-direction: ${(props) =>
    props.theme.widget?.emailEngagementModal?.listItems?.alignments
      ?.flexDirection};
  align-items: ${(props) =>
    props.theme.widget?.emailEngagementModal?.listItems?.alignments
      ?.alignItems};
`

export const ImageContainer = styled.div`
  overflow: hidden;
  object-fit: cover;
  min-width: 260px;
  min-height: 112px;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    picture {
      display: block;
      height: 100%;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    flex-direction: row;
    height: auto;
  }
  background: ${(props) =>
    props.theme.widget?.emailEngagementModal?.formContainer?.background};
`

export const SubmittedTitle = styled.h1`
  ${Text('medium1', 'alternate')};
  text-align: center;
  margin-top: ${spacing(4)};
`

export const SubmittedText = styled.h1`
  ${Text('small', 'default')};
  text-align: center;
  margin-top: ${spacing(4)};
`

export const SuccessButton = styled(Button)`
  min-width: 100%;
  margin-top: ${spacing(4)};
`
export const SuccessTick = styled(Tick)`
  width: 35px;
  height: 35px;
  stroke: ${(props) => props.theme.colors.palette.brand.base};
  display: block;
  margin: auto;
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: ${spacing(2)};
`

export const LegalText = styled(SafeHtml)`
  ${Text('small', 'default')};
  width: 100%;
  margin-top: ${spacing(2)};
  a {
    ${Text('small', 'alternate')};
    ${(props) =>
      props.theme.widget?.emailEngagementModal?.description?.textColor &&
      `color : ${props.theme.widget?.emailEngagementModal?.description?.textColor}`};
  }
  ${(props) =>
    props.theme.widget?.emailEngagementModal?.description?.textColor &&
    `color : ${props.theme.widget?.emailEngagementModal?.description?.textColor}`};
`

export const PrivacyPolicy = styled(Button)`
  width: 100%;
  margin-top: ${spacing(1)};

  &:hover {
    width: 100%;
    ${(props) =>
      props.theme.widget?.emailEngagementModal?.privacyPolicyText?.hoverColor &&
      `color : ${props.theme.widget?.emailEngagementModal.privacyPolicyText.hoverColor}`};
  }

  &:focus {
    width: 100%;
  }

  ${(props) =>
    props.theme.widget?.emailEngagementModal?.privacyPolicyText?.color &&
    `color : ${props.theme.widget?.emailEngagementModal.privacyPolicyText.color}`};
`

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  margin: ${spacing(3)};
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${spacing(8)} ${spacing(4)} ${spacing(4)};
    justify-content: center;
  }
`

export const MessageContainer = styled.div`
  margin: auto;
  padding-top: ${spacing(3)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding-top: 0;
  }
`
