import {
  FacetInput,
  RangedFacet,
  SimpleFacet,
  SliderFacet,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import { PageOptions } from '../ProductListPage'

export enum Facet {
  SIMPLE = 'SimpleFacet',
  RANGED = 'RangedFacet',
  SLIDER = 'SliderFacet',
}

export type FacetType = SimpleFacet | RangedFacet | SliderFacet

export interface BaseFacetProps {
  i18nText: { [key: string]: string }
  selectedFacets: FacetInput[]
  onInputChange: (newInput: PageOptions) => void
}

export interface FacetProps extends BaseFacetProps {
  facet: FacetType
}

export interface FacetsProps extends BaseFacetProps {
  facets: FacetType[]
  enableAlternateMobileFacet?: boolean
}

export interface FacetDataLayerSchema {
  filter?: {
    propertyKey: string
    label: string
  }
  filter_category?: {
    propertyKey: string
    label: string
  }
  filter_selection?: {
    propertyKey: string
    label: string
  }
}
