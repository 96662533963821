import { PurchaseOptions } from '@thg-commerce/enterprise-pages/src/Product/theme'
import { margin, mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import SvgIcon from '@thg-commerce/gravity-icons/src/components/SvgIcon'
import { TabList } from '@thg-commerce/gravity-patterns/Tabs/styles'

export const TabsContainer = styled.div<{
  subscriptionTabsTheme?: PurchaseOptions
  enableSubscribeAndSaveInlinePricing?: boolean
}>`
  ${TabList} {
    background-color: ${(props) =>
      props.enableSubscribeAndSaveInlinePricing
        ? 'transparent'
        : props?.subscriptionTabsTheme?.tabList?.backgroundColor
        ? props.subscriptionTabsTheme?.tabList.backgroundColor
        : props.theme.colors.palette.greys.lighter};
    width: ${(props) =>
      props.subscriptionTabsTheme?.tabContainer?.width || '100%'};
    button {
      display: flex;
      ${(props) =>
        props.enableSubscribeAndSaveInlinePricing
          ? ''
          : 'justify-content: center;'}
      align-items: center;
      padding: ${(props) =>
        props.enableSubscribeAndSaveInlinePricing
          ? `${spacing(3.5)} ${spacing(3.5)} ${spacing(3.5)} ${spacing(5)}`
          : spacing(3.5)};

      height: 42px;
      width: 100%;
      &:hover {
        cursor: pointer;
        background-color: ${(props) =>
          props.enableSubscribeAndSaveInlinePricing
            ? 'transparent'
            : props.subscriptionTabsTheme?.tabList?.button.hover
                .backgroundColor || props.theme.colors.palette.brand.lightest}
        color: ${(props) =>
          props.subscriptionTabsTheme?.tabList?.button.hover.color ||
          props.theme.colors.palette.brand.base};
        svg {
          fill: ${(props) => props.theme.colors.palette.brand.base};
        }
      }
      &:active {
        svg {
          fill: ${(props) => props.theme.colors.palette.brand.base};
        }
      }
      &:focus {
        svg {
          fill: ${(props) => props.theme.colors.palette.brand.base};
        }
        background-color: ${(props) =>
          props.subscriptionTabsTheme?.tabList?.button.focus?.backgroundColor};
        border-left: ${(props) =>
          props.subscriptionTabsTheme?.tabList?.button.focus?.border};
        border-right: ${(props) =>
          props.subscriptionTabsTheme?.tabList?.button.focus?.border};
        border-top: 1px solid
          ${(props) =>
            props.subscriptionTabsTheme?.tabList?.button.focus
              ?.backgroundColor};
      }
      ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
        height: 42px;
      }
    }
  }
  width: ${(props) => props.subscriptionTabsTheme?.tabList?.width || '100%'};
  ${(props) =>
    props.subscriptionTabsTheme?.tabList?.margin &&
    margin(props.subscriptionTabsTheme.tabList.margin)}
  div[role='tabpanel'] {
    min-height: auto;
    margin-bottom: ${spacing(2)};

    :focus {
      border-color: transparent;
    }
  }
`

export const StyledIcon = styled(SvgIcon)<{
  fill: string
  focus: string
}>`
  margin-left: ${spacing(1)};
  fill: ${(props) => props.fill || props.theme.colors.palette.brand.base};
`
