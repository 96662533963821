import * as React from 'react'
export const SvgWhiteOutlineFacebook = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={26}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.675 8.554V5.956c0-1.147.028-2.914.86-4.008C11.412.788 12.615 0 14.686 0c3.372 0 4.793.48 4.793.48l-.668 3.961s-1.114-.32-2.154-.32c-1.04 0-1.971.372-1.971 1.41v3.023h4.263l-.298 3.868h-3.965v13.443H9.675V12.422H7V8.554h2.675Z"
      fill="#fff"
    />
  </svg>
)
export default SvgWhiteOutlineFacebook
