import * as React from 'react'

import { SafeHtml } from '@thg-commerce/gravity-elements'
import { Font } from '@thg-commerce/gravity-patterns'
import {
  css,
  margin,
  mq,
  padding,
  spacing,
  styled,
  Text,
  ThemeInterface,
} from '@thg-commerce/gravity-theme'
import { IconStyling } from '@thg-commerce/gravity-theme/icons'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { DrawFocus } from '../../mixin/focus'

interface BadgeTitleInterface {
  textStyle: Font
  padding?: Padding
  content?: string
  margin?: Margin
  textAlign?: string
  width?: string
  fontWeight?: string
}

const FontWeightOverride = ({
  fontWeight,
  theme,
}: BadgeTitleInterface & { theme: ThemeInterface }) =>
  fontWeight &&
  css`
    font-weight: ${fontWeight};

    ${mq(theme.breakpointUtils.map, 'sm')} {
      font-weight: ${fontWeight};
    }
  `

const BadgeTitle = styled(SafeHtml)<BadgeTitleInterface>`
  ${(props) => Text(props.textStyle.entry, props.textStyle.style)};
  color: ${(props) =>
    props.theme.colors.system.pap.title ||
    props.theme.colors.palette.brand.base};
  text-decoration: ${(props) => props.textStyle?.textDecoration || 'none'};
  text-transform: ${(props) => props.textStyle?.transform};
  ${(props) => props.padding && props.content && padding(props.padding)};
  ${(props) => props.margin && props.content && margin(props.margin)};

  > b {
    ${(props) => Text(props.textStyle.entry, 'alternate')};
    color: ${(props) =>
      props.theme.colors.system.pap.title ||
      props.theme.colors.palette.brand.base};
  }

  ${FontWeightOverride}
`

const StyledButton = styled.button<{
  border?: { width: string; type: string }
  content?: string
  margin?: Margin
  width?: string
  justifyContent?: string
}>`
  display: flex;
  align-items: center;
  ${DrawFocus}

  width: ${(props) => props.width};
  justify-content: ${(props) => props.justifyContent};

  & {
    ${(props) => props.margin && props.content && margin(props.margin)};
  }

  ${(props) =>
    props.border &&
    ` outline: ${props.border.width} ${props.border.type} ${
      props.theme.colors.system.pap.border
    };
      svg {
        margin-left: ${spacing(1)};
      }
    `};
`

export interface ProductSpecialOfferBadgeProps {
  text: string
  showGiftsIcon?: boolean
  onClick?: (event?: React.MouseEvent) => void
  padding?: Padding
  margin?: Margin
  border?: { width: string; type: string }
  textStyle: Font
  marketingIcon?: IconStyling
  textAlign?: string
  width?: string
  fontWeight?: string
  justifyContent?: string
}

export const ProductSpecialOfferBadge = (
  props: ProductSpecialOfferBadgeProps,
) => {
  return props?.onClick ? (
    <StyledButton
      onClick={props.onClick}
      data-testid="special-offer-badge-button"
      border={props.border}
      content={props.text}
      margin={props.margin}
      width={props.width}
      justifyContent={props.justifyContent}
    >
      {props.marketingIcon && (
        <svg
          width={props.marketingIcon.width}
          height={props.marketingIcon.height}
          viewBox={props.marketingIcon.viewBox}
          fill={props.marketingIcon.color}
        >
          <path d={props.marketingIcon.svgPath} />
        </svg>
      )}
      <BadgeTitle
        textStyle={props.textStyle}
        content={props.text}
        removeTagsAndContent={false}
        padding={props.padding}
        fontWeight={props.fontWeight}
      />
    </StyledButton>
  ) : (
    <div data-testid="special-offer-badge-div">
      <BadgeTitle
        content={props.text}
        removeTagsAndContent={false}
        textStyle={props.textStyle}
        padding={props.padding}
        margin={props.margin}
        textAlign={props.textAlign}
        width={props.width}
        fontWeight={props.fontWeight}
      />
    </div>
  )
}
