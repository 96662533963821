import * as React from 'react'
import debounce from 'lodash/debounce'
// tslint:disable-next-line:no-duplicate-imports
import {
  object as yupObject,
  ObjectSchema,
  string as yupString,
  ValidationError,
} from 'yup'

import { Flex } from '@thg-commerce/gravity-system'
import { spacing, styled } from '@thg-commerce/gravity-theme'

import { ErrorMessage } from '../ErrorMessage'

import { InputProps } from './Input/Input'

export type CheckboxOptions = {
  checked: boolean
  optionKey: string
  translation?: string
}
interface FormItemI18nText {
  confirm?: string
  mustMatch?: string
}

export interface FormItemInputProps {
  /** If a boolean and is true, shows the error state without a message. If a string is passed, shows it as a message with the error state. */
  error?: string | boolean
  bindref?: React.RefObject<HTMLInputElement>
  /** Whther the FormItem is in view (visible in the viewport) */
  inView?: boolean
  i18nText?: FormItemI18nText
  offset?: number
  onValidationSuccess?: (validValue: any) => void
  setIsNotValidated?: (isNotValidated: boolean) => void
}

export interface FormItemProps {
  row?: boolean
  children?: React.ReactNode
  disableSpacing?: boolean
  className?: string
}

export type OptionProps = {
  i18nText?: {
    minSelectedError?: string
    maxSelectedUpdate?: string
    selectedCount?: string
    successMessage?: string
    closeAriaLabel?: string
  }
  id?: string
  required?: boolean
  label: string
  labelHidden?: boolean
  maxSelected?: number
  minSelected?: number
  checkboxes: CheckboxOptions[]
  previousValues?: string[]
  bindref?: React.RefObject<HTMLInputElement>
}

const defaultProps = {
  row: false,
  inView: false,
  offset: 0,
  disableSpacing: false,
}

const StyledFormItem = styled(Flex)<FormItemProps>`
  flex-wrap: wrap;
  font-family: Avenir;
  margin-bottom: ${(props) => (props.disableSpacing ? 0 : spacing(4))};
  ${(props) => props.row && 'align-items: center;'};
`

const INVALID_SELECTION = 'INVALID_SELECTION'

const schemaFromProps = (
  props: HTMLInputElement & InputProps & OptionProps,
) => {
  let schema = yupString()

  Object.keys(props).map((key) => {
    switch (key) {
      case 'type':
        if (props[key].toLowerCase() === 'email') {
          schema = schema.concat(
            yupString().email(
              props?.i18nText?.emailValidationError || undefined,
            ),
          )
        }
        break
      case 'required':
        if (props.required) {
          schema = schema.required(props?.i18nText?.requiredError || undefined)
        }
        break
      case 'minLength':
      case 'minlength':
        schema = schema.concat(
          yupString().min(
            props[key] || 1,
            props?.i18nText?.minLengthError || undefined,
          ),
        )
        break
      case 'maxLength':
      case 'maxlength':
      case 'minlength':
        schema = schema.concat(
          yupString().max(
            props[key] || 255,
            props?.i18nText?.maxLengthError || undefined,
          ),
        )
        break
      case 'pattern':
        schema = schema.concat(
          yupString().matches(new RegExp(props[key]), {
            excludeEmptyString: true,
            message: props?.i18nText?.patternError || undefined,
          }),
        )
        break
      case 'minSelected':
      case 'minselected':
        schema = schema.concat(
          yupString()
            // - html input element will concatenate an array of values into a string of comma concatenated values
            // - transformation here undoes the concatenation so we can correctly validate the number of selected options
            .transform((selectedValues: string) => {
              const selectedValuesArray: string[] = selectedValues.split(',')

              return props[key] &&
                props[key] > 0 &&
                (selectedValuesArray.length < props[key] ||
                  selectedValuesArray[0] === '')
                ? INVALID_SELECTION
                : selectedValuesArray.length.toString()
            })
            .notOneOf(
              [INVALID_SELECTION],
              props.i18nText?.minSelectedError || undefined,
            ),
        )
        break
    }
  })

  return schema
}

const FormItemChild = (
  props: FormItemProps & FormItemInputProps,
  c: React.ReactNode,
) => {
  const child = c as React.ReactElement<HTMLInputElement & InputProps>
  const inputRef = React.useRef<HTMLInputElement>(null)
  const confirmInputRef = React.useRef<HTMLInputElement>(null)
  const [error, setError] = React.useState(props.error)
  const [confirmError, setConfirmError] = React.useState('')
  const [validateOnBlur, shouldValidateOnBlur] = React.useState(true)

  React.useEffect(() => {
    if (props.error) {
      setError(props.error)
    }
  }, [props.error])

  const validateFieldValue = debounce(
    (
      value: boolean | string,
      element: HTMLInputElement,
      validationSchema: ObjectSchema<any>,
      confirmableRef?: HTMLInputElement | null | false,
    ): boolean => {
      try {
        if (element.type === 'button') return true // Prevent buttons being validated

        validationSchema.validateSync(
          { validator: value },
          { abortEarly: false },
        )

        shouldValidateOnBlur(true)
        setError(element.validity.customError)
        if (element.hasOwnProperty('setCustomValidity')) {
          element.setCustomValidity('')
        }

        if (confirmableRef) {
          if (confirmableRef.value === value) {
            setConfirmError('')
          } else {
            shouldValidateOnBlur(false)
            setConfirmError(
              `${element.getAttribute('label')} ${
                props.i18nText ? props.i18nText.mustMatch : 'must match'
              }`,
            )
            return false
          }
        }
        props.setIsNotValidated && props.setIsNotValidated(false)
        return true
      } catch (e) {
        const error = e as ValidationError
        if (error.inner.length > 0) {
          shouldValidateOnBlur(false)
          if (element.hasOwnProperty('setCustomValidity')) {
            element.setCustomValidity(error.inner[0].message)
          }
          props.setIsNotValidated && props.setIsNotValidated(true)
          setError(error.inner[0].message)
        }
      }

      return false
    },
    25,
    {
      leading: true,
      trailing: false,
    },
  )
  const fieldValidCheck = (
    value: string,
    element: HTMLInputElement,
    validationSchema: ObjectSchema<any>,
  ) => {
    try {
      if (element.type === 'button') return true // Prevent buttons being validated
      validationSchema.validateSync({ validator: value }, { abortEarly: false })
      return true
    } catch (e) {
      return false
    }
  }

  const testid = (label: string, confirmable: boolean) =>
    `${label
      ?.toLowerCase()
      .replace(/\:|\?|\.|\!|\"|\'/g, '')
      .split(' ')
      .join('-')}-form-item-id${confirmable ? '-confirm' : ''}`

  /**
   * We need to be able to expose a function on a ref so that validation
   * can be carried out via a higher component.
   * i.e. ref.current.function()
   *
   * validate() will carry out validation imperatively
   * without onChange or onBlur being called.
   */

  const inputLabelEl =
    inputRef != null &&
    inputRef.current != null &&
    inputRef.current.parentElement != null
      ? inputRef.current.parentElement.previousElementSibling
      : null

  React.useImperativeHandle(props.bindref, () => ({
    ...child.props,
    focus: () => {
      if (inputRef != null && inputRef.current != null) {
        inputRef.current.focus()
      }
    },
    validate: () => {
      if (inputRef != null && inputRef.current != null) {
        return validateFieldValue(
          inputRef.current.value,
          inputRef.current,
          validationSchema,
          confirmInputRef.current !== null && confirmInputRef.current,
        )
      }

      return false
    },
    value:
      inputRef != null && inputRef.current != null
        ? inputRef.current.value
        : '',
    inView: props.inView || false,
    offsetTop:
      inputRef != null && inputRef.current != null && inputLabelEl != null
        ? (inputLabelEl as HTMLElement).offsetTop - Number(props.offset)
        : 0,
  }))

  const validatorMap = (
    validatorName: string,
    value: string | number,
    validators: Object,
  ) => {
    switch (validatorName) {
      case 'MIN_LENGTH':
        validators['minLength'] = value
        break
      case 'MAX_LENGTH':
        validators['maxLength'] = value
        break
      case 'REGEX_MATCHES':
        validators['pattern'] = value
        break
    }

    return validators
  }

  const validators = {}
  child.props.validators?.forEach((validator) =>
    validatorMap(validator.name, validator.argument, validators),
  )

  const validationSchema = yupObject().shape({
    validator: schemaFromProps({
      ...child.props,
      ...validators,
      checkboxes: [],
    }).label(child.props.label),
  })

  const errorMessageId = `${child.props.label}-error`
  const confirmErrorMessageId = `${
    props.i18nText ? props.i18nText.confirm : 'Confirm'
  }-${child.props.label}-error`

  return (
    <React.Fragment>
      <StyledFormItem
        flexDirection={props.row ? 'row' : 'column'}
        disableSpacing={props.disableSpacing}
        data-testid={testid(child.props.label, false)}
        className={props.className}
      >
        {React.cloneElement<HTMLInputElement & InputProps>(child, {
          ...child.props,
          error,
          bindref: inputRef,
          onChange: child.props.onChange
            ? (event) => {
                const valid = validateFieldValue(
                  event.target.value,
                  event.target,
                  validationSchema,
                )

                if (child.props.onChange) {
                  child.props.onChange(event)
                  valid &&
                    props.onValidationSuccess &&
                    props.onValidationSuccess(event.target.value)
                }
              }
            : (event) => {
                const eventTarget = event.target as HTMLInputElement
                if (!validateOnBlur) {
                  const valid = validateFieldValue(
                    event.target.value,
                    event.target,
                    validationSchema,
                  )

                  valid &&
                    props.onValidationSuccess &&
                    props.onValidationSuccess(eventTarget.value)

                  if (
                    confirmInputRef.current &&
                    confirmInputRef.current.value === eventTarget.value
                  ) {
                    setConfirmError('')
                  }
                }
              },
          onBlur: (event) => {
            const eventTarget = event.target as HTMLInputElement
            if (validateOnBlur) {
              validateFieldValue(
                eventTarget.value,
                eventTarget,
                validationSchema,
                confirmInputRef.current !== null && confirmInputRef.current,
              )

              if (confirmInputRef.current) {
                if (
                  confirmInputRef.current.value === eventTarget.value ||
                  confirmInputRef.current.value === ''
                ) {
                  setConfirmError('')
                }
              }
            }
            fieldValidCheck(eventTarget.value, eventTarget, validationSchema) &&
              props.onValidationSuccess &&
              props.onValidationSuccess(eventTarget.value)
          },
          ariaDescribedBy: errorMessageId,
        })}
        {error && typeof error !== 'boolean' && (
          <ErrorMessage id={errorMessageId} error={error} />
        )}
      </StyledFormItem>
      {child.props.confirmable && (
        <StyledFormItem
          flexDirection={props.row ? 'row' : 'column'}
          data-testid={testid(child.props.label, true)}
        >
          {React.cloneElement<HTMLInputElement & InputProps>(child, {
            ...child.props,
            error: !error && confirmError,
            label: `${props.i18nText ? props.i18nText.confirm : 'Confirm'} ${
              child.props.label
            }`,
            helperText: '',
            bindref: confirmInputRef,
            onChange: () => {
              if (
                inputRef.current &&
                confirmInputRef.current &&
                inputRef.current.value === confirmInputRef.current.value
              ) {
                setConfirmError('')
              }
            },
            onBlur: () => {
              if (
                !error &&
                inputRef.current &&
                confirmInputRef.current &&
                inputRef.current.value !== confirmInputRef.current.value
              ) {
                setConfirmError(
                  `${child.props.label} ${
                    props.i18nText ? props.i18nText.mustMatch : 'must match'
                  }`,
                )
              }
            },
            ariaDescribedBy: confirmErrorMessageId,
          })}
          {!error && confirmError && (
            <ErrorMessage id={confirmErrorMessageId} error={confirmError} />
          )}
        </StyledFormItem>
      )}
    </React.Fragment>
  )
}

/** @component */
export const FormItem = (
  props: FormItemProps & FormItemInputProps = defaultProps,
) =>
  React.Children.map(props.children, (child: React.ReactNode) =>
    FormItemChild(props, child),
  )

export default FormItem
