import * as React from 'react'
import dynamic from 'next/dynamic'

const RemoveCircleIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/FacetRemove'),
)
const RemoveIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Close'),
)
import { i18n, useEnterpriseContext } from '@thg-commerce/enterprise-core'
import { currencyMap } from '@thg-commerce/enterprise-pricing'
import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { VisuallyHidden } from '@thg-commerce/gravity-elements'

import { Facet, FacetsProps } from '../types'
import { removeFacet } from '../utils'

const RemoveSelectedFacetButton = styled.button<{
  enableAlternateMobileFacet?: boolean
}>`
  ${Text('bodyText', 'default')};
  text-align: left;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  margin-top: ${(props) =>
    props.enableAlternateMobileFacet ? '0px' : `${spacing(2)}`};
  padding: ${(props) =>
    props.enableAlternateMobileFacet ? `${spacing(2)}` : '0px'};
  width: ${(props) => (props.enableAlternateMobileFacet ? `100%` : 'auto')};
  border-bottom: ${(props) =>
    props.enableAlternateMobileFacet
      ? `1px solid ${props.theme.colors.palette.greys.light}`
      : '2px solid transparent'};

  svg {
    display: block;
    margin-right: ${spacing(1)};
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.greys.darker};
  }
`

const SelectedFacetName = styled.span`
  ${Text('bodyText', 'alternate')};
  padding-left: ${spacing(0.5)};
  word-break: break-word;
`

export const SelectedFacets = (props: FacetsProps) => {
  const i18nText = {
    removeFilter: i18n('product.list.facets.removefilter.text'),
    currentFilters: i18n('product.list.facets.currentfilters.text'),
  }
  const { currency } = useEnterpriseContext()
  const selectedFacets = props.selectedFacets.map((facet) =>
    facet.selections.map((selection) => {
      const selectedFacetDisplayText =
        props.facets.find(
          (findFacet) => findFacet.facetName === facet.facetName,
        )?.facetHeader || facet.facetName

      const getSelectedOptionDisplayText = () => {
        const foundFacet = props.facets.find(
          (findFacet) => findFacet.facetName === facet.facetName,
        )

        switch (foundFacet?.__typename) {
          case Facet.SIMPLE:
            return (
              foundFacet.options.find(
                (option) => option.optionName === selection.optionName,
              )?.displayName || selection.optionName
            )
          case Facet.RANGED:
            return (
              foundFacet.options.find(
                (option) =>
                  option.from === selection.from && option.to === selection.to,
              )?.displayName || selection.from
            )
          case Facet.SLIDER:
            return `${currencyMap[currency].symbol}${selection.from} - ${selection.to}`
        }
      }

      return (
        <li
          key={`selectedfacet-${getSelectedOptionDisplayText()}`}
          data-testid={`selected-facet-${getSelectedOptionDisplayText()}`}
        >
          <RemoveSelectedFacetButton
            data-testid="remove-selected-facet-button"
            enableAlternateMobileFacet={props.enableAlternateMobileFacet}
            onClick={() =>
              props.onInputChange({
                facets: removeFacet(
                  facet.facetName,
                  selection.optionName
                    ? selection
                    : {
                        from: selection.from,
                        to: selection.to,
                      },
                  props.selectedFacets,
                ),
              })
            }
          >
            <span>
              {props.enableAlternateMobileFacet ? (
                <RemoveIcon width="24px" height="24px" />
              ) : (
                <RemoveCircleIcon width="16px" height="16px" />
              )}
              <VisuallyHidden text={i18nText.removeFilter} />
            </span>
            <span>
              <span>{selectedFacetDisplayText}:</span>
              <SelectedFacetName>
                {getSelectedOptionDisplayText()}
              </SelectedFacetName>
            </span>
          </RemoveSelectedFacetButton>
        </li>
      )
    }),
  )

  return (
    <ul data-testid="current-filters" aria-label={i18nText.currentFilters}>
      {selectedFacets}
    </ul>
  )
}
