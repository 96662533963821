import * as React from 'react'
import merge from 'lodash.merge'
import Head from 'next/head'

import {
  pageTTLConfig,
  PageType as CachePageType,
} from '@thg-commerce/enterprise-cache'
import PowerReviewHead from '@thg-commerce/enterprise-components/src/PowerReview/PowerReviewHead'
import {
  EnterpriseNextPage,
  EnterpriseNextPageContext,
  getSafeUrlParametersFromWindow,
  i18n,
  Routes,
  useCheckoutStart,
  useEnterpriseContext,
  useSessionSettings,
  useSiteConfig,
  useTheme,
  withCacheConfiguration,
} from '@thg-commerce/enterprise-core'
import { PageType } from '@thg-commerce/enterprise-core/src/App/types'
import {
  AddToBasketItem,
  InteractionLocation,
  useAddToBasket,
} from '@thg-commerce/enterprise-core/src/Basket/hooks/useAddToBasket/useAddToBasket'
import { useBasket } from '@thg-commerce/enterprise-core/src/Basket/hooks/useBasketId'
import { FullHeaderLayout } from '@thg-commerce/enterprise-core/src/Layouts'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  pushFulfilmentData,
  pushToEnhancedDataLayer,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'
import {
  ComponentName,
  ComponentWidgetsVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/ComponentWidgets'
import { campaignVary } from '@thg-commerce/enterprise-network/src/enrichers/vary/campaign'
import {
  BasketItemsFragment,
  Country,
  Currency,
  Feature,
  FulfilmentMethod,
  Maybe,
  QueryPageArgs,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  BasketPageLayout,
  loadPageTheme,
  PageTheme,
} from '@thg-commerce/enterprise-pages'
import { ThemeProvider } from '@thg-commerce/enterprise-theme'

import { CheckoutErrorType } from '../CheckoutError'
import { Empty } from '../Empty'
import { Skeleton } from '../Skeleton'

import { BasketWithItems } from './Components/BasketWithItems/BasketWithItems'
import { ComponentWidgets as WIDGET_QUERY } from './ComponentWidgets.graphql'
import {
  LiveChatTagWrapper,
  PageContainer,
  PageGridItem,
  StyledPageWrapper,
} from './styles'

interface BasketPageProps {
  pageTheme: PageTheme<BasketPageLayout> | null
  componentWidgets?: Omit<Widget, 'query'>[]
}

const getCategory = (
  product: BasketItemsFragment['items'][0]['product']['product'],
): string =>
  product?.breadcrumbs
    ?.filter((breadcrumb) => breadcrumb.pagePath !== '/')
    .map((breadcrumb) => breadcrumb.displayName)
    .join('/') || ''

const showDropshipMessage = (
  eligibleForDropshipReturn?: boolean,
  enableDropshipMessageOnBasket?: boolean,
) => enableDropshipMessageOnBasket && eligibleForDropshipReturn

export const BasketPage: EnterpriseNextPage<BasketPageProps> = (
  props: BasketPageProps,
) => {
  const theme = useTheme()
  const { horizonFeatures } = useEnterpriseContext()
  const { execute: addToBasket } = useAddToBasket({
    forceAddToBasket: true,
  })

  const {
    execute: executeCheckoutStart,
    data: checkoutStartData,
  } = useCheckoutStart()

  const {
    hasLivePerson,
    hideProductRecommendations,
    enhancedEcommerceEnabled,
    showDeliveryCalculatedAtCheckoutMessage,
    basketShowTotalRrpDiscountPerItem,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    useGA4EnhancedEcom,
    hasAdaChatbot,
    enableDropshipMessageOnBasket,
  } = useSiteConfig()

  const sessionSettings = useSessionSettings()

  const clickAndCollectEnabled =
    horizonFeatures?.includes(Feature.ClickAndCollect) || false

  const [
    selectYourSampleInteracted,
    setSelectYourSampleInteracted,
  ] = React.useState(false)
  const [
    selectYourSampleModalOpen,
    setSelectYourSampleModalOpen,
  ] = React.useState(false)

  const { basket, loading } = useBasket()

  const urlParams =
    typeof window !== 'undefined' &&
    getSafeUrlParametersFromWindow<{
      checkoutError: CheckoutErrorType
      buylist: string
    }>(window, ['checkoutError', 'buylist'])

  const shouldSelectYourSampleBeOpen = React.useMemo(
    () => (tiers: BasketData['selectYourSample'][0]['tiers']) =>
      basket?.selectYourSample.length === 1 &&
      tiers.reduce((acc, tier) => acc + tier.products.length, 0) < 7,
    [basket?.selectYourSample],
  )

  const shouldDisplayClickAndCollect =
    clickAndCollectEnabled && basket?.hasProductWithClickAndCollectFulfilment

  const urlParamsBuylist = urlParams['buylist']

  React.useEffect(() => {
    if (!loading) {
      enhancedEcommerceEnabled &&
        pushToEnhancedDataLayer({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: { step: 1 },
              products: basket?.items.map((item) => {
                const homeDeliveryAvailable = item.product
                  ?.eligibleForFulfilmentMethods
                  ? Boolean(
                      item.product?.eligibleForFulfilmentMethods?.includes(
                        FulfilmentMethod.HomeDelivery,
                      ),
                    )
                  : true
                const clickAndCollectAvailable = item.product
                  ?.eligibleForFulfilmentMethods
                  ? Boolean(
                      item.product?.eligibleForFulfilmentMethods?.includes(
                        FulfilmentMethod.CollectInStore,
                      ),
                    )
                  : false

                const productViewData = pushFulfilmentData(
                  item.product.inStock,
                  homeDeliveryAvailable,
                  clickAndCollectAvailable,
                  item.fulfilmentMethod,
                )

                return {
                  name: item.product.title,
                  id:
                    (useExternalIdentifier || useExternalIdentifierInSchema) &&
                    item.product?.externalIdentifier
                      ? item.product.externalIdentifier
                      : item.product.sku.toString(),
                  price: item.standardPricePerUnit.amount,
                  category: getCategory(item.product.product),
                  ...(clickAndCollectEnabled && {
                    brand: item.product.product?.brand?.name,
                    ...productViewData,
                    price: item.chargePricePerUnit.amount,
                  }),
                  quantity: item.quantity,
                }
              }),
            },
          },
        })
      useGA4EnhancedEcom &&
        pushToEnhancedDataLayer({
          event: 'view_cart',
          ecommerce: {
            currency: sessionSettings?.currency,
            value: basket?.chargePrice?.amount,
            items: basket?.items.map((item, index) => {
              return {
                index,
                item_id:
                  (useExternalIdentifier || useExternalIdentifierInSchema) &&
                  item.product?.externalIdentifier
                    ? item.product.externalIdentifier
                    : item.product.product?.externalIdentifier
                    ? item.product.product.externalIdentifier
                    : item.product.sku?.toString() || '',
                item_name: item.product.title,
                item_variant: item.product?.variantIdentifier || '',
                price: item.standardPricePerUnit.amount,
                item_category: getCategory(item.product.product),
                item_brand: item.product.product?.brand?.name || '',
                quantity: item.quantity,
              }
            }),
          },
        })
    }
  }, [
    loading,
    basket?.chargePrice?.amount,
    basket?.items,
    clickAndCollectEnabled,
    enhancedEcommerceEnabled,
    sessionSettings?.currency,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    useGA4EnhancedEcom,
  ])

  React.useEffect(() => {
    if (!loading && basket) {
      basket.selectYourSample.map((sample) => {
        if (shouldSelectYourSampleBeOpen(sample.tiers)) {
          pushToDataLayer({
            type: 'elysiumEvent',
            eventData: {
              eventAction: 'Opened Free Gift On Load',
              eventCategory: 'freeProductSelection',
              eventLabel: 'Offer Id',
              eventLabelValue: sample.id,
            },
          })
        }

        pushToDataLayer({
          type: 'elysiumEvent',
          eventData: {
            eventAction: 'Free Gift Appeared',
            eventCategory: 'freeProductSelection',
            eventLabel: 'Offer Id',
            eventLabelValue: sample.id,
          },
        })
      })
    }
  }, [basket, loading, shouldSelectYourSampleBeOpen])

  React.useEffect(() => {
    if (urlParamsBuylist) {
      const skus: AddToBasketItem[] = urlParamsBuylist?.split(',').map((sku) =>
        sku.includes(':')
          ? {
              sku: sku.split(':')[0],
              quantity: parseInt(sku.split(':')[1], 10),
            }
          : { sku, quantity: 1 },
      )

      if (skus && skus.length) {
        addToBasket(skus, {
          fromRecommendations: false,
          location: InteractionLocation.FREQUENTLY_BOUGHT_TOGETHER,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsBuylist])

  const i18nText = {
    deliveryChargeMessage: i18n('basket.delivery.message.text'),
    metaTagTitle: i18n('general.page.title.basket.text'),
    siteTitleEnd: i18n('titles.end.text'),
    totalSaving: i18n('basket.discount.summary.title'),
    discountSaving: i18n('basket.discount.saving.text'),
    totalRrp: i18n('basket.total.rrp.text'),
    pageTitle: i18n('general.page.title.basket.text'),
    checkoutStart: i18n('basket.checkoutstart.text'),
    recommendationsTitle: i18n('basket.recommendations.title.text'),
    basketSubtotal: `${i18n('basket.subtotal.text').replace(/:$/, '')}:`,
    basketTotal: `${i18n('basket.total.text').replace(/:$/, '')}:`,
    item: i18n('general.item.text'),
    items: i18n('general.items.text'),
    continueShopping: i18n('general.continueshopping.label'),
    loyaltyText: i18n(
      'basket.loyalty.text',
      String(basket?.earnableLoyaltyPoints),
    ),
    rrpText: i18n('product.price.rrp.text'),
    orderSummaryTitle: i18n('basket.orders.summary.heading'),
    standardPriceTitle: i18n('basket.totalbeforediscount.label'),
    dropshipMessage: i18n('basket.dropship.message'),
  }

  const emptyI18nText = {
    title: i18n('basket.empty.title.text'),
    subtitle: i18n('basket.empty.subtitle.text'),
    continueShopping: i18n('general.continueshopping.label'),
  }

  const checkoutStartError: CheckoutErrorType | undefined =
    checkoutStartData?.checkout.error ||
    (urlParams ? urlParams?.checkoutError : undefined)

  const onCheckoutClick = () => {
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Go To Checkout',
        eventCategory: 'Link',
      },
    })

    if (basket?.selectYourSample.length) {
      if (!selectYourSampleInteracted) {
        setSelectYourSampleInteracted(true)
        setSelectYourSampleModalOpen(true)
        return
      }
      pushToDataLayer({
        type: 'elysiumEvent',
        eventData: {
          eventAction: 'Clicked to go straight to checkout',
          eventCategory: 'qualifiedFreeGiftModal',
        },
      })
    }
    executeCheckoutStart()
  }

  const displayChatContainer = (hasLivePerson?: boolean) => {
    if (hasLivePerson) {
      return (
        <LiveChatTagWrapper>
          {/* Don't change these class names, GTM uses them to add the LivePerson scripts in */}
          <div className="lp-panel">
            <div id="checkout-lpButtonDiv"></div>
          </div>
        </LiveChatTagWrapper>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <Head>
        <title>
          {i18nText.metaTagTitle} {i18nText.siteTitleEnd}
        </title>
      </Head>
      <PowerReviewHead />
      <ThemeProvider
        theme={merge(theme, { pageTheme: props.pageTheme?.theme })}
      >
        <PageContainer>
          <StyledPageWrapper compactMargin={true}>
            {typeof window === 'undefined' ||
            (loading && basket === undefined) ? (
              <PageGridItem colSpan={[12, 12, 12, 12]} colStart={[0, 0, 0, 0]}>
                <Skeleton />
              </PageGridItem>
            ) : !basket || (basket && basket.items.length === 0) ? (
              <PageGridItem colSpan={[12, 12, 12, 12]} colStart={[0, 0, 0, 0]}>
                <Empty
                  i18nText={emptyI18nText}
                  widgets={props.componentWidgets}
                />
              </PageGridItem>
            ) : (
              <BasketWithItems
                showDeliveryCalculatedAtCheckoutMessage={
                  showDeliveryCalculatedAtCheckoutMessage
                }
                showDropshipMessage={showDropshipMessage(
                  basket?.eligibleForDropshipReturn,
                  enableDropshipMessageOnBasket,
                )}
                basketShowTotalRrpDiscountPerItem={
                  basketShowTotalRrpDiscountPerItem
                }
                shouldDisplayClickAndCollect={shouldDisplayClickAndCollect}
                shouldSelectYourSampleBeOpen={shouldSelectYourSampleBeOpen}
                basket={basket}
                onCheckoutClick={onCheckoutClick}
                checkoutStartError={checkoutStartError}
                hideProductRecommendations={hideProductRecommendations || false}
                i18nText={i18nText}
                selectYourSampleModalOpen={selectYourSampleModalOpen}
                setSelectYourSampleModalOpen={setSelectYourSampleModalOpen}
                selectYourSampleInteracted={selectYourSampleInteracted}
                setSelectYourSampleInteracted={setSelectYourSampleInteracted}
              />
            )}
            {displayChatContainer(hasLivePerson)}
          </StyledPageWrapper>
        </PageContainer>
      </ThemeProvider>
    </React.Fragment>
  )
}

BasketPage.Layout = FullHeaderLayout

BasketPage.pageType = PageType.BASKET

BasketPage.supportsConcessions = true

BasketPage.getInitialProps = withCacheConfiguration(
  async (ctx: EnterpriseNextPageContext) => {
    const {
      siteDefinition,
      shippingDestination,
      siteConfig,
    } = ctx.config.publicRuntimeConfig
    const { horizonFeatures } = ctx.req
    const { sessionSettings } = ctx.req.config

    const pageTheme = await loadPageTheme<BasketPageLayout>({
      page: 'basket',
      brand: siteDefinition.brand,
      subsite: siteDefinition.subsite,
    })

    const vipPriceEnabled =
      horizonFeatures?.includes(Feature.VipPricingEnabled) ?? false

    const { data } = await ctx.apolloClient.query<
      {
        componentWidgets: Maybe<Widget[]>
      },
      QueryPageArgs & ComponentWidgetsVariables
    >({
      query: WIDGET_QUERY,
      variables: {
        vipPriceEnabled,
        path: Routes.Basket,
        name: ComponentName.BASKET,
        currency:
          sessionSettings?.currency ||
          (siteDefinition.defaultCurrency as Currency),
        shippingDestination:
          sessionSettings?.shippingDestination ||
          (shippingDestination.code as Country),
        subscriptionsEnabled:
          horizonFeatures?.includes(Feature.Subscriptions) || false,
        subscriptionContractsEnabled:
          horizonFeatures?.includes(Feature.SubscribeAndSave) || false,
      },
    })

    const varyHeaders = ctx.req.config.enableVary
      ? await campaignVary({
          paths: ['/header', '/footer'],
          siteId: parseInt(siteDefinition.siteId, 10),
          subsite: siteDefinition.subsite,
          config: siteConfig,
        })
      : []

    return {
      pageTheme,
      componentWidgets: data?.componentWidgets || [],
      cache: {
        ...(pageTTLConfig(CachePageType.BASKET) || { ttl: 300, grace: 86400 }),
        vary: varyHeaders,
      },
    }
  },
)
