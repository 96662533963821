import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'

import { ProductType } from '../../../../types/enterpriseTypes'
import {
  Product as HorizonProduct,
  ProductImage,
  ProductPrice,
  ProductReviews,
  ProductVariant,
} from '../../../generated/graphql'
import { product } from '../../../graphql/Query/Product/Product.graphql'
import { mergeVariantProduct } from '../../../transformers/product'
import { ClientBehaviours } from '../../utils'

export enum PRODUCT_CONTENT_KEY {
  MAT_CATEGORY = 'mat_category',
}

export const Product = (
  client: ApolloClient<NormalizedCacheObject>,
  { behaviours }: { behaviours: ClientBehaviours },
) => async (_parent, args: any) => {
  if (behaviours.override) {
    return await import('../../data/OldProductMock').then((mod) => {
      return {
        ...mod.MOCK_PRODUCT,
        ...getProductPrice(mod.MOCK_PRODUCT.variants),
        recommendations: getRecommendations(mod.MOCK_PRODUCT.recommendations),
        productType:
          mod.MOCK_PRODUCT.variants.length > 1
            ? ProductType.COMPLEX
            : ProductType.SIMPLE,
      }
    })
  }

  const { data } = await client.query({
    query: product,
    variables: args,
  })

  if (!data?.product) {
    return null
  }

  const productType =
    (data.product.variants || []).length > 1
      ? ProductType.COMPLEX
      : ProductType.SIMPLE

  if (data.product?.sku === Number(args.sku)) {
    const variant =
      data.product?.variants?.find(
        (variant) => variant.sku === Number(args.sku),
      ) ||
      data.product?.defaultVariant ||
      data.product?.variants[0]

    const { variants, ...updatedData } = data.product

    const subscriptionContracts = variants.flatMap((item) =>
      Array.isArray(item.subscriptionContracts)
        ? item.subscriptionContracts
        : [item.subscriptionContracts],
    )

    return {
      ...updatedData,
      ...getProductPrice([variant]),
      subscriptionContracts,
      productType,
      externalIdentifier: variant?.externalIdentifier || '',
      recommendations: getRecommendations(data.product?.recommendations),
      inStock: variant?.inStock || false,
      variantCount: (data.product?.variants || []).length,
    }
  }

  if (data.product?.variants?.length > 0) {
    const variantProduct = data.product.variants.find(
      (varProduct: ProductVariant) => varProduct.sku === Number(args.sku),
    )

    if (variantProduct) {
      return {
        ...mergeVariantProduct(data.product, variantProduct),
        ...getProductPrice([variantProduct]),
        productType,
        externalIdentifier: data.product?.externalIdentifier || '',
        recommendations: getRecommendations(data.product?.recommendations),
        variantCount: (data.product?.variants || []).length,
        __typename: 'Product',
      }
    }
  }

  return {
    ...data.product,
    ...getProductPrice(data.product?.variants),
    productType,
    externalIdentifier: data.product?.externalIdentifier || '',
    inStock: data.product?.inStock || false,
    recommendations: getRecommendations(data.product?.recommendations),
    variantCount: (data.product?.variants || []).length,
    __typename: 'Product',
  }
}

export const getRecommendations = (
  productRecommendations:
    | {
        sku?: number
        url?: string
        title?: string
        brand?: {
          name?: string
        }
        images?: ProductImage[]
        variants?: ProductVariant[]
        reviews?: ProductReviews
        map(param: (recommendation) => any): HorizonProduct[] | undefined
      }
    | null
    | undefined,
) => {
  let recommendations: HorizonProduct[] | undefined
  if (productRecommendations) {
    recommendations = productRecommendations.map((recommendation) => {
      return import('../../data/OldProductMock').then((mod) => {
        return {
          ...recommendation,
          ...getProductPrice(recommendation.variants),
          productType:
            mod.MOCK_PRODUCT.variants.length > 1
              ? ProductType.COMPLEX
              : ProductType.SIMPLE,
        }
      })
    })
  }
  return recommendations
}

export const getProductPrice = (
  variants: { price?: ProductPrice }[] | null | undefined,
) => {
  if (!variants || variants.length === 0) {
    return {}
  }

  if (variants.length === 1) {
    return {
      hasFromPrice: false,
      price: {
        displayValue: variants[0]?.vipPrice
          ? variants[0]?.vipPrice?.displayValue
          : variants[0]?.price?.price?.displayValue,
        amount: variants[0]?.vipPrice
          ? variants[0]?.vipPrice?.amount
          : variants[0]?.price?.price?.amount,
        __typename: 'MoneyValue',
      },
      rrp: {
        displayValue: variants[0]?.price?.rrp?.displayValue,
        amount: variants[0]?.price?.rrp?.amount,
        __typename: 'MoneyValue',
      },
    }
  }

  const sortedVariantPrices = [...variants].sort(
    (a, b) =>
      parseFloat(
        a?.vipPrice ? a?.vipPrice?.amount : a?.price?.price?.amount || '1',
      ) -
      parseFloat(
        b?.vipPrice ? b?.vipPrice?.amount : b?.price?.price?.amount || '0',
      ),
  )

  const lastProduct = sortedVariantPrices[variants.length - 1]
  const lowestPrice =
    (sortedVariantPrices[0].price &&
      sortedVariantPrices[0].price.price &&
      sortedVariantPrices[0].price.price.amount) ||
    '0'
  const highestPrice =
    (lastProduct.price &&
      lastProduct.price.price &&
      lastProduct.price.price.amount) ||
    '0'
  const lowestVipPrice = sortedVariantPrices[0].vipPrice
    ? sortedVariantPrices[0].vipPrice.amount
    : '0'
  const highestVipPrice = lastProduct.vipPrice
    ? lastProduct.vipPrice.amount
    : '0'

  const hasFromPrice =
    lowestVipPrice < highestVipPrice || lowestPrice < highestPrice

  return {
    hasFromPrice,
    price: {
      displayValue: sortedVariantPrices[0]?.vipPrice
        ? sortedVariantPrices[0]?.vipPrice?.displayValue
        : sortedVariantPrices[0]?.price?.price?.displayValue,
      amount: sortedVariantPrices[0]?.vipPrice
        ? sortedVariantPrices[0]?.vipPrice?.amount
        : sortedVariantPrices[0]?.price?.price?.amount,
      __typename: 'MoneyValue',
    },
  }
}
