import * as React from 'react'
import { Property } from 'csstype'

import {
  Button,
  ProductSpecialOfferBadge,
} from '@thg-commerce/gravity-elements'
import { IconStyling } from '@thg-commerce/gravity-icons/src/types'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { Font } from '../../index'

import {
  Container,
  Description,
  FreeGiftPrices,
  FreeGiftTitle,
  StyledDiv,
  StyledImage,
} from './styles'

export interface Images {
  largeProduct: string
}

export interface FreeGiftProduct {
  images: Images[]
  title: string
  price: {
    rrp: {
      displayValue: string
    }
  }
}

export enum MarketedSpecialOfferDirection {
  Row = 'row',
  RowReverse = 'row-reverse',
  Column = 'column',
  ColumnReverse = 'column-reverse',
}

export interface MarketedSpecialOfferProps {
  title?: string
  description?: string
  cta?: {
    text: string
    href: string
    openExternally?: boolean
    noFollow?: boolean
  }
  freeGiftProduct?: FreeGiftProduct
  imageWidth: number
  direction?: MarketedSpecialOfferDirection
  i18nFreeGiftMessage?: string
  showIcon?: boolean
  onBadgeClick?: (event?: React.MouseEvent) => void
  onlyDisplayOfferBadge?: boolean
  reduceBottomMarginContainer?: boolean
  badgeStyle: {
    icon?: IconStyling
    textStyle: Font
    padding: Padding
    margin: Margin
    border?: { width: string; type: string }
    textAlign?: string
    width?: string
  }
  containerStyle?: {
    border?: {
      width: string
      type: string
    }
    margin?: Margin
  }
  modalStyle?: {
    display?: string
    direction?: string
    alignment?: string
    textStyle?: Font
    descriptionColorText?: Property.Color
  }
  className?: string
  handleEvents?: () => void
  isInView?: boolean
}

export const MarketedSpecialOffer = React.forwardRef(
  (
    {
      handleEvents,
      isInView,
      title,
      cta,
      ...otherProps
    }: MarketedSpecialOfferProps,
    ref: any,
  ) => {
    React.useEffect(() => {
      if (isInView && handleEvents) {
        handleEvents()
      }
    }, [isInView, title, cta?.text, handleEvents])

    return (
      <Container
        className={otherProps.className}
        direction={otherProps.direction}
        data-testid="marketed-special-offer"
        onlyDisplayOfferBadge={otherProps.onlyDisplayOfferBadge}
        reduceBottomMargin={otherProps.reduceBottomMarginContainer}
        margin={otherProps.containerStyle?.margin}
        ref={ref}
      >
        {otherProps.freeGiftProduct &&
          otherProps.freeGiftProduct?.images[0] &&
          !otherProps.onlyDisplayOfferBadge && (
            <div>
              <StyledImage
                src={[
                  {
                    url: otherProps.freeGiftProduct.images[0].largeProduct,
                    width: otherProps.imageWidth,
                  },
                ]}
                isAmp={false}
                alt=""
                width={otherProps.imageWidth}
                height={otherProps.imageWidth}
              />
            </div>
          )}
        <StyledDiv {...otherProps.modalStyle}>
          <ProductSpecialOfferBadge
            text={title || ''}
            showGiftsIcon={otherProps.showIcon}
            onClick={otherProps.onBadgeClick}
            {...{
              ...otherProps.badgeStyle,
              icon: undefined,
              marketingIcon: otherProps.badgeStyle.icon,
            }}
          />
          {!otherProps.onlyDisplayOfferBadge && (
            <React.Fragment>
              {otherProps.freeGiftProduct?.title && (
                <FreeGiftTitle>
                  {otherProps.freeGiftProduct.title}
                </FreeGiftTitle>
              )}
              {otherProps.freeGiftProduct?.price?.rrp?.displayValue && (
                <FreeGiftPrices data-testid="marketed-special-offer-gift-price">
                  {`${otherProps.i18nFreeGiftMessage}${otherProps.freeGiftProduct.price.rrp.displayValue}`}
                </FreeGiftPrices>
              )}
              {otherProps.description && (
                <Description
                  content={otherProps.description}
                  removeTagsAndContent={false}
                  textColor={otherProps.modalStyle?.descriptionColorText}
                />
              )}
              {cta && (
                <Button
                  sizing="regular"
                  emphasis="medium"
                  renderedAs="a"
                  href={cta.href}
                  rel={cta.noFollow ? 'nofollow' : undefined}
                  target={cta.openExternally ? '_blank' : undefined}
                >
                  {cta.text}
                </Button>
              )}
            </React.Fragment>
          )}
        </StyledDiv>
      </Container>
    )
  },
)
