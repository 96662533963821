import * as React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { Button } from '@thg-commerce/gravity-elements'

import { facetsCallbacks } from '../../dataTracking'
import { FacetContent } from '../FacetContent/FacetContent'
import { FacetDataLayerSchema, FacetProps } from '../types'
import { clearFacetSelection } from '../utils'

import {
  ArrowLeft,
  ArrowRight,
  BackButton,
  ChevronLeft,
  ChevronRight,
  ClearAllWrapper,
  FacetSectionContent,
  FacetTitleButton,
  Header,
  SaveButton,
  SaveWrapper,
  SectionCount,
  SectionCountWrapper,
  StyledHeading,
  StyledTitle,
} from './styles'

const getFacetArrows = (enableAlternateMobileFacet?: boolean) => {
  return enableAlternateMobileFacet
    ? [<ArrowLeft />, <ArrowRight />]
    : [<ChevronLeft />, <ChevronRight />]
}

export interface MobileFacetSectionProps extends FacetProps {
  i18nText: {
    save: string
    back: string
    clear: string
    searchBrandLabel: string
    searchBrandPlaceholder: string
    minimumLabel: string
    maximumLabel: string
    sliderHeader: string
  }
  setShowMobileFacets: (state: boolean) => void
  setRefreshFacets: (state: boolean) => void
  facetSchema: FacetDataLayerSchema
  enableAlternateMobileFacet?: boolean
}

export const MobileFacetSection = (props: MobileFacetSectionProps) => {
  const [openFacetContent, setOpenFacetContent] = React.useState(false)
  const [brandSearch, setBrandSearch] = React.useState('')
  const { useGA4EnhancedEcom } = useSiteConfig()
  const handleBackButtonClick = () => {
    setBrandSearch('')
    setOpenFacetContent(false)
    props.setRefreshFacets(true)
    facetsCallbacks.closeMobileFacetsContentClicked(props.facet.facetHeader)
  }

  const handleSaveButtonClick = () => {
    setBrandSearch('')
    setOpenFacetContent(false)
    props.setShowMobileFacets(false)
  }

  const sectionCount = props.selectedFacets.find(
    (selection) => selection.facetName === props.facet.facetName,
  )?.selections.length

  const showSectionCount = sectionCount !== undefined && sectionCount > 0
  const [chevronLeft, chevronRight] = getFacetArrows(
    props.enableAlternateMobileFacet,
  )

  return (
    <React.Fragment>
      <FacetTitleButton
        data-testid="mobile-facets-section-title-button"
        enableAlternateMobileFacet={props.enableAlternateMobileFacet}
        onClick={() => {
          setOpenFacetContent(true)
          facetsCallbacks.openMobileFacetsContentClicked(
            props.facet.facetHeader,
          )
        }}
      >
        {showSectionCount ? (
          <SectionCountWrapper>
            {props.facet.facetHeader}
            <SectionCount>{sectionCount}</SectionCount>
          </SectionCountWrapper>
        ) : (
          props.facet.facetHeader
        )}
        {chevronRight}
      </FacetTitleButton>
      <FacetSectionContent
        showFacetContent={openFacetContent}
        enableAlternateMobileFacet={props.enableAlternateMobileFacet}
      >
        <Header enableAlternateMobileFacet={props.enableAlternateMobileFacet}>
          <BackButton
            data-testid="mobile-facets-section-back-button"
            onClick={handleBackButtonClick}
            enableAlternateMobileFacet={props.enableAlternateMobileFacet}
          >
            {chevronLeft}
            {!props.enableAlternateMobileFacet && props.i18nText.back}
          </BackButton>
          {props.enableAlternateMobileFacet && (
            <StyledHeading>{props.facet.facetHeader}</StyledHeading>
          )}
          {(brandSearch.length > 0 || showSectionCount) && (
            <ClearAllWrapper>
              <Button
                data-testid="mobile-facets-section-clear-button"
                emphasis="low"
                onClick={() => {
                  setBrandSearch('')
                  props.onInputChange({
                    facets: clearFacetSelection(
                      props.facet.facetName,
                      props.selectedFacets,
                    ),
                  })
                }}
              >
                {props.i18nText.clear}
              </Button>
            </ClearAllWrapper>
          )}
        </Header>
        {!props.enableAlternateMobileFacet &&
          (showSectionCount ? (
            <SectionCountWrapper>
              <StyledTitle>{props.facet.facetHeader}</StyledTitle>
              <SectionCount addTitleMargins>{sectionCount}</SectionCount>
            </SectionCountWrapper>
          ) : (
            <StyledTitle>{props.facet.facetHeader}</StyledTitle>
          ))}
        <FacetContent
          isMobile
          facet={props.facet}
          selectedFacets={props.selectedFacets}
          onInputChange={props.onInputChange}
          brandSearch={{
            state: brandSearch,
            setState: setBrandSearch,
          }}
          i18nText={props.i18nText}
          useGA4EnhancedEcom={useGA4EnhancedEcom}
          facetSchema={props.facetSchema}
          enableAlternateMobileFacet={props.enableAlternateMobileFacet}
        />
        {openFacetContent && (
          <SaveWrapper
            enableAlternateMobileFacet={props.enableAlternateMobileFacet}
          >
            <SaveButton
              data-testid="mobile-facets-section-save-button"
              onClick={handleSaveButtonClick}
            >
              {props.i18nText.save}
            </SaveButton>
          </SaveWrapper>
        )}
      </FacetSectionContent>
    </React.Fragment>
  )
}
