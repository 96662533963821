import * as React from 'react'

import { useTheme } from '@thg-commerce/enterprise-theme'
import { ButtonEmphases } from '@thg-commerce/gravity-elements/Button/Button'
import { PictureProps } from '@thg-commerce/gravity-system'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import {
  BannerContainer,
  ButtonsContainer,
  HighlightContainer,
  ImageItem,
  StyledButton,
  StyledLink,
  StyledLogoImage,
  StyledPicture,
  Subtitle,
  TextContainer,
  TextGrid,
  TextGridItem,
  Title,
} from './styles'

export type BoxHorizontalAlignment = 'left' | 'middle' | 'right'
export type TextHorizontalAlignment = 'left' | 'center'

const PrefetchStyledLink = withPrefetch('href', StyledLink)

export interface CtaProps {
  text: string
  link: string
  emphasis?: ButtonEmphases
}

export interface BannerProps {
  picture: PictureProps
  imageLink?: string
  title?: {
    text: string
    headingLevel?: 'h1' | 'h2'
  }
  logoImage?: PictureProps & {
    white?: string
    altText: string
    margin?: Margin
  }
  marginBottom?: string
  subtitle?: string
  containBanner?: boolean
  textColor?: 'light' | 'dark'
  ctas?: [CtaProps, ...CtaProps[]]
  textContainerOptions?: {
    width: 'full' | 'contained'
    contentAlignment: {
      box: BoxHorizontalAlignment
      text: TextHorizontalAlignment
    }
  }
  highlight?: string
  containerBackground?: string
  spacingAdjust?: boolean
  lazy?: boolean
  fetchPriority?: 'auto' | 'high'
  hideGap?: boolean
  enableBannerTextOverlay?: boolean
}

const Image = (props: {
  picture: PictureProps
  useImageLink: boolean
  imageLink?: string
  logoImage?: PictureProps & {
    white?: string
    altText: string
    margin?: Margin
  }
  title?: {
    text: string
    headingLevel?: 'h1' | 'h2'
  }
  lazy?: boolean
  fetchPriority?: 'auto' | 'high'
}) => {
  const imageDescription =
    props.picture?.alt ||
    props.logoImage?.altText ||
    props?.title?.text ||
    props.imageLink ||
    ''
  const picture = (
    <StyledPicture
      {...props.picture}
      alt={imageDescription}
      lazy={props.lazy}
      fetchPriority={props.fetchPriority || 'high'}
    />
  )
  return props.useImageLink ? (
    <PrefetchStyledLink
      data-testid="banner-picture-link"
      href={props.imageLink}
      aria-label={imageDescription}
    >
      {picture}
    </PrefetchStyledLink>
  ) : (
    picture
  )
}

export const Banner = (props: BannerProps) => {
  const theme = useTheme()

  const buttons = props.ctas
    ?.filter((cta) => cta.text && cta.link)
    .map((cta, index) => (
      <StyledButton
        renderedAs="a"
        emphasis={
          theme.widget.GlobalPrimaryBanner.button?.emphasis ||
          cta.emphasis ||
          'medium'
        }
        href={cta.link}
        key={index}
      >
        {cta.text}
      </StyledButton>
    ))

  const renderCtas = buttons && buttons.length > 0

  const useImageLink = Boolean(props.imageLink)

  const middleSpacing = props.spacingAdjust ? 7 : 8
  const textColStart = [
    { left: 1, middle: 5, right: 9 },
    { left: 1, middle: middleSpacing, right: 14 },
    { left: 1, middle: middleSpacing, right: 15 },
  ]

  return (
    <BannerContainer
      rows={2}
      columns={1}
      rowGap={0}
      containBanner={props.containBanner}
      marginBottom={props.marginBottom}
      spacingAdjust={props.spacingAdjust}
      hideGap={props.hideGap}
    >
      <ImageItem
        data-testid="banner-picture-grid"
        rowStart={1}
        colStart={1}
        colSpan={1}
        rowSpan={props.enableBannerTextOverlay ? [2, 2, 2, 2] : [1, 2, 2, 2]}
      >
        <Image
          picture={props.picture}
          useImageLink={useImageLink}
          imageLink={props.imageLink}
          logoImage={props.logoImage}
          title={props.title}
          lazy={props.lazy}
          fetchPriority={props.fetchPriority}
        />
      </ImageItem>
      {(props.title || props.subtitle || props.ctas) && (
        <TextContainer
          rowStart={[2, 1, 1, 1]}
          colStart={1}
          colSpan={1}
          rowSpan={[1, 2, 2, 2]}
          increaseMaxWidth={props.textContainerOptions?.width === 'full'}
          removePointerEvents={useImageLink}
          spacingAdjust={props.spacingAdjust}
        >
          <TextGrid columns={24} spacingAdjust={props.spacingAdjust}>
            <TextGridItem
              colSpan={
                props.spacingAdjust
                  ? [18, 16, 12, 12]
                  : theme.widget.banner.textColSpan
              }
              colStart={
                props.textContainerOptions
                  ? [
                      1,
                      textColStart[0][
                        props.textContainerOptions.contentAlignment.box
                      ],
                      textColStart[1][
                        props.textContainerOptions.contentAlignment.box
                      ],
                      textColStart[2][
                        props.textContainerOptions.contentAlignment.box
                      ],
                    ]
                  : 1
              }
              containerBackground={props.containerBackground}
            >
              {props.highlight && (
                <HighlightContainer
                  textAlign={props.textContainerOptions?.contentAlignment.text}
                  containerBackground={props.containerBackground}
                  data-testid="highlight"
                >
                  {props.highlight}
                </HighlightContainer>
              )}
              {props.logoImage?.default && (
                <StyledLogoImage
                  sources={[
                    {
                      sourceSet: props.logoImage.white || '',
                      media: '(max-width: 600px)',
                    },
                    {
                      sourceSet: props.logoImage.default || '',
                    },
                  ]}
                  default={props.logoImage.default}
                  alt={props.logoImage.altText}
                  margin={theme.widget.banner.logoImage.margin}
                />
              )}
              {props.title && (
                <Title
                  renderedAs={props.title.headingLevel || 'h2'}
                  textAlign={props.textContainerOptions?.contentAlignment.text}
                  lightText={props.textColor === 'light'}
                  spacingAdjust={props.spacingAdjust}
                  content={props.title.text}
                  enableBannerTextOverlay={props.enableBannerTextOverlay}
                />
              )}
              {props.subtitle && (
                <Subtitle
                  renderedAs="p"
                  textAlign={props.textContainerOptions?.contentAlignment.text}
                  lightText={props.textColor === 'light'}
                  containerBackground={props.containerBackground}
                  content={props.subtitle}
                  enableBannerTextOverlay={props.enableBannerTextOverlay}
                />
              )}
              {renderCtas && (
                <ButtonsContainer
                  textAlign={props.textContainerOptions?.contentAlignment.text}
                >
                  {buttons}
                </ButtonsContainer>
              )}
            </TextGridItem>
          </TextGrid>
        </TextContainer>
      )}
    </BannerContainer>
  )
}
