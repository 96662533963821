import * as React from 'react'
import { Property } from 'csstype'

import { ChevronRight, SvgIcon } from '@thg-commerce/gravity-icons/src'
import { css, styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

import Navigation from '../Navigation/Navigation'
import { NavigationLink } from '../NavigationTree'
import { removeSpacesAndCharacters } from '../Utils'

export const navigationLinkBorder = css<{ hideBottomBorder?: boolean }>`
  position: relative;
  ::after {
    content: '';
    position: absolute;
    width: ${(props) =>
      props.theme.patterns.header.navigation.navigationTextItem?.width ??
      `calc(100% - ${spacing(2)});`};
    bottom: 0;
    z-index: -1;
    left: ${(props) =>
      props.theme.patterns.header.navigation.navigationTextItem?.left ?? '8px'};
    border-bottom: ${(props) =>
      !props.hideBottomBorder
        ? props.theme.patterns.header.navigation.navigationTextItem
            ?.borderBottom ??
          props.theme.patterns.header.navigation.borderBottom
        : 'none'};
  }
  &:focus::after {
    border-bottom: none;
  }
`

const highlightStyles = css<{
  thirdLevelLink?: boolean
  promotionBackgroundColour?: Property.Color
  promotionBackgroundColourTwo?: Property.Color
  currentLevel?: number
  promotionTextColour?: Property.Color
  promotionTextColourTwo?: Property.Color
  isLevelHome?: boolean
}>`
  background-color: ${(props) =>
    props.theme.patterns.header.navigation.mobileLinkBackgroundHover};
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.mobileLinkHover.entry,
      props.thirdLevelLink
        ? 'default'
        : props.theme.patterns.header.navigation.mobileLinkHover.style,
    )}

  ${(props) =>
    props.currentLevel === 2 &&
    !props.isLevelHome &&
    Text(
      props.theme.patterns.header.navigation.mobileLinkFontThirdTier?.entry ||
        'bodyText',
      props.thirdLevelLink
        ? 'default'
        : props.theme.patterns.header.navigation.mobileLinkFontThirdTier
            ?.style || 'default',
    )}
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.mobileLinkHover.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.mobileLinkHover.textDecoration};
  color: ${(props) =>
    props.theme.patterns.header.navigation.mobileLinkHover.textColor};
  ${(props) =>
    (props.promotionTextColour || props.promotionTextColourTwo) &&
    props.currentLevel === 0 &&
    `color: ${(props) =>
      props.promotionTextColour || props.promotionTextColourTwo};`}
  ${(props) =>
    (props.promotionBackgroundColour || props.promotionBackgroundColourTwo) &&
    props.currentLevel === 0 &&
    `background-color: ${(props) =>
      props.promotionBackgroundColour || props.promotionBackgroundColourTwo};`}

  ${(props) =>
    props.currentLevel === 2 &&
    !props.isLevelHome &&
    `text-transform: capitalize;
  `}
`

const navigationLinkCommonStyles = css<{
  enableThreeTierNav?: boolean
  currentLevel?: number
  thirdLevelLink?: boolean
  isSubheading?: boolean
  promotionBackgroundColour?: Property.Color
  promotionBackgroundColourTwo?: Property.Color
  promotionTextColour?: Property.Color
  promotionTextColourTwo?: Property.Color
  navSubLevelPromotion?: boolean
  showLabel?: boolean
  isLevelHome?: boolean
}>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.enableThreeTierNav &&
    `
      position: relative;
      text-align: left;
    `};
  justify-content: space-between;
  height: ${(props) =>
    props.thirdLevelLink
      ? 'auto'
      : props.enableThreeTierNav
      ? props.currentLevel === 0
        ? '72px'
        : props.isSubheading
        ? '48px'
        : '56px'
      : 'auto'};

  width: 100%;
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.mobileLinkFont.entry,
      props.thirdLevelLink
        ? 'default'
        : props.theme.patterns.header.navigation.mobileLinkFont.style,
    )};
  padding: ${(props) =>
    props.enableThreeTierNav
      ? `0 ${spacing(
          props.theme.patterns.header.navigation.mobileLinkPadding || 1.75,
        )}`
      : props.currentLevel === 0 || props.currentLevel === 1
      ? `${spacing(
          props.theme.patterns.header.navigation.mobileLinkPadding ?? 0,
        )} ${spacing(1)}}`
      : `0 ${spacing(1)}`};

  border: 2px solid transparent;
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.mobileLinkFont.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.mobileLinkFont.transform};

  ${(props) =>
    props.currentLevel === 2 &&
    !props.isLevelHome &&
    ` text-transform: capitalize;`}
  ${(props) =>
    props.currentLevel === 2 &&
    !props.isLevelHome &&
    Text(
      props.theme.patterns.header.navigation.mobileLinkFontThirdTier?.entry ||
        'bodyText',
      props.thirdLevelLink
        ? 'default'
        : props.theme.patterns.header.navigation.mobileLinkFontThirdTier
            ?.style || 'default',
    )}


  ${(props) =>
    props.isLevelHome &&
    props.currentLevel !== 0 &&
    `background-color: ${
      props.theme.patterns.header.navigation.mobileNavThirdTierHeader
        ?.backgroundColor || ''
    };
    text-transform: capitalize;
    margin: ${
      props.theme.patterns.header.navigation.homeLevel?.margin?.top || 0
    } ${props.theme.patterns.header.navigation.homeLevel?.margin?.right || 0} ${
      props.theme.patterns.header.navigation.homeLevel?.margin?.bottom || 0
    } ${props.theme.patterns.header.navigation.homeLevel?.margin?.left || 0};
  `}


  ${(props) =>
    props.isSubheading &&
    `
      background-color: ${
        props.theme.patterns.header.navigation.mobileLinkSubheading
          .backgroundColor
      };
      margin-bottom: ${spacing(1)};
      text-transform: ${
        props.theme.patterns.header.navigation.mobileLinkSubheading
          .textTransform || 'none'
      };
    `}

  ${(props) =>
    props.navSubLevelPromotion &&
    props.currentLevel === 1 &&
    `
      color: ${
        props.theme.patterns.header.navigation.subMenu.promotion?.textColor ||
        props.theme.colors.system.pap.sale
      };
    `}
  ${(props) =>
    props.thirdLevelLink &&
    `
      text-transform: none;
    `}

  @media (hover: none) {
    &:active {
      ${highlightStyles}
    }
  }

  @media (hover: hover) {
    &:hover {
      ${highlightStyles}
    }
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    text-transform: ${(props) =>
      props.theme.patterns.header.navigation.mobileLinkFont.transform};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      Text(
        props.theme.patterns.header.navigation.mobileLinkFont.entry,
        props.thirdLevelLink
          ? 'default'
          : props.theme.patterns.header.navigation.mobileLinkFont.style,
      )}
    text-transform: ${(props) =>
      props.theme.patterns.header.navigation.mobileLinkFont.transform};
    text-decoration: ${(props) =>
      props.theme.patterns.header.navigation.mobileLinkFont.textDecoration};
    color: ${(props) =>
      props.theme.patterns.header.navigation.mobileLinkFont.textColor};
  }
  ${(props) =>
    (props.promotionTextColour || props.promotionTextColourTwo) &&
    props.currentLevel === 0 &&
    `color: ${props.promotionTextColour || props.promotionTextColourTwo};`}

  ${(props) =>
    (props.promotionBackgroundColour || props.promotionBackgroundColourTwo) &&
    props.currentLevel === 0 &&
    `
      background-color: ${
        props.promotionBackgroundColour || props.promotionBackgroundColourTwo
      };
    `}
`

const NavigationLinkText = styled.span`
  text-align: left;
`

const NavigationLinkWithChildren = styled.button`
  ${navigationLinkCommonStyles}
`

const NavigationStyledLinkPromotion = (props) =>
  props.promotion &&
  css`
    color: ${props.theme.patterns.header.navigation.subMenu.promotion
      ?.textColor || props.theme.colors.system.pap.sale};
  `

const NavigationStyledLink = styled.a<{
  showBottomBorder?: boolean
  enableThreeTierNav?: boolean
  currentLevel?: number
  thirdLevelLink?: boolean
  isSubheading?: boolean
  promotionBackgroundColour?: Property.Color
  promotionBackgroundColourTwo?: Property.Color
  promotionTextColour?: Property.Color
  promotionTextColourTwo?: Property.Color
  isLevelHome?: boolean
}>`
  ${navigationLinkCommonStyles}
  ${(props) => props.showBottomBorder && navigationLinkBorder}
  ${NavigationStyledLinkPromotion}
  padding: 6px 8px;
`

const StyledChevronRight = styled(ChevronRight)`
  width: 24px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
`

const checkNavPromotionLink = (
  navSubLevelPromotionList?: string[],
  url?: string,
) => {
  return (navSubLevelPromotionList ?? []).includes(url ?? '')
}

export interface NavigationTextItemProps {
  hasChildren: boolean
  text: string
  link?: NavigationLink
  onClick?: () => void
  'aria-label'?: string
  shouldFocus?: boolean
  currentLevel?: number
  enableThreeTierNav?: boolean
  showBottomBorder?: boolean
  promotionTextColour?: Property.Color
  thirdLevelLink?: boolean
  promotionTextColourTwo?: Property.Color
  promotionBackgroundColour?: Property.Color
  promotionBackgroundColourTwo?: Property.Color
  eventHandler?: (text: string) => void
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  ['data-testid']?: string
  ['data-cs-override-id']: string | undefined
  isLevelHome?: boolean
  topLevelName?: string
  hideFocusOnLevelOneNav?: boolean
}

const getLinkAttributes = (link) => ({
  href: link?.url || undefined,
  target: link?.openExternally ? '_BLANK' : undefined,
  rel: `${link?.noFollow ? 'nofollow' : ''} ${link?.noIndex ? 'noindex' : ''}`,
})

const getPromotionValue = (topLevelName, navLevelThreePromotionList, text) =>
  topLevelName &&
  (navLevelThreePromotionList?.map((i) => i.toLowerCase()) ?? []).includes(
    `${topLevelName}/${text}`.toLowerCase(),
  )

export const NavigationTextItem = ({
  promotionTextColour,
  promotionTextColourTwo,
  promotionBackgroundColour,
  promotionBackgroundColourTwo,
  thirdLevelLink,
  navSubLevelPromotionList,
  navLevelThreePromotionList,
  isLevelHome,
  ...props
}: NavigationTextItemProps) => {
  const theme = useTheme()
  const isSubheading =
    props.currentLevel === 2 && props.enableThreeTierNav && !thirdLevelLink

  const linkAttributes = getLinkAttributes(props?.link)
  const promotionValue = getPromotionValue(
    props.topLevelName,
    navLevelThreePromotionList,
    props.text,
  )

  const sanitisedMenuText = removeSpacesAndCharacters(props.text)

  return props.hasChildren ? (
    <NavigationLinkWithChildren
      aria-label={props['aria-label']}
      onClick={props.onClick}
      data-should-focus={props.shouldFocus}
      enableThreeTierNav={props.enableThreeTierNav}
      currentLevel={props.currentLevel}
      thirdLevelLink={thirdLevelLink}
      isSubheading={isSubheading}
      promotionBackgroundColour={promotionBackgroundColour}
      promotionBackgroundColourTwo={promotionBackgroundColourTwo}
      promotionTextColour={promotionTextColour}
      promotionTextColourTwo={promotionTextColourTwo}
      data-testid={`navigation-item-${
        props['data-testid'] ? props['data-testid'] : props.text
      }`}
      data-cs-override-id={`${props['data-cs-override-id']}${sanitisedMenuText}`}
      navSubLevelPromotion={checkNavPromotionLink(
        navSubLevelPromotionList,
        props.link?.url,
      )}
    >
      <NavigationLinkText>{props.text}</NavigationLinkText>
      <IconWrapper>
        {theme.patterns.header.flyout.chevronRightIcon.svgPath ? (
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={theme.patterns.header.flyout.chevronRightIcon.viewBox}
            width={theme.patterns.header.flyout.chevronRightIcon.width}
            height={theme.patterns.header.flyout.chevronRightIcon.height}
          >
            <path
              d={theme.patterns.header.flyout.chevronRightIcon.svgPath}
              fillRule="evenodd"
            ></path>
          </SvgIcon>
        ) : (
          <StyledChevronRight />
        )}
      </IconWrapper>
    </NavigationLinkWithChildren>
  ) : (
    <NavigationStyledLink
      {...linkAttributes}
      showBottomBorder={props.showBottomBorder}
      enableThreeTierNav={props.enableThreeTierNav}
      currentLevel={props.currentLevel}
      thirdLevelLink={thirdLevelLink}
      isSubheading={isSubheading}
      promotionBackgroundColour={promotionBackgroundColour}
      promotionBackgroundColourTwo={promotionBackgroundColourTwo}
      promotionTextColour={promotionTextColour}
      promotionTextColourTwo={promotionTextColourTwo}
      data-should-focus={props.shouldFocus}
      data-cs-override-id={`${props['data-cs-override-id']}${sanitisedMenuText}`}
      data-testid={`navigation-link-${props.text}`}
      promotion={promotionValue}
      {...(props.eventHandler && {
        onClick: () => {
          props.eventHandler && props.eventHandler(props.text)
        },
      })}
      isLevelHome={isLevelHome}
    >
      <NavigationLinkText>{props.text}</NavigationLinkText>
    </NavigationStyledLink>
  )
}
