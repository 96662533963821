import * as React from 'react'

import { styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing } from '@thg-commerce/gravity-theme'

import { CountrySelectorProps } from './CountrySelector/CountrySelector'
import { FooterNavIcon } from './FooterNavigationColumn/FooterNavIcon'
import {
  FooterTopLevelNavigationItem,
  IconWrapper,
  LinkWrapper,
  NavigationList,
  SubNavigationLink,
} from './FooterNavigationColumn/FooterNavigationColumn'
import { CountrySelector } from './CountrySelector'
import { FooterNavigationColumn } from './FooterNavigationColumn'

interface ActionProps {
  children: React.ReactElement
  href: string
  [key: string]: string | React.ReactElement | undefined
  as?: string
}

export interface FooterNavigationProps {
  currentBrand: string
  userAgent: string
  topLevel: FooterTopLevelNavigationItem[]
  showSubsiteSelector: boolean | undefined
  showCookieSettings: boolean | undefined
  countrySelector: CountrySelectorProps
  actionComponent: React.FunctionComponent<ActionProps>
  cookieConsentButtonText?: string
  cookieSettingsHandler?: Function
}

const FooterNavigationContainer = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.patterns.footer.navigation.backgroundColor};
  padding-top: 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding-top: ${spacing(4)};
  }
`

const FooterNavigationContent = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.site.siteWidth};
  display: flex;
  flex-direction: column;
  padding: ${spacing(4)} ${spacing(2)};

  box-shadow: inset 0
    ${(props) => props.theme.patterns.footer.navigation.headerBorder.width} 0
    ${(props) => props.theme.patterns.footer.navigation.headerBorder.color};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    border-top: 1px solid transparent;
    padding: 0 ${spacing(4)} ${spacing(4)};
    flex-direction: row;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: 0 ${spacing(2)} ${spacing(4)};
  }
`

const PrimaryNavigationColumn = styled.div<{ numberOfNavItems: number }>`
  width: 100%;
  margin: 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 50%;
    margin: 0 ${spacing(2)} 0 0;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0;
    width: ${(props) => {
      if (props.numberOfNavItems > 3) {
        return `${100 * (1 / props.numberOfNavItems)}%`
      }
      return `50%`
    }};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: ${(props) => {
      if (props.theme.patterns.footer.navigation.equalColumnSpread) {
        if (props.numberOfNavItems > 3) {
          return `${100 * (1 / props.numberOfNavItems)}%`
        }
      }
      return `50%`
    }};
  }
`

const PrimaryNavigationColumnContent = styled.div`
  width: 100%;
`

const SecondaryNavigationColumnContainer = styled.div<{
  numberOfNavItems: number
}>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  ${(
    props,
  ) => `border-bottom:  ${props.theme.patterns.footer.navigation.headerBorder.width} solid
    ${props.theme.patterns.footer.navigation.headerBorder.color};`}
  margin: ${spacing(2)} 0 0 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 50%;
    margin: 0 0 0 ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0;
    border-bottom: 1px solid transparent;
    flex-direction: row;
    width: ${(props) => {
      if (props.numberOfNavItems > 3) {
        return `${
          100 * ((props.numberOfNavItems - 1) / props.numberOfNavItems)
        }%`
      }
      return `50%`
    }};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    margin: 0;
    border-bottom: 1px solid transparent;
    flex-direction: row;
    width: ${(props) => {
      if (props.theme.patterns.footer.navigation.equalColumnSpread) {
        if (props.numberOfNavItems > 3) {
          return `${
            100 * ((props.numberOfNavItems - 1) / props.numberOfNavItems)
          }%`
        }
      }
      return `50%`
    }};
  }
`
const SecondaryNavigationColumn = styled.div<{ numberOfNavItems: number }>`
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: ${(props) => {
      if (props.numberOfNavItems > 3) {
        return `${100 / (props.numberOfNavItems - 1)}%`
      }
      if (props.numberOfNavItems === 3) {
        return `50%`
      }
      return `100%`
    }};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: ${(props) => {
      if (props.theme.patterns.footer.navigation.equalColumnSpread) {
        if (props.numberOfNavItems > 3) {
          return `${100 / (props.numberOfNavItems - 1)}%`
        }
        if (props.numberOfNavItems === 3) {
          return `50%`
        }
      }
      return `100%`
    }};
  }
`

const AdditionalNavigationList = styled(NavigationList)`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0 ${spacing(2)};
  }
`

const getSecondaryNavColumns = (
  topLevel: FooterTopLevelNavigationItem[],
  userAgent: string,
  actionComponent: React.FunctionComponent<ActionProps>,
) => {
  if (!topLevel) {
    return null
  }

  return topLevel.map((element, index) => {
    if (index === 0) {
      return null
    }
    return (
      <SecondaryNavigationColumn
        key={`secondary-nav-column-${index}`}
        numberOfNavItems={topLevel.length}
      >
        <FooterNavigationColumn
          {...element}
          id={`secondary-nav-column-${index}`}
          expandable={true}
          userAgent={userAgent}
          actionComponent={actionComponent}
        />
      </SecondaryNavigationColumn>
    )
  })
}

export const FooterNavigation = (props: FooterNavigationProps) => {
  const theme = useTheme()

  const topLevelLength = props.topLevel ? props.topLevel.length : 0
  const primaryNav = props.topLevel && props.topLevel[0]

  if (!props.topLevel && !props.showSubsiteSelector) {
    return null
  }

  return (
    <FooterNavigationContainer>
      <FooterNavigationContent>
        <PrimaryNavigationColumn numberOfNavItems={topLevelLength}>
          <PrimaryNavigationColumnContent>
            <FooterNavigationColumn
              {...primaryNav}
              userAgent={props.userAgent}
              id="primary-nav-column"
              firstFooterNav={true}
              actionComponent={props.actionComponent}
            />
            {props.showCookieSettings && (
              <AdditionalNavigationList>
                <LinkWrapper
                  hasIcons={true}
                  key="subnavigation-link-cookie-consent"
                  data-testid="subnavigation-link"
                >
                  <IconWrapper data-testid="footer-navigation-icon">
                    <FooterNavIcon
                      iconName="cookie"
                      fillColor={theme.patterns.footer.navigation?.iconColor}
                    />
                  </IconWrapper>
                  <SubNavigationLink
                    href={`javascript:void(0);`}
                    onClick={() => {
                      props.cookieSettingsHandler &&
                        props.cookieSettingsHandler()
                    }}
                  >
                    {props.cookieConsentButtonText
                      ? props.cookieConsentButtonText
                      : 'Cookie Consent'}
                  </SubNavigationLink>
                </LinkWrapper>
              </AdditionalNavigationList>
            )}
            {props.showSubsiteSelector && (
              <CountrySelector {...props.countrySelector} />
            )}
          </PrimaryNavigationColumnContent>
        </PrimaryNavigationColumn>
        <SecondaryNavigationColumnContainer numberOfNavItems={topLevelLength}>
          {getSecondaryNavColumns(
            props.topLevel,
            props.userAgent,
            props.actionComponent,
          )}
        </SecondaryNavigationColumnContainer>
      </FooterNavigationContent>
    </FooterNavigationContainer>
  )
}
