import { CustomDropdown, Skeleton } from '@thg-commerce/gravity-elements'
import { mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

export const StyledDropdown = styled(CustomDropdown)`
  width: 100%;
`

export const SkeletonProductOption = styled(Skeleton)`
  width: 100%;
  height: 100%;
  min-height: 100px;
`

export const QuantitySelectorContainer = styled.div`
  margin-bottom: ${spacing(5)};
`

export const QuantityText = styled.p`
  ${Text('bodyText', 'alternate')};
  margin-bottom: ${spacing(1)};
`
export const Container = styled.div<{
  productOptionPlacement: string | undefined
  enableFBTProductOptionPlacement: boolean | undefined
}>`
  display: flex;
  flex-direction: column;
  width: auto;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    flex-direction: ${({
      enableFBTProductOptionPlacement,
      productOptionPlacement,
    }) =>
      enableFBTProductOptionPlacement ? productOptionPlacement : 'column'};
  }
`

export const OptionContainer = styled.div<{
  order: number
  enableFBTProductOptionPlacement: boolean | undefined
}>`
  display: flex;
  flex-direction: column;
  width: auto;
  gap: ${spacing(1)};
  order: ${({ order }) => order};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    margin-right: ${({ enableFBTProductOptionPlacement }) =>
      enableFBTProductOptionPlacement ? '8px' : '0'};
  }
`
