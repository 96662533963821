export { SvgAccount as Account } from './components/AccountIcons'
export { SvgAccountIcon as AccountIcon } from './components/AccountIcons'
export { SvgAccountReturns as AccountReturns } from './components/AccountReturns'
export { SvgAddressBook as AddressBook } from './components/AddressBook'
export { SvgAddressOutline as AddressOutline } from './components/AddressOutline'
export { SvgAuthentication as Authentication } from './components/Authentication'
export { SvgBag as Bag } from './components/Bag'
export { SvgBagSelected as BagSelected } from './components/BagSelected'
export { SvgBasketIcon as BasketIcon } from './components/BasketIcon'
export { SvgBat as Bat } from './components/Bat'
export { SvgBatSelected as BatSelected } from './components/BatSelected'
export { SvgBell as Bell } from './components/Bell'
export { SvgBellDisable as BellDisable } from './components/BellDisable'
export { SvgBin as Bin } from './components/Bin'
export { SvgBlog as Blog } from './components/Blog'
export { SvgBnplOptions as BnplOptions } from './components/BnplOptions'
export { SvgBox as Box } from './components/Box'
export { SvgBurgerIcon as BurgerIcon } from './components/BurgerIcon'
export { SvgCalender as Calender } from './components/Calender'
export { SvgCarbs as Carbs } from './components/Carbs'
export { SvgCard as Card } from './components/Card'
export { SvgCentreAligned as CentreAligned } from './components/CentreAligned'
export { SvgChat as Chat } from './components/Chat'
export { SvgChatOnlineCircle as ChatOnlineCircle } from './components/ChatOnlineCircle'
export { SvgChatSelected as ChatSelected } from './components/ChatSelected'
export { SvgCheckmark as Checkmark } from './components/Checkmark'
export { SvgChevronDown as ChevronDown } from './components/ChevronDown'
export { SvgChevronLeft as ChevronLeft } from './components/ChevronLeft'
export { SvgChevronRight as ChevronRight } from './components/ChevronRight'
export { SvgChevronUp as ChevronUp } from './components/ChevronUp'
export { SvgCircularEmail as CircularEmail } from './components/CircularEmail'
export { SvgCircularMessenger as CircularMessenger } from './components/CircularMessenger'
export { SvgCircularSms as CircularSms } from './components/CircularSms'
export { SvgClickAndCollect as ClickAndCollect } from './components/ClickAndCollect'
export { SvgClickAndCollectSuccess as ClickAndCollectSuccess } from './components/ClickAndCollectSuccess'
export { SvgClipboard as Clipboard } from './components/Clipboard'
export { SvgClose as Close } from './components/Close'
export { SvgCloudOff as CloudOff } from './components/CloudOff'
export { SvgContactUs as ContactUs } from './components/ContactUs'
export { SvgContentEmail as ContentEmail } from './components/ContentEmail'
export { SvgContentEmailUsp as ContentEmailUsp } from './components/ContentEmailUsp'
export { SvgContentFacebook as ContentFacebook } from './components/ContentFacebook'
export { SvgContentPinterest as ContentPinterest } from './components/ContentPinterest'
export { SvgContentTwitter as ContentTwitter } from './components/ContentTwitter'
export { SvgContentWhatsapp as ContentWhatsapp } from './components/ContentWhatsapp'
export { SvgCookie as Cookie } from './components/Cookie'
export { SvgCopyright as Copyright } from './components/Copyright'
export { SvgCopyrightIngenuity as CopyrightIngenuity } from './components/CopyrightIngenuity'
export { CopyrightThg } from './components/CopyrightThg'
export { CopyrightThgIngenuity } from './components/CopyrightThgIngenuity'
export { SvgCopyToClipboard as CopyToClipboard } from './components/CopyToClipboard'
export { CustomIcon } from './components/CustomIcon/CustomIcon'
export { SvgDelivery as Delivery } from './components/Delivery'
export { SvgDeliveryUnavailable as DeliveryUnavailable } from './components/DeliveryUnavailable'
export { SvgDislike as Dislike } from './components/Dislike'
export { DownloadSvg as Download } from './components/Download'
export { SvgEmailOutline as EmailOutline } from './components/EmailOutline'
export { SvgEye as Eye } from './components/Eye'
export { SvgFacebook as Facebook } from './components/Facebook'
export { SvgFacebookOutline as FacebookOutline } from './components/FacebookOutline'
export { SvgFacetRemove as FacetRemove } from './components/FacetRemove'
export { SvgFats as Fats } from './components/Fats'
export { SvgFavouritesOutline as FavouritesOutline } from './components/FavouritesOutline'
export { SvgCookieFooter as FooterCookie } from './components/FooterCookie'
export { SvgFullscreen as Fullscreen } from './components/Fullscreen'
export { SvgGoogle as Google } from './components/Google'
export { SvgGoogleplus as Googleplus } from './components/Googleplus'
export { SvgGravityCircle as GravityCircle } from './components/GravityCircle'
export { SvgHangerIcon as hangerIcon } from './components/hangerIcon'
export { SvgHelpDesk as HelpDesk } from './components/HelpDesk'
export { SvgHelpDeskOutline as HelpDeskOutline } from './components/HelpDeskOutline'
export { SvgHome as Home } from './components/Home'
export { SvgIconDelete as IconDelete } from './components/IconDelete'
export { SvgIconError as IconError } from './components/IconError'
export { SvgIconErrorAlt as IconErrorAlt } from './components/IconErrorAlt'
export { SvgIconInfo as IconInfo } from './components/IconInfo'
export { SvgIconInfoOutline as IconInfoOutline } from './components/IconInfoOutline'
export { SvgIconSuccess as IconSuccess } from './components/IconSuccess'
export { InfoClickAndCollect } from './components/InfoClickAndCollect'
export { InfoDeliveryIcon } from './components/InfoDeliveryIcon'
export { InfoFreeReturn } from './components/InfoFreeReturn'
export { SvgInstagramOutline as InstagramOutline } from './components/InstagramOutline'
export { SvgInternationalDelivery as InternationalDelivery } from './components/InternationalDelivery'
export { SvgLeftAligned as LeftAligned } from './components/LeftAligned'
export { SvgLike as Like } from './components/Like'
export { ListIcon } from './components/ListIcon'
export { SvgLiveChat as LiveChat } from './components/LiveChat'
export { SvgLiveChatOutline as LiveChatOutline } from './components/LiveChatOutline'
export { SvgLocationCircle as LocationCircle } from './components/LocationCircle'
export { SvgLock as Lock } from './components/Lock'
export { SvgLoyaltyCardOutline as LoyaltyCardOutline } from './components/LoyaltyCardOutline'
export { LoyaltyIcon } from './components/LoyaltyIcon'
export { SvgMailFromUk as MailFromUk } from './components/MailFromUk'
export { MapIcon } from './components/MapIcon'
export { SvgMemberPerks as MemberPerks } from './components/MemberPerks'
export { SvgMessage as Message } from './components/Message'
export { SvgMessagesAppOutline as MessagesAppOutline } from './components/MessagesAppOutline'
export { SvgMinus as Minus } from './components/Minus'
export { SvgOneItemPerRowIcon as OneColumnOnMobilePLP } from './components/OneColumnOnMobilePLP'
export { SvgOneItemPerRowIconSelected as OneColumnOnMobilePLPSelected } from './components/OneColumnOnMobilePLPSelected'
export { SvgOrderOutline as OrderOutline } from './components/OrderOutline'
export { SvgPause as Pause } from './components/Pause'
export { SvgAfterpay as Afterpay } from './components/PaymentIcons/Afterpay'
export { SvgAlipay as AliPay } from './components/PaymentIcons/AliPay'
export { SvgAlipayPlus as AlipayPlus } from './components/PaymentIcons/AlipayPlus'
export { SvgAmazonPay as AmazonPay } from './components/PaymentIcons/AmazonPay'
export { SvgAmericanExpress as AmericanExpress } from './components/PaymentIcons/AmericanExpress'
export { SvgApplePay as ApplePay } from './components/PaymentIcons/ApplePay'
export { SvgArvato as Arvato } from './components/PaymentIcons/Arvato'
export { SvgAtome as Atome } from './components/PaymentIcons/Atome'
export { SvgBanContact as Bancontact } from './components/PaymentIcons/Bancontact'
export { SvgClearPay as ClearPay } from './components/PaymentIcons/ClearPay'
export { SvgDinersClub as DinersClub } from './components/PaymentIcons/DinersClub'
export { SvgDiscover as Discover } from './components/PaymentIcons/Discover'
export { SvgElo as Elo } from './components/PaymentIcons/Elo'
export { SvgGiftCard as GiftCard } from './components/PaymentIcons/GiftCard'
export { SvgGooglePay as GooglePay } from './components/PaymentIcons/GooglePay'
export { SvgHiper as Hiper } from './components/PaymentIcons/Hiper'
export { SvgHumm as Humm } from './components/PaymentIcons/Humm'
export { SvgIdeal as Ideal } from './components/PaymentIcons/Ideal'
export { SvgInterac as Interac } from './components/PaymentIcons/Interac'
export { SvgJcb as Jcb } from './components/PaymentIcons/Jcb'
export { SvgKcp as Kcp } from './components/PaymentIcons/Kcp'
export { SvgKlarna as Klarna } from './components/PaymentIcons/Klarna'
export { SvgLayBuy as LayBuy } from './components/PaymentIcons/LayBuy'
export { SvgMaestro as Maestro } from './components/PaymentIcons/Maestro'
export { SvgMastercard as Mastercard } from './components/PaymentIcons/Mastercard'
export { SvgMolPay as MolPay } from './components/PaymentIcons/MolPay'
export { Multibanco } from './components/PaymentIcons/Multibanco'
export { SvgNewPay as NewPay } from './components/PaymentIcons/NewPay'
export { One4All } from './components/PaymentIcons/One4All'
export { SvgOpenpay as Openpay } from './components/PaymentIcons/Openpay'
export { SvgPaypal as Paypal } from './components/PaymentIcons/Paypal'
export { SvgPaytm as Paytm } from './components/PaymentIcons/Paytm'
export { SvgPrimeiroPay as PrimeiroPay } from './components/PaymentIcons/PrimeiroPay'
export { SvgQuadpay as Quadpay } from './components/PaymentIcons/Quadpay'
export { SvgRuPay as RuPay } from './components/PaymentIcons/RuPay'
export { SvgSepa as Sepa } from './components/PaymentIcons/Sepa'
export { SvgSezzle as Sezzle } from './components/PaymentIcons/Sezzle'
export { SvgSolo as Solo } from './components/PaymentIcons/Solo'
export { SvgSplitIt as SplitIt } from './components/PaymentIcons/SplitIt'
export { SvgSpotii as Spotii } from './components/PaymentIcons/Spotii'
export { SvgTenPay as TenPay } from './components/PaymentIcons/TenPay'
export { SvgTrustPay as TrustPay } from './components/PaymentIcons/TrustPay'
export { SvgUnion as Union } from './components/PaymentIcons/Union'
export { UPI as Upi } from './components/PaymentIcons/Upi'
export { SvgVisa as VisaCredit } from './components/PaymentIcons/Visa'
export { SvgVisaDebit as VisaDebit } from './components/PaymentIcons/VisaDebit'
export { SvgVisaElectron as VisaElectron } from './components/PaymentIcons/VisaElectron'
export { SvgWebMoney as WebMoney } from './components/PaymentIcons/WebMoney'
export { WeChatPay } from './components/PaymentIcons/WeChatPay'
export { SvgYandex as Yandex } from './components/PaymentIcons/Yandex'
export { SvgZippay as Zippay } from './components/PaymentIcons/Zippay'
export { SvgPaymentOutline as PaymentOutline } from './components/PaymentOutline'
export { SvgPencil as Pencil } from './components/Pencil'
export { SvgPlay as Play } from './components/Play'
export { SvgPlayArrow as PlayArrow } from './components/PlayArrow'
export { SvgPlayDefaultCircle as PlayDefaultCircle } from './components/PlayDefaultCircle'
export { SvgPlus as Plus } from './components/Plus'
export { SvgPopTracker as PopTracker } from './components/PopTracker'
export { SvgPopTrackerSelected as PopTrackerSelected } from './components/PopTrackerSelected'
export { SvgPropositionIcon as PropositionCheckIcon } from './components/PropositionCheckIcon'
export { SvgProtein as Protein } from './components/Protein'
export { SvgProofOfImpactIcon as ProvenanceProofOfImpact } from './components/ProvenanceProofOfImpact'
export { SvgReferralsAccount as ReferralsAccount } from './components/ReferralsAccount'
export { SvgReferralsCode as ReferralsCode } from './components/ReferralsCode'
export { SvgReferralsCredit as ReferralsCredit } from './components/ReferralsCredit'
export { SvgReferralsDelivery as ReferralsDelivery } from './components/ReferralsDelivery'
export { SvgReferralsFriend as ReferralsFriend } from './components/ReferralsFriend'
export { SvgReferralsMeal as ReferralsMeal } from './components/ReferralsMeal'
export { SvgReferralsOrder as ReferralsOrder } from './components/ReferralsOrder'
export { SvgReferralsReferral as ReferralsReferral } from './components/ReferralsReferral'
export { SvgReferralsShop as ReferralsShop } from './components/ReferralsShop'
export { SvgReferralsVitamin as ReferralsVitamin } from './components/ReferralsVitamin'
export { SvgRefineIcon as RefineIcon } from './components/RefineIcon'
export { SvgRefineOutline as RefineOutline } from './components/RefineOutline'
export { SvgReturns as Returns } from './components/Returns'
export { SvgReviewStar as ReviewStar } from './components/ReviewStar'
export { SvgReviewStarSelected as ReviewStarSelected } from './components/ReviewStarSelected'
export { SvgRewardPoints as RewardPoints } from './components/RewardPoints'
export { SvgRewardPointsOutline as RewardPointsOutline } from './components/RewardPointsOutline'
export { SvgRightAligned as RightAligned } from './components/RightAligned'
export { SvgSearchIcon as Search } from './components/SearchIcon'
export { SvgSettings as Settings } from './components/Settings'
export { ShoppingBag } from './components/ShoppingBag'
export { SvgSizeGuide as SizeGuide } from './components/SizeGuide'
export { SvgSmartphone as Smartphone } from './components/Smartphone'
export { SvgBlackFacebook as BlackFacebook } from './components/SocialIcons/Black/BlackFacebook'
export { SvgBlackInstagram as BlackInstagram } from './components/SocialIcons/Black/BlackInstagram'
export { SvgBlackTikTok as BlackTikTok } from './components/SocialIcons/Black/BlackTikTok'
export { SvgBlackTwitter as BlackTwitter } from './components/SocialIcons/Black/BlackTwitter'
export { SvgBlackYouTube as BlackYouTube } from './components/SocialIcons/Black/BlackYouTube'
export { SvgCircular21Buttons as Circular21Buttons } from './components/SocialIcons/Colour/Circular21Buttons'
export { SvgCircularFacebook as CircularFacebook } from './components/SocialIcons/Colour/CircularFacebook'
export { SvgCircularInstagram as CircularInstagram } from './components/SocialIcons/Colour/CircularInstagram'
export { SvgCircularLine as CircularLine } from './components/SocialIcons/Colour/CircularLine'
export { SvgCircularPinterest as CircularPinterest } from './components/SocialIcons/Colour/CircularPinterest'
export { SvgCircularSnapchat as CircularSnapchat } from './components/SocialIcons/Colour/CircularSnapchat'
export { SvgCircularTikTok as CircularTikTok } from './components/SocialIcons/Colour/CircularTikTok'
export { SvgCircularTwitch as CircularTwitch } from './components/SocialIcons/Colour/CircularTwitch'
export { SvgCircularTwitter as CircularTwitter } from './components/SocialIcons/Colour/CircularTwitter'
export { SvgCircularVk as CircularVk } from './components/SocialIcons/Colour/CircularVk'
export { SvgCircularWhatsapp as CircularWhatsapp } from './components/SocialIcons/Colour/CircularWhatsapp'
export { SvgCircularYoutube as CircularYoutube } from './components/SocialIcons/Colour/CircularYoutube'
export { SvgCircularFacebook as GreyFacebook } from './components/SocialIcons/Grey/GreyFacebook'
export { SvgCircularInstagram as GreyInstagram } from './components/SocialIcons/Grey/GreyInstagram'
export { SvgGreyPinterest as GreyPinterest } from './components/SocialIcons/Grey/GreyPinterest'
export { SvgGreyCircularTikTok as GreyTikTok } from './components/SocialIcons/Grey/GreyTikTok'
export { SvgGreyTwitter as GreyTwitter } from './components/SocialIcons/Grey/GreyTwitter'
export { SvgGreyYouTube as GreyYouTube } from './components/SocialIcons/Grey/GreyYouTube'
export { SvgCircularWhiteFacebook as WhiteOpacityFacebook } from './components/SocialIcons/WhiteOpacity/WhiteOpacityFacebook'
export { SvgCircularWhiteInstagram as WhiteOpacityInstagram } from './components/SocialIcons/WhiteOpacity/WhiteOpacityInstagram'
export { SvgCircularWhitePinterest as WhiteOpacityPinterest } from './components/SocialIcons/WhiteOpacity/WhiteOpacityPinterest'
export { SvgCircularWhiteTiktok as WhiteOpacityTiktok } from './components/SocialIcons/WhiteOpacity/WhiteOpacityTiktok'
export { SvgCircularWhiteTwitter as WhiteOpacityTwitter } from './components/SocialIcons/WhiteOpacity/WhiteOpacityTwitter'
export { SvgCircularWhiteYouTube as WhiteOpacityYouTube } from './components/SocialIcons/WhiteOpacity/WhiteOpacityYouTube'
export { SvgSocialLinkOutline as SocialLinkOutline } from './components/SocialLinkOutline'
export { SvgStore as Store } from './components/Store'
export { SVGStorefront as Storefront } from './components/Storefront'
export { SvgStoreInfo as StoreInfo } from './components/StoreInfo'
export { SvgStoreLocator as StoreLocator } from './components/StoreLocator'
export { SvgStoreStatus as StoreStatus } from './components/StoreStatus'
export { SvgSuccess as Success } from './components/Success'
export { SvgIcon } from './components/SvgIcon'
export { SwatchDisableCircle } from './components/SwatchDisableCircle'
export { SwatchDisableSquare } from './components/SwatchDisableSquare'
export { SvgSystemIconPlus as SystemIconPlus } from './components/SystemIconPlus'
export { SvgTelephone as Telephone } from './components/Telephone'
export { SvgTelephoneOutline as TelephoneOutline } from './components/TelephoneOutline'
export { SvgTelephoneUsp as TelephoneUsp } from './components/TelephoneUsp'
export { SvgTick as Tick } from './components/Tick'
export { SvgTime as Time } from './components/Time'
export { SvgTrending as TrendingCurved } from './components/TrendingCurved'
export { SvgTruck as Truck } from './components/Truck'
export { SvgTwitter as Twitter } from './components/Twitter'
export { SvgTwitterOutline as TwitterOutline } from './components/TwitterOutline'
export { SvgTwoItemsPerRowIcon as TwoColumnOnMobilePLP } from './components/TwoColumnOnMobilePLP'
export { SvgTwoItemsPerRowIconSelected as TwoColumnOnMobilePLPSelected } from './components/TwoColumnOnMobilePLPSelected'
export { SvgUseMyLocation as UseMyLocation } from './components/UseMyLocation'
export { SvgWechat as WeChat } from './components/WeChat'
export { SvgWeibo as Weibo } from './components/Weibo'
export { SvgWhatsappIcon as WhatsappIcon } from './components/WhatsappIcon'
export { SvgWishlist as Wishlist } from './components/Wishlist'
export { SvgWishlistSelected as WishlistSelected } from './components/WishlistSelected'
export { SvgZipGrey as ZipGrey } from './components/ZipGrey'
export { SvgZoomIn as ZoomIn } from './components/ZoomIn'
export { SvgZoomOut as ZoomOut } from './components/ZoomOut'
export type { IconStyling } from './types'
