import * as React from 'react'
export const SvgWhiteOutlineTwitter = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={26}
    height={26}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1227"
    enableBackground="new 0 0 1200 1227"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M714.2 519.3 1160.9 0H1055L667.1 450.9 357.3 0H0l468.5 681.8L0 1226.4h105.9l409.6-476.2 327.2 476.2H1200L714.2 519.3zm-145 168.5-47.5-67.9L144 79.7h162.6l304.8 436 47.5 67.9 396.2 566.7H892.5L569.2 687.8z"
      style={{
        fill: '#fff',
        stroke: '#fff',
        strokeMiterlimit: 10,
      }}
    />
  </svg>
)
export default SvgWhiteOutlineTwitter
