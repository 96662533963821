import * as React from 'react'
import useMap from 'react-hanger/array/useMap'
import withHydrationOnDemand from 'react-hydration-on-demand'
import ReactResizeDetector from 'react-resize-detector'
import loadable from '@loadable/component'

import { Overlay } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import { KeyboardKeys } from '@thg-commerce/gravity-theme'

import {
  NavigationItem,
  NavigationItemType,
  NavigationTree,
} from '../NavigationTree'
import { HeaderZIndexLevel } from '../types'
import { removeSpacesAndCharacters } from '../Utils'

import { Brand } from './NavigationContent/Brands/Brands'
import { NavigationContent } from './NavigationContent'
import { NavigationTabPanel } from './NavigationTabPanel'
import {
  A11yButton,
  ContentPanelWrapper,
  NavigationWrapper,
  NavItemContainer,
  StyledEmptyNavigation,
  StyledLink,
  StyledNavigation,
} from './styles'

const PrefetchableLink = withPrefetch('href', StyledLink)

const SvgChevronDown = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronDown'),
  { ssr: true, fallback: <div style={{ width: 14, height: 14 }} /> },
)

enum AnimationActionsType {
  CLOSE = 'close',
  CLOSE_INSTANT = 'closeInstant',
  OPEN = 'open',
  OPEN_INSTANT = 'openInstant',
}

interface NavigationProps {
  truncateNavigationItems?: boolean
  navigationTree: NavigationTree
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  hasDynamicHeaderDropdown?: boolean
  hasWaterfallMenu?: boolean
  i18nText: {
    categoryHomeText: string
    viewAllBrandsText: string
    navAriaLabel: string
    menuButtonAriaLabel: string
    closeButtonAriaLabel: string
  }
  brandsData: { brands: Brand[] }
  routes: { viewAllBrandsLink: string; listExtension: string }
  navWrapperHeight: string
  navHeaderHeight?: number
  headerRef?: React.RefObject<HTMLDivElement>
  fullWidthSeparator?: boolean
  enableThreeTierNav?: boolean
  selectTopLevelNavigationItem?: (path: string) => void
  selectedTopLevelNavigationItemIndex?: number
  navigationOnClickCallback?: (data: NavigationEventData) => void
  enableAlternateNavGrid?: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  enableMasonryGridMenu?: boolean
}

export interface NavigationEventData {
  selectedTopLevelNavigation?: string
  selectedSecondLevelNavigation?: string
  selectedThirdLevelNavigation?: string
}

interface NavLinkInterface
  extends Pick<
    NavigationProps,
    | 'i18nText'
    | 'hasWaterfallMenu'
    | 'brandsData'
    | 'routes'
    | 'enableAlternateNavGrid'
    | 'hasDynamicHeaderDropdown'
    | 'navWrapperHeight'
    | 'navHeaderHeight'
    | 'navSubLevelPromotionList'
    | 'navLevelThreePromotionList'
    | 'enableThreeTierNav'
    | 'enableMasonryGridMenu'
  > {
  item: NavigationItem
  index: number
  onLinkHover: (index: number, showContent: boolean) => void
  onLinkKeyDown: (event: React.KeyboardEvent, index: number) => void
  onContentKeyDown: (
    event: React.KeyboardEvent,
    index: number,
    showSubmenuContent: boolean,
  ) => void
  onBlur: (event: React.FocusEvent) => void
  onMouseEnter: () => void
  isActive: boolean
  hasFeaturedColumn: boolean
  hasDetachedMenu: boolean
  animations: Map<string, AnimationActionsType>
}

const navigationWrapper =
  typeof document !== 'undefined'
    ? document.querySelector("[data-testid='navigation-wrapper']")
    : null

const NavigationTabPanelComponent = withHydrationOnDemand({
  on: [
    ['mouseenter', () => navigationWrapper],
    ['focus', () => navigationWrapper],
    ['keydown', () => navigationWrapper],
  ],
})(NavigationTabPanel)

const LEFT_OFFSET = 0

const NavigationContentRenderer = (
  props: Pick<
    NavLinkInterface,
    | 'item'
    | 'brandsData'
    | 'routes'
    | 'hasWaterfallMenu'
    | 'hasDynamicHeaderDropdown'
    | 'enableThreeTierNav'
    | 'enableAlternateNavGrid'
    | 'navSubLevelPromotionList'
    | 'navLevelThreePromotionList'
    | 'enableMasonryGridMenu'
  > & {
    i18nText: {
      viewAllBrands: string
      navAriaLabel: string
    }
    navigationOnClickCallback?: (data: NavigationEventData) => void
    navigationTopLevel?: string
  },
) => (
  <NavigationContent
    hasWaterfallMenu={props.hasWaterfallMenu || false}
    dynamicWidth={!!props.hasDynamicHeaderDropdown}
    item={props.item}
    brandsData={props.brandsData}
    routes={props.routes}
    i18nText={props.i18nText}
    enableThreeTierNav={props.enableThreeTierNav || false}
    enableAlternateNavGrid={props.enableAlternateNavGrid}
    enableMasonryGridMenu={props.enableMasonryGridMenu}
    navSubLevelPromotionList={props.navSubLevelPromotionList}
    navLevelThreePromotionList={props.navLevelThreePromotionList}
    onClick={(e?: string) => {
      props.navigationOnClickCallback &&
        props.enableThreeTierNav &&
        props.navigationOnClickCallback({
          selectedTopLevelNavigation: props.navigationTopLevel,
          selectedSecondLevelNavigation: props.item?.displayName,
          selectedThirdLevelNavigation: e || '',
        })
    }}
    navigationOnClickCallback={props.navigationOnClickCallback}
    navigationTopLevel={props.navigationTopLevel}
  />
)

const NewNavLink = (
  props: NavLinkInterface & {
    handleClick: (item: NavigationItem, index: number) => void
    promotionTextColour?: string
    promotionTextStyle?: string
    promotionBackgroundColour?: string
  },
) => {
  const showContent =
    props.item.type === 'BRANDS' || !!props.item?.subNavigation?.length
  const isOpen =
    props.animations.get(`${props.index}`) === AnimationActionsType.OPEN ||
    props.animations.get(`${props.index}`) === AnimationActionsType.OPEN_INSTANT
  const showSubmenuContent = showContent && !isOpen

  const sanitisedMenuText = removeSpacesAndCharacters(props.item.displayName)

  return (
    <NavItemContainer key={`nav-item-${props.index}`}>
      <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
        <PrefetchableLink
          onMouseEnter={() => {
            !props.enableThreeTierNav &&
              props.onLinkHover(props.index, showContent)
          }}
          onClick={() => props.handleClick(props.item, props.index)}
          onKeyDown={(e) => {
            props.onLinkKeyDown(e, props.index)
          }}
          onBlur={(e) => props.onBlur(e)}
          promotionTextColour={props.promotionTextColour}
          promotionBackgroundColour={props.promotionBackgroundColour}
          ref={null}
          href={props.item.link?.url}
          data-tab-index={`${props.index}`}
          data-testid={`navigation-link-${props.index}`}
          data-cs-override-id={`${sanitisedMenuText}`}
          aria-expanded={isOpen}
          isActive={props.isActive}
          enableThreeTierNav={props.enableThreeTierNav}
          firstTier={true}
        >
          {props.item.displayName}
        </PrefetchableLink>
        {!props.enableThreeTierNav && props.item.subNavigation && (
          <A11yButton
            aria-expanded={isOpen}
            aria-label={
              isOpen
                ? props.i18nText.closeButtonAriaLabel
                : props.i18nText.menuButtonAriaLabel
            }
            hasOpenSubmenu={isOpen}
            onClick={() => {
              props.onLinkHover(props.index, showSubmenuContent)
            }}
          >
            <SvgChevronDown />
          </A11yButton>
        )}
      </div>
      <ContentPanelWrapper
        isOpen={isOpen}
        positionTopOffset={props.navWrapperHeight}
        type={props.item.type}
        onKeyDown={(e) => {
          props.onContentKeyDown(e, props.index, showSubmenuContent)
        }}
        onMouseEnter={() => props.onMouseEnter()}
      >
        <NavigationTabPanelComponent
          navHeaderHeight={props.navHeaderHeight}
          hasFeaturedColumn={props.hasFeaturedColumn}
          hasDetachedMenu={props.hasDetachedMenu}
          positionLeftOffset={LEFT_OFFSET}
          isVariableWidth={!!props.hasDynamicHeaderDropdown}
          type={props.item.type}
          animation={
            props.animations.get(`${props.index}`) || AnimationActionsType.CLOSE
          }
          index={props.index}
        >
          {!props.enableThreeTierNav && showContent && (
            <NavigationContentRenderer
              item={props.item}
              brandsData={props.brandsData}
              i18nText={{
                viewAllBrands: props.i18nText.viewAllBrandsText,
                navAriaLabel: props.i18nText.navAriaLabel,
              }}
              routes={props.routes}
              hasWaterfallMenu={props.hasWaterfallMenu}
              hasDynamicHeaderDropdown={props.hasDynamicHeaderDropdown}
              enableThreeTierNav={props.enableThreeTierNav}
              enableAlternateNavGrid={props.enableAlternateNavGrid}
              enableMasonryGridMenu={props.enableMasonryGridMenu}
              navSubLevelPromotionList={props.navSubLevelPromotionList}
              navLevelThreePromotionList={props.navLevelThreePromotionList}
            />
          )}
        </NavigationTabPanelComponent>
      </ContentPanelWrapper>
    </NavItemContainer>
  )
}

const ThreeTierNavLink = (
  props: NavLinkInterface & {
    navigationOnClickCallback?: (data: NavigationEventData) => void
    navigationTopLevel?: string
  },
) => {
  const showContent =
    props.item.type === 'BRANDS' || !!props.item?.subNavigation?.length
  const isOpen =
    props.animations.get(`${props.index}`) === AnimationActionsType.OPEN ||
    props.animations.get(`${props.index}`) === AnimationActionsType.OPEN_INSTANT
  const showSubmenuContent = showContent && !isOpen

  return (
    <NavItemContainer
      data-cs-override-id={`${props.item?.displayName}_${props.item.displayName}`}
      key={`three-tier-nav-item-${props.index}`}
      enableThreeTierNav={props.enableThreeTierNav}
    >
      <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
        <PrefetchableLink
          onMouseEnter={() => props.onLinkHover(props.index, showContent)}
          onKeyDown={(e) => {
            props.onLinkKeyDown(e, props.index)
          }}
          onBlur={(e) => props.onBlur(e)}
          {...(props.navigationOnClickCallback && {
            onClick: () => {
              props.navigationOnClickCallback?.({
                selectedTopLevelNavigation: props.navigationTopLevel,
                selectedSecondLevelNavigation: props.item?.displayName,
              })
            },
          })}
          ref={null}
          data-tab-index={`${props.index}`}
          href={props.item.link?.url}
          data-testid={`three-tier-navigation-link-${props.index}`}
          aria-expanded={isOpen}
          isActive={props.isActive}
          enableThreeTierNav={props.enableThreeTierNav}
        >
          {props.item.displayName}
        </PrefetchableLink>
        {props.item.subNavigation && (
          <A11yButton
            aria-expanded={isOpen}
            aria-label={
              isOpen
                ? props.i18nText.closeButtonAriaLabel
                : props.i18nText.menuButtonAriaLabel
            }
            hasOpenSubmenu={isOpen}
            onClick={() => {
              props.onLinkHover(props.index, showSubmenuContent)
            }}
          >
            <SvgChevronDown />
          </A11yButton>
        )}
      </div>
      <ContentPanelWrapper
        isOpen={isOpen}
        positionTopOffset={props.navWrapperHeight}
        type={props.item.type}
        enableThreeTierNav={props.enableThreeTierNav}
        onKeyDown={(e) => {
          props.onContentKeyDown(e, props.index, showSubmenuContent)
        }}
        onMouseEnter={() => props.onMouseEnter()}
      >
        <NavigationTabPanelComponent
          navHeaderHeight={props.navHeaderHeight}
          hasFeaturedColumn={props.hasFeaturedColumn}
          hasDetachedMenu={props.hasDetachedMenu}
          positionLeftOffset={LEFT_OFFSET}
          isVariableWidth={!!props.hasDynamicHeaderDropdown}
          type={props.item.type}
          animation={
            props.animations.get(`${props.index}`) || AnimationActionsType.CLOSE
          }
          index={props.index}
        >
          {showContent && (
            <NavigationContentRenderer
              item={props.item}
              brandsData={props.brandsData}
              i18nText={{
                viewAllBrands: props.i18nText.viewAllBrandsText,
                navAriaLabel: props.i18nText.navAriaLabel,
              }}
              routes={props.routes}
              hasWaterfallMenu={props.hasWaterfallMenu}
              hasDynamicHeaderDropdown={props.hasDynamicHeaderDropdown}
              enableThreeTierNav={props.enableThreeTierNav}
              enableAlternateNavGrid={props.enableAlternateNavGrid}
              enableMasonryGridMenu={props.enableMasonryGridMenu}
              navSubLevelPromotionList={props.navSubLevelPromotionList}
              navigationOnClickCallback={props.navigationOnClickCallback}
              navigationTopLevel={props.navigationTopLevel}
            />
          )}
        </NavigationTabPanelComponent>
      </ContentPanelWrapper>
    </NavItemContainer>
  )
}

const hasFeaturedColumn = (item: NavigationItem) => {
  const firstSubNav = item.subNavigation?.[0]
  return firstSubNav?.type === NavigationItemType.FEATURED || false
}

const hasDetachedMenu = (item: NavigationItem) => {
  const secondSubNav = item.subNavigation?.[1]
  return secondSubNav?.type === NavigationItemType.DETACHED || false
}

export const Navigation = (props: NavigationProps) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(-1)
  const [activeClickedIndex, setActiveClickedIndex] = React.useState<number>(-1)
  const [headerWidth, setHeaderWidth] = React.useState<number>(1400)

  const navWrapperRef = React.useRef<HTMLDivElement>(null)
  const threeTierNavWrapperRef = React.useRef<HTMLDivElement>(null)
  const debounceTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | number
  >(0)

  const theme = useTheme()
  const topLevel =
    props.navigationTree && props.navigationTree.navigation.topLevel

  const navigationMenus = props.enableThreeTierNav
    ? topLevel?.[activeClickedIndex]?.subNavigation || []
    : topLevel

  const startingArray: Array<[string, AnimationActionsType]> = Array.from(
    { length: navigationMenus?.length },
    (_, index) => {
      return [`${index}`, AnimationActionsType.CLOSE]
    },
  )

  const [animations, animationActions] = useMap(
    new Map<string, AnimationActionsType>(startingArray),
  )

  React.useEffect(() => {
    if (typeof props.selectedTopLevelNavigationItemIndex === 'number') {
      setActiveClickedIndex(props.selectedTopLevelNavigationItemIndex)
    }
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = 0
      }
    }
  }, [props.selectedTopLevelNavigationItemIndex])

  if (!props.navigationTree) {
    return <StyledEmptyNavigation />
  }

  const hasAllClosed = (animationArray: Map<string, AnimationActionsType>) => {
    return !Array.from(animationArray.values()).some((el) => {
      return (
        el === AnimationActionsType.OPEN ||
        el === AnimationActionsType.OPEN_INSTANT
      )
    })
  }

  const onLinkHover = (index: number, showContent: boolean) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current)
      debounceTimeoutRef.current = 0
    }
    if (!showContent) {
      debounceTimeoutRef.current = setTimeout(() => {
        animationActions.initialize(startingArray)
      }, 200)
      return
    }
    if (hasAllClosed(animations) || !animations.get(`${index}`)) {
      debounceTimeoutRef.current = setTimeout(() => {
        animationActions.set(`${index}`, AnimationActionsType.OPEN)
        setActiveIndex(index)
      }, 200)
    } else {
      debounceTimeoutRef.current = setTimeout(() => {
        animationActions.initialize(
          startingArray.map((_, itemIndex) =>
            index === itemIndex
              ? [`${itemIndex}`, AnimationActionsType.OPEN_INSTANT]
              : [`${itemIndex}`, AnimationActionsType.CLOSE_INSTANT],
          ),
        )
        setActiveIndex(index)
      }, 200)
    }
  }

  const onLinkKeyDown = (event: React.KeyboardEvent, index: number) => {
    switch (event.key) {
      case KeyboardKeys.ArrowRight:
        const nextTab: HTMLElement | null = document.querySelector(
          `[data-tab-index="${index + 1}"]`,
        )
        if (nextTab) {
          nextTab.focus()
        }
        break
      case KeyboardKeys.ArrowLeft:
        const prevTab: HTMLElement | null = document.querySelector(
          `[data-tab-index="${index - 1}"]`,
        )
        if (prevTab) {
          prevTab.focus()
        }
        break
    }
  }

  const onContentKeyDown = (
    event: React.KeyboardEvent,
    index: number,
    showSubmenuContent: boolean,
  ) => {
    switch (event.key) {
      case KeyboardKeys.Escape:
        onLinkHover(index, showSubmenuContent)
        break
    }
  }

  const onBlur = (event: React.FocusEvent) => {
    if (
      event.relatedTarget &&
      event.relatedTarget instanceof Node &&
      !event.currentTarget.contains(event.relatedTarget)
    ) {
      clearTimeout(debounceTimeoutRef.current)
      debounceTimeoutRef.current = 0
      animationActions.initialize(startingArray)
      setActiveIndex(-1)
    }
  }

  const onMouseEnter = () => {
    clearTimeout(debounceTimeoutRef.current)
    debounceTimeoutRef.current = 0
  }

  const onMouseLeave = () => {
    clearTimeout(debounceTimeoutRef.current)
    debounceTimeoutRef.current = 0
    animationActions.initialize(startingArray)
    setActiveIndex(-1)
  }

  const handleClick = (item: NavigationItem, index: number) => {
    props.selectTopLevelNavigationItem &&
      item.link?.url &&
      props.selectTopLevelNavigationItem(item.link.url)
    props.enableThreeTierNav && setActiveClickedIndex(index)
    props.navigationOnClickCallback &&
      props.navigationOnClickCallback({
        selectedTopLevelNavigation: item?.displayName ?? '',
      })
  }

  const promotionsBackgroundColor = (item: NavigationItem) => {
    const backgroundColour =
      props.headerPromotionUrl && props.headerPromotionUrl === item.link?.url
        ? theme?.patterns.header.navigation.promotionItem.backgroundColour
        : ''

    const backgroundColourTwo =
      props.headerPromotionUrlTwo &&
      props.headerPromotionUrlTwo === item.link?.url
        ? theme?.patterns.header.navigation.promotionItem.backgroundColourTwo
        : ''

    return backgroundColourTwo || backgroundColour
  }

  const promotionsTextColor = (item: NavigationItem) => {
    const textColour =
      props.headerPromotionUrl && props.headerPromotionUrl === item.link?.url
        ? theme?.patterns.header.navigation.promotionItem.textColourOne ||
          theme?.colors.system.pap.sale ||
          theme?.colors.system.pap.title
        : ''

    const textColourTwo =
      props.headerPromotionUrlTwo &&
      props.headerPromotionUrlTwo === item.link?.url
        ? theme?.patterns.header.navigation.promotionItem.textColourTwo
        : ''

    return textColour || textColourTwo
  }

  const promotionsTextStyle = (item: NavigationItem) =>
    props.headerPromotionUrl && props.headerPromotionUrl === item.link?.url
      ? theme?.patterns.header.navigation.promotionItem.textStyleOne
      : ''

  const navLinksProps = {
    onLinkHover,
    onLinkKeyDown,
    onContentKeyDown,
    onBlur,
    onMouseEnter,
    animations,
    enableThreeTierNav: props.enableThreeTierNav,
    enableAlternateNavGrid: props.enableAlternateNavGrid,
    i18nText: props.i18nText,
    navWrapperHeight: props.navWrapperHeight,
    navHeaderHeight: props.navHeaderHeight,
    navSubLevelPromotionList: props.navSubLevelPromotionList,
    hasDynamicHeaderDropdown: props.hasDynamicHeaderDropdown,
    hasWaterfallMenu: props.hasWaterfallMenu,
    brandsData: props.brandsData,
    routes: props.routes,
  }

  return (
    <React.Fragment>
      <StyledNavigation fullWidthSeparator={props.fullWidthSeparator}>
        <NavigationWrapper
          isOpen={!hasAllClosed(animations)}
          fullWidthSeparator={
            props.fullWidthSeparator && !props.enableThreeTierNav
          }
          truncateNavigationItems={props.truncateNavigationItems}
          onMouseEnter={() => {}}
          onMouseLeave={() => {
            !props.enableThreeTierNav && onMouseLeave()
          }}
          onBlur={(e) => onBlur(e)}
          data-testid="navigation-wrapper"
          ref={navWrapperRef}
        >
          {topLevel.map((item, index) => (
            <NewNavLink
              {...navLinksProps}
              item={item}
              index={index}
              handleClick={handleClick}
              promotionTextColour={promotionsTextColor(item)}
              promotionTextStyle={promotionsTextStyle(item)}
              promotionBackgroundColour={promotionsBackgroundColor(item)}
              isActive={
                props.enableThreeTierNav
                  ? activeClickedIndex === index
                  : activeIndex === index
              }
              hasFeaturedColumn={hasFeaturedColumn(item)}
              hasDetachedMenu={hasDetachedMenu(item)}
              navLevelThreePromotionList={props.navLevelThreePromotionList}
              enableMasonryGridMenu={props.enableMasonryGridMenu}
            />
          ))}
        </NavigationWrapper>
        {props.enableThreeTierNav && (
          <ReactResizeDetector
            handleWidth
            refreshMode="debounce"
            refreshRate={100}
            onResize={() =>
              props.headerRef?.current &&
              setHeaderWidth(props.headerRef.current?.clientWidth)
            }
          >
            <NavigationWrapper
              isOpen={!hasAllClosed(animations)}
              fullWidthSeparator={props.fullWidthSeparator}
              truncateNavigationItems={props.truncateNavigationItems}
              onMouseEnter={() => {}}
              onMouseLeave={() => onMouseLeave()}
              onBlur={(e) => onBlur(e)}
              data-testid="three-tier-navigation-wrapper"
              ref={threeTierNavWrapperRef}
              enableThreeTierNav={props.enableThreeTierNav}
              headerWidth={headerWidth}
            >
              {(topLevel?.[activeClickedIndex]?.subNavigation || []).map(
                (item, index) => (
                  <ThreeTierNavLink
                    {...navLinksProps}
                    item={item}
                    index={index}
                    isActive={activeIndex === index}
                    navigationOnClickCallback={props.navigationOnClickCallback}
                    navigationTopLevel={
                      topLevel?.[activeClickedIndex]?.displayName
                    }
                    hasFeaturedColumn={hasFeaturedColumn(item)}
                    hasDetachedMenu={hasDetachedMenu(item)}
                  />
                ),
              )}
            </NavigationWrapper>
          </ReactResizeDetector>
        )}
      </StyledNavigation>
      <Overlay
        isShowing={!hasAllClosed(animations)}
        zIndex={HeaderZIndexLevel.DesktopNavOverlay}
      />
    </React.Fragment>
  )
}

export default Navigation
