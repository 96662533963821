import * as React from 'react'
import { Property } from 'csstype'

import {
  css,
  spacing,
  styled,
  Text,
  ThemeInterface,
  withTheme,
} from '@thg-commerce/gravity-theme'

export type ButtonSizes = 'micro' | 'regular' | 'maxi'
export type ButtonEmphases = 'high' | 'medium' | 'low'
type IconAlignment = 'left' | 'center' | 'right'

export enum ButtonState {
  Hover = 'hover',
  Active = 'active',
  Focus = 'focus',
  Disabled = 'disabled',
  Default = 'default',
}

export interface ButtonProps {
  children?: React.ReactNode
  theme?: ThemeInterface
  /** Changes the size of the button */
  sizing?: ButtonSizes
  /** Changes the emphasis of the button */
  emphasis?: ButtonEmphases
  /** Sets the button as disabled or not */
  disabled?: boolean
  icon?: React.ElementType
  /** Changes icon alignment */
  iconAlignment?: IconAlignment
  /** Changes the icons color */
  iconColor?: Property.Color
  style?: React.CSSProperties
  type?: 'button' | 'submit'
  onClick?: (event: React.MouseEvent) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  /** Override to create an `a` tag that visually appears as a button */
  renderedAs?: undefined | 'a' | 'div'
  /** Used when creating a button as a link */
  href?: string
  target?: '_blank'
  rel?: 'nofollow'
  className?: string
  ariaLabel?: string
  _artemisOverride?: any
  _dangerouslyPreventDefaultOnMouseDown?: boolean
  testId?: string
  formId?: string
  ['data-testid']?: string
  id?: string
  tabIndex?: number
}

export const ButtonMinTouchSize: string = '48px'

const defaultBase = (props: ButtonProps) => {
  const emph = props.emphasis || 'high'
  const size = props.sizing || 'regular'
  return props.theme && props.theme.button[emph][size]
}

export const styling = (
  props: ButtonProps,
  buttonState: ButtonState = ButtonState.Default,
) => {
  const base = defaultBase(props)
  return (
    base &&
    `
    outline: none;
    background-color: ${base[buttonState].backgroundColor};
    color: ${base[buttonState].color};
    ${
      props.emphasis === 'low'
        ? `border: ${base[buttonState].borderWidth || '0px'} ${
            base[buttonState].borderStyle || 'solid'
          }  ${base[buttonState].borderColor || 'transparent'};`
        : base[buttonState].borderType === 'border'
        ? `border: ${base[buttonState].borderWidth || '0px'} ${
            base[buttonState].borderStyle || 'solid'
          } ${base[buttonState].backgroundColor || 'transparent'};`
        : `border: ${base[buttonState].borderWidth || '0px'} ${
            base[buttonState].borderStyle
          } ${base[buttonState].borderColor || 'transparent'};`
    }
    ${
      props.emphasis === 'low'
        ? `padding: calc(${base[buttonState].verticalPadding} - ${
            base[buttonState].borderWidth
          }) calc(${conditionalPaddingRight(base, props, buttonState)} - ${
            base[buttonState].borderWidth
          }) calc(${base[buttonState].verticalPadding} - ${
            base[buttonState].borderWidth
          } ) calc(${conditionalPaddingLeft(base, props, buttonState)} - ${
            base[buttonState].borderWidth
          });`
        : `padding: calc(${base[buttonState].verticalPadding} - (2 * ${
            base[buttonState].borderWidth
          })) calc(${conditionalPaddingRight(
            base,
            props,
            buttonState,
          )} - (2 * ${base[buttonState].borderWidth})) calc(${
            base[buttonState].verticalPadding
          } - (2 * ${
            base[buttonState].borderWidth
          })) calc(${conditionalPaddingLeft(base, props, buttonState)} - (2 * ${
            base[buttonState].borderWidth
          }));`
    }
    border-radius: ${base[buttonState].borderRadius};
    ${
      props.emphasis === 'low'
        ? `box-shadow: ${base[buttonState].boxShadow.shadowX} ${base[buttonState].boxShadow.shadowY} ${base[buttonState].boxShadow.shadowBlur} ${base[buttonState].boxShadow.shadowSpread} ${base[buttonState].boxShadow.shadowColor};`
        : ``
    }

    ${
      props.emphasis === 'low'
        ? `height: ${base[buttonState].height};`
        : `height: 100%;`
    }
    ${
      props.emphasis === 'low'
        ? `width: ${base[buttonState].width};`
        : `width: 100%;`
    }


    text-decoration: ${base[buttonState].textDecoration};
    & svg {
      fill: ${base[buttonState].color};
    }
    `
  )
}

export const stylingButtonBorder = (
  props: ButtonProps,
  buttonState: ButtonState = ButtonState.Default,
) => {
  const base = defaultBase(props)
  return (
    base &&
    `
    ${
      base[buttonState].borderType === 'border'
        ? `border: ${base[buttonState].borderWidth || '0px'}
        ${base[buttonState].borderStyle} ${
            base[buttonState].borderColor || 'transparent'
          };`
        : `border: ${base[buttonState].borderWidth || '0px'}
    ${base[buttonState].borderStyle} ${
            base[buttonState].backgroundColor || 'transparent'
          };`
    }
    box-shadow: ${base[buttonState].boxShadow.shadowX} ${
      base[buttonState].boxShadow.shadowY
    } ${base[buttonState].boxShadow.shadowBlur} ${
      base[buttonState].boxShadow.shadowSpread
    } ${base[buttonState].boxShadow.shadowColor};
    border-radius: ${base[buttonState].borderRadius};
    background-color: ${base[buttonState].backgroundColor};
    display: inline-flex;
    height: ${base[buttonState].height};
    width: ${base[buttonState].width};
    `
  )
}

const fontWeightStyle = (props: ButtonProps, buttonState: ButtonState) => {
  const base = defaultBase(props)
  return (base && base[buttonState].style) || 'alternate'
}

export const textStyle = (props: ButtonProps, buttonState: ButtonState) => {
  const emph = props.emphasis ? props.emphasis : 'high'
  const commons = props.theme && props.theme.button[emph]
  return (
    commons &&
    Text(commons.common.font.entry, fontWeightStyle(props, buttonState))
  )
}

export const textTransform = (props: ButtonProps) => {
  const emph = props.emphasis ? props.emphasis : 'high'
  const commons = props.theme && props.theme.button[emph]
  return commons && `text-transform: ${commons.common.font.transform}`
}

const conditionalPaddingRight = (
  base,
  props: ButtonProps,
  buttonState: ButtonState,
) => {
  if (props.icon) {
    if (props.iconAlignment === 'right') {
      return `calc(${base[buttonState].horizontalPadding} + ${spacing(1.5)})`
    }
    if (props.iconAlignment === 'left') {
      return `calc(${base[buttonState].horizontalPadding} + ${spacing(0.5)})`
    }
  }
  return base[buttonState].horizontalPadding
}

const conditionalPaddingLeft = (
  base,
  props: ButtonProps,
  buttonState: ButtonState,
) => {
  if (props.icon) {
    if (props.iconAlignment === 'right') {
      return `calc(${base[buttonState].horizontalPadding} + ${spacing(0.5)})`
    }
    if (props.iconAlignment === 'left') {
      return `calc(${base[buttonState].horizontalPadding} + ${spacing(1.5)})`
    }
  }
  return base[buttonState].horizontalPadding
}

const iconStyle = ({ icon, iconAlignment }: ButtonProps) => {
  return iconAlignment !== 'center'
    ? {
        position: 'absolute',
        right: icon && (iconAlignment === 'right' ? spacing(1.5) : ''),
        left: icon && (iconAlignment === 'left' ? spacing(1.5) : ''),
      }
    : {}
}

const StyledButton = styled.button<ButtonProps>`
  ${(props: ButtonProps) => {
    const cssStyles = css`
      justify-content: center;
      align-items: center;
      display: inline-flex;
      position: relative;
      ${textTransform(props)};
      transition: background-color 0.2s, border-color 0.2s;

      ${textStyle(props, ButtonState.Default)};
      ${styling(props)};
      &:hover {
        ${textStyle(props, ButtonState.Hover)};
        ${styling(props, ButtonState.Hover)};
      }

      &:focus {
        ${textStyle(props, ButtonState.Focus)};
        ${styling(props, ButtonState.Focus)};
      }
      &:active {
        ${textStyle(props, ButtonState.Active)};
        ${styling(props, ButtonState.Active)};
      }
      &:disabled {
        ${textStyle(props, ButtonState.Disabled)};
        ${styling(props, ButtonState.Disabled)};
      }
    `
    if (props._artemisOverride && props.emphasis !== 'low') {
      return styling(props._artemisOverride, props._artemisOverride.buttonState)
    }

    return cssStyles
  }}
`

const ButtonBorder = styled.div<ButtonProps>`
  ${(props: ButtonProps) => {
    const cssStyles = css`
      transition: background-color 0.2s, border-color 0.2s;

      ${stylingButtonBorder(props)};
      &:hover {
        ${stylingButtonBorder(props, ButtonState.Hover)};
      }
      &:focus {
        ${stylingButtonBorder(props, ButtonState.Focus)};
      }
      &:focus-within {
        ${stylingButtonBorder(props, ButtonState.Focus)};
      }
      &:active {
        ${stylingButtonBorder(props, ButtonState.Active)};
      }
      &:disabled {
        ${stylingButtonBorder(props, ButtonState.Disabled)};
      }
    `

    return cssStyles
  }}
`

/** @component */
export const Button = withTheme(
  React.forwardRef((props: ButtonProps, ref?: any) => {
    const {
      icon,
      iconAlignment = 'left',
      emphasis,
      sizing,
      theme,
      type,
      renderedAs,
      href,
      _dangerouslyPreventDefaultOnMouseDown,
      className,
      style,
      ariaLabel,
      ...otherProps
    } = props
    const Icon: React.ElementType | undefined = icon
    const preventDefault = (e: React.SyntheticEvent) => {
      e.preventDefault()
    }

    const checkForSentenceCase = (buttonText) => {
      const buttonChildType = typeof props.children?.valueOf()
      const emph = props.emphasis ? props.emphasis : 'high'
      const commons = props.theme && props.theme.button[emph]
      if (
        commons?.common.font.transform !== 'uppercase' &&
        commons?.common.font.transform !== 'lowercase' &&
        buttonChildType === 'string' &&
        emph !== 'low'
      ) {
        const sentenceCase =
          buttonText &&
          buttonText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, (change) => {
            return change.toUpperCase()
          })
        return sentenceCase
      }
      return buttonText
    }

    if (emphasis === 'low') {
      return (
        <StyledButton
          type={(renderedAs !== 'a' && (props.type || 'button')) || undefined}
          ref={ref}
          onMouseDown={
            _dangerouslyPreventDefaultOnMouseDown ? preventDefault : undefined
          }
          className={className}
          style={style}
          href={href}
          as={renderedAs}
          aria-label={ariaLabel}
          emphasis={emphasis}
          sizing={sizing}
          disabled={props.disabled}
          {...otherProps}
          form={props.formId}
        >
          {Icon && <Icon style={iconStyle({ icon, iconAlignment })} />}
          {checkForSentenceCase(props.children)}
        </StyledButton>
      )
    }
    return (
      <ButtonBorder
        className={className}
        style={style}
        emphasis={emphasis}
        sizing={sizing}
        id={props.id}
        tabIndex={props.tabIndex}
      >
        <StyledButton
          data-testid={
            props['data-testid'] ? `${props['data-testid']}` : 'button'
          }
          emphasis={emphasis}
          sizing={sizing}
          type={(renderedAs !== 'a' && (props.type || 'button')) || undefined}
          ref={ref}
          onMouseDown={
            _dangerouslyPreventDefaultOnMouseDown ? preventDefault : undefined
          }
          theme={theme}
          _artemisOverride={props._artemisOverride}
          href={href}
          aria-label={ariaLabel}
          as={renderedAs}
          {...otherProps}
          form={props.formId}
        >
          {Icon && <Icon style={iconStyle({ icon, iconAlignment })} />}
          {checkForSentenceCase(props.children)}
        </StyledButton>
      </ButtonBorder>
    )
  }),
)
export default Button
