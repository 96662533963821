export enum CarouselReducerActionTypes {
  Resize = 'RESIZE',
  SlideLeft = 'SLIDE_LEFT',
  SlideRight = 'SLIDE_RIGHT',
  SlideTo = 'SLIDE_TO',
  Pause = 'PAUSE',
}

export type HorizontalAlignment = 'left' | 'center' | 'right'

type CarouselReducerResizeAction = {
  type: CarouselReducerActionTypes.Resize
  containerWidth: number
  itemsLength: number
  itemsPerSlide: number | number[]
  hideSlidePreview?: boolean
  hideControlsOnSingleSlide?: boolean
}

type CarouselSlideLeftAction = {
  type: CarouselReducerActionTypes.SlideLeft
}

type CarouselSlideRightAction = {
  type: CarouselReducerActionTypes.SlideRight
}

type CarouselPauseAction = {
  type: CarouselReducerActionTypes.Pause
}

type CarouselSlideToAction = {
  type: CarouselReducerActionTypes.SlideTo
  toIndex: number
}

export type CarouselI18nText = {
  leftScrollLabel?: string
  rightScrollLabel?: string
  playPauseLabel?: string
  closeI18nText: {
    closeAriaLabel: string
    closeLabel: string
  }
}

export type CarouselReducerActions =
  | CarouselReducerResizeAction
  | CarouselSlideLeftAction
  | CarouselSlideRightAction
  | CarouselSlideToAction
  | CarouselPauseAction

export interface CarouselState {
  totalSlides: number
  currentSlide: number
  slideWidth: number
  itemsPerSlide: number
  containerWidth: number
  paused: boolean
  autoPlay: boolean
  showControls: boolean
  loopSlides?: boolean
  slideTo?: number
  onChange?: (index: number) => void
  disableDragGesture?: boolean
}

export interface AmpCarouselProps {
  height: number
  width: number
  type?: 'slides'
  role?: string
}

export enum CarouselButtonPlacement {
  Split = 'SPLIT',
  MiddleRight = 'MIDDLE_RIGHT',
  BottomRight = 'BOTTOM_RIGHT',
  BottomReversed = 'BOTTOM_REVERSED',
  BottomRightFloat = 'BOTTOM_RIGHT_FLOAT',
}
