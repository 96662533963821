import {
  Button,
  ErrorMessage,
  PlatformMessage,
  ProductImage,
} from '@thg-commerce/gravity-elements'
import { PageMessage } from '@thg-commerce/gravity-patterns/PageMessage'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import {
  BreakpointArray,
  mediaQueryRenderer,
  mq,
  spacing,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

export const ModalTitle = styled.h2`
  ${Text('large1', 'alternate')};
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: ${spacing(2)};
  padding: 0 ${spacing(3)};
`

export const StyledErrorWrapper = styled.div`
  margin-top: ${(props) =>
    props.theme.patterns.quickBuyModal?.errorMessage?.marginTop || '0'};
`

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: ${spacing(4)};
`

export const ProductTitle = styled.h3<{ reducedBottomMargin: boolean }>`
  ${Text('large1', 'alternate')};
  width: 100%;
  display: block;
  text-align: left;
  margin-top: ${spacing(2)};
  margin-bottom: ${(props) => spacing(props.reducedBottomMargin ? 2 : 3)};

  ${({ theme }) => mq(theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(2)};
    margin-top: 0;
  }
`
export const ProductTagsContainer = styled.div`
  margin-bottom: ${spacing(2)};
`

export const QuantityPlatformMessage = styled(PlatformMessage)`
  margin-bottom: ${spacing(2)};
`
export const SubscriptionInfoMessage = styled(PlatformMessage)`
  margin-bottom: ${spacing(2)};
`

export const ModalContentWrapper = styled.div<{
  reducedBottomPadding?: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: ${(props) =>
    props.reducedBottomPadding
      ? `0 ${spacing(3)}`
      : `0 ${spacing(3)} ${spacing(4)} ${spacing(3)}`};

  ${({ theme }) => mq(theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
    padding: 0 ${spacing(3)} ${spacing(4)} ${spacing(3)};
  }
`

export const ContentContainer = styled.div`
  flex-grow: 1;

  ${({ theme }) => mq(theme.breakpointUtils.map, 'md')} {
    margin-left: ${spacing(4)};
  }
`

export const PricingSection = styled.div<{
  flexDirection: BreakpointArray<string>
  alignItems: BreakpointArray<string>
}>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: space-between;
  margin-bottom: ${spacing(2)};

  ${(props) =>
    props.flexDirection &&
    mediaQueryRenderer(
      props.flexDirection,
      (direction) => `flex-direction: ${direction};`,
    )}

  ${(props) =>
    props.alignItems &&
    mediaQueryRenderer(
      props.alignItems,
      (alignItems) => `align-items: ${alignItems};`,
    )}
`

export const AvailabilityMessage = styled.div`
  ${Text('small', 'default')};
  color: ${({ theme }) => theme.colors.palette.greys.dark};
  margin-bottom: ${spacing(3)};
`

export const AddToBasketButton = styled(Button)`
  width: 100%;
  margin-bottom: ${spacing(2)};
`

export const ViewInfoButton = styled(Button)`
  width: 100%;
`

export const StyledProductImage = styled(ProductImage)`
  flex-shrink: 0;

  ${(props) =>
    props.theme.patterns.quickBuyModal.product?.image?.display &&
    mediaQueryRenderer(
      props.theme.patterns.quickBuyModal.product?.image?.display,
      (display) => display && `display: ${display};`,
    )}
`

export const StyledPageMessage = styled(PageMessage)`
  margin-bottom: ${spacing(4)};
`

export const QuantityText = styled.p`
  ${Text('bodyText', 'alternate')};
  margin-bottom: ${spacing(1)};
  display: ${(props) =>
    props.theme.patterns.quickBuyModal.quantityLabel.display};
`
export const ModalFooterContainer = styled.div<{
  modalContentScrollable?: boolean
}>`
  display: flex;
  position: sticky;
  padding: ${spacing(1)} ${spacing(3)} ${spacing(3)};
  width: 100%;
  ${zIndex(ZIndexLevel.Highest)};
  bottom: 0;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  ${(props) =>
    props.modalContentScrollable &&
    `box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.2);`}
  flex-wrap: wrap;
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`
