export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AccountLinkingExtensionToken: { input: any; output: any }
  AccountLinkingToken: { input: any; output: any }
  Base64Bytes: { input: any; output: any }
  Colour: { input: any; output: any }
  Date: { input: any; output: any }
  DeliveryMethod: { input: any; output: any }
  DisplayString: { input: any; output: any }
  Locale: { input: any; output: any }
  LongInt: { input: any; output: any }
  MarketingToken: { input: any; output: any }
  Money: { input: any; output: any }
  Month: { input: any; output: any }
  OrderNumber: { input: any; output: any }
  PagePath: { input: any; output: any }
  PasswordResetToken: { input: any; output: any }
  SKU: { input: any; output: any }
  SocialAuthenticationToken: { input: any; output: any }
  SocialLinkID: { input: any; output: any }
  SpecialOfferGroup: { input: any; output: any }
  Timestamp: { input: any; output: any }
  URL: { input: any; output: any }
  Void: { input: any; output: any }
  Year: { input: any; output: any }
}

export type AccordionWidget = Widget & {
  __typename?: 'AccordionWidget'
  content?: Maybe<RichContent>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type AccordionWidgetContainer = Widget & {
  __typename?: 'AccordionWidgetContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  heading?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
  titlePosition?: Maybe<Scalars['String']['output']>
}

/**  From the uuid parameter from the email unsubscribe link */
export enum AccountCreationMarketingPreferences {
  /** Returned if a customer already exists for this email address */
  CustomerAlreadyExists = 'CUSTOMER_ALREADY_EXISTS',
  /** Returned when the email address has no customer and is not signed up for newsletters */
  DoesNotReceiveMarketingMaterial = 'DOES_NOT_RECEIVE_MARKETING_MATERIAL',
  /** Returned when the email address has signed up for newsletters but has no customer */
  ReceivesMarketingMaterial = 'RECEIVES_MARKETING_MATERIAL',
}

/**
 * Contains information about an AccountLinkingToken, to be displayed for the target user to accept the link
 *
 * If error is non-null, the rest of the data will be null
 */
export type AccountLinkingTokenInfo = {
  __typename?: 'AccountLinkingTokenInfo'
  email?: Maybe<Scalars['String']['output']>
  error?: Maybe<AccountLinkingTokenInfoError>
}

export enum AccountLinkingTokenInfoError {
  InvalidLinkingToken = 'INVALID_LINKING_TOKEN',
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
  TargetAlreadyLinked = 'TARGET_ALREADY_LINKED',
}

export type AccountUpdateStatus = {
  __typename?: 'AccountUpdateStatus'
  customer?: Maybe<Customer>
  error?: Maybe<AuthenticationError>
  fieldErrors?: Maybe<Array<Maybe<FormFieldValidationError>>>
}

export type AddDiscussionMessageInput = {
  attachmentToken?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
}

export type AddProductToBasketItem = {
  quantity: Scalars['Int']['input']
  sku: Scalars['SKU']['input']
}

export enum AddReviewError {
  InvalidInfo = 'INVALID_INFO',
}

export type AddReviewInput = {
  fields: Array<ReviewFieldInput>
  sku: Scalars['SKU']['input']
}

export type AddReviewResponse = {
  __typename?: 'AddReviewResponse'
  error?: Maybe<AddReviewError>
  fieldErrors: Array<FormFieldValidationError>
}

export type Address = {
  __typename?: 'Address'
  /** House number / House Name / Apartment Number / Floor / Suite number */
  addressLine1: Scalars['String']['output']
  /** Street */
  addressLine2?: Maybe<Scalars['String']['output']>
  addressLine3?: Maybe<Scalars['String']['output']>
  /** City */
  addressLine4?: Maybe<Scalars['String']['output']>
  /** Region / County */
  addressLine5?: Maybe<Scalars['String']['output']>
  addresseeName: Scalars['String']['output']
  clickAndCollect: Scalars['Boolean']['output']
  companyName?: Maybe<Scalars['String']['output']>
  country: Country
  phoneNumber?: Maybe<Scalars['String']['output']>
  postalCode: Scalars['String']['output']
  /** Mutually exclusive with addressLine5 */
  state?: Maybe<State>
}

export type AddressInput = {
  /** House number / House Name / Apartment Number / Floor / Suite number */
  addressLine1: Scalars['String']['input']
  /** Street */
  addressLine2?: InputMaybe<Scalars['String']['input']>
  addressLine3?: InputMaybe<Scalars['String']['input']>
  /** City */
  addressLine4?: InputMaybe<Scalars['String']['input']>
  /** Region / County */
  addressLine5?: InputMaybe<Scalars['String']['input']>
  addresseeName: Scalars['String']['input']
  companyName?: InputMaybe<Scalars['String']['input']>
  country: Country
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  /** Post Code / Zip Code */
  postalCode: Scalars['String']['input']
  /** Mutually exclusive with addressLine5 */
  state?: InputMaybe<State>
}

export type AddressRecord = {
  __typename?: 'AddressRecord'
  address: Address
  id: Scalars['ID']['output']
}

export type Addresses = {
  __typename?: 'Addresses'
  addresses: Array<AddressRecord>
  hasMore: Scalars['Boolean']['output']
  total: Scalars['Int']['output']
}

export type AddressesFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/**
 * URLs to alternative locale versions of the current page or product.
 *
 * These should be added to the page in <link rel="alternate" hreflang="$locale" href="$url"> tags to
 * provide hints for SEO purposes.
 */
export type AlternateLink = {
  __typename?: 'AlternateLink'
  locale: Scalars['Locale']['output']
  url: Scalars['URL']['output']
}

export type AnswerOption = {
  __typename?: 'AnswerOption'
  /**
   * This is an option to a question, an optionKey will always be provided and a translation may sometimes be provided.
   * If a translation is not provided, you should use the optionKey to present a translated value to the customer.
   */
  optionKey: Scalars['String']['output']
  translation?: Maybe<Scalars['DisplayString']['output']>
}

export type AppliedOffer = {
  __typename?: 'AppliedOffer'
  info?: Maybe<Scalars['DisplayString']['output']>
  message: Scalars['DisplayString']['output']
  /** Some offers automatically apply so cannot be removed */
  removeable: Scalars['Boolean']['output']
  /** May be null if the discount amount should not be shown */
  totalBasketDiscount?: Maybe<MoneyValue>
}

export type ApproveSocialLinkInput = {
  socialLinkId: Scalars['SocialLinkID']['input']
}

/**
 * To upload an attachment:
 *
 * 1) Query for the customer's Attachment Uploader using the attachmentUploaderConfig query.
 * 2) POST the file to the upload URL of the sites domain with the authToken in the "uploadToken" form field
 *    and the attachment in the "attachment" form field as "Content type: application/octet-stream"
 * 3) Get the "token" from the response and submit that as part of your mutation input as attachmentToken
 */
export type AttachmentUploaderConfig = {
  __typename?: 'AttachmentUploaderConfig'
  authToken: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export enum AuthenticationError {
  /** On registration, an account with the given email address already exists */
  AccountExists = 'ACCOUNT_EXISTS',
  /** The user's account is locked, and can be unlocked by resetting their password with the forgottenPassword mutation */
  AccountLocked = 'ACCOUNT_LOCKED',
  AlreadyUsedToken = 'ALREADY_USED_TOKEN',
  /**
   * On registration, there was already an account, but the account did not have a password set up.
   *
   * We sent the user a verification email which will allow them to set a password on their existing account.
   *
   * This will happen if registration occurs over a social login or guest checkout account.
   */
  EmailVerificationSent = 'EMAIL_VERIFICATION_SENT',
  ExpiredToken = 'EXPIRED_TOKEN',
  /** On login, either the user's email was not found, or their password was incorrect */
  FailedLogin = 'FAILED_LOGIN',
  /** The data provided on registration or social login was invalid, or insufficient to create an account. */
  InvalidData = 'INVALID_DATA',
  /** The password provided on registration did not meet the password complexity requirements */
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidToken = 'INVALID_TOKEN',
  /**
   * On Social Login, the link between the account on the social authentication provider and the customer was unable to
   * be verified.  It can be verified via email.  A social link verification email can be sent with the
   * requestSocialLinkVerificationEmail mutation
   */
  SocialLinkPending = 'SOCIAL_LINK_PENDING',
}

/**
 * Returned on any field intended to log the user in.
 *
 * For Web clients, just receiving a successful AuthenticationResponse is enough to be logged in, as
 * the HTTP response will come with an authentication cookie.
 *
 * For API clients, the token field should be extracted on a successful login and used
 * as the Authorization header for future API requests, in the following format:
 *
 * Authorization: Opaque <token>
 */
export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse'
  customer?: Maybe<Customer>
  error?: Maybe<AuthenticationError>
  fieldErrors?: Maybe<Array<Maybe<FormFieldValidationError>>>
  newCustomer?: Maybe<Scalars['Boolean']['output']>
}

export type AvailablePaymentOption = {
  __typename?: 'AvailablePaymentOption'
  option: Scalars['String']['output']
  subOption?: Maybe<Scalars['String']['output']>
}

export type AverageFeatureScore = {
  __typename?: 'AverageFeatureScore'
  name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type BmiCalculator = Widget & {
  __typename?: 'BMICalculator'
  calculateButtonText?: Maybe<Scalars['String']['output']>
  formSummaryDefaultText?: Maybe<Scalars['String']['output']>
  formSummaryDefaultTitle?: Maybe<Scalars['String']['output']>
  formSummaryNormalWeightText?: Maybe<Scalars['String']['output']>
  formSummaryNormalWeightTitle?: Maybe<Scalars['String']['output']>
  formSummaryObeseWeightText?: Maybe<Scalars['String']['output']>
  formSummaryObeseWeightTitle?: Maybe<Scalars['String']['output']>
  formSummaryOverWeightText?: Maybe<Scalars['String']['output']>
  formSummaryOverWeightTitle?: Maybe<Scalars['String']['output']>
  formSummaryUnderWeightText?: Maybe<Scalars['String']['output']>
  formSummaryUnderWeightTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imperialDefault?: Maybe<Scalars['String']['output']>
  nextButtonNormalWeightLink?: Maybe<Scalars['String']['output']>
  nextButtonObeseWeightLink?: Maybe<Scalars['String']['output']>
  nextButtonOverWeightLink?: Maybe<Scalars['String']['output']>
  nextButtonText?: Maybe<Scalars['String']['output']>
  nextButtonUnderWeightLink?: Maybe<Scalars['String']['output']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type BmiCalculatorV2 = Widget & {
  __typename?: 'BMICalculatorV2'
  id: Scalars['ID']['output']
  imperialDefault?: Maybe<Scalars['String']['output']>
  nextButtonNormalWeightLink?: Maybe<Scalars['String']['output']>
  nextButtonObeseWeightLink?: Maybe<Scalars['String']['output']>
  nextButtonOverWeightLink?: Maybe<Scalars['String']['output']>
  nextButtonText?: Maybe<Scalars['String']['output']>
  nextButtonUnderWeightLink?: Maybe<Scalars['String']['output']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type Basket = {
  __typename?: 'Basket'
  appliedOffers: Array<AppliedOffer>
  availablePaymentOptions: Array<AvailablePaymentOption>
  /** The total price after applying any discounts. */
  chargePrice: MoneyValue
  /** This object will be null if no code is on the basket. */
  code?: Maybe<BasketCode>
  discount: MoneyValue
  discountFromRrp: MoneyValue
  discountFromRrpExcludingOffers: MoneyValue
  earnableLoyaltyPoints?: Maybe<Scalars['Int']['output']>
  guestCheckoutAllowed: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  items: Array<BasketItem>
  /**
   * Have the contents of the current customer's saved basket been merged into this basket.
   * If this flag is true, then checkout will be blocked.
   * Clear by setting the "acknowledgeMerge" flag on the basket query.
   */
  merged: Scalars['Boolean']['output']
  messages: Array<BasketMessage>
  recommendations?: Maybe<Array<Product>>
  selectYourSample: Array<SelectYourSample>
  /** The total price before applying any discounts. */
  standardPrice: MoneyValue
  totalQuantity: Scalars['Int']['output']
}

export type BasketRecommendationsArgs = {
  limit?: Scalars['Int']['input']
}

export type BasketCode = {
  __typename?: 'BasketCode'
  /** May be null if the offer triggered by the code is not valid anymore but the code is yet to be removed */
  offer?: Maybe<AppliedOffer>
  value: Scalars['String']['output']
}

export type BasketItem = {
  __typename?: 'BasketItem'
  appliedOffers: Array<AppliedOffer>
  /**
   * The price after applying any discounts.  Will be 0 for free gifts
   * @deprecated Use chargePricePerUnit instead
   */
  chargePrice: MoneyValue
  /** The price per unit after applying any discounts.  Will be 0 for free gifts */
  chargePricePerUnit: MoneyValue
  discountPerUnit: MoneyValue
  /** If the product has been added as a free gift by an offer */
  freeGift: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  product: ProductVariant
  quantity: Scalars['Int']['output']
  /** The price per unit before applying any discounts. */
  standardPricePerUnit: MoneyValue
  subscriptionContract?: Maybe<SubscriptionContract>
  /** The price after applying any discounts.  Will be 0 for free gifts */
  totalChargePrice: MoneyValue
  totalDiscount: MoneyValue
  totalDiscountFromRrp?: Maybe<MoneyValue>
  /** The price before applying any discounts. */
  totalStandardPrice: MoneyValue
  personalisationValues: Array<ProductPersonalisationValue>
}

export type ProductPersonalisationValue = {
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Messages to show at the top of the basket */
export type BasketMessage = {
  __typename?: 'BasketMessage'
  message?: Maybe<Scalars['DisplayString']['output']>
  type: BasketMessageType
}

export enum BasketMessageType {
  /** Display as an error.  Number of unique products in the basket has exceeded maximum allowed. */
  BasketMaxSizeExceeded = 'BASKET_MAX_SIZE_EXCEEDED',
  /**
   * Display as info.  The code the user applied with an `applyCodeToBasket` mutation was valid, but cancels out an
   * existing, higher value offer. Note: This does not prevent a user overriding their current code with a lower value code
   */
  BetterOfferAlreadyApplied = 'BETTER_OFFER_ALREADY_APPLIED',
  /** Display as success.  The code the user applied with an `applyCodeToBasket` mutation was valid */
  CodeApplied = 'CODE_APPLIED',
  /** Display as an error.  The code the user applied with an `applyCodeToBasket` mutation is valid, but has expired. */
  CodeExpired = 'CODE_EXPIRED',
  /** Display as an error.  The code the user applied with an `applyCodeToBasket` mutation is invalid. */
  CodeInvalid = 'CODE_INVALID',
  /** Display as an error.  The code the user applied with an `applyCodeToBasket` mutation is valid, but not applicable to their basket. */
  CodeValidButNotApplicableToBasket = 'CODE_VALID_BUT_NOT_APPLICABLE_TO_BASKET',
  /**
   * Display as an error.  One or more gifts (detailed in the message) are currently out of
   * stock.
   */
  GiftOutOfStock = 'GIFT_OUT_OF_STOCK',
  /** Display as success.  An offer has been applied to the basket */
  OfferApplied = 'OFFER_APPLIED',
  /** Display as an error.  Product quantity has exceeded maximum allowed per order. */
  ProductMaxPerOrderExceeded = 'PRODUCT_MAX_PER_ORDER_EXCEEDED',
  /** Display as an error.  One or more products (detailed in the message) are currently out of stock. */
  ProductOutOfStock = 'PRODUCT_OUT_OF_STOCK',
  /** Display as an error.  The code the user applied with an `applyCodeToBasket` mutation is a promotion code that has already been used. */
  PromoCodeAlreadyUsed = 'PROMO_CODE_ALREADY_USED',
  /** Display as info.  The referral code is valid but as an existing customer you are not eligible to use it */
  RefereeNotEligible = 'REFEREE_NOT_ELIGIBLE',
  /** Display as success.  The referral code is valid and has been applied to the basket */
  ReferralCodeInserted = 'REFERRAL_CODE_INSERTED',
  /** Display as info.  The referral code is valid but needs the user to register or log in to apply it */
  ReferralCodeNeedsRegistration = 'REFERRAL_CODE_NEEDS_REGISTRATION',
  /** Display as an error.  The referral code is valid, but the account is already referred or is not a new account */
  ReferrerNotEligible = 'REFERRER_NOT_ELIGIBLE',
  /** Display as success.  The user became eligible for a free gift selection */
  SelectYourSampleApplied = 'SELECT_YOUR_SAMPLE_APPLIED',
  /** Display as info.  The user can add additional products to their basket to receive a discount. */
  Upsell = 'UPSELL',
}

export type Brand = {
  __typename?: 'Brand'
  /** Not all brands have an associated logo */
  imageUrl?: Maybe<Scalars['URL']['output']>
  name: Scalars['DisplayString']['output']
  page?: Maybe<BrandPage>
}

export type BrandPage = {
  __typename?: 'BrandPage'
  path: Scalars['PagePath']['output']
}

export type BrandsPageWidget = Widget & {
  __typename?: 'BrandsPageWidget'
  id: Scalars['ID']['output']
  query: Query
}

/**
 * Breadcrumbs are links to other pages that appear at the top of a page, providing a way for customers
 * to get back to a "parent" page, for example from the "Protein" page to the general "Nutrition" page.
 */
export type Breadcrumb = {
  __typename?: 'Breadcrumb'
  displayName: Scalars['DisplayString']['output']
  pagePath: Scalars['PagePath']['output']
}

export type BuildYourOwnBundleProductList = Widget & {
  __typename?: 'BuildYourOwnBundleProductList'
  disablePackQuantity?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  list_row_bundle_size?: Maybe<Scalars['String']['output']>
  maxSelectableErrorMessage?: Maybe<Scalars['String']['output']>
  maxSelectableProducts?: Maybe<Scalars['Int']['output']>
  productCount?: Maybe<Scalars['Int']['output']>
  productList?: Maybe<Scalars['String']['output']>
  quantitySelector?: Maybe<Scalars['String']['output']>
  query: Query
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type BuyNowPayLaterProvider = {
  __typename?: 'BuyNowPayLaterProvider'
  displayName: Scalars['DisplayString']['output']
  /** The instalment amount is calculated from VIP pricing if available. */
  instalmentAmount: MoneyValue
  landingPageLink: Scalars['URL']['output']
  numberOfInstalments: Scalars['Int']['output']
  providerName: Scalars['String']['output']
}

export type CancelOrderInput = {
  orderNumber: Scalars['OrderNumber']['input']
  reason: OrderCancellationReason
}

export type CancelOrderProductInput = {
  quantity: Scalars['Int']['input']
  reason: OrderCancellationReason
  sku: Scalars['SKU']['input']
}

export type CancelOrderProductsInput = {
  orderNumber: Scalars['OrderNumber']['input']
  products: Array<CancelOrderProductInput>
}

export type CancelOrderSpecialOfferGroupInput = {
  group: Scalars['SpecialOfferGroup']['input']
  reason: OrderCancellationReason
}

export type CancelOrderSpecialOfferGroupsInput = {
  groups: Array<CancelOrderSpecialOfferGroupInput>
  orderNumber: Scalars['OrderNumber']['input']
}

export type CaptchaConfiguration = {
  __typename?: 'CaptchaConfiguration'
  /**
   * Any **public** configuration information for this captcha type.
   * Not required for mobile app mechanisms.
   */
  siteKey?: Maybe<Scalars['String']['output']>
  type: CaptchaType
}

export enum CaptchaType {
  AndroidPlayIntegrity = 'ANDROID_PLAY_INTEGRITY',
  AndroidSafetyNetAttestation = 'ANDROID_SAFETY_NET_ATTESTATION',
  AppleDeviceCheck = 'APPLE_DEVICE_CHECK',
  V2Invisible = 'V2_INVISIBLE',
  V2Visible = 'V2_VISIBLE',
}

export enum CheckoutStartError {
  /**
   * The customer's baskets were merged when attempting to start checkout.
   * They should be shown the basket again and asked to confirm.
   *
   * Only occurs on checkoutStart
   */
  BasketsMerged = 'BASKETS_MERGED',
  /** Only occurs on guestCheckoutStart */
  CustomerLoggedIn = 'CUSTOMER_LOGGED_IN',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidBasket = 'INVALID_BASKET',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidBasketMaxQuantityExceeded = 'INVALID_BASKET_MAX_QUANTITY_EXCEEDED',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidBasketNoItemOnBasket = 'INVALID_BASKET_NO_ITEM_ON_BASKET',
  /** Only occurs on guestCheckoutStart */
  InvalidBasketSubscriptionsOnGuestCheckout = 'INVALID_BASKET_SUBSCRIPTIONS_ON_GUEST_CHECKOUT',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidCurrency = 'INVALID_CURRENCY',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidPaymentOption = 'INVALID_PAYMENT_OPTION',
  /** Occurs on both checkoutStart and guestCheckoutStart */
  InvalidShippingDestination = 'INVALID_SHIPPING_DESTINATION',
  /** Only occurs on checkoutStart */
  NotEligibleForReferral = 'NOT_ELIGIBLE_FOR_REFERRAL',
  NoPaymentProblem = 'NO_PAYMENT_PROBLEM',
  NoSuchOrder = 'NO_SUCH_ORDER',
  NoSuchSubscription = 'NO_SUCH_SUBSCRIPTION',
}

export type CheckoutStartInput = {
  basketId: Scalars['ID']['input']
  currency: Currency
  paymentOption?: InputMaybe<PaymentOption>
  shippingDestination: Country
}

export type CheckoutStartResponse = {
  __typename?: 'CheckoutStartResponse'
  checkoutUrl?: Maybe<Scalars['URL']['output']>
  error?: Maybe<CheckoutStartError>
}

export enum CodeValidationResult {
  AlreadyUsed = 'ALREADY_USED',
  Invalid = 'INVALID',
  NotActive = 'NOT_ACTIVE',
  Valid = 'VALID',
}

/** As described here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
export enum Country {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  /**
   * [Netherlands Antilles](https://en.wikipedia.org/wiki/Netherlands_Antilles). No longer legally
   * exists but still used as a shipping country within our system.
   */
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export type CountryDeliveryInfo = {
  __typename?: 'CountryDeliveryInfo'
  country: Country
  /** An ordered list of values to use as the cells for this table row */
  values: Array<CountryDeliveryInfoCell>
}

export type CountryDeliveryInfoCell = {
  __typename?: 'CountryDeliveryInfoCell'
  header: Scalars['String']['output']
  value: Scalars['DisplayString']['output']
}

export type CreateDiscussionInput = {
  category: DiscussionCategory
  message: AddDiscussionMessageInput
  selection?: InputMaybe<DiscussionSelectionInput>
}

export type CreditAccount = {
  __typename?: 'CreditAccount'
  actions: CreditActions
  /**
   * The total credit for this account for this currency, including credit shared from linked accounts.
   * Always in the currency of the credit account.
   */
  balance: MoneyValue
  currency: Currency
  expiringIn: MoneyValue
  linkedAccounts: Array<LinkedCreditAccount>
}

export type CreditAccountActionsArgs = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CreditAccountExpiringInArgs = {
  days?: Scalars['Int']['input']
}

export type CreditAccountsFilterInput = {
  currency?: InputMaybe<Currency>
}

export type CreditAction = {
  __typename?: 'CreditAction'
  addedAt: Scalars['Timestamp']['output']
  /** Amounts are always in the currency of the credit account. */
  amount: MoneyValue
  /** Will always be `amount` minus `amountUsed`. */
  amountAvailable: MoneyValue
  /** Will always be between 0 and `amount`, will be 0 for Reservations and Paid Out Actions. */
  amountUsed: MoneyValue
  expiresAt?: Maybe<Scalars['Timestamp']['output']>
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['DisplayString']['output']>
  order?: Maybe<Order>
  status: CreditActionStatus
  type: CreditActionType
}

export enum CreditActionStatus {
  Expired = 'EXPIRED',
  Valid = 'VALID',
}

export enum CreditActionType {
  PaidIn = 'PAID_IN',
  PaidOut = 'PAID_OUT',
  Reservation = 'RESERVATION',
}

export type CreditActions = {
  __typename?: 'CreditActions'
  actions: Array<CreditAction>
  hasMore: Scalars['Boolean']['output']
  total: Scalars['Int']['output']
}

export type CriteoSponsoredBannerAds = Widget & {
  __typename?: 'CriteoSponsoredBannerAds'
  SponsoredAdsPlacement?: Maybe<Array<Maybe<HmacAppliedPlacement>>>
  id: Scalars['ID']['output']
  query: Query
  widgetVersion?: Maybe<Scalars['String']['output']>
}

export type CriteoSponsoredBannerAdsFlagship = Widget & {
  __typename?: 'CriteoSponsoredBannerAdsFlagship'
  id: Scalars['ID']['output']
  query: Query
  sponsoredFlagshipAdsImage?: Maybe<Scalars['URL']['output']>
}

export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Azn = 'AZN',
  Bam = 'BAM',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Byr = 'BYR',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Czk = 'CZK',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Eur = 'EUR',
  Fjd = 'FJD',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Kes = 'KES',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kzt = 'KZT',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Mad = 'MAD',
  Mkd = 'MKD',
  Mop = 'MOP',
  Mxn = 'MXN',
  Myr = 'MYR',
  Ngn = 'NGN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Tnd = 'TND',
  Try = 'TRY',
  Twd = 'TWD',
  Uah = 'UAH',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Wst = 'WST',
  Xcd = 'XCD',
  Zar = 'ZAR',
}

export type Customer = {
  __typename?: 'Customer'
  addresses?: Maybe<Addresses>
  creditAccounts?: Maybe<Array<CreditAccount>>
  /** @deprecated Use the paginated returns field instead. */
  customerReturns: Array<CustomerReturn>
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  /**
   * Returns all discussions for this customer.
   * If status is provided, the result will be filtered to just discussions with the given status.
   */
  discussions?: Maybe<Discussions>
  email: Scalars['String']['output']
  fullName: Scalars['String']['output']
  hashedEmail?: Maybe<Scalars['String']['output']>
  invoices: Array<Scalars['String']['output']>
  loyalty?: Maybe<CustomerLoyalty>
  loyaltyAccountDashboard?: Maybe<LoyaltyAccountDashboard>
  loyaltyOptIn?: Maybe<Scalars['Boolean']['output']>
  marketingPreferences?: Maybe<Scalars['Boolean']['output']>
  orders?: Maybe<Orders>
  paymentCards?: Maybe<PaymentCards>
  phoneNumber?: Maybe<Scalars['String']['output']>
  referralCode: Scalars['String']['output']
  referralCount: Scalars['Int']['output']
  referralLink: Scalars['String']['output']
  returns?: Maybe<CustomerReturns>
  /**
   * All social links for the customer with the given status.
   *
   * If status is null or not supplied, all social links are returned
   */
  socialLinks?: Maybe<Array<SocialLink>>
  socialReferralMethods: Array<SocialReferralMethod>
  /** Returns all subscriptions for the user.  If status is supplied, only returns subscriptions matching the given status */
  subscriptions?: Maybe<Subscriptions>
  wishlist?: Maybe<WishlistItems>
}

export type CustomerAddressesArgs = {
  filter?: InputMaybe<AddressesFilterInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CustomerCreditAccountsArgs = {
  filter?: InputMaybe<CreditAccountsFilterInput>
}

export type CustomerDiscussionsArgs = {
  filter?: InputMaybe<DiscussionsFilterInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CustomerInvoicesArgs = {
  orderNumber: Scalars['OrderNumber']['input']
  sku: Scalars['SKU']['input']
}

export type CustomerLoyaltyAccountDashboardArgs = {
  currency: Currency
}

export type CustomerMarketingPreferencesArgs = {
  type: MarketingType
}

export type CustomerOrdersArgs = {
  filter?: InputMaybe<OrdersFilterInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CustomerPaymentCardsArgs = {
  filter?: InputMaybe<PaymentCardsFilterInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CustomerReturnsArgs = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CustomerSocialLinksArgs = {
  status?: InputMaybe<SocialLinkStatus>
}

export type CustomerSubscriptionsArgs = {
  filter?: InputMaybe<SubscriptionsFilterInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type CustomerWishlistArgs = {
  currency: Currency
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  shippingDestination: Country
  sort?: WishlistSort
}

export type CustomerLoyalty = {
  __typename?: 'CustomerLoyalty'
  pendingPoints?: Maybe<Scalars['Int']['output']>
  points: Scalars['Int']['output']
  tier?: Maybe<LoyaltyTier>
  transactions?: Maybe<Array<LoyaltyTransaction>>
}

export type CustomerReturn = {
  __typename?: 'CustomerReturn'
  customerReturnInfo: CustomerReturnInfo
  label?: Maybe<LabelWithDetails>
  returnMethod: Scalars['String']['output']
  trackingLink?: Maybe<Scalars['URL']['output']>
}

export type CustomerReturnInfo = {
  __typename?: 'CustomerReturnInfo'
  cancelledAt?: Maybe<Scalars['Timestamp']['output']>
  completedAt?: Maybe<Scalars['Timestamp']['output']>
  createdAt: Scalars['Timestamp']['output']
  customerReturnInfoLines: Array<CustomerReturnInfoLine>
  customerReturnStatus: CustomerReturnStatus
  dispatchedAt?: Maybe<Scalars['Timestamp']['output']>
  refundValue: MoneyValue
  returnNumber: Scalars['Int']['output']
  shippingPrice?: Maybe<MoneyValue>
  totalValue: MoneyValue
  tracking: Scalars['Boolean']['output']
}

export type CustomerReturnInfoLine = {
  __typename?: 'CustomerReturnInfoLine'
  product: ProductVariant
  quantity: Scalars['Int']['output']
}

export enum CustomerReturnStatus {
  /** The return has been cancelled */
  Cancelled = 'CANCELLED',
  /** The return parcel is in transit */
  InTransit = 'IN_TRANSIT',
  /** The label has been created */
  Packing = 'PACKING',
  /** The return has been processed by the warehouse */
  Refunding = 'REFUNDING',
  /** The return parcel has been delivered */
  Returned = 'RETURNED',
}

export type CustomerReturns = {
  __typename?: 'CustomerReturns'
  customerReturns: Array<CustomerReturn>
  hasMore: Scalars['Boolean']['output']
  total: Scalars['Int']['output']
}

export type DateRange = {
  __typename?: 'DateRange'
  from?: Maybe<Scalars['Date']['output']>
  to?: Maybe<Scalars['Date']['output']>
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo'
  groups: Array<DeliveryInfoGroup>
}

/** Represents a single grouping of shipping destinations, for example "Europe", "North America" or "Rest Of The World" */
export type DeliveryInfoGroup = {
  __typename?: 'DeliveryInfoGroup'
  /** Unordered list of countries.  You may wish to sort this alphabetically by the country's translated name. */
  countryDetails: Array<CountryDeliveryInfo>
  /** An ordered list of keys representing the headers of the table columns */
  headers: Array<Scalars['String']['output']>
  title: Scalars['DisplayString']['output']
}

export type DeliveryInfoWidget = Widget & {
  __typename?: 'DeliveryInfoWidget'
  id: Scalars['ID']['output']
  query: Query
}

export type DeliveryInformationWidget = Widget & {
  __typename?: 'DeliveryInformationWidget'
  deliveryOption?: Maybe<Scalars['String']['output']>
  deliveryPrice?: Maybe<Scalars['String']['output']>
  hasDetails?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  sectionDetails?: Maybe<RichContent>
  sectionTitle?: Maybe<Scalars['String']['output']>
}

export type DeliveryInstructions = {
  __typename?: 'DeliveryInstructions'
  generalInstructions: Array<Scalars['DisplayString']['output']>
  title: Scalars['DisplayString']['output']
}

export type Discussion = {
  __typename?: 'Discussion'
  category: DiscussionCategory
  createdAt: Scalars['Timestamp']['output']
  id: Scalars['ID']['output']
  messages: DiscussionMessages
  read: Scalars['Boolean']['output']
  selection?: Maybe<DiscussionSelection>
  status: DiscussionStatus
  updatedAt: Scalars['Timestamp']['output']
}

export type DiscussionMessagesArgs = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  sort?: MessageSort
}

export enum DiscussionCategory {
  Cancellation = 'CANCELLATION',
  Checkout = 'CHECKOUT',
  DamagedItem = 'DAMAGED_ITEM',
  DeliveryProblem = 'DELIVERY_PROBLEM',
  Feedback = 'FEEDBACK',
  FreeGift = 'FREE_GIFT',
  HelpWithAnNhsOrder = 'HELP_WITH_AN_NHS_ORDER',
  IncorrectItemReceived = 'INCORRECT_ITEM_RECEIVED',
  InvoiceRequest = 'INVOICE_REQUEST',
  IWantToCreateAnAccount = 'I_WANT_TO_CREATE_AN_ACCOUNT',
  IWantToReturnAnItem = 'I_WANT_TO_RETURN_AN_ITEM',
  IWantToSubmitADataRequest = 'I_WANT_TO_SUBMIT_A_DATA_REQUEST',
  MissingItem = 'MISSING_ITEM',
  MyTrackingHasntUpdated = 'MY_TRACKING_HASNT_UPDATED',
  NutritionalAdvice = 'NUTRITIONAL_ADVICE',
  OffersAndPromotions = 'OFFERS_AND_PROMOTIONS',
  Payment = 'PAYMENT',
  PlacingAnOrder = 'PLACING_AN_ORDER',
  PricebeaterRequest = 'PRICEBEATER_REQUEST',
  ProblemWithMyOrder = 'PROBLEM_WITH_MY_ORDER',
  ProductInformation = 'PRODUCT_INFORMATION',
  QualityIssue = 'QUALITY_ISSUE',
  ReturnsAndRefunds = 'RETURNS_AND_REFUNDS',
  StockAvailability = 'STOCK_AVAILABILITY',
  TaxRequest = 'TAX_REQUEST',
  UpdateOnAReturn = 'UPDATE_ON_A_RETURN',
  WebsiteIssues = 'WEBSITE_ISSUES',
  WhereIsMyOrder = 'WHERE_IS_MY_ORDER',
}

export type DiscussionMessage = {
  __typename?: 'DiscussionMessage'
  createdAt: Scalars['Timestamp']['output']
  id: Scalars['ID']['output']
  message: Scalars['DisplayString']['output']
  type: DiscussionMessageType
}

export enum DiscussionMessageType {
  Query = 'QUERY',
  Response = 'RESPONSE',
}

export type DiscussionMessages = {
  __typename?: 'DiscussionMessages'
  hasMore: Scalars['Boolean']['output']
  messages: Array<DiscussionMessage>
  total: Scalars['Int']['output']
}

export type DiscussionSelection = {
  __typename?: 'DiscussionSelection'
  selectedOrder?: Maybe<Order>
  selectedProducts: Array<OrderProduct>
}

export type DiscussionSelectionInput = {
  selectedOrderId: Scalars['ID']['input']
  selectedProductSkus: Array<Scalars['SKU']['input']>
}

export enum DiscussionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Outstanding = 'OUTSTANDING',
  Pending = 'PENDING',
}

export type Discussions = {
  __typename?: 'Discussions'
  discussions: Array<Discussion>
  hasMore: Scalars['Boolean']['output']
  total: Scalars['Int']['output']
}

export type DiscussionsFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  orderNumber?: InputMaybe<Scalars['OrderNumber']['input']>
  status?: InputMaybe<DiscussionStatus>
}

export enum DynamicEntityType {
  BrandEntity = 'BRAND_ENTITY',
  Designer = 'DESIGNER',
  FullBrandEntity = 'FULL_BRAND_ENTITY',
  Outfit = 'OUTFIT',
  Story = 'STORY',
}

export type DynamicReferralWidget = Widget & {
  __typename?: 'DynamicReferralWidget'
  balanceNote?: Maybe<Scalars['String']['output']>
  codeSubtitle?: Maybe<Scalars['String']['output']>
  codeTitle?: Maybe<Scalars['String']['output']>
  copySubtitle?: Maybe<Scalars['String']['output']>
  copyTitle?: Maybe<Scalars['String']['output']>
  friendsNote?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mainSubtitle?: Maybe<Scalars['String']['output']>
  mainTitle?: Maybe<Scalars['String']['output']>
  query: Query
  sharingSubtitle?: Maybe<Scalars['String']['output']>
  sharingTitle?: Maybe<Scalars['String']['output']>
  showCopyYourCodeSection?: Maybe<Scalars['String']['output']>
  showYourCodeSection?: Maybe<Scalars['String']['output']>
}

export type EGift = {
  __typename?: 'EGift'
  productVariant: ProductVariant
  quantity: Scalars['Int']['output']
}

export type EGiftSummary = {
  __typename?: 'EGiftSummary'
  code: Scalars['String']['output']
  codeValidationResult: CodeValidationResult
  eGifts: Array<Maybe<EGift>>
}

export type Easiware = Widget & {
  __typename?: 'Easiware'
  id: Scalars['ID']['output']
  query: Query
}

export type EditorialWidget = Widget & {
  __typename?: 'EditorialWidget'
  content?: Maybe<RichContent>
  id: Scalars['ID']['output']
  query: Query
}

export type EmailReEngagementModal = Widget & {
  __typename?: 'EmailReEngagementModal'
  defaultImageHeight?: Maybe<Scalars['String']['output']>
  defaultImageWidth?: Maybe<Scalars['String']['output']>
  emailReEngagementBulletText1?: Maybe<Scalars['String']['output']>
  emailReEngagementBulletText2?: Maybe<Scalars['String']['output']>
  emailReEngagementBulletText3?: Maybe<Scalars['String']['output']>
  emailReEngagementOptInDiscount?: Maybe<Scalars['String']['output']>
  emailReEngagementTitleText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageAltText?: Maybe<Scalars['String']['output']>
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  query: Query
}

export type Facet = {
  facetHeader: Scalars['DisplayString']['output']
  facetName: Scalars['String']['output']
}

export type FacetInput = {
  facetName: Scalars['String']['input']
  selections: Array<FacetSelectionInput>
}

/** If optionName is non-null, from and to are ignored. */
export type FacetSelectionInput = {
  from?: InputMaybe<Scalars['Float']['input']>
  optionName?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['Float']['input']>
}

export type FastTrackBanner = Widget & {
  __typename?: 'FastTrackBanner'
  fastTrackContent?: Maybe<Scalars['String']['output']>
  fastTrackHeader?: Maybe<Scalars['String']['output']>
  fastTrackUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export enum Feature {
  AccountLinking = 'ACCOUNT_LINKING',
  AddressManagement = 'ADDRESS_MANAGEMENT',
  AgeGating = 'AGE_GATING',
  AgeOnRegistration = 'AGE_ON_REGISTRATION',
  AlternateAuth = 'ALTERNATE_AUTH',
  AssociatedProducts = 'ASSOCIATED_PRODUCTS',
  BuyNowPayLater = 'BUY_NOW_PAY_LATER',
  ClickAndCollect = 'CLICK_AND_COLLECT',
  CocaColaPersonalisation = 'COCA_COLA_PERSONALISATION',
  Concessions = 'CONCESSIONS',
  Credit = 'CREDIT',
  CrossSiteReferrals = 'CROSS_SITE_REFERRALS',
  DateOfBirth = 'DATE_OF_BIRTH',
  DefaultAddresses = 'DEFAULT_ADDRESSES',
  DoubleOptIn = 'DOUBLE_OPT_IN',
  DynamicEntityManagement = 'DYNAMIC_ENTITY_MANAGEMENT',
  DynamicEntityManagementV2 = 'DYNAMIC_ENTITY_MANAGEMENT_V2',
  FastTrack = 'FAST_TRACK',
  /**
   * Whether or not the site supports login with passwords to dedicated user accounts on the site.
   *
   * If this feature is not available, other methods of logging in or proceeding may be available such
   * as social login or guest checkout
   */
  FirstPartyAuth = 'FIRST_PARTY_AUTH',
  FormInfo = 'FORM_INFO',
  FriendsReferralScheme = 'FRIENDS_REFERRAL_SCHEME',
  FullNameOnRegistration = 'FULL_NAME_ON_REGISTRATION',
  GenderOnRegistration = 'GENDER_ON_REGISTRATION',
  GiftingEmails = 'GIFTING_EMAILS',
  /**  Whether or not the site allows for the purchasing and using of gift cards. */
  GiftCards = 'GIFT_CARDS',
  GuestCheckout = 'GUEST_CHECKOUT',
  GuestCheckoutWithoutEmail = 'GUEST_CHECKOUT_WITHOUT_EMAIL',
  /** Whether products have multiple availability sources */
  HasMultipleAvailabilitySources = 'HAS_MULTIPLE_AVAILABILITY_SOURCES',
  Loyalty = 'LOYALTY',
  LoyaltyOptIn = 'LOYALTY_OPT_IN',
  /** Whether or not the site supports manual recommendations */
  ManualRecommendations = 'MANUAL_RECOMMENDATIONS',
  MarketingConsentOnRegistration = 'MARKETING_CONSENT_ON_REGISTRATION',
  MobileNumber = 'MOBILE_NUMBER',
  NextDayDelivery = 'NEXT_DAY_DELIVERY',
  /** Whether or not the site supports returns */
  OrderReturns = 'ORDER_RETURNS',
  OfflineOrders = 'OFFLINE_ORDERS',
  PayOnDelivery = 'PAY_ON_DELIVERY',
  PhysicalStore = 'PHYSICAL_STORE',
  PopInABoxTracker = 'POP_IN_A_BOX_TRACKER',
  ProductPersonalisation = 'PRODUCT_PERSONALISATION',
  Profiles = 'PROFILES',
  ReferralCodeOnRegistration = 'REFERRAL_CODE_ON_REGISTRATION',
  Reviews = 'REVIEWS',
  ReviewVoting = 'REVIEW_VOTING',
  RewardCards = 'REWARD_CARDS',
  SiteProperties = 'SITE_PROPERTIES',
  SplitNamesOnRegistration = 'SPLIT_NAMES_ON_REGISTRATION',
  SponsoredAds = 'SPONSORED_ADS',
  SubscribeAndSave = 'SUBSCRIBE_AND_SAVE',
  Subscriptions = 'SUBSCRIPTIONS',
  SubscriptionsCancelOnline = 'SUBSCRIPTIONS_CANCEL_ONLINE',
  SubscriptionsDelay = 'SUBSCRIPTIONS_DELAY',
  SubscriptionGifts = 'SUBSCRIPTION_GIFTS',
  Supersize = 'SUPERSIZE',
  Surveys = 'SURVEYS',
  TitleOnRegistration = 'TITLE_ON_REGISTRATION',
  /**  Whether vip pricing is available on the site and should be calculated  */
  VipPricingEnabled = 'VIP_PRICING_ENABLED',
  Waitlist = 'WAITLIST',
  WhereDidYouHearOnRegistration = 'WHERE_DID_YOU_HEAR_ON_REGISTRATION',
  Wishlist = 'WISHLIST',
}

/**
 * A downloadable file, which can be saved or displayed on the client.
 * This is needed primarily for workflows (e.g. returns) that include programatically-created PDF files.
 */
export type FileDownload = {
  __typename?: 'FileDownload'
  /** The contents of this file, base64 encoded. */
  content: Scalars['Base64Bytes']['output']
  /**
   * The [MIME type](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types)
   * (as used in `Content-Type` headers) for this file. e.g. `application/pdf` or `text/plain`.
   */
  mimeType: Scalars['String']['output']
}

/**
 * Flags are available as part of the extensions for every request.
 *
 * On each GraphQL request, the extensions will contain a list of all flags that apply.
 *
 * Each flag will appear at most once.
 */
export enum Flag {
  LoggedIn = 'LOGGED_IN',
  ProductContentItemsUnavailable = 'PRODUCT_CONTENT_ITEMS_UNAVAILABLE',
  Recognised = 'RECOGNISED',
  RegistrationUnavailable = 'REGISTRATION_UNAVAILABLE',
}

export type Footer = {
  __typename?: 'Footer'
  navigation?: Maybe<Navigation>
  widgets?: Maybe<Array<Widget>>
}

export enum ForgottenPasswordError {
  UnknownError = 'UNKNOWN_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
}

export type ForgottenPasswordInput = {
  username: Scalars['String']['input']
}

export type ForgottenPasswordResponse = {
  __typename?: 'ForgottenPasswordResponse'
  /** Check to make sure error is not set to confirm success */
  error?: Maybe<ForgottenPasswordError>
  /** @deprecated Check error instead */
  success: Scalars['Boolean']['output']
}

export type Form = {
  __typename?: 'Form'
  /** Can this form be sumitted (again)? */
  canSubmit: Scalars['Boolean']['output']
  /** Some forms may offer a credit reward for completion. */
  creditReward?: Maybe<MoneyValue>
  /** How the form should be displayed once you have started it. */
  displayStyle: FormDisplayStyle
  /** An ordered list of fields to be included with the form */
  fields: Array<FormField>
  /** Used as an identifier and for translations. */
  identifier: Scalars['ID']['output']
  /** Can this form be submitted multiple times? */
  isMultiSubmission: Scalars['Boolean']['output']
  /** An optional SKU which this form relates to. */
  productSku?: Maybe<Scalars['SKU']['output']>
  /** Get a single submission by ID */
  submission?: Maybe<FormSubmission>
  /** A list of all submissions to this form. */
  submissions: Array<FormSubmission>
  /** The type of form (see the enum for descriptions) */
  type: FormType
}

export type FormSubmissionArgs = {
  submissionId?: InputMaybe<Scalars['ID']['input']>
}

export type FormAnswer = {
  __typename?: 'FormAnswer'
  /** Sometimes a question is locked and cannot be edited */
  locked: Scalars['Boolean']['output']
  /** Use to get the "name" of the Field, to pre-populate the values. */
  question: FormField
  /** For single-select or free text answers, a singleton list will be returned. */
  values: Array<Scalars['String']['output']>
}

export type FormAnswerInput = {
  /** Provide the text entered into the "other" field when offered. */
  otherText?: InputMaybe<Scalars['String']['input']>
  questionName: Scalars['String']['input']
  /** For single-select or free text answers, only a singleton list will be accepted */
  values: Array<Scalars['String']['input']>
}

export enum FormDisplayStyle {
  OneQuestionPerPage = 'ONE_QUESTION_PER_PAGE',
  ProductSurvey = 'PRODUCT_SURVEY',
  SinglePage = 'SINGLE_PAGE',
}

export type FormField = {
  __typename?: 'FormField'
  answerOptions?: Maybe<Array<Maybe<AnswerOption>>>
  /**
   * If confirmable is true, the field should be rendered with an additional confirmation field, where the user must
   * re-enter the value.  Validation of this should be done on the frontend and this does not affect the data that should
   * be sent to the GraphQL API.
   */
  confirmable: Scalars['Boolean']['output']
  /**
   * The default value of this field.  May be ignored, for example if there's an existing value from
   * a submission being edited.
   */
  defaultValue?: Maybe<Scalars['String']['output']>
  /**
   * Can this field be edited? If true, then this field should still be shown on the form with the standard logic to
   * determine its initial value, but the entry should not be changed. If this field is submitted with the rest of the
   * form to the API (optional) then its value will be ignored
   */
  disabled: Scalars['Boolean']['output']
  label?: Maybe<Scalars['String']['output']>
  /**
   * This will only be non-null for likert scale questions, in which case there should be a grid of radio buttons
   * where the options are provider for each likert question.
   * The answers should be submitted in the order specified here.
   */
  likertQuestions?: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  /** Should the customer be offered the chance to choose "other" and input their own answer? */
  offerOtherTextInput: Scalars['Boolean']['output']
  /**
   * If answerOptions is non-null, the field will be rejected if the value is not one of the values provided, or null.
   * @deprecated User answerOptions instead
   */
  options?: Maybe<Array<Scalars['String']['output']>>
  /** @deprecated Use AnswerOption.translation instead */
  optionsAreLocalised: Scalars['Boolean']['output']
  /**
   * A list of requirements, all of which must be met in order for this field to be displayed.
   * If the field is not displayed, then it should not be incliuded when the form is submitted to the API.
   * These requirement will be dynamic to the form will become visible or hidden as the answers to other questions change.
   */
  renderConditions: Array<RenderCondition>
  /** If required is true, the field will be rejected if the value is empty or null */
  required: Scalars['Boolean']['output']
  translation?: Maybe<Scalars['DisplayString']['output']>
  type?: Maybe<FormFieldType>
  validators: Array<Validator>
}

export enum FormFieldType {
  /** E.g. a checkbox. */
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Email = 'EMAIL',
  Featurerating = 'FEATURERATING',
  FileUpload = 'FILE_UPLOAD',
  /** E.g. radio buttons. */
  Group = 'GROUP',
  LikertScale = 'LIKERT_SCALE',
  /** E.g. a drop down list. */
  List = 'LIST',
  MarketingConsent = 'MARKETING_CONSENT',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  Password = 'PASSWORD',
  PhoneNumber = 'PHONE_NUMBER',
  Text = 'TEXT',
  Textbox = 'TEXTBOX',
}

export type FormFieldValidationError = {
  __typename?: 'FormFieldValidationError'
  fieldName: Scalars['String']['output']
  /** true if this field has a strict set of options (LIST / GROUP) but the value provided was not one of them */
  invalidOption: Scalars['Boolean']['output']
  /** true if this field was marked as required, but the provided value was empty or null */
  requiredButNotProvided: Scalars['Boolean']['output']
  /** The list of validators which rejected this field.  A subset of the validators returned in the form */
  validators: Array<ValidatorName>
}

export type FormFilterInput = {
  identifier?: InputMaybe<Scalars['ID']['input']>
  /** You may filter by either Form Type or Identifier (or neither). */
  type?: InputMaybe<FormType>
}

export type FormInput = {
  identifier: Scalars['ID']['input']
}

export type FormSubmission = {
  __typename?: 'FormSubmission'
  answers: Array<FormAnswer>
  complete: Scalars['Boolean']['output']
  editable: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
}

export type FormSubmissionInput = {
  answers: Array<FormAnswerInput>
  identifier: Scalars['ID']['input']
  /** The submission being edited, null if this is a new submission. */
  submissionId?: InputMaybe<Scalars['ID']['input']>
}

export type FormSubmissionResponse = {
  __typename?: 'FormSubmissionResponse'
  fieldErrors?: Maybe<Array<FormFieldValidationError>>
  outcome?: Maybe<ProfileOutcomeResponse>
  status: FormSubmissionStatus
}

export enum FormSubmissionStatus {
  /** This form is locked, or otherwise unavailable, don't offer a retry. */
  CantBeSubmitted = 'CANT_BE_SUBMITTED',
  /** Success, Form was edited, but the form is still incomplete. */
  EditedIncomplete = 'EDITED_INCOMPLETE',
  /** Success, Form was edited, and this submission completed the form. */
  EditedNowComplete = 'EDITED_NOW_COMPLETE',
  /** Success, Form was edited, and it is still complete. */
  EditedStillComplete = 'EDITED_STILL_COMPLETE',
  /** There was invalid data in this submission, fix the error and retry. */
  ErrorSubmitting = 'ERROR_SUBMITTING',
  /** Success, Form was submitted and is complete. */
  Submitted = 'SUBMITTED',
  /** Success, Form was submitted, but was not completed, questions missing. */
  SubmittedIncomplete = 'SUBMITTED_INCOMPLETE',
}

export enum FormType {
  /** Forms for use within Account Creation */
  AccountCreation = 'ACCOUNT_CREATION',
  AccountSettings = 'ACCOUNT_SETTINGS',
  /** Additional Profiles will be shown on another page within the Account Section */
  AdditionalProfile = 'ADDITIONAL_PROFILE',
  /** Profiles that hold custom marketing preferences information */
  CustomMarketingPreferences = 'CUSTOM_MARKETING_PREFERENCES',
  /** Profiles that return outcomes based on answers given */
  GoalSelector = 'GOAL_SELECTOR',
  /** Main Profiles should be displayed prominently on the Account Home page */
  MainProfile = 'MAIN_PROFILE',
  /** Product recommendation as a profile */
  ProductRecommender = 'PRODUCT_RECOMMENDER',
  /** Forms for product reviews */
  ProductReview = 'PRODUCT_REVIEW',
  /** Surveys will have their own page within the Account Section too */
  ProductSurvey = 'PRODUCT_SURVEY',
  /** Professional accounts application form as a profile */
  ProfessionalAccounts = 'PROFESSIONAL_ACCOUNTS',
  /** Subscription Cancellation form as a profile */
  SubscriptionCancellation = 'SUBSCRIPTION_CANCELLATION',
  /** Trade accounts application form as a profile */
  TradeAccounts = 'TRADE_ACCOUNTS',
}

export type FoundationFinderLandingPageWidget = Widget & {
  __typename?: 'FoundationFinderLandingPageWidget'
  id: Scalars['ID']['output']
  query: Query
}

export type GenericProductItem = {
  __typename?: 'GenericProductItem'
  product?: Maybe<Product>
  productVariant?: Maybe<ProductVariant>
}

export enum GetAccountExtensionTokenError {
  InvalidLinkingToken = 'INVALID_LINKING_TOKEN',
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
  TargetAlreadyLinked = 'TARGET_ALREADY_LINKED',
}

export type GetAccountExtensionTokenInput = {
  accountLinkingToken?: InputMaybe<Scalars['AccountLinkingToken']['input']>
}

/** This response will contain either the error field or the accountExtensionToken field */
export type GetAccountExtensionTokenResponse = {
  __typename?: 'GetAccountExtensionTokenResponse'
  accountExtensionToken?: Maybe<
    Scalars['AccountLinkingExtensionToken']['output']
  >
  error?: Maybe<GetAccountExtensionTokenError>
}

export enum GetAccountLinkingTokenError {
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
}

/** This response will contain either the error field or the accountLinkingToken field */
export type GetAccountLinkingTokenResponse = {
  __typename?: 'GetAccountLinkingTokenResponse'
  accountLinkingToken?: Maybe<Scalars['AccountLinkingToken']['output']>
  error?: Maybe<GetAccountLinkingTokenError>
}

export type GiftCard = {
  __typename?: 'GiftCard'
  cardNumber: Scalars['String']['output']
  cardUuid: Scalars['String']['output']
  /** @deprecated The card number is not obfuscated. Please use cardNumber field */
  obfuscatedCardNumber: Scalars['String']['output']
}

export type GlobalAboutUsVideo = Widget & {
  __typename?: 'GlobalAboutUsVideo'
  buttonLink?: Maybe<Scalars['String']['output']>
  buttonText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  textBlock?: Maybe<RichContent>
  title?: Maybe<Scalars['String']['output']>
  transcript?: Maybe<RichContent>
  videoTitle?: Maybe<Scalars['String']['output']>
  videoURL?: Maybe<Scalars['String']['output']>
}

export type GlobalAccreditationIcon = Widget & {
  __typename?: 'GlobalAccreditationIcon'
  altText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageAltText?: Maybe<Scalars['String']['output']>
  imagePath?: Maybe<Scalars['URL']['output']>
  openNewWindow?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type GlobalAccreditationIconCollection = Widget & {
  __typename?: 'GlobalAccreditationIconCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalBrandLogos = Widget & {
  __typename?: 'GlobalBrandLogos'
  brandLogosItemOneImageVariant?: Maybe<Scalars['URL']['output']>
  brandLogosItemOneImageVariantAlt?: Maybe<Scalars['String']['output']>
  brandLogosItemOneVariantURL?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isScrollable?: Maybe<Scalars['String']['output']>
  itemFiveImage?: Maybe<Scalars['URL']['output']>
  itemFiveImageAlt?: Maybe<Scalars['String']['output']>
  itemFiveURL?: Maybe<Scalars['String']['output']>
  itemFourImage?: Maybe<Scalars['URL']['output']>
  itemFourImageAlt?: Maybe<Scalars['String']['output']>
  itemFourURL?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneImageAlt?: Maybe<Scalars['String']['output']>
  itemOneURL?: Maybe<Scalars['String']['output']>
  itemSixImage?: Maybe<Scalars['URL']['output']>
  itemSixImageAlt?: Maybe<Scalars['String']['output']>
  itemSixURL?: Maybe<Scalars['String']['output']>
  itemThreeImage?: Maybe<Scalars['URL']['output']>
  itemThreeImageAlt?: Maybe<Scalars['String']['output']>
  itemThreeURL?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoImageAlt?: Maybe<Scalars['String']['output']>
  itemTwoURL?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
  titleAlign?: Maybe<Scalars['String']['output']>
}

export type GlobalBuyingRightNow = Widget & {
  __typename?: 'GlobalBuyingRightNow'
  id: Scalars['ID']['output']
  productTextFour?: Maybe<Scalars['String']['output']>
  productTextOne?: Maybe<Scalars['String']['output']>
  productTextThree?: Maybe<Scalars['String']['output']>
  productTextTwo?: Maybe<Scalars['String']['output']>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  sku?: Maybe<ProductVariant>
  skuNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skufour?: Maybe<ProductVariant>
  skufourNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuthree?: Maybe<ProductVariant>
  skuthreeNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skutwo?: Maybe<ProductVariant>
  skutwoNew?: Maybe<WidgetProductItem>
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalBuyingRightNowManualProductUrl = Widget & {
  __typename?: 'GlobalBuyingRightNowManualProductURL'
  id: Scalars['ID']['output']
  productTextFour?: Maybe<Scalars['String']['output']>
  productTextOne?: Maybe<Scalars['String']['output']>
  productTextThree?: Maybe<Scalars['String']['output']>
  productTextTwo?: Maybe<Scalars['String']['output']>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  sku?: Maybe<ProductVariant>
  skuNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skufour?: Maybe<ProductVariant>
  skufourNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuthree?: Maybe<ProductVariant>
  skuthreeNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skutwo?: Maybe<ProductVariant>
  skutwoNew?: Maybe<WidgetProductItem>
  title?: Maybe<Scalars['String']['output']>
  url1?: Maybe<Scalars['String']['output']>
  url2?: Maybe<Scalars['String']['output']>
  url3?: Maybe<Scalars['String']['output']>
  url4?: Maybe<Scalars['String']['output']>
}

export type GlobalCardScrollerCard = Widget & {
  __typename?: 'GlobalCardScrollerCard'
  altText?: Maybe<Scalars['String']['output']>
  buttonText?: Maybe<Scalars['String']['output']>
  colorOfText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  verticalPositionOfText?: Maybe<Scalars['String']['output']>
}

export type GlobalCardScrollerSet = Widget & {
  __typename?: 'GlobalCardScrollerSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  cardStyle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalDispatchAndDateCountdownWidget = Widget & {
  __typename?: 'GlobalDispatchAndDateCountdownWidget'
  countDownEndDay?: Maybe<Scalars['String']['output']>
  countDownEndHour?: Maybe<Scalars['Int']['output']>
  countDownEndMinutes?: Maybe<Scalars['Int']['output']>
  countDownEndMonth?: Maybe<Scalars['String']['output']>
  countDownEndSeconds?: Maybe<Scalars['Int']['output']>
  countDownEndYear?: Maybe<Scalars['String']['output']>
  countDownSubTitle?: Maybe<Scalars['String']['output']>
  countDownTitle?: Maybe<Scalars['String']['output']>
  daysTitle?: Maybe<Scalars['String']['output']>
  decrement?: Maybe<Scalars['Int']['output']>
  defaultNextDayDeliveryCutoff?: Maybe<Scalars['Int']['output']>
  direction?: Maybe<Scalars['String']['output']>
  dispatchIcon?: Maybe<Scalars['String']['output']>
  dispatchTimeForFriday?: Maybe<Scalars['Int']['output']>
  dispatchTimeForMonday?: Maybe<Scalars['Int']['output']>
  dispatchTimeForSaturday?: Maybe<Scalars['Int']['output']>
  dispatchTimeForSunday?: Maybe<Scalars['Int']['output']>
  dispatchTimeForThursday?: Maybe<Scalars['Int']['output']>
  dispatchTimeForTuesday?: Maybe<Scalars['Int']['output']>
  dispatchTimeForWednesday?: Maybe<Scalars['Int']['output']>
  dispatchTimer?: Maybe<Scalars['String']['output']>
  hoursTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  linkUrl?: Maybe<Scalars['String']['output']>
  minutesTitle?: Maybe<Scalars['String']['output']>
  query: Query
  removeOnEnd?: Maybe<Scalars['String']['output']>
  secondsTitle?: Maybe<Scalars['String']['output']>
  separator?: Maybe<Scalars['String']['output']>
  showTimer?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['String']['output']>
  textAlignment?: Maybe<Scalars['String']['output']>
  useLocal?: Maybe<Scalars['String']['output']>
  weeksTitle?: Maybe<Scalars['String']['output']>
  titleColour?: Scalars['String']['output']
  subtitleColour?: Scalars['String']['output']
  digitsColour?: Scalars['String']['output']
  backgroundColour?: Scalars['String']['output']
  separatorColour?: Scalars['String']['output']
}

export type GlobalEditorialWithFeature = Widget & {
  __typename?: 'GlobalEditorialWithFeature'
  CTAFeatured?: Maybe<Scalars['String']['output']>
  CTAItemOne?: Maybe<Scalars['String']['output']>
  CTAItemTwo?: Maybe<Scalars['String']['output']>
  altItemTwo?: Maybe<Scalars['String']['output']>
  altTextFeatured?: Maybe<Scalars['String']['output']>
  altTextItemOne?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  descriptionFeatured?: Maybe<Scalars['String']['output']>
  descriptionItemOne?: Maybe<Scalars['String']['output']>
  descriptionItemTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageFeaturedLarge?: Maybe<Scalars['URL']['output']>
  imageFeaturedSmall?: Maybe<Scalars['URL']['output']>
  imageOneLarge?: Maybe<Scalars['URL']['output']>
  imageOneSmall?: Maybe<Scalars['URL']['output']>
  imageTwoLarge?: Maybe<Scalars['URL']['output']>
  imageTwoSmall?: Maybe<Scalars['URL']['output']>
  linkFeatured?: Maybe<Scalars['String']['output']>
  linkItemOne?: Maybe<Scalars['String']['output']>
  linkItemTwo?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
  titleFeatured?: Maybe<Scalars['String']['output']>
  titleItemOne?: Maybe<Scalars['String']['output']>
  titleItemTwo?: Maybe<Scalars['String']['output']>
}

export type GlobalFooterAccreditationIcons = Widget & {
  __typename?: 'GlobalFooterAccreditationIcons'
  Image2Alt?: Maybe<Scalars['String']['output']>
  Image3Alt?: Maybe<Scalars['String']['output']>
  Image3Href?: Maybe<Scalars['URL']['output']>
  id: Scalars['ID']['output']
  image1?: Maybe<Scalars['URL']['output']>
  image1Alt?: Maybe<Scalars['String']['output']>
  image1Href?: Maybe<Scalars['URL']['output']>
  image2?: Maybe<Scalars['URL']['output']>
  image2Href?: Maybe<Scalars['URL']['output']>
  image3?: Maybe<Scalars['URL']['output']>
  query: Query
  subtitle1?: Maybe<Scalars['String']['output']>
  subtitle2?: Maybe<Scalars['String']['output']>
  subtitle3?: Maybe<Scalars['String']['output']>
  subtitle4?: Maybe<Scalars['String']['output']>
}

export type GlobalFooterContactUs = Widget & {
  __typename?: 'GlobalFooterContactUs'
  id: Scalars['ID']['output']
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  line3?: Maybe<Scalars['String']['output']>
  line4?: Maybe<Scalars['String']['output']>
  line5?: Maybe<Scalars['String']['output']>
  line6?: Maybe<Scalars['String']['output']>
  line7?: Maybe<Scalars['String']['output']>
  line8?: Maybe<Scalars['String']['output']>
  line9?: Maybe<Scalars['String']['output']>
  line10?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalFourBestSellers = Widget & {
  __typename?: 'GlobalFourBestSellers'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductFour?: Maybe<ProductVariant>
  ProductFourButtonText?: Maybe<Scalars['String']['output']>
  ProductFourNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductOne?: Maybe<ProductVariant>
  ProductOneButtonText?: Maybe<Scalars['String']['output']>
  ProductOneNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductThree?: Maybe<ProductVariant>
  ProductThreeButtonText?: Maybe<Scalars['String']['output']>
  ProductThreeNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductTwo?: Maybe<ProductVariant>
  ProductTwoButtonText?: Maybe<Scalars['String']['output']>
  ProductTwoNew?: Maybe<WidgetProductItem>
  Title?: Maybe<Scalars['String']['output']>
  disableTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalFourButtonLink = Widget & {
  __typename?: 'GlobalFourButtonLink'
  buttonTextFour?: Maybe<Scalars['String']['output']>
  buttonTextOne?: Maybe<Scalars['String']['output']>
  buttonTextThree?: Maybe<Scalars['String']['output']>
  buttonTextTwo?: Maybe<Scalars['String']['output']>
  buttonUrlFour?: Maybe<Scalars['String']['output']>
  buttonUrlOne?: Maybe<Scalars['String']['output']>
  buttonUrlThree?: Maybe<Scalars['String']['output']>
  buttonUrlTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalFourItemEditorial = Widget & {
  __typename?: 'GlobalFourItemEditorial'
  altItemFour?: Maybe<Scalars['String']['output']>
  altItemOne?: Maybe<Scalars['String']['output']>
  altItemThree?: Maybe<Scalars['String']['output']>
  altItemTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemFourCTA?: Maybe<Scalars['String']['output']>
  itemFourDescription?: Maybe<Scalars['String']['output']>
  itemFourImage?: Maybe<Scalars['URL']['output']>
  itemFourSubtitle?: Maybe<Scalars['String']['output']>
  itemFourTitle?: Maybe<Scalars['String']['output']>
  itemFourUrl?: Maybe<Scalars['String']['output']>
  itemOneCTA?: Maybe<Scalars['String']['output']>
  itemOneDescription?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneSubtitle?: Maybe<Scalars['String']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneUrl?: Maybe<Scalars['String']['output']>
  itemThreeCTA?: Maybe<Scalars['String']['output']>
  itemThreeDescription?: Maybe<Scalars['String']['output']>
  itemThreeImage?: Maybe<Scalars['URL']['output']>
  itemThreeSubtitle?: Maybe<Scalars['String']['output']>
  itemThreeTitle?: Maybe<Scalars['String']['output']>
  itemThreeUrl?: Maybe<Scalars['String']['output']>
  itemTwoCTA?: Maybe<Scalars['String']['output']>
  itemTwoDescription?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoSubtitle?: Maybe<Scalars['String']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoUrl?: Maybe<Scalars['String']['output']>
  itemsCTAAlignment?: Maybe<Scalars['String']['output']>
  noWrapItems?: Maybe<Scalars['String']['output']>
  query: Query
  styleModifier?: Maybe<Scalars['String']['output']>
  textAlignment?: Maybe<Scalars['String']['output']>
  widgetLink?: Maybe<Scalars['String']['output']>
  widgetLinkText?: Maybe<Scalars['String']['output']>
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalGeneralImageBanner = Widget & {
  __typename?: 'GlobalGeneralImageBanner'
  id: Scalars['ID']['output']
  imageAltText?: Maybe<Scalars['String']['output']>
  largeImage?: Maybe<Scalars['URL']['output']>
  linkUrl?: Maybe<Scalars['String']['output']>
  mediumImage?: Maybe<Scalars['URL']['output']>
  query: Query
  smallImage?: Maybe<Scalars['URL']['output']>
}

export type GlobalHelpCentreAccordion = Widget & {
  __typename?: 'GlobalHelpCentreAccordion'
  accordionContent?: Maybe<RichContent>
  accordionHeading?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalHelpCentreAccordion2 = Widget & {
  __typename?: 'GlobalHelpCentreAccordion2'
  accordionContent?: Maybe<RichContent>
  accordionHeading?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalHelpCentreAccordionCollection = Widget & {
  __typename?: 'GlobalHelpCentreAccordionCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalHelpCentreCollection = Widget & {
  __typename?: 'GlobalHelpCentreCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalHeroCtaBanner = Widget & {
  __typename?: 'GlobalHeroCTABanner'
  altTextImg?: Maybe<Scalars['String']['output']>
  buttonOne?: Maybe<Scalars['String']['output']>
  buttonOneLink?: Maybe<Scalars['String']['output']>
  buttonTwo?: Maybe<Scalars['String']['output']>
  buttonTwoLink?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageDesktop?: Maybe<Scalars['URL']['output']>
  imageExtraSmall?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
}

export type GlobalImageCard = Widget & {
  __typename?: 'GlobalImageCard'
  altText?: Maybe<Scalars['String']['output']>
  buttonText?: Maybe<Scalars['String']['output']>
  colorOfText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  noBorder?: Maybe<Scalars['Boolean']['output']>
  openLinksInNewTab?: Maybe<Scalars['String']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  verticalPositionOfText?: Maybe<Scalars['String']['output']>
}

export type GlobalImageCardSet = Widget & {
  __typename?: 'GlobalImageCardSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  cardStyle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  noBorder?: Maybe<Scalars['Boolean']['output']>
  query: Query
  stackCardsOnMobile?: Maybe<Scalars['Boolean']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalMultiButton = Widget & {
  __typename?: 'GlobalMultiButton'
  id: Scalars['ID']['output']
  multiButtonWidget_buttonStyle?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemFiveCTAText?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemFiveURL?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemFourCTAText?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemFourURL?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemOneCTAText?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemOneURL?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemSixCTAText?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemSixURL?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemThreeCTAText?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemThreeURL?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemTwoCTAText?: Maybe<Scalars['String']['output']>
  multiButtonWidget_itemTwoURL?: Maybe<Scalars['String']['output']>
  query: Query
}

export type GlobalPrimaryBanner = Widget & {
  __typename?: 'GlobalPrimaryBanner'
  altImageLarge?: Maybe<Scalars['String']['output']>
  altLogoPng?: Maybe<Scalars['String']['output']>
  bannerURL?: Maybe<Scalars['String']['output']>
  contentAlign?: Maybe<Scalars['String']['output']>
  contentBoxPosition?: Maybe<Scalars['String']['output']>
  contentTheme?: Maybe<Scalars['String']['output']>
  ctaOne?: Maybe<Scalars['String']['output']>
  ctaOneAriaLabel?: Maybe<Scalars['String']['output']>
  ctaOneURL?: Maybe<Scalars['String']['output']>
  ctaOverride?: Maybe<Scalars['String']['output']>
  ctaTwo?: Maybe<Scalars['String']['output']>
  ctaTwoAriaLabel?: Maybe<Scalars['String']['output']>
  ctaTwoURL?: Maybe<Scalars['String']['output']>
  hasMargin?: Maybe<Scalars['String']['output']>
  hasRevieveButtons?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  logopngImageBG?: Maybe<Scalars['URL']['output']>
  logopngWhiteBG?: Maybe<Scalars['URL']['output']>
  query: Query
  revievePartnerId_1?: Maybe<Scalars['String']['output']>
  revievePartnerId_1_buttonText?: Maybe<Scalars['String']['output']>
  revievePartnerId_2?: Maybe<Scalars['String']['output']>
  revievePartnerId_2_buttonText?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  useH1?: Maybe<Scalars['String']['output']>
}

export type GlobalPrimaryBannerWithList = Widget & {
  __typename?: 'GlobalPrimaryBannerWithList'
  altImageLarge?: Maybe<Scalars['String']['output']>
  altLogoPng?: Maybe<Scalars['String']['output']>
  bannerURL?: Maybe<Scalars['String']['output']>
  contentAlign?: Maybe<Scalars['String']['output']>
  contentBoxPosition?: Maybe<Scalars['String']['output']>
  contentTheme?: Maybe<Scalars['String']['output']>
  ctaOne?: Maybe<Scalars['String']['output']>
  ctaOneAriaLabel?: Maybe<Scalars['String']['output']>
  ctaOneURL?: Maybe<Scalars['String']['output']>
  ctaTwo?: Maybe<Scalars['String']['output']>
  ctaTwoAriaLabel?: Maybe<Scalars['String']['output']>
  ctaTwoURL?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  listBrowserSection?: Maybe<Scalars['String']['output']>
  logopngImageBG?: Maybe<Scalars['URL']['output']>
  logopngWhiteBG?: Maybe<Scalars['URL']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
}

export type GlobalPrimaryBannerWithTextOverlay = Widget & {
  __typename?: 'GlobalPrimaryBannerWithTextOverlay'
  altImageLarge?: Maybe<Scalars['String']['output']>
  altLogoPng?: Maybe<Scalars['String']['output']>
  bannerURL?: Maybe<Scalars['String']['output']>
  contentAlign?: Maybe<Scalars['String']['output']>
  contentBoxPosition?: Maybe<Scalars['String']['output']>
  contentTheme?: Maybe<Scalars['String']['output']>
  ctaOne?: Maybe<Scalars['String']['output']>
  ctaOneAriaLabel?: Maybe<Scalars['String']['output']>
  ctaOneURL?: Maybe<Scalars['String']['output']>
  ctaOverride?: Maybe<Scalars['String']['output']>
  ctaTwo?: Maybe<Scalars['String']['output']>
  ctaTwoAriaLabel?: Maybe<Scalars['String']['output']>
  ctaTwoURL?: Maybe<Scalars['String']['output']>
  hasMargin?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  logopngImageBG?: Maybe<Scalars['URL']['output']>
  logopngWhiteBG?: Maybe<Scalars['URL']['output']>
  openInTabOne?: Maybe<Scalars['String']['output']>
  openInTabTwo?: Maybe<Scalars['String']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  useH1?: Maybe<Scalars['String']['output']>
}

export type GlobalProductCardScroller = Widget & {
  __typename?: 'GlobalProductCardScroller'
  hasArrows?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  productSkuList?: Maybe<Array<Maybe<ProductVariant>>>
  productSkuListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalReferral3StepGuide = Widget & {
  __typename?: 'GlobalReferral3StepGuide'
  id: Scalars['ID']['output']
  query: Query
  smallprint?: Maybe<Scalars['String']['output']>
  step1icon?: Maybe<Scalars['String']['output']>
  step1text?: Maybe<Scalars['String']['output']>
  step1title?: Maybe<Scalars['String']['output']>
  step2icon?: Maybe<Scalars['String']['output']>
  step2text?: Maybe<Scalars['String']['output']>
  step2title?: Maybe<Scalars['String']['output']>
  step3icon?: Maybe<Scalars['String']['output']>
  step3text?: Maybe<Scalars['String']['output']>
  step3title?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalScalableLogos = Widget & {
  __typename?: 'GlobalScalableLogos'
  altTextFive?: Maybe<Scalars['String']['output']>
  altTextFour?: Maybe<Scalars['String']['output']>
  altTextOne?: Maybe<Scalars['String']['output']>
  altTextSix?: Maybe<Scalars['String']['output']>
  altTextThree?: Maybe<Scalars['String']['output']>
  altTextTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imagePathFive?: Maybe<Scalars['URL']['output']>
  imagePathFour?: Maybe<Scalars['URL']['output']>
  imagePathOne?: Maybe<Scalars['URL']['output']>
  imagePathSix?: Maybe<Scalars['URL']['output']>
  imagePathThree?: Maybe<Scalars['URL']['output']>
  imagePathTwo?: Maybe<Scalars['URL']['output']>
  query: Query
  titleFive?: Maybe<Scalars['String']['output']>
  titleFour?: Maybe<Scalars['String']['output']>
  titleOne?: Maybe<Scalars['String']['output']>
  titleSix?: Maybe<Scalars['String']['output']>
  titleThree?: Maybe<Scalars['String']['output']>
  titleTwo?: Maybe<Scalars['String']['output']>
  urlFive?: Maybe<Scalars['String']['output']>
  urlFour?: Maybe<Scalars['String']['output']>
  urlOne?: Maybe<Scalars['String']['output']>
  urlSix?: Maybe<Scalars['String']['output']>
  urlThree?: Maybe<Scalars['String']['output']>
  urlTwo?: Maybe<Scalars['String']['output']>
}

export type GlobalSectionPeek = Widget & {
  __typename?: 'GlobalSectionPeek'
  id: Scalars['ID']['output']
  numberOfProducts?: Maybe<Scalars['Int']['output']>
  productList?: Maybe<ProductList>
  qubitPlacementId?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
  titleAlign?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type GlobalSectionPeekProductListArgs = {
  input: ProductListInput
}

export type GlobalSetAndromeda = Widget & {
  __typename?: 'GlobalSetAndromeda'
  banners?: Maybe<Array<Maybe<Widget>>>
  columns?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalSimpleTextCtaWidget = Widget & {
  __typename?: 'GlobalSimpleTextCTAWidget'
  id: Scalars['ID']['output']
  itemOneCTAText?: Maybe<Scalars['String']['output']>
  itemOneURL?: Maybe<Scalars['String']['output']>
  query: Query
  text?: Maybe<Scalars['String']['output']>
  textAlign?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalSixItemCategories = Widget & {
  __typename?: 'GlobalSixItemCategories'
  altItemFive?: Maybe<Scalars['String']['output']>
  altItemFour?: Maybe<Scalars['String']['output']>
  altItemOne?: Maybe<Scalars['String']['output']>
  altItemSix?: Maybe<Scalars['String']['output']>
  altItemThree?: Maybe<Scalars['String']['output']>
  altItemTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemCTAAlign?: Maybe<Scalars['String']['output']>
  itemFiveCTA?: Maybe<Scalars['String']['output']>
  itemFiveImage?: Maybe<Scalars['URL']['output']>
  itemFiveLink?: Maybe<Scalars['String']['output']>
  itemFiveTitle?: Maybe<Scalars['String']['output']>
  itemFourCTA?: Maybe<Scalars['String']['output']>
  itemFourImage?: Maybe<Scalars['URL']['output']>
  itemFourLink?: Maybe<Scalars['String']['output']>
  itemFourTitle?: Maybe<Scalars['String']['output']>
  itemOneCTA?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneLink?: Maybe<Scalars['String']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemSixCTA?: Maybe<Scalars['String']['output']>
  itemSixImage?: Maybe<Scalars['URL']['output']>
  itemSixLink?: Maybe<Scalars['String']['output']>
  itemSixTitle?: Maybe<Scalars['String']['output']>
  itemThreeCTA?: Maybe<Scalars['String']['output']>
  itemThreeImage?: Maybe<Scalars['URL']['output']>
  itemThreeLink?: Maybe<Scalars['String']['output']>
  itemThreeTitle?: Maybe<Scalars['String']['output']>
  itemTitleAlign?: Maybe<Scalars['String']['output']>
  itemTwoCTA?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoLink?: Maybe<Scalars['String']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalSocialIcon = Widget & {
  __typename?: 'GlobalSocialIcon'
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  url?: Maybe<Scalars['String']['output']>
}

export type GlobalSocialIconCollection = Widget & {
  __typename?: 'GlobalSocialIconCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalSocialIconCollectionv2 = Widget & {
  __typename?: 'GlobalSocialIconCollectionv2'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalSocialIconv2 = Widget & {
  __typename?: 'GlobalSocialIconv2'
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  url?: Maybe<Scalars['String']['output']>
}

export type GlobalStripBanner = Widget & {
  __typename?: 'GlobalStripBanner'
  CTAAlignment?: Maybe<Scalars['String']['output']>
  hideDefaultBanner?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  stripBannerText?: Maybe<Scalars['String']['output']>
  stripBannerTextAccount?: Maybe<Scalars['String']['output']>
  stripBannerTextBasket?: Maybe<Scalars['String']['output']>
  stripBannerTextHomepage?: Maybe<Scalars['String']['output']>
  stripBannerTextPDP?: Maybe<Scalars['String']['output']>
  stripBannerTextPLP?: Maybe<Scalars['String']['output']>
  stripBannerURL?: Maybe<Scalars['String']['output']>
  stripBannerURLAccount?: Maybe<Scalars['String']['output']>
  stripBannerURLBasket?: Maybe<Scalars['String']['output']>
  stripBannerURLHomepage?: Maybe<Scalars['String']['output']>
  stripBannerURLPDP?: Maybe<Scalars['String']['output']>
  stripBannerURLPLP?: Maybe<Scalars['String']['output']>
}

export type GlobalSubscriptionOptions = Widget & {
  __typename?: 'GlobalSubscriptionOptions'
  id: Scalars['ID']['output']
  query: Query
  subscriptionOptionsRibbonMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsShowRibbon?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFour?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourCTAText?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourDeliveryInfo?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourImage?: Maybe<Scalars['URL']['output']>
  subscriptionOptionsSkuFourPrice?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourPriceAppend?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourSaveMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourTitle?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuFourUspMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOne?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOneCTAText?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOneDeliveryInfo?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOneImage?: Maybe<Scalars['URL']['output']>
  subscriptionOptionsSkuOnePrice?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOnePriceAppend?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOneSaveMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOneTitle?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuOneUspMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThree?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreeCTAText?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreeDeliveryInfo?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreeImage?: Maybe<Scalars['URL']['output']>
  subscriptionOptionsSkuThreePrice?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreePriceAppend?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreeSaveMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreeTitle?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuThreeUspMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwo?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoCTAText?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoDeliveryInfo?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoImage?: Maybe<Scalars['URL']['output']>
  subscriptionOptionsSkuTwoPrice?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoPriceAppend?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoSaveMessage?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoTitle?: Maybe<Scalars['String']['output']>
  subscriptionOptionsSkuTwoUspMessage?: Maybe<Scalars['String']['output']>
}

export type GlobalTabbedWidgetSet = Widget & {
  __typename?: 'GlobalTabbedWidgetSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  tabbedWidgetSetSubTitle?: Maybe<Scalars['String']['output']>
  tabbedWidgetSetTitle?: Maybe<Scalars['String']['output']>
  tabbedWidgetTabTitle_1?: Maybe<Scalars['String']['output']>
  tabbedWidgetTabTitle_2?: Maybe<Scalars['String']['output']>
}

export type GlobalThreeItemEditorial = Widget & {
  __typename?: 'GlobalThreeItemEditorial'
  altItemOne?: Maybe<Scalars['String']['output']>
  altItemThree?: Maybe<Scalars['String']['output']>
  altItemTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemOneCTAText?: Maybe<Scalars['String']['output']>
  itemOneDescription?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneSubtitle?: Maybe<Scalars['String']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneUrl?: Maybe<Scalars['String']['output']>
  itemOne_secondaryCTAText?: Maybe<Scalars['String']['output']>
  itemOne_secondaryCTAUrl?: Maybe<Scalars['String']['output']>
  itemThreeCTAText?: Maybe<Scalars['String']['output']>
  itemThreeDescription?: Maybe<Scalars['String']['output']>
  itemThreeImage?: Maybe<Scalars['URL']['output']>
  itemThreeSubtitle?: Maybe<Scalars['String']['output']>
  itemThreeTitle?: Maybe<Scalars['String']['output']>
  itemThreeUrl?: Maybe<Scalars['String']['output']>
  itemThree_secondaryCTAText?: Maybe<Scalars['String']['output']>
  itemThree_secondaryCTAUrl?: Maybe<Scalars['String']['output']>
  itemTwoCTAText?: Maybe<Scalars['String']['output']>
  itemTwoDescription?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoSubtitle?: Maybe<Scalars['String']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoUrl?: Maybe<Scalars['String']['output']>
  itemTwo_secondaryCTAText?: Maybe<Scalars['String']['output']>
  itemTwo_secondaryCTAUrl?: Maybe<Scalars['String']['output']>
  query: Query
  threeItemItemOneAltTextVariant?: Maybe<Scalars['String']['output']>
  threeItemItemOneCTATextVariant?: Maybe<Scalars['String']['output']>
  threeItemItemOneDescriptionVariant?: Maybe<Scalars['String']['output']>
  threeItemItemOneImageVariant?: Maybe<Scalars['URL']['output']>
  threeItemItemOneSubtitleVariant?: Maybe<Scalars['String']['output']>
  threeItemItemOneTitleVariant?: Maybe<Scalars['String']['output']>
  threeItemItemOneVariantUrl?: Maybe<Scalars['String']['output']>
  useCircularImages?: Maybe<Scalars['String']['output']>
  widgetHeadingSize?: Maybe<Scalars['String']['output']>
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalThreeItemEditorialSubtitleBg = Widget & {
  __typename?: 'GlobalThreeItemEditorialSubtitleBG'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemOneAltText?: Maybe<Scalars['String']['output']>
  itemOneCTAText?: Maybe<Scalars['String']['output']>
  itemOneDescription?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneSubtitle?: Maybe<Scalars['String']['output']>
  itemOneSubtitleBGColour?: Maybe<Scalars['String']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneUrl?: Maybe<Scalars['String']['output']>
  itemThreeAltText?: Maybe<Scalars['String']['output']>
  itemThreeCTAText?: Maybe<Scalars['String']['output']>
  itemThreeDescription?: Maybe<Scalars['String']['output']>
  itemThreeImage?: Maybe<Scalars['URL']['output']>
  itemThreeSubtitle?: Maybe<Scalars['String']['output']>
  itemThreeSubtitleBGColour?: Maybe<Scalars['String']['output']>
  itemThreeTitle?: Maybe<Scalars['String']['output']>
  itemThreeUrl?: Maybe<Scalars['String']['output']>
  itemTwoAltText?: Maybe<Scalars['String']['output']>
  itemTwoCTAText?: Maybe<Scalars['String']['output']>
  itemTwoDescription?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoSubtitle?: Maybe<Scalars['String']['output']>
  itemTwoSubtitleBGColour?: Maybe<Scalars['String']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoUrl?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalTransformationSlider = Widget & {
  __typename?: 'GlobalTransformationSlider'
  afterImageAltText?: Maybe<Scalars['String']['output']>
  afterImageLarge?: Maybe<Scalars['URL']['output']>
  afterImageMedium?: Maybe<Scalars['URL']['output']>
  afterImageSmall?: Maybe<Scalars['URL']['output']>
  beforeImageAltText?: Maybe<Scalars['String']['output']>
  beforeImageLarge?: Maybe<Scalars['URL']['output']>
  beforeImageMedium?: Maybe<Scalars['URL']['output']>
  beforeImageSmall?: Maybe<Scalars['URL']['output']>
  dividingLineColor?: Maybe<Scalars['String']['output']>
  dragButtonPosition?: Maybe<Scalars['String']['output']>
  dragLinePosition?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
  titlePosition?: Maybe<Scalars['String']['output']>
}

export type GlobalTrendingHashtagBlock = Widget & {
  __typename?: 'GlobalTrendingHashtagBlock'
  hashtag?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  url?: Maybe<Scalars['String']['output']>
}

export type GlobalTrendingHashtagCollection = Widget & {
  __typename?: 'GlobalTrendingHashtagCollection'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalTwoBestSellers = Widget & {
  __typename?: 'GlobalTwoBestSellers'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductOne?: Maybe<ProductVariant>
  ProductOneButtonText?: Maybe<Scalars['String']['output']>
  ProductOneNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductTwo?: Maybe<ProductVariant>
  ProductTwoButtonText?: Maybe<Scalars['String']['output']>
  ProductTwoNew?: Maybe<WidgetProductItem>
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalTwoItemEditorial = Widget & {
  __typename?: 'GlobalTwoItemEditorial'
  altItemOne?: Maybe<Scalars['String']['output']>
  altItemTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemOneCTAText?: Maybe<Scalars['String']['output']>
  itemOneDescription?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneURL?: Maybe<Scalars['String']['output']>
  itemTwoCTAText?: Maybe<Scalars['String']['output']>
  itemTwoDescription?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoURL?: Maybe<Scalars['String']['output']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalTwoItemImageTextBlock = Widget & {
  __typename?: 'GlobalTwoItemImageTextBlock'
  backgroundColour?: Maybe<Scalars['String']['output']>
  button2Text?: Maybe<Scalars['String']['output']>
  button2Url?: Maybe<Scalars['String']['output']>
  hasMargin?: Maybe<Scalars['String']['output']>
  hasPadding?: Maybe<Scalars['String']['output']>
  hasRevieveButtons?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  imageAnnotationText?: Maybe<Scalars['String']['output']>
  imageProductText?: Maybe<Scalars['String']['output']>
  imageProductURL?: Maybe<Scalars['String']['output']>
  itemAlign?: Maybe<Scalars['String']['output']>
  itemAlt?: Maybe<Scalars['String']['output']>
  itemButton?: Maybe<Scalars['String']['output']>
  itemImage?: Maybe<Scalars['URL']['output']>
  itemImageSmall?: Maybe<Scalars['URL']['output']>
  itemText?: Maybe<RichContent>
  itemTitle?: Maybe<Scalars['String']['output']>
  itemURL?: Maybe<Scalars['String']['output']>
  lightMode?: Maybe<Scalars['String']['output']>
  query: Query
  revievePartnerId_1?: Maybe<Scalars['String']['output']>
  revievePartnerId_2?: Maybe<Scalars['String']['output']>
  textAlign?: Maybe<Scalars['String']['output']>
  transcript?: Maybe<RichContent>
  videoTitle?: Maybe<Scalars['String']['output']>
  videoUrl?: Maybe<Scalars['String']['output']>
  textColour?: Maybe<Scalars['String']['output']>
}

export type GlobalTwoItemImageTextCta = Widget & {
  __typename?: 'GlobalTwoItemImageTextCTA'
  backgroundColour?: Maybe<Scalars['String']['output']>
  contentTheme?: Maybe<Scalars['String']['output']>
  hasMargin?: Maybe<Scalars['String']['output']>
  hasPadding?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemOneCTAText?: Maybe<Scalars['String']['output']>
  itemOneContentAlign?: Maybe<Scalars['String']['output']>
  itemOneDescription?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneImageAltText?: Maybe<Scalars['String']['output']>
  itemOneImageSmall?: Maybe<Scalars['URL']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneURL?: Maybe<Scalars['String']['output']>
  itemTwoCTAText?: Maybe<Scalars['String']['output']>
  itemTwoContentAlign?: Maybe<Scalars['String']['output']>
  itemTwoDescription?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoImageAltText?: Maybe<Scalars['String']['output']>
  itemTwoImageSmall?: Maybe<Scalars['URL']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoURL?: Maybe<Scalars['String']['output']>
  query: Query
}

export type GlobalTwoItemImageTextCta3070 = Widget & {
  __typename?: 'GlobalTwoItemImageTextCTA3070'
  contentTheme?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemOneCTAText?: Maybe<Scalars['String']['output']>
  itemOneContentAlign?: Maybe<Scalars['String']['output']>
  itemOneDescription?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneImageAltText?: Maybe<Scalars['String']['output']>
  itemOneImageSmall?: Maybe<Scalars['URL']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneURL?: Maybe<Scalars['String']['output']>
  itemTwoCTAText?: Maybe<Scalars['String']['output']>
  itemTwoContentAlign?: Maybe<Scalars['String']['output']>
  itemTwoDescription?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoImageAltText?: Maybe<Scalars['String']['output']>
  itemTwoImageSmall?: Maybe<Scalars['URL']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoURL?: Maybe<Scalars['String']['output']>
  query: Query
}

export type GlobalUgcCarousel = Widget & {
  __typename?: 'GlobalUGCCarousel'
  category?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  tag?: Maybe<Scalars['String']['output']>
  ugcSubText?: Maybe<Scalars['String']['output']>
  ugcTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalVideoGallery = Widget & {
  __typename?: 'GlobalVideoGallery'
  id: Scalars['ID']['output']
  itemFiveButtonLink?: Maybe<Scalars['String']['output']>
  itemFiveCTA?: Maybe<Scalars['String']['output']>
  itemFiveCdnPath?: Maybe<Scalars['String']['output']>
  itemFiveImage?: Maybe<Scalars['URL']['output']>
  itemFiveImageAltText?: Maybe<Scalars['String']['output']>
  itemFiveTitle?: Maybe<Scalars['String']['output']>
  itemFiveTranscript?: Maybe<RichContent>
  itemFiveVideoURL?: Maybe<Scalars['String']['output']>
  itemFourButtonLink?: Maybe<Scalars['String']['output']>
  itemFourCTA?: Maybe<Scalars['String']['output']>
  itemFourCdnPath?: Maybe<Scalars['String']['output']>
  itemFourImage?: Maybe<Scalars['URL']['output']>
  itemFourImageAltText?: Maybe<Scalars['String']['output']>
  itemFourTitle?: Maybe<Scalars['String']['output']>
  itemFourTranscript?: Maybe<RichContent>
  itemFourVideoURL?: Maybe<Scalars['String']['output']>
  itemOneButtonLink?: Maybe<Scalars['String']['output']>
  itemOneCTA?: Maybe<Scalars['String']['output']>
  itemOneCdnPath?: Maybe<Scalars['String']['output']>
  itemOneImage?: Maybe<Scalars['URL']['output']>
  itemOneImageAltText?: Maybe<Scalars['String']['output']>
  itemOneTitle?: Maybe<Scalars['String']['output']>
  itemOneTranscript?: Maybe<RichContent>
  itemOneVideoURL?: Maybe<Scalars['String']['output']>
  itemSixButtonLink?: Maybe<Scalars['String']['output']>
  itemSixCTA?: Maybe<Scalars['String']['output']>
  itemSixCdnPath?: Maybe<Scalars['String']['output']>
  itemSixImage?: Maybe<Scalars['URL']['output']>
  itemSixImageAltText?: Maybe<Scalars['String']['output']>
  itemSixTitle?: Maybe<Scalars['String']['output']>
  itemSixTranscript?: Maybe<RichContent>
  itemSixVideoURL?: Maybe<Scalars['String']['output']>
  itemThreeButtonLink?: Maybe<Scalars['String']['output']>
  itemThreeCTA?: Maybe<Scalars['String']['output']>
  itemThreeCdnPath?: Maybe<Scalars['String']['output']>
  itemThreeImage?: Maybe<Scalars['URL']['output']>
  itemThreeImageAltText?: Maybe<Scalars['String']['output']>
  itemThreeTitle?: Maybe<Scalars['String']['output']>
  itemThreeTranscript?: Maybe<RichContent>
  itemThreeVideoURL?: Maybe<Scalars['String']['output']>
  itemTwoButtonLink?: Maybe<Scalars['String']['output']>
  itemTwoCTA?: Maybe<Scalars['String']['output']>
  itemTwoCdnPath?: Maybe<Scalars['String']['output']>
  itemTwoImage?: Maybe<Scalars['URL']['output']>
  itemTwoImageAltText?: Maybe<Scalars['String']['output']>
  itemTwoTitle?: Maybe<Scalars['String']['output']>
  itemTwoTranscript?: Maybe<RichContent>
  itemTwoVideoURL?: Maybe<Scalars['String']['output']>
  query: Query
  textAlign?: Maybe<Scalars['String']['output']>
  textBlock?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  useTitleHeadingLevel2?: Maybe<Scalars['Boolean']['output']>
}

export type GlobalVideoHeroBannerWidget = Widget & {
  __typename?: 'GlobalVideoHeroBannerWidget'
  VideoPreviewImageTablet?: Maybe<Scalars['URL']['output']>
  autoplayVideo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  muteVideo?: Maybe<Scalars['String']['output']>
  query: Query
  transcript?: Maybe<RichContent>
  videoAssetPathMp4?: Maybe<Scalars['String']['output']>
  videoAssetPathWebm?: Maybe<Scalars['String']['output']>
  videoCaptionPathVTT?: Maybe<Scalars['URL']['output']>
  videoHeroContentAlign?: Maybe<Scalars['String']['output']>
  videoHeroContentBoxPosition?: Maybe<Scalars['String']['output']>
  videoHeroContentTheme?: Maybe<Scalars['String']['output']>
  videoHeroCtaOne?: Maybe<Scalars['String']['output']>
  videoHeroCtaOneAriaLabel?: Maybe<Scalars['String']['output']>
  videoHeroCtaOneURL?: Maybe<Scalars['String']['output']>
  videoHeroCtaTwo?: Maybe<Scalars['String']['output']>
  videoHeroCtaTwoAriaLabel?: Maybe<Scalars['String']['output']>
  videoHeroCtaTwoURL?: Maybe<Scalars['String']['output']>
  videoHeroHeadline?: Maybe<Scalars['String']['output']>
  videoHeroSubtitle?: Maybe<Scalars['String']['output']>
  videoHeroUseH1?: Maybe<Scalars['String']['output']>
  videoPreviewImage?: Maybe<Scalars['URL']['output']>
  videoPreviewImageMobile?: Maybe<Scalars['URL']['output']>
  videoTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalVideoTextImageCta = Widget & {
  __typename?: 'GlobalVideoTextImageCTA'
  id: Scalars['ID']['output']
  itemCTA?: Maybe<Scalars['String']['output']>
  itemCTALink?: Maybe<Scalars['String']['output']>
  itemDescription?: Maybe<Scalars['String']['output']>
  itemImage?: Maybe<Scalars['URL']['output']>
  itemImageLink?: Maybe<Scalars['String']['output']>
  query: Query
  videoUrl?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalWaitListSignUpFormResponses = Widget & {
  __typename?: 'GlobalWaitListSignUpFormResponses'
  continueButtonText?: Maybe<Scalars['String']['output']>
  errorButtonText?: Maybe<Scalars['String']['output']>
  formResponse?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  registerButtonText?: Maybe<Scalars['String']['output']>
  responseStartSubText?: Maybe<Scalars['String']['output']>
  responseSubText?: Maybe<RichContent>
  responseTitle?: Maybe<Scalars['String']['output']>
}

export type GlobalWaitListSignUpWidget = Widget & {
  __typename?: 'GlobalWaitListSignUpWidget'
  buttonText?: Maybe<Scalars['String']['output']>
  campaignCode?: Maybe<Scalars['ID']['output']>
  enableNameField?: Maybe<Scalars['String']['output']>
  enableSignUp?: Maybe<Scalars['String']['output']>
  errorReturnUrl?: Maybe<Scalars['String']['output']>
  fullNamePlaceholderText?: Maybe<Scalars['String']['output']>
  gdprDisclaimerText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  noAccountReturnUrl?: Maybe<Scalars['String']['output']>
  placeholderText?: Maybe<Scalars['String']['output']>
  query: Query
  registeredReturnUrl?: Maybe<Scalars['String']['output']>
  signUpText?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalWidgetSirius = Widget & {
  __typename?: 'GlobalWidgetSirius'
  altText?: Maybe<Scalars['String']['output']>
  button?: Maybe<Scalars['String']['output']>
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  imagePosition?: Maybe<Scalars['String']['output']>
  link?: Maybe<Scalars['String']['output']>
  query: Query
  subTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  widgetSiriusAltTextVariant?: Maybe<Scalars['String']['output']>
  widgetSiriusButtonVariant?: Maybe<Scalars['String']['output']>
  widgetSiriusHighlightVariant?: Maybe<Scalars['String']['output']>
  widgetSiriusImageVariant?: Maybe<Scalars['URL']['output']>
  widgetSiriusLinkVariant?: Maybe<Scalars['String']['output']>
  widgetSiriusSubTitleVariant?: Maybe<Scalars['String']['output']>
  widgetSiriusTitleVariant?: Maybe<Scalars['String']['output']>
}

export type GlobalWidgetVega = Widget & {
  __typename?: 'GlobalWidgetVega'
  altTextImg?: Maybe<Scalars['String']['output']>
  button?: Maybe<Scalars['String']['output']>
  container?: Maybe<Scalars['String']['output']>
  containerBg?: Maybe<Scalars['String']['output']>
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageDesktop?: Maybe<Scalars['URL']['output']>
  imageMobile?: Maybe<Scalars['URL']['output']>
  link?: Maybe<Scalars['String']['output']>
  query: Query
  subTitle?: Maybe<Scalars['String']['output']>
  textAlign?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GlobalYoutubeGalleryItem = Widget & {
  __typename?: 'GlobalYoutubeGalleryItem'
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
  videoId?: Maybe<Scalars['String']['output']>
}

export type GlobalYoutubeGallerySet = Widget & {
  __typename?: 'GlobalYoutubeGallerySet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type GuestCheckoutEmailInput = {
  email: Scalars['String']['input']
  marketingConsent: MarketingConsentPreference
  marketingConsentAuditData: MarketingConsentAuditData
}

export type GuestCheckoutStartInput = {
  checkoutStartInput: CheckoutStartInput
  guestCheckoutEmailInput: GuestCheckoutEmailInput
}

export type Header = {
  __typename?: 'Header'
  navigation?: Maybe<Navigation>
  widgets?: Maybe<Array<Widget>>
}

export type HmacAppliedPlacement = {
  __typename?: 'HmacAppliedPlacement'
  onClickBeacon?: Maybe<Scalars['String']['output']>
  onLoadBeacon?: Maybe<Scalars['String']['output']>
  onViewBeacon?: Maybe<Scalars['String']['output']>
  placementFormatToProducts: Array<PlacementFormatElement>
  rendering: Array<RenderingElement>
}

export type HmacAppliedPlacementPlacementFormatToProductsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type HmacAppliedProduct = {
  __typename?: 'HmacAppliedProduct'
  onBasketChangeBeacon?: Maybe<Scalars['String']['output']>
  onClickBeacon?: Maybe<Scalars['String']['output']>
  onLoadBeacon?: Maybe<Scalars['String']['output']>
  onViewBeacon?: Maybe<Scalars['String']['output']>
  onWishlistBeacon?: Maybe<Scalars['String']['output']>
  /** @deprecated Use the field with type 'WidgetProductItem' to retrieve either 'Product' or 'ProductVariant' */
  product?: Maybe<Product>
  widgetProductItem?: Maybe<WidgetProductItem>
}

export type Hyperlink = {
  __typename?: 'Hyperlink'
  noFollow?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated No longer supported */
  noIndex?: Maybe<Scalars['Boolean']['output']>
  openExternally?: Maybe<Scalars['Boolean']['output']>
  text: Scalars['DisplayString']['output']
  url: Scalars['URL']['output']
}

export type Image = {
  __typename?: 'Image'
  alt?: Maybe<Scalars['DisplayString']['output']>
  url: Scalars['URL']['output']
}

export type InstantSearchCorrection = {
  __typename?: 'InstantSearchCorrection'
  correction: Scalars['DisplayString']['output']
  highlightedSearchCorrection: Scalars['DisplayString']['output']
}

export type InstantSearchResult = {
  __typename?: 'InstantSearchResult'
  corrections: Array<InstantSearchCorrection>
  products: Array<Product>
  suggestedSearchQueries: Array<Scalars['DisplayString']['output']>
}

export enum InteractionAwardType {
  AccountCreation = 'ACCOUNT_CREATION',
  ProfileCompletion = 'PROFILE_COMPLETION',
  Review = 'REVIEW',
}

export type LabelWithDetails = {
  __typename?: 'LabelWithDetails'
  deliveryInstructions: DeliveryInstructions
  label: FileDownload
}

export enum LinkAccountsError {
  InvalidExtensionToken = 'INVALID_EXTENSION_TOKEN',
  OriginAlreadyLinked = 'ORIGIN_ALREADY_LINKED',
  TargetAlreadyLinked = 'TARGET_ALREADY_LINKED',
}

export type LinkAccountsInput = {
  accountExtensionToken?: InputMaybe<
    Scalars['AccountLinkingExtensionToken']['input']
  >
}

/** This response will contain either the error field or the email field */
export type LinkAccountsResponse = {
  __typename?: 'LinkAccountsResponse'
  email?: Maybe<Scalars['String']['output']>
  error?: Maybe<LinkAccountsError>
}

export type LinkedCreditAccount = {
  __typename?: 'LinkedCreditAccount'
  /** Always in the currency of the credit account. */
  balance: MoneyValue
  email: Scalars['String']['output']
  /** The site code, e.g. "lfint", of the linked account. */
  site: Scalars['String']['output']
}

export type LiveChatWidget = Widget & {
  __typename?: 'LiveChatWidget'
  id: Scalars['ID']['output']
  query: Query
}

export type LocalizedString = {
  __typename?: 'LocalizedString'
  key: Scalars['String']['output']
  location: LocalizedStringLocation
  value: Scalars['DisplayString']['output']
}

export enum LocalizedStringLocation {
  Global = 'GLOBAL',
  Language = 'LANGUAGE',
  Locale = 'LOCALE',
  Site = 'SITE',
  SiteAndLanguage = 'SITE_AND_LANGUAGE',
  SiteAndLocale = 'SITE_AND_LOCALE',
}

export type LoginAndApproveSocialLinkInput = {
  verificationToken: Scalars['String']['input']
}

export type LoginInput = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type LoyaltyAccountDashboard = {
  __typename?: 'LoyaltyAccountDashboard'
  pointsBreakdown?: Maybe<LoyaltyPointsBreakdown>
  redemptionRates?: Maybe<Array<LoyaltyRedemptionRate>>
  summary: LoyaltyCustomerSummary
  tiers?: Maybe<Array<LoyaltyTierEntry>>
}

export type LoyaltyAccountDashboardRedemptionRatesArgs = {
  currency: Currency
}

export type LoyaltyCustomerSummary = {
  __typename?: 'LoyaltyCustomerSummary'
  nonSettledPoints: Scalars['Int']['output']
  optIn: Scalars['Boolean']['output']
  pointsToNextTier: Scalars['Int']['output']
  spendablePoints: Scalars['Int']['output']
  tierOrder: Scalars['Int']['output']
  totalPoints: Scalars['Int']['output']
}

export type LoyaltyHistoryEntry = {
  __typename?: 'LoyaltyHistoryEntry'
  earnDate: Scalars['Timestamp']['output']
  earnedPoints: Scalars['Int']['output']
  interactionType: LoyaltyHistoryInteractionType
  miscSubtype?: Maybe<LoyaltyMiscInteractionType>
  orderNumber?: Maybe<Scalars['String']['output']>
}

export enum LoyaltyHistoryInteractionType {
  Misc = 'MISC',
  Order = 'ORDER',
}

export type LoyaltyHubBirthdayGift = Widget & {
  __typename?: 'LoyaltyHubBirthdayGift'
  banner?: Maybe<Scalars['URL']['output']>
  customerLists?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  formInstruction?: Maybe<Scalars['String']['output']>
  giftActive?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  modalHeading?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
  userNotification?: Maybe<Scalars['String']['output']>
}

export type LoyaltyHubTier = Widget & {
  __typename?: 'LoyaltyHubTier'
  banner?: Maybe<Scalars['URL']['output']>
  bannerAlt?: Maybe<Scalars['String']['output']>
  hidden?: Maybe<Scalars['String']['output']>
  iconType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  rewardListText1?: Maybe<Scalars['String']['output']>
  rewardListText2?: Maybe<Scalars['String']['output']>
  rewardListText3?: Maybe<Scalars['String']['output']>
  rewardListText4?: Maybe<Scalars['String']['output']>
  rewardListText5?: Maybe<Scalars['String']['output']>
  rewardsHeader?: Maybe<Scalars['String']['output']>
  textPart1?: Maybe<Scalars['String']['output']>
  textPart2?: Maybe<Scalars['String']['output']>
  textPart3?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type LoyaltyInteractionAward = {
  __typename?: 'LoyaltyInteractionAward'
  earnablePoints: Scalars['Int']['output']
  type: InteractionAwardType
}

export enum LoyaltyMiscInteractionType {
  AccountCreation = 'ACCOUNT_CREATION',
  Other = 'OTHER',
  ProfileCompletion = 'PROFILE_COMPLETION',
  Review = 'REVIEW',
}

export type LoyaltyPendingPointsInfo = {
  __typename?: 'LoyaltyPendingPointsInfo'
  earnDate: Scalars['Timestamp']['output']
  pointsPending: Scalars['Int']['output']
  product: ProductVariant
  quantity: Scalars['Int']['output']
}

export type LoyaltyPointsBreakdown = {
  __typename?: 'LoyaltyPointsBreakdown'
  nonSettledPoints: Scalars['Int']['output']
  pointsHistory: Array<LoyaltyHistoryEntry>
  pointsPendingInfo: Array<LoyaltyPendingPointsInfo>
}

export type LoyaltyRedemptionRate = {
  __typename?: 'LoyaltyRedemptionRate'
  credit: MoneyValue
  pointsRequired: Scalars['Int']['output']
  redemptionRateId: Scalars['Int']['output']
}

export type LoyaltyRewardTier = Widget & {
  __typename?: 'LoyaltyRewardTier'
  id: Scalars['ID']['output']
  query: Query
  tierListItem1?: Maybe<Scalars['String']['output']>
  tierListItem2?: Maybe<Scalars['String']['output']>
  tierListItem3?: Maybe<Scalars['String']['output']>
  tierListItem4?: Maybe<Scalars['String']['output']>
  tierListItem5?: Maybe<Scalars['String']['output']>
  tierName?: Maybe<Scalars['String']['output']>
  tierRange?: Maybe<Scalars['String']['output']>
  tierType?: Maybe<Scalars['String']['output']>
}

export type LoyaltyRewardTiers = Widget & {
  __typename?: 'LoyaltyRewardTiers'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  subTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type LoyaltyScheme = {
  __typename?: 'LoyaltyScheme'
  interactionAwards: Array<LoyaltyInteractionAward>
  /** Loyalty schemes that are not tier based will have an empty list of tiers as opposed to a single tier */
  tiers: Array<LoyaltyTier>
}

export type LoyaltyTier = {
  __typename?: 'LoyaltyTier'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  rewards: Array<LoyaltyTierReward>
  threshold: Scalars['Int']['output']
}

export type LoyaltyTierEntry = {
  __typename?: 'LoyaltyTierEntry'
  /**  Conflicts with loyalty.graphql so appended 'Entry' */
  name: Scalars['DisplayString']['output']
  order: Scalars['Int']['output']
  threshold: Scalars['Int']['output']
}

export type LoyaltyTierReward = {
  __typename?: 'LoyaltyTierReward'
  description: Scalars['DisplayString']['output']
  name: Scalars['DisplayString']['output']
  type: LoyaltyTierRewardType
}

export enum LoyaltyTierRewardType {
  BirthdayGift = 'BIRTHDAY_GIFT',
  Generic = 'GENERIC',
  SubscriptionGift = 'SUBSCRIPTION_GIFT',
}

export type LoyaltyTransaction = {
  __typename?: 'LoyaltyTransaction'
  clears?: Maybe<Scalars['Timestamp']['output']>
  earned: Scalars['Timestamp']['output']
  pointsEarned: Scalars['Int']['output']
  productVariant?: Maybe<ProductVariant>
  quantity: Scalars['Int']['output']
}

export type MailingList = Widget & {
  __typename?: 'MailingList'
  buttonText?: Maybe<Scalars['String']['output']>
  campaignCode?: Maybe<Scalars['ID']['output']>
  dropdownAnswer1?: Maybe<Scalars['String']['output']>
  dropdownAnswer2?: Maybe<Scalars['String']['output']>
  dropdownAnswer3?: Maybe<Scalars['String']['output']>
  dropdownAnswer4?: Maybe<Scalars['String']['output']>
  dropdownAnswer5?: Maybe<Scalars['String']['output']>
  dropdownAnswer6?: Maybe<Scalars['String']['output']>
  dropdownAnswer7?: Maybe<Scalars['String']['output']>
  dropdownAnswer8?: Maybe<Scalars['String']['output']>
  dropdownAnswer9?: Maybe<Scalars['String']['output']>
  dropdownAnswer10?: Maybe<Scalars['String']['output']>
  dropdownAnswerPlaceholder?: Maybe<Scalars['String']['output']>
  dropdownAnswerQuestionText?: Maybe<Scalars['String']['output']>
  emailLabelText?: Maybe<Scalars['String']['output']>
  emailPlaceholderText?: Maybe<Scalars['String']['output']>
  enableDropdownAnswerField?: Maybe<Scalars['String']['output']>
  enableNameField?: Maybe<Scalars['String']['output']>
  enableSignUp?: Maybe<Scalars['String']['output']>
  enablefreeAnswerField?: Maybe<Scalars['String']['output']>
  errorReturnUrl?: Maybe<Scalars['String']['output']>
  freeAnswerQuestionText?: Maybe<Scalars['String']['output']>
  fullNameLabelText?: Maybe<Scalars['String']['output']>
  fullNamePlaceholderText?: Maybe<Scalars['String']['output']>
  gdprDisclaimerText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  registeredReturnUrl?: Maybe<Scalars['String']['output']>
  signUpText?: Maybe<Scalars['String']['output']>
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type MarkDiscussionMessagesAsReadInput = {
  discussionId: Scalars['ID']['input']
  upToMessageId: Scalars['ID']['input']
}

export enum MarketingConsent {
  DoesNotReceiveMarketingMaterial = 'DOES_NOT_RECEIVE_MARKETING_MATERIAL',
  ReceivesMarketingMaterial = 'RECEIVES_MARKETING_MATERIAL',
}

export type MarketingConsentAuditData = {
  /** An identifier of the form being submitted */
  formIdentifier: Scalars['String']['input']
  /** A string that identifies where on site the opt-in was displayed, such as a URL */
  formLocation: Scalars['String']['input']
  /** The message shown to the user for marketing consent, either as plaintext, or HTML. */
  messageShown: Scalars['String']['input']
}

export enum MarketingConsentPreference {
  IConsentToReceivingMarketingMaterial = 'I_CONSENT_TO_RECEIVING_MARKETING_MATERIAL',
  IDoNotConsentToReceivingMarketingMaterial = 'I_DO_NOT_CONSENT_TO_RECEIVING_MARKETING_MATERIAL',
  KeepExistingMarketingPreferences = 'KEEP_EXISTING_MARKETING_PREFERENCES',
}

export type MarketingPreferences = {
  __typename?: 'MarketingPreferences'
  marketingConsent: MarketingConsent
  registered: Scalars['Boolean']['output']
  type: MarketingType
}

export type MarketingPreferencesInput = {
  type: MarketingType
  username: Scalars['String']['input']
}

export enum MarketingType {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum MessageSort {
  Default = 'DEFAULT',
  LatestToOldest = 'LATEST_TO_OLDEST',
  OldestToLatest = 'OLDEST_TO_LATEST',
}

export type MoneyValue = {
  __typename?: 'MoneyValue'
  /** A string containing a decimal number, to avoid rounding and precision issues */
  amount: Scalars['String']['output']
  currency: Currency
  /** A fully formatted string for displaying this amount of money in HTML. e.g "&#163;76.49" */
  displayValue: Scalars['String']['output']
  scalarValue: Scalars['Money']['output']
}

export type MultipleCtaBanner = Widget & {
  __typename?: 'MultipleCTABanner'
  altImageLarge?: Maybe<Scalars['String']['output']>
  altLogoPng?: Maybe<Scalars['String']['output']>
  bannerURL?: Maybe<Scalars['String']['output']>
  contentBoxPosition?: Maybe<Scalars['String']['output']>
  contentTheme?: Maybe<Scalars['String']['output']>
  ctaOne?: Maybe<Scalars['String']['output']>
  ctaOneURL?: Maybe<Scalars['String']['output']>
  ctaTwo?: Maybe<Scalars['String']['output']>
  ctaTwoURL?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  logopngImageBG?: Maybe<Scalars['URL']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * Adds a new address based on the given input.
   * Requires Authentication.
   */
  addAddress: Scalars['ID']['output']
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   * @deprecated Use addProductsToBasket instead
   */
  addProductToBasket: Basket
  addProductToBasketWithSubscriptionContract: Basket
  addPersonalisedProductToBasket: Basket
  addProductToWishlist?: Maybe<Scalars['Boolean']['output']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  addProductsToBasket: Basket
  addReview?: Maybe<AddReviewResponse>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  addSelectYourSampleProductToBasket: Basket
  addSubscriptionProducts?: Maybe<Scalars['Boolean']['output']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  applyCodeToBasket: Basket
  approveSocialLink?: Maybe<Scalars['Boolean']['output']>
  /** Cancels an entire order, if it is cancellable. */
  cancelOrder?: Maybe<Scalars['Void']['output']>
  /** Partially cancels an order. Cannot be used to cancel OrderProducts which are in a special offer group. */
  cancelOrderProducts?: Maybe<Scalars['Void']['output']>
  /** Partially cancels an order, cancelling entire special offer groups. */
  cancelOrderSpecialOfferGroups?: Maybe<Scalars['Void']['output']>
  cancelReturn?: Maybe<Scalars['Void']['output']>
  cancelSubscription?: Maybe<Scalars['Void']['output']>
  cancelSubscriptionDelay?: Maybe<Scalars['Void']['output']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   * Provide the item id of the product to be switched to subscribe and save and it will be changed in the basket.
   * The toSubscription input field is used to specify the direction in which to switch. It should
   * be true when switching a normal product to subscribe and save and false in the opposite case. When changing the contract of an existing 'subscribe and save' product it should be passed as true.
   * If the purpose of the call is to change from a subscribe and save product to a normal product, this mutation can be called without the contractId input.
   */
  changeProductSubscriptionContractInBasket: Basket
  check?: Maybe<Scalars['String']['output']>
  checkout?: Maybe<CheckoutStartResponse>
  /**
   * Requires authentication
   * Returns the id of the newly created messaged
   * New discussions
   */
  createDiscussion: Scalars['ID']['output']
  delaySubscription?: Maybe<Scalars['Void']['output']>
  /**
   * Deletes the address with the given ID if it exists.
   * Requires Authentication.
   */
  deleteAddress?: Maybe<Scalars['Void']['output']>
  deletePaymentCard?: Maybe<Scalars['Void']['output']>
  deleteProfileSubmission?: Maybe<Scalars['Void']['output']>
  /** If a null basketId is provided, this will fall back to the logged in customer's saved basket. */
  emptyBasket: Basket
  /**
   * Causes a PasswordResetToken to be sent to the user's email address, if an account exists with the given username.
   *
   * The PasswordResetToken from the email can be used with the resetPassword mutation.
   */
  forgottenPassword?: Maybe<ForgottenPasswordResponse>
  /**
   * The second stage of the account linking process.
   *
   * This should be called on the target site while logged in, with the AccountLinkingToken returned from
   * `getAccountLinkingToken`, which was called on the origin site.
   */
  getAccountExtensionToken?: Maybe<GetAccountExtensionTokenResponse>
  /**
   * The first stage of the account linking process.
   *
   * This should be called on the origin site while logged in.
   */
  getAccountLinkingToken?: Maybe<GetAccountLinkingTokenResponse>
  guestCheckout?: Maybe<CheckoutStartResponse>
  guestCheckoutWithoutEmail?: Maybe<CheckoutStartResponse>
  /**
   * The third stage of the account linking process.
   *
   * This should be called on the origin site while logged in, with the AccountLinkingExtensionToken returned from
   * `getAccountExtensionToken`, which was called on the target site.
   *
   * This will complete the account linking.
   */
  linkAccounts?: Maybe<LinkAccountsResponse>
  login?: Maybe<AuthenticationResponse>
  loginAndApproveSocialLink?: Maybe<AuthenticationResponse>
  logout?: Maybe<Scalars['Void']['output']>
  /**
   * Requires authentication
   * If there are newer messages than the provided message ID, the discussion stays unread, preventing an unseen
   * message from being unknowingly marked as read
   */
  markDiscussionMessagesAsRead?: Maybe<Scalars['Void']['output']>
  redeemPoints: RedemptionRateSubmissionStatus
  /**
   * Registration forms can be dynamic. To get the exact fields and options required to register for a site, query the forms API with
   * the identifier ACCOUNT_CREATION. If this doesn't exist, the fields are static and visible in the RegistrationInput type.
   */
  register: AuthenticationResponse
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  removeCodeFromBasket: Basket
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  removeProductFromBasket: Basket
  removeProductFromWishlist?: Maybe<Scalars['Boolean']['output']>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  removeSelectYourSampleProductFromBasket: Basket
  removeSocialLink?: Maybe<Scalars['Boolean']['output']>
  removeSubscriptionProducts?: Maybe<Scalars['Boolean']['output']>
  /**
   * Deletes the address with the given ID if it exists.
   * And adds a new address based on the given input and returns the ID of the new address.
   * This does not update the address with the given ID.
   * Requires Authentication.
   */
  replaceAddress: Scalars['ID']['output']
  /**
   * Requires authentication
   * Returns the id of the newly created message
   */
  replyToDiscussion: Scalars['ID']['output']
  reportReview?: Maybe<ReviewActionResult>
  /**
   * Causes a PasswordResetToken to be sent to the user's email address.  The email's wording is different, as this is
   * intended to be used for accounts which do not already have a password (such as Wechat social accounts)
   *
   * The PasswordResetToken from the email can be used with the resetPassword mutation.
   */
  requestSetPasswordEmail?: Maybe<RequestSetPasswordEmailResponse>
  /** The following endpoints handle the approving of a link between a Social User and a Customer on this site. */
  requestSocialLinkVerificationEmail?: Maybe<
    RequestSocialLinkVerificationEmailResponse
  >
  resetPassword?: Maybe<AuthenticationResponse>
  resolveOrderPaymentProblem?: Maybe<CheckoutStartResponse>
  resolveSubscriptionPaymentProblem?: Maybe<CheckoutStartResponse>
  sendReferralEmail?: Maybe<Scalars['Void']['output']>
  /**  Conflicts with loyalty.graphql so used 'set' instead of 'update' */
  setLoyaltyOptIn: AccountUpdateStatus
  signUpForEmailMarketingCampaign?: Maybe<Scalars['Void']['output']>
  /** Sign up for email or SMS marketing without the requirement to be logged in */
  signUpForMarketing?: Maybe<SignUpResult>
  signUpForPushNotifications?: Maybe<PushSubscriptionResponse>
  /**
   * Attempts to log you into the website using the SocialAuthenticationToken returned from Social Login Service
   * or the token returned from a previous attempt to log in.
   *
   * socialAuthenticationToken and missingInformation should be considered deprecated andwill be removed in due course.
   * Please only submit the SocialLoginInput object
   */
  socialLogin?: Maybe<SocialAuthenticationResponse>
  submitProfile?: Maybe<FormSubmissionResponse>
  submitSurvey?: Maybe<FormSubmissionResponse>
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   * Provide the item id of the product to be supersized (must be supersizable) and it will be added to the basket and the current product removed.
   */
  supersizeProductInBasket: Basket
  swapSubscriptionProducts?: Maybe<Scalars['Boolean']['output']>
  unlinkAccounts?: Maybe<Scalars['Boolean']['output']>
  unsubscribeMarketing?: Maybe<Scalars['Void']['output']>
  unsubscribeSmsMarketing?: Maybe<Scalars['Boolean']['output']>
  /** See the Form from the `accountSettingsForm` query */
  updateAccountSettings: AccountUpdateStatus
  /** @deprecated Use updateAccountSettings instead */
  updateDateOfBirth: AccountUpdateStatus
  updateEmailAddress: AccountUpdateStatus
  updateLoyaltyOptIn: AccountUpdateStatus
  updateMarketingPreferences?: Maybe<UpdateMarketingPreferencesResponse>
  /** @deprecated Use updateAccountSettings instead */
  updateMobilePhoneNumber: AccountUpdateStatus
  /** @deprecated Use updateAccountSettings instead */
  updateName: AccountUpdateStatus
  updatePassword: PasswordUpdateStatus
  /**
   * If a null basketId is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  updateProductQuantityInBasket: Basket
  updateSubscriptionAddress?: Maybe<Scalars['Void']['output']>
  updateSubscriptionAutoRenewalOptInType?: Maybe<Scalars['Boolean']['output']>
  updateSubscriptionBillingDate?: Maybe<Scalars['Boolean']['output']>
  updateSubscriptionFrequency?: Maybe<Scalars['Void']['output']>
  updateSubscriptionPaymentMethod?: Maybe<CheckoutStartResponse>
  voteReviewNegative?: Maybe<ReviewActionResult>
  voteReviewPositive?: Maybe<ReviewActionResult>
}

export type MutationAddAddressArgs = {
  input: AddressInput
}

export type MutationAddProductToBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  quantity: Scalars['Int']['input']
  settings: SessionSettings
  sku: Scalars['SKU']['input']
}

export type MutationAddProductToBasketWithSubscriptionContractArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  contractId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
  settings: SessionSettings
  sku: Scalars['SKU']['input']
}

export type MutationAddPersonalisedProductToBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  personalisationValues: ProductPersonalisationSubmission
  quantity: Scalars['Int']['input']
  settings: SessionSettings
  sku: Scalars['SKU']['input']
}

export type ProductPersonalisationSubmission = {
  fieldSubmissionList: Array<ProductPersonalisationFieldSubmission>
}

export type MultiSelectionSubmissions = {
  value: Scalars['String']['input']
  quantity: Scalars['Int']['input']
}

export type ProductPersonalisationFieldSubmission = {
  name: Scalars['String']['input']
  value: Scalars['String']['input']
  multiSelectionSubmissions?: Array<MultiSelectionSubmissions>
}

export type MutationAddProductToWishlistArgs = {
  sku: Scalars['SKU']['input']
}

export type MutationAddProductsToBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  items: Array<AddProductToBasketItem>
  settings: SessionSettings
}

export type MutationAddReviewArgs = {
  input: AddReviewInput
}

export type MutationAddSelectYourSampleProductToBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  selectYourSampleId: Scalars['ID']['input']
  settings: SessionSettings
  sku?: InputMaybe<Scalars['SKU']['input']>
  tierId: Scalars['ID']['input']
}

export type MutationAddSubscriptionProductsArgs = {
  addOns: Array<ProductAddOnInput>
  subscriptionId: Scalars['ID']['input']
}

export type MutationApplyCodeToBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  code: Scalars['String']['input']
  settings: SessionSettings
}

export type MutationApproveSocialLinkArgs = {
  input?: InputMaybe<ApproveSocialLinkInput>
}

export type MutationCancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationCancelOrderProductsArgs = {
  input: CancelOrderProductsInput
}

export type MutationCancelOrderSpecialOfferGroupsArgs = {
  input: CancelOrderSpecialOfferGroupsInput
}

export type MutationCancelReturnArgs = {
  returnNumber: Scalars['Int']['input']
}

export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID']['input']
}

export type MutationCancelSubscriptionDelayArgs = {
  id: Scalars['ID']['input']
}

export type MutationChangeProductSubscriptionContractInBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  contractId?: InputMaybe<Scalars['ID']['input']>
  settings: SessionSettings
  sku: Scalars['SKU']['input']
  toSubscription: Scalars['Boolean']['input']
}

export type MutationCheckoutArgs = {
  input: CheckoutStartInput
}

export type MutationCreateDiscussionArgs = {
  input: CreateDiscussionInput
}

export type MutationDelaySubscriptionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteAddressArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeletePaymentCardArgs = {
  cardId: Scalars['ID']['input']
}

export type MutationDeleteProfileSubmissionArgs = {
  submissionId: Scalars['ID']['input']
}

export type MutationEmptyBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  settings: SessionSettings
}

export type MutationForgottenPasswordArgs = {
  input: ForgottenPasswordInput
}

export type MutationGetAccountExtensionTokenArgs = {
  input: GetAccountExtensionTokenInput
}

export type MutationGuestCheckoutArgs = {
  input: GuestCheckoutStartInput
}

export type MutationGuestCheckoutWithoutEmailArgs = {
  input: CheckoutStartInput
}

export type MutationLinkAccountsArgs = {
  input: LinkAccountsInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationLoginAndApproveSocialLinkArgs = {
  input: LoginAndApproveSocialLinkInput
}

export type MutationMarkDiscussionMessagesAsReadArgs = {
  input?: InputMaybe<MarkDiscussionMessagesAsReadInput>
}

export type MutationRedeemPointsArgs = {
  redemptionId: Scalars['Int']['input']
}

export type MutationRegisterArgs = {
  input: RegistrationInput
}

export type MutationRemoveCodeFromBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  settings: SessionSettings
}

export type MutationRemoveProductFromBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  itemId: Scalars['ID']['input']
  settings: SessionSettings
}

export type MutationRemoveProductFromWishlistArgs = {
  sku: Scalars['SKU']['input']
}

export type MutationRemoveSelectYourSampleProductFromBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  selectYourSampleId: Scalars['ID']['input']
  settings: SessionSettings
  sku?: InputMaybe<Scalars['SKU']['input']>
  tierId: Scalars['ID']['input']
}

export type MutationRemoveSocialLinkArgs = {
  input?: InputMaybe<RemoveSocialLinkInput>
}

export type MutationRemoveSubscriptionProductsArgs = {
  removals: Array<Scalars['SKU']['input']>
  subscriptionId: Scalars['ID']['input']
}

export type MutationReplaceAddressArgs = {
  input: ReplaceAddressInput
}

export type MutationReplyToDiscussionArgs = {
  discussionId: Scalars['ID']['input']
  input: AddDiscussionMessageInput
}

export type MutationReportReviewArgs = {
  input: ReviewVotingInput
}

export type MutationRequestSetPasswordEmailArgs = {
  input: RequestSetPasswordEmailInput
}

export type MutationRequestSocialLinkVerificationEmailArgs = {
  input: RequestSocialLinkVerificationEmailInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationResolveOrderPaymentProblemArgs = {
  orderNumber: Scalars['OrderNumber']['input']
}

export type MutationResolveSubscriptionPaymentProblemArgs = {
  subscriptionId: Scalars['ID']['input']
}

export type MutationSendReferralEmailArgs = {
  emailAddresses: Array<Scalars['String']['input']>
}

export type MutationSetLoyaltyOptInArgs = {
  newValue: Scalars['Boolean']['input']
}

export type MutationSignUpForEmailMarketingCampaignArgs = {
  input: SignUpForEmailMarketingCampaignInput
}

export type MutationSignUpForMarketingArgs = {
  input: SignUpForMarketingInput
}

export type MutationSignUpForPushNotificationsArgs = {
  input: PushSubscriptionInput
}

export type MutationSocialLoginArgs = {
  input?: InputMaybe<SocialLoginInput>
  missingInformation?: InputMaybe<SocialLoginMissingInformation>
  socialAuthenticationToken?: InputMaybe<
    Scalars['SocialAuthenticationToken']['input']
  >
}

export type MutationSubmitProfileArgs = {
  input: FormSubmissionInput
}

export type MutationSubmitSurveyArgs = {
  input: FormSubmissionInput
}

export type MutationSupersizeProductInBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  itemId: Scalars['ID']['input']
  settings: SessionSettings
}

export type MutationSwapSubscriptionProductsArgs = {
  subscriptionId: Scalars['ID']['input']
  swaps: Array<ProductSwapInput>
}

export type MutationUnsubscribeMarketingArgs = {
  input: TokenMarketingInput
}

export type MutationUnsubscribeSmsMarketingArgs = {
  phoneNumber: Scalars['String']['input']
}

export type MutationUpdateAccountSettingsArgs = {
  input: Array<SettingsFieldInput>
}

export type MutationUpdateDateOfBirthArgs = {
  changes: UpdateDateOfBirthInput
}

export type MutationUpdateEmailAddressArgs = {
  changes: UpdateEmailAddressInput
}

export type MutationUpdateLoyaltyOptInArgs = {
  newValue: Scalars['Boolean']['input']
}

export type MutationUpdateMarketingPreferencesArgs = {
  input: UpdateMarketingPreferencesInput
}

export type MutationUpdateMobilePhoneNumberArgs = {
  changes: UpdateMobilePhoneNumberInput
}

export type MutationUpdateNameArgs = {
  changes: UpdateNameInput
}

export type MutationUpdatePasswordArgs = {
  changes: UpdatePasswordInput
}

export type MutationUpdateProductQuantityInBasketArgs = {
  basketId?: InputMaybe<Scalars['ID']['input']>
  itemId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
  settings: SessionSettings
}

export type MutationUpdateSubscriptionAddressArgs = {
  addressId: Scalars['ID']['input']
  subscriptionId: Scalars['ID']['input']
}

export type MutationUpdateSubscriptionAutoRenewalOptInTypeArgs = {
  optInTypeInput: SubscriptionAutoRenewalOptInTypeInput
  subscriptionId: Scalars['ID']['input']
}

export type MutationUpdateSubscriptionBillingDateArgs = {
  newBillingDate: Scalars['Date']['input']
  subscriptionId: Scalars['ID']['input']
}

export type MutationUpdateSubscriptionFrequencyArgs = {
  newFrequency: SubscriptionFrequencyDurationInput
  subscriptionId: Scalars['ID']['input']
}

export type MutationUpdateSubscriptionPaymentMethodArgs = {
  subscriptionId: Scalars['ID']['input']
}

export type MutationVoteReviewNegativeArgs = {
  input: ReviewVotingInput
}

export type MutationVoteReviewPositiveArgs = {
  input: ReviewVotingInput
}

export type Navigation = {
  __typename?: 'Navigation'
  topLevel: Array<NavigationItem>
}

export type NavigationItem = {
  __typename?: 'NavigationItem'
  displayName: Scalars['DisplayString']['output']
  iconName?: Maybe<Scalars['String']['output']>
  image?: Maybe<Image>
  link?: Maybe<Hyperlink>
  subNavigation?: Maybe<Array<NavigationItem>>
  /** Null if only a heading */
  type: NavigationItemType
}

export enum NavigationItemType {
  /**
   * This navigation item can only appear at the top level.  This navigation item can have no
   * children.  When hovered over in the top-level nav,  this item should cause a new menu to
   * appear below it.  This menu contains a brand selector.  This contains a list of brands in
   * alphabetical order, each acting as a link to the brand's landing page.  At the top of the menu
   * should be buttons for each letter of the alphabet, with the menu only showing brands that begin
   * with the selected letter.
   *
   * The brands can be accessed via the `brands` query.
   *
   * For an example, see the documentation
   */
  Brands = 'BRANDS',
  /**
   * This navigation item can only appear at the second level, and only on the alternative navigation
   * display.  The detached navigation type is used where a separate section of the navigation tree
   * breaks off from the core tree at the second level, appearing to the right of its sibling items,
   * creating its own navigation tree.
   *
   * For an example, see the documentation
   */
  Detached = 'DETACHED',
  /**
   * This navigation item can only appear at the second level.  This type is used where a separate
   * section of the navigation tree breaks off from an existing TEXT tree.  This navigation item
   * appears to the left of all of the other sibling items.  This item is rendered as a list of its
   * children, with no header.
   *
   * For an example, see the documentation
   */
  Featured = 'FEATURED',
  /**
   * This navigation item can only appear at the top level.  The children of this navigation item
   * should each be displayed as a box with a large central image, with bold text below it.
   *
   * For an example, see the documentation
   */
  ImageCard = 'IMAGE_CARD',
  /**
   * Depending on platform and level, render as either a simple text link, a list of links containing
   * its children, a top-level button which causes its children to appear below when hovered over, or
   * a button which causes the children to open over the current nav as a new panel when tapped.
   *
   * For examples of each of these states, see the documentation.
   */
  Text = 'TEXT',
  /**
   * This navigation item can only appear at the top level.  The children of this navigation item
   * should each be displayed as a box with bold text in it.  On mobile apps, this should render the
   * same as TEXT.
   *
   * For an example, see the documentation.
   */
  TextCard = 'TEXT_CARD',
  /**
   * This navigation item can only appear at the top level.  The children of this navigation item
   * should each be displayed as a box with bold text in it, with an image to the left, if one is
   * provided.  If no image is provided, this should render the same as TEXT_CARD.
   *
   * For an example, see the documentation.
   */
  ThumbnailCard = 'THUMBNAIL_CARD',
  /**
   * This navigation item signifies that at least one of the children of this item will be of type DETACHED.
   * This allows the client to render a different view without needing to traverse all children of a top
   * level item to check for a child of type DETACHED to know whether to render a special view that
   * supports the detached sections.
   *
   * For an example, see the documentation
   */
  Waterfall = 'WATERFALL',
}

export type OptInForm = {
  __typename?: 'OptInForm'
  showForm: Scalars['Boolean']['output']
}

/** A single choice for a single option, such as "Chocolate" for the "Flavour" option. */
export type OptionChoice = {
  __typename?: 'OptionChoice'
  colour?: Maybe<Scalars['Colour']['output']>
  key: Scalars['String']['output']
  optionKey: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type Order = {
  __typename?: 'Order'
  cancellable: Scalars['Boolean']['output']
  /** This is when the order is created and the payment taken */
  createdAt: Scalars['Timestamp']['output']
  deliveryAddress?: Maybe<Address>
  deliveryCost?: Maybe<MoneyValue>
  denialOfReceiptForm: Scalars['URL']['output']
  discounts?: Maybe<Array<OrderDiscount>>
  /**
   * Returns a paginated list of discussions associated with this order.
   *
   * Equivalent to querying Customer.discussions with an order number filter
   */
  discussions?: Maybe<Discussions>
  /** If the order has been dispatched, this is the dispatched time */
  dispatchedAt?: Maybe<Scalars['Timestamp']['output']>
  eligibleForSelfServiceDenialOfReceipt?: Maybe<Scalars['Boolean']['output']>
  /** If no input provided, all skus in the product will be used */
  isReturnable?: Maybe<ReturnsEligibilityResult>
  orderNumber: Scalars['OrderNumber']['output']
  /** @deprecated Use usedPaymentMethods -> paymentCard instead. */
  paymentCard?: Maybe<PaymentCard>
  /** @deprecated Use usedPaymentMethods -> paymentType instead. */
  paymentType?: Maybe<Scalars['String']['output']>
  products: Array<OrderProduct>
  /** If no input provided, all skus in the product will be used */
  returnUrl?: Maybe<Scalars['URL']['output']>
  status: OrderStatus
  totalCost: MoneyValue
  totalQuantity: Scalars['Int']['output']
  usedPaymentMethods: Array<UsedPaymentMethod>
}

export type OrderDiscussionsArgs = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type OrderIsReturnableArgs = {
  input?: InputMaybe<ReturnsEligibilityInput>
}

export type OrderReturnUrlArgs = {
  input?: InputMaybe<ReturnUrlInput>
}

export enum OrderCancellationReason {
  AmendmentsNeeded = 'AMENDMENTS_NEEDED',
  DiscountCodeIssue = 'DISCOUNT_CODE_ISSUE',
  DuplicateOrder = 'DUPLICATE_ORDER',
  FoundCheaperElsewhere = 'FOUND_CHEAPER_ELSEWHERE',
  NoLongerRequired = 'NO_LONGER_REQUIRED',
  OrderedWrongItem = 'ORDERED_WRONG_ITEM',
  WaitedTooLong = 'WAITED_TOO_LONG',
}

export type OrderDiscount = {
  __typename?: 'OrderDiscount'
  amount: MoneyValue
  message?: Maybe<Scalars['DisplayString']['output']>
}

export type OrderProduct = {
  __typename?: 'OrderProduct'
  cancellable: Scalars['Boolean']['output']
  cancellableQuantity?: Maybe<Scalars['Int']['output']>
  cancelledQuantity?: Maybe<Scalars['Int']['output']>
  costPerUnit?: Maybe<MoneyValue>
  deliveryDateRange?: Maybe<DateRange>
  deliveryMethod?: Maybe<Scalars['DeliveryMethod']['output']>
  /** For order products which are not yet dispatched, this is the estimated date */
  dispatchDate?: Maybe<Scalars['Date']['output']>
  dispatchedQuantity?: Maybe<Scalars['Int']['output']>
  /** The named-day delivery date as entered on checkout */
  namedDeliveryDate?: Maybe<Scalars['Date']['output']>
  pendingCancelQuantity?: Maybe<Scalars['Int']['output']>
  pendingRefundQuantity?: Maybe<Scalars['Int']['output']>
  pendingReplaceQuantity?: Maybe<Scalars['Int']['output']>
  productVariant?: Maybe<ProductVariant>
  /**
   * The total requested quantity of this product in the order.
   *
   * This is unaffected by the status of the individual items (such as cancellation or refunds)
   */
  quantity?: Maybe<Scalars['Int']['output']>
  refundedQuantity?: Maybe<Scalars['Int']['output']>
  replacementQuantity?: Maybe<Scalars['Int']['output']>
  /** If the product is no longer visible, sku will be non-null while product is null */
  sku: Scalars['SKU']['output']
  /**
   * Products that are in a special offer group can only be cancelled as part of that group, and should be displayed
   * together in the cancellation UI
   */
  specialOfferGroup?: Maybe<Scalars['SpecialOfferGroup']['output']>
  status?: Maybe<OrderStatus>
  trackingUrls?: Maybe<Array<Scalars['URL']['output']>>
}

export enum OrderQueryStatusFilter {
  /** The order has reached a terminal state, i.e. it has been dispatched or cancelled. */
  Completed = 'COMPLETED',
  /** The order has been dispatched (currently the final state). Can be used to show all successful orders that are completed. */
  Dispatched = 'DISPATCHED',
  /** The order has not reached a terminal state, i.e. processing, payment problem etc. */
  Outstanding = 'OUTSTANDING',
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Dispatched = 'DISPATCHED',
  OrderPlaced = 'ORDER_PLACED',
  PaymentProblem = 'PAYMENT_PROBLEM',
  Processing = 'PROCESSING',
}

export type Orders = {
  __typename?: 'Orders'
  hasMore: Scalars['Boolean']['output']
  orders: Array<Order>
  total?: Maybe<Scalars['Int']['output']>
}

export type OrdersFilterInput = {
  orderNumber?: InputMaybe<Scalars['OrderNumber']['input']>
  status?: InputMaybe<OrderQueryStatusFilter>
}

export enum OutcomeType {
  ExternalUrl = 'EXTERNAL_URL',
  InternalUrl = 'INTERNAL_URL',
}

export type Page = {
  __typename?: 'Page'
  alternateLinks: Array<PageAlternateLink>
  breadcrumbs: Array<Breadcrumb>
  canonicalUrl?: Maybe<Scalars['URL']['output']>
  flattenedChildWidgets?: Maybe<Array<Widget>>
  metaDescription?: Maybe<Scalars['String']['output']>
  metaSearchKeywords: Array<Scalars['String']['output']>
  noFollow: Scalars['Boolean']['output']
  noIndex: Scalars['Boolean']['output']
  path: Scalars['PagePath']['output']
  title: Scalars['DisplayString']['output']
  widget?: Maybe<Widget>
  widgets?: Maybe<Array<Widget>>
}

export type PageWidgetArgs = {
  id: Scalars['ID']['input']
}

export type PageAlternateLink = {
  __typename?: 'PageAlternateLink'
  locale: Scalars['Locale']['output']
  hreflang: Scalars['String']['output']
  pagePath: Scalars['PagePath']['output']
}

export enum PasswordResetTokenState {
  AlreadyUsed = 'ALREADY_USED',
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Valid = 'VALID',
}

export type PasswordUpdateStatus = {
  __typename?: 'PasswordUpdateStatus'
  error?: Maybe<AuthenticationError>
  fieldErrors?: Maybe<Array<Maybe<FormFieldValidationError>>>
}

export type PaymentCard = {
  __typename?: 'PaymentCard'
  issueNumber?: Maybe<Scalars['String']['output']>
  nameOnCard: Scalars['String']['output']
  obfuscatedCardNumber: Scalars['String']['output']
  type?: Maybe<PaymentCardType>
  validFromMonth?: Maybe<Scalars['Month']['output']>
  validFromYear?: Maybe<Scalars['Year']['output']>
  validToMonth: Scalars['Month']['output']
  validToYear: Scalars['Year']['output']
}

export type PaymentCardRecord = {
  __typename?: 'PaymentCardRecord'
  card: PaymentCard
  id: Scalars['ID']['output']
}

export enum PaymentCardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Solo = 'SOLO',
  Visa = 'VISA',
}

export type PaymentCards = {
  __typename?: 'PaymentCards'
  cards: Array<PaymentCardRecord>
  hasMore: Scalars['Boolean']['output']
  total: Scalars['Int']['output']
}

export type PaymentCardsFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum PaymentMethod {
  AdyenV2 = 'ADYEN_V2',
  AfterPay = 'AFTER_PAY',
  AliPay = 'ALI_PAY',
  AliPayPlus = 'ALI_PAY_PLUS',
  Amazonpay = 'AMAZONPAY',
  AmericanExpress = 'AMERICAN_EXPRESS',
  ApplePay = 'APPLE_PAY',
  Arvato = 'ARVATO',
  Atome = 'ATOME',
  Bancontact = 'BANCONTACT',
  ClearPay = 'CLEAR_PAY',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Elo = 'ELO',
  FrasersPlus = 'FRASERS_PLUS',
  GiftCard = 'GIFT_CARD',
  GiroPay = 'GIRO_PAY',
  GooglePay = 'GOOGLE_PAY',
  Hiper = 'HIPER',
  Humm = 'HUMM',
  Ideal = 'IDEAL',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Kcp = 'KCP',
  Klarna = 'KLARNA',
  Laybuy = 'LAYBUY',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Mode = 'MODE',
  MolPay = 'MOL_PAY',
  Multibanco = 'MULTIBANCO',
  Newpay = 'NEWPAY',
  One4All = 'ONE4ALL',
  OpenPay = 'OPEN_PAY',
  Paypal = 'PAYPAL',
  PaypalIn_3 = 'PAYPAL_IN_3',
  Paytm = 'PAYTM',
  Payvyne = 'PAYVYNE',
  PayOnDelivery = 'PAY_ON_DELIVERY',
  PrimeiroPay = 'PRIMEIRO_PAY',
  QiwiWallet = 'QIWI_WALLET',
  Quadpay = 'QUADPAY',
  Razorpay = 'RAZORPAY',
  Rupay = 'RUPAY',
  Sepa = 'SEPA',
  Sezzle = 'SEZZLE',
  Sofort = 'SOFORT',
  Solo = 'SOLO',
  SplitIt = 'SPLIT_IT',
  Spotii = 'SPOTII',
  Tenpay = 'TENPAY',
  Trustly = 'TRUSTLY',
  TrustPay = 'TRUST_PAY',
  Union = 'UNION',
  VisaCredit = 'VISA_CREDIT',
  VisaDebit = 'VISA_DEBIT',
  VisaElectron = 'VISA_ELECTRON',
  WebMoney = 'WEB_MONEY',
  Wechat = 'WECHAT',
  Yandex = 'YANDEX',
  ZipPay = 'ZIP_PAY',
}

export type PaymentOption = {
  option: Scalars['String']['input']
  subOption?: InputMaybe<Scalars['String']['input']>
}

/** The role of this is to represent the placement format map in HmacAppliedPlacement */
export type PlacementFormatElement = {
  __typename?: 'PlacementFormatElement'
  key?: Maybe<Scalars['String']['output']>
  value?: Maybe<Array<Maybe<HmacAppliedProduct>>>
}

export type Product = {
  __typename?: 'Product'
  alternateLinks: Array<AlternateLink>
  associatedProducts?: Maybe<Array<Product>>
  brand?: Maybe<Brand>
  breadcrumbs?: Maybe<Array<Breadcrumb>>
  cheapestVariant?: Maybe<ProductVariant>
  /** @deprecated Use cheapestVariant instead */
  cheapestVariantPrice?: Maybe<ProductPrice>
  content: Array<ProductContentItem>
  defaultVariant?: Maybe<ProductVariant>
  eligibleForWishlist: Scalars['Boolean']['output']
  externalIdentifier?: Maybe<Scalars['String']['output']>
  images: Array<ProductImage>
  /**
   * True if the wishlist contains this product with no selected variant,
   * if a variant is selected, the inWishlist flag on the variant will be true instead.
   */
  inWishlist?: Maybe<Scalars['Boolean']['output']>
  /** If the product have linked SKUs, this will expose what option it is linked on */
  linkedOn?: Maybe<Scalars['String']['output']>
  /** A marketed special offer to display on search results and product lists. */
  marketedSpecialOffer?: Maybe<ProductMarketedSpecialOffer>
  mostExpensiveVariant?: Maybe<ProductVariant>
  options: Array<VariantOption>
  /** (e.g. "DVD" or "Blu-Ray") */
  platform?: Maybe<Scalars['DisplayString']['output']>
  preorder: Scalars['Boolean']['output']
  preorderReleaseDate?: Maybe<Scalars['Date']['output']>
  recommendations?: Maybe<Array<Product>>
  reviewTemplate?: Maybe<ReviewTemplate>
  reviews?: Maybe<ProductReviews>
  /** As a dynamic content */
  sizeGuide?: Maybe<RichContent>
  sku: Scalars['SKU']['output']
  sponsoredAds: Array<HmacAppliedPlacement>
  title: Scalars['String']['output']
  /** In the format '/sports-nutrition/impact-whey-protein/10530943.html' */
  url: Scalars['URL']['output']
  variants: Array<ProductVariant>
}

export type ProductCheapestVariantArgs = {
  currency: Currency
  includeLinkedProducts?: InputMaybe<Scalars['Boolean']['input']>
  shippingDestination: Country
}

export type ProductCheapestVariantPriceArgs = {
  currency: Currency
  includeLinkedProducts?: InputMaybe<Scalars['Boolean']['input']>
  shippingDestination: Country
}

export type ProductContentArgs = {
  keys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ProductDefaultVariantArgs = {
  options: VariantOptions
}

export type ProductImagesArgs = {
  limit?: Scalars['Int']['input']
}

export type ProductMostExpensiveVariantArgs = {
  currency: Currency
  includeLinkedProducts?: InputMaybe<Scalars['Boolean']['input']>
  shippingDestination: Country
}

export type ProductRecommendationsArgs = {
  limit?: Scalars['Int']['input']
  type: ProductRecommendationType
}

export type ProductAddOnInput = {
  newQuantity: Scalars['Int']['input']
  newSku: Scalars['SKU']['input']
}

export type ProductContentAttachmentValue = {
  __typename?: 'ProductContentAttachmentValue'
  value: Scalars['URL']['output']
}

export type ProductContentDateValue = {
  __typename?: 'ProductContentDateValue'
  value: Scalars['Timestamp']['output']
}

export type ProductContentIntListValue = {
  __typename?: 'ProductContentIntListValue'
  value: Array<Scalars['Int']['output']>
}

export type ProductContentIntValue = {
  __typename?: 'ProductContentIntValue'
  value: Scalars['Int']['output']
}

export type ProductContentItem = {
  __typename?: 'ProductContentItem'
  key: Scalars['String']['output']
  value: ProductContentValue
}

export type ProductContentRichContentListValue = {
  __typename?: 'ProductContentRichContentListValue'
  value: Array<RichContent>
}

export type ProductContentRichContentValue = {
  __typename?: 'ProductContentRichContentValue'
  value: RichContent
}

export type ProductContentStringListValue = {
  __typename?: 'ProductContentStringListValue'
  value: Array<Scalars['String']['output']>
}

export type ProductContentStringValue = {
  __typename?: 'ProductContentStringValue'
  value: Scalars['String']['output']
}

export type ProductContentValue =
  | ProductContentAttachmentValue
  | ProductContentDateValue
  | ProductContentIntListValue
  | ProductContentIntValue
  | ProductContentRichContentListValue
  | ProductContentRichContentValue
  | ProductContentStringListValue
  | ProductContentStringValue

export type ProductImage = {
  __typename?: 'ProductImage'
  /** 300x300 Pixel Image */
  largeProduct?: Maybe<Scalars['URL']['output']>
  /** Unaltered version of image uploaded to our system */
  original?: Maybe<Scalars['URL']['output']>
  /** 70x70 Pixel Image */
  thumbnail?: Maybe<Scalars['URL']['output']>
  /** 960x960 Pixel Image */
  zoom?: Maybe<Scalars['URL']['output']>
}

export type ProductList = {
  __typename?: 'ProductList'
  /**
   * If this is non-null, 0 results were found, but results were found for a similar query,
   * which was returned instead.
   */
  correctedQuery?: Maybe<Scalars['DisplayString']['output']>
  facets: Array<Facet>
  hasMore: Scalars['Boolean']['output']
  products: Array<Product>
  sponsoredAds: Array<HmacAppliedPlacement>
  total: Scalars['Int']['output']
}

export type ProductListInput = {
  barcode?: InputMaybe<Scalars['String']['input']>
  concessionCode?: InputMaybe<Scalars['String']['input']>
  currency: Currency
  facets: Array<FacetInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  shippingDestination: Country
  sort?: ProductSort
}

export type ProductListWidget = Widget & {
  __typename?: 'ProductListWidget'
  descriptionHtml?: Maybe<RichContent>
  id: Scalars['ID']['output']
  productList?: Maybe<ProductList>
  query: Query
  seoDescriptionHtml?: Maybe<RichContent>
  title?: Maybe<Scalars['String']['output']>
}

export type ProductListWidgetProductListArgs = {
  input: ProductListInput
}

export type ProductMarketedSpecialOffer = {
  __typename?: 'ProductMarketedSpecialOffer'
  description?: Maybe<RichContent>
  /** This will only be non-null when the best special offer for this product is a free gift offer */
  freeGiftProduct?: Maybe<ProductVariant>
  landingPageLink?: Maybe<Hyperlink>
  title?: Maybe<RichContent>
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  /** @deprecated Use earnableLoyaltyPoints on the variant object instead */
  earnableLoyaltyPoints?: Maybe<Scalars['Int']['output']>
  price: MoneyValue
  rrp: MoneyValue
  /**
   * This price will be provided only if a vip price offer is set on the site and following conditions
   * are met:
   *  - the customer is logged in and part of the customer list set on the offer
   *  - the product is set of the product list set on the offer
   *  - the vip pricing feature flag is enabled
   * @deprecated Use vipPrice on the variant object instead
   */
  vipPrice?: Maybe<MoneyValue>
}

export type ProductPriceEarnableLoyaltyPointsArgs = {
  sku: Scalars['SKU']['input']
}

export type ProductPriceVipPriceArgs = {
  sku: Scalars['SKU']['input']
}

export enum ProductRecommendationType {
  /**
   * This looks at what products other customers added to their basket when browsing the product(s)
   * currently being viewed. If the probability of adding a particular product to basket is high
   * then that's what will be recommended.
   */
  CustomersEventuallyBought = 'CUSTOMERS_EVENTUALLY_BOUGHT',
  FrequentlyBoughtTogether = 'FREQUENTLY_BOUGHT_TOGETHER',
  Personalised = 'PERSONALISED',
  PostAddToBasket = 'POST_ADD_TO_BASKET',
  /**
   * This returns products of a similar category that are in stock. This is used on THG sites
   * to suggest other products to a customer when the product they are viewing is out of stock.
   */
  SimilarProducts = 'SIMILAR_PRODUCTS',
}

export type ProductRecommenderItem = Widget & {
  __typename?: 'ProductRecommenderItem'
  id: Scalars['ID']['output']
  itemNumber?: Maybe<Scalars['String']['output']>
  itemSKU?: Maybe<Scalars['String']['output']>
  itemTitle?: Maybe<Scalars['String']['output']>
  query: Query
}

export type ProductReviews = {
  __typename?: 'ProductReviews'
  averageScore: Scalars['Float']['output']
  count1Score: Scalars['Int']['output']
  count2Score: Scalars['Int']['output']
  count3Score: Scalars['Int']['output']
  count4Score: Scalars['Int']['output']
  count5Score: Scalars['Int']['output']
  featureScores: Array<AverageFeatureScore>
  maxScore: Scalars['Float']['output']
  /** @deprecated Field still valid. SKU no longer required as input as the SKU of the product this is attached to will be used */
  newReviewForm?: Maybe<Form>
  reviews?: Maybe<Reviews>
  total: Scalars['Int']['output']
}

export type ProductReviewsNewReviewFormArgs = {
  sku?: InputMaybe<Scalars['SKU']['input']>
}

export type ProductReviewsReviewsArgs = {
  filter?: InputMaybe<ReviewsFilterInput>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  sort?: ReviewSort
}

export enum ProductSort {
  DiscountPercentageHighToLow = 'DISCOUNT_PERCENTAGE_HIGH_TO_LOW',
  NameAlphabetically = 'NAME_ALPHABETICALLY',
  NewestToOldest = 'NEWEST_TO_OLDEST',
  Popularity = 'POPULARITY',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH',
  Relevance = 'RELEVANCE',
}

/** An e-commerce subscription, would be just a "Subscription" if it weren't for the GraphQL feature! */
export type ProductSubscription = {
  __typename?: 'ProductSubscription'
  autoRenewal: SubscriptionAutoRenewal
  availableAddOnProducts: Array<ProductVariant>
  availableBillingDateChanges?: Maybe<Array<DateRange>>
  availableFrequencyChanges?: Maybe<Array<SubscriptionFrequencyDuration>>
  billingAddress?: Maybe<Address>
  cancellable: Scalars['Boolean']['output']
  /**
   * The orders created to fulfil this subscription.
   * @deprecated Use orders instead,which has support both Legacy orders and Pagination
   */
  childOrders?: Maybe<Array<Order>>
  costPerPaymentPeriod: MoneyValue
  createdAt: Scalars['Timestamp']['output']
  delayCancellable: Scalars['Boolean']['output']
  delayCount: Scalars['Int']['output']
  /** @deprecated Use delayCount instead */
  delayStatus: SubscriptionDelay
  delayable: Scalars['Boolean']['output']
  deliveryAddress?: Maybe<Address>
  id: Scalars['ID']['output']
  nextBillingDate?: Maybe<Scalars['Date']['output']>
  nextGiftProductId?: Maybe<Scalars['SKU']['output']>
  /** The parent order that spawned this subscription. */
  order?: Maybe<Order>
  orders?: Maybe<Orders>
  paymentCard?: Maybe<PaymentCard>
  /** @deprecated Use subscriptionItems instead, which has support for both Legacy Subscriptions and Subs by Sku */
  product?: Maybe<ProductVariant>
  productAddOnAllowed: Scalars['Boolean']['output']
  productRemovalAllowed: Scalars['Boolean']['output']
  productSwapAllowed: Scalars['Boolean']['output']
  schedule: SubscriptionSchedule
  /**
   * The product that represents the subscription as a whole, i.e. the one placed in basket when
   * initially signing-up, not the ones actually sent out.
   * @deprecated Use subscriptionItems instead, as this field is of no use on its own
   */
  sku?: Maybe<Scalars['SKU']['output']>
  status: SubscriptionStatus
  subscriptionItems: Array<SubscriptionItem>
}

/** An e-commerce subscription, would be just a "Subscription" if it weren't for the GraphQL feature! */
export type ProductSubscriptionOrdersArgs = {
  endDate?: InputMaybe<Scalars['Timestamp']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['Timestamp']['input']>
}

export type ProductSwapInput = {
  /** Quantity of newSku; oldSku will be removed from the subscription */
  newQuantity: Scalars['Int']['input']
  newSku: Scalars['SKU']['input']
  oldSku: Scalars['SKU']['input']
}

export type ProductUnit = {
  __typename?: 'ProductUnit'
  /** E.g. "kg" in 5 kg or "servings" in 35 servings */
  unit: Scalars['String']['output']
  /** E.g. "5" in 5 kg or "35" in 35 servings */
  value: Scalars['String']['output']
}

export type ProductVariant = {
  __typename?: 'ProductVariant'
  availabilityMessage: Scalars['DisplayString']['output']
  barcode?: Maybe<Scalars['String']['output']>
  buyNowPayLaterProviders?: Maybe<Array<BuyNowPayLaterProvider>>
  choices: Array<OptionChoice>
  content: Array<ProductContentItem>
  earnableLoyaltyPoints?: Maybe<Scalars['Int']['output']>
  eligibleForWishlist: Scalars['Boolean']['output']
  externalIdentifier?: Maybe<Scalars['String']['output']>
  images: Array<ProductImage>
  inStock: Scalars['Boolean']['output']
  inWishlist?: Maybe<Scalars['Boolean']['output']>
  isAutoRenewSubscription: Scalars['Boolean']['output']
  /** Legacy subscription fields. This functionality is being replaced by Subs By Sku. */
  isSubscription: Scalars['Boolean']['output']
  /** A marketed special offer to display on product pages. */
  marketedSpecialOffer?: Maybe<ProductMarketedSpecialOffer>
  maxPerOrder?: Maybe<Scalars['Int']['output']>
  price?: Maybe<ProductPrice>
  /**
   * The main product which includes this variant.
   * May not necessarily be the product under which this was queried.
   */
  product?: Maybe<Product>
  sku: Scalars['SKU']['output']
  subscriptionContracts: Array<SubscriptionContract>
  subscriptionFrequency?: Maybe<Scalars['String']['output']>
  subscriptionPaymentType?: Maybe<Scalars['String']['output']>
  subscriptionTerm?: Maybe<Scalars['Int']['output']>
  supersize?: Maybe<Supersize>
  /** @deprecated Use supersize -> variant instead */
  supersizeVariant?: Maybe<ProductVariant>
  title: Scalars['String']['output']
  /**
   * This price will be provided only if a vip price offer is set on the site and following conditions
   * are met:
   *  - the customer is logged in and part of the customer list set on the offer
   *  - the product is set of the product list set on the offer
   *  - the vip pricing feature flag is enabled
   */
  vipPrice?: Maybe<MoneyValue>
}

export type ProductVariantBuyNowPayLaterProvidersArgs = {
  settings: SessionSettings
}

export type ProductVariantContentArgs = {
  keys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ProductVariantEarnableLoyaltyPointsArgs = {
  settings: SessionSettings
}

export type ProductVariantImagesArgs = {
  limit?: Scalars['Int']['input']
}

export type ProductVariantPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export type ProductVariantSupersizeArgs = {
  settings: SessionSettings
}

export type ProductVariantList = {
  __typename?: 'ProductVariantList'
  /**
   * If this is non-null, 0 results were found, but results were found for a similar query,
   * which was returned instead.
   */
  correctedQuery?: Maybe<Scalars['DisplayString']['output']>
  facets: Array<Facet>
  hasMore: Scalars['Boolean']['output']
  productVariants: Array<ProductVariant>
  sponsoredAds: Array<HmacAppliedPlacement>
  total: Scalars['Int']['output']
}

export type ProfileOutcomeResponse = {
  __typename?: 'ProfileOutcomeResponse'
  outcomeType: OutcomeType
  outcomeValue: Scalars['URL']['output']
}

export type ProfileWidget = Widget & {
  __typename?: 'ProfileWidget'
  hasProgressBar?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  isForWidget?: Maybe<Scalars['Boolean']['output']>
  profileKey?: Maybe<Scalars['String']['output']>
  query: Query
  startLayoutTwoCol?: Maybe<Scalars['Boolean']['output']>
  useGridLayout?: Maybe<Scalars['Boolean']['output']>
}

export type PushSubscriptionInput = {
  deviceUuid?: InputMaybe<Scalars['String']['input']>
  externalDeviceId?: InputMaybe<Scalars['String']['input']>
  subscribed: Scalars['Boolean']['input']
  subscriptionToken: Scalars['String']['input']
  tokenType: PushTokenType
}

export type PushSubscriptionResponse = {
  __typename?: 'PushSubscriptionResponse'
  deviceUuid?: Maybe<Scalars['String']['output']>
}

export enum PushTokenType {
  Apns = 'APNS',
  Firebase = 'FIREBASE',
}

export type Query = {
  __typename?: 'Query'
  accountCreationForm: Form
  accountCreationMarketingPreferences?: Maybe<
    AccountCreationMarketingPreferences
  >
  accountLinkingTokenInfo?: Maybe<AccountLinkingTokenInfo>
  accountSettingsForm: Form
  attachmentUploaderConfig?: Maybe<AttachmentUploaderConfig>
  /**
   * If a null id is provided, this will fall back to the logged in customer's saved basket, or a new basket.
   * This may return a different basket ID than provided, if the basket moved, merged, or didn't exist.
   */
  basket?: Maybe<Basket>
  brands: Array<Brand>
  /** @deprecated Included in extensions if applicable. */
  captchaConfiguration: Array<CaptchaConfiguration>
  check?: Maybe<Scalars['String']['output']>
  /**
   * Widgets are usually associated with pages on site, but there is sometimes the need to display widgets across other components such as a popup, or alongside core e-commerce objects like the basket.
   *
   * These "special" sets of widgets can be queried through the componentWidgets query and the supported components can be found using the supportedComponentWidgetNames query.
   */
  componentWidgets: Array<Widget>
  /** Returns the current customer if the customer is logged in, or null otherwise */
  customer?: Maybe<Customer>
  deliveryInfo?: Maybe<DeliveryInfo>
  eGiftSummary?: Maybe<EGiftSummary>
  emailField?: Maybe<FormField>
  /**
   * This is a query despite having side effects so it can be queried on the server-side at the same time as querying
   * other information to deliver the first page load
   *
   * token should be considered deprecated and will be removed in due course.
   * Please only submit the SocialLoginInput object
   */
  exchangeSocialAuthenticationToken?: Maybe<SocialAuthenticationResponse>
  features: Array<Feature>
  /** @deprecated Use features instead */
  flags: Array<Feature>
  footer?: Maybe<Footer>
  form?: Maybe<Form>
  forms?: Maybe<Array<Form>>
  header?: Maybe<Header>
  instantSearch?: Maybe<InstantSearchResult>
  /**
   * Interaction awards are points based awards given for action on site.
   * This includes things like leaving product reviews or completing your customer profile.
   * This data for all reward types can also be requested on the LoyaltyScheme object.
   */
  interactionAward?: Maybe<LoyaltyInteractionAward>
  loyaltyScheme?: Maybe<LoyaltyScheme>
  /** @deprecated Use accountCreationMarketingPreferences instead */
  marketingPreferences?: Maybe<MarketingPreferences>
  page?: Maybe<Page>
  passwordField?: Maybe<FormField>
  /** Returns information about a password reset token without consuming it. */
  passwordResetTokenValidity?: Maybe<PasswordResetTokenState>
  paymentMethods: Array<PaymentMethod>
  /**
   * If strict is false, the api is allowed to perform redirects, such as returning a master product when given the SKU
   * of one of its variants.
   *
   * If skipRedirects is set to true, redirects will be skipped but as long as strict is false,
   * master product will still be checked if a variant SKU is provided instead of a master SKU
   */
  product?: Maybe<Product>
  productVariant?: Maybe<ProductVariant>
  productVariants: Array<ProductVariant>
  products: Array<Product>
  profiles?: Maybe<Array<Form>>
  referralCodeField?: Maybe<FormField>
  /**
   * Default value of skipRedirects is true. When set to true, search results will be returned even
   * when there is a redirect and client can decide whether to redirect the customers or not
   */
  search?: Maybe<SearchResult>
  /**
   * Returns all site properties for the site.
   *
   * If propertyBucket is provided, that bucket will be used to retrieve properties.  Otherwise, it will default to
   * "frontend".
   *
   * Providing a preview ID allows property changes to be previewed before committing them.  The preview ID
   * should come from the Properties-Preview header.
   */
  siteProperties?: Maybe<SiteProperties>
  sitemap: Sitemap
  socialAccounts: Array<SocialAccount>
  socialLoginProviders?: Maybe<Array<SocialLoginProvider>>
  supportedComponentWidgetNames: Array<Scalars['String']['output']>
  supportedCurrencies: Array<Currency>
  supportedShippingDestinations: Array<Country>
  video?: Maybe<Video>
  voucherCodes?: Maybe<VoucherCodes>
}

export type QueryAccountCreationMarketingPreferencesArgs = {
  email: Scalars['String']['input']
}

export type QueryAccountLinkingTokenInfoArgs = {
  accountLinkingToken: Scalars['AccountLinkingToken']['input']
}

export type QueryBasketArgs = {
  acknowledgeMerge?: Scalars['Boolean']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  settings: SessionSettings
}

export type QueryComponentWidgetsArgs = {
  name: Scalars['String']['input']
}

export type QueryEGiftSummaryArgs = {
  code: Scalars['String']['input']
}

export type QueryExchangeSocialAuthenticationTokenArgs = {
  input?: InputMaybe<SocialLoginInput>
  token?: InputMaybe<Scalars['SocialAuthenticationToken']['input']>
}

export type QueryFormArgs = {
  input: FormInput
}

export type QueryFormsArgs = {
  input: FormFilterInput
}

export type QueryInstantSearchArgs = {
  concessionCode?: InputMaybe<Scalars['String']['input']>
  currency: Currency
  limit?: Scalars['Int']['input']
  query: Scalars['String']['input']
  shippingDestination: Country
}

export type QueryInteractionAwardArgs = {
  type: InteractionAwardType
}

export type QueryMarketingPreferencesArgs = {
  input: MarketingPreferencesInput
}

export type QueryPageArgs = {
  path: Scalars['PagePath']['input']
}

export type QueryPasswordResetTokenValidityArgs = {
  token: Scalars['PasswordResetToken']['input']
}

export type QueryProductArgs = {
  skipRedirects?: InputMaybe<Scalars['Boolean']['input']>
  sku: Scalars['SKU']['input']
  strict: Scalars['Boolean']['input']
}

export type QueryProductVariantArgs = {
  sku: Scalars['SKU']['input']
}

export type QueryProductVariantsArgs = {
  skus: Array<Scalars['SKU']['input']>
}

export type QueryProductsArgs = {
  skipRedirects?: InputMaybe<Scalars['Boolean']['input']>
  skus: Array<Scalars['SKU']['input']>
}

export type QueryProfilesArgs = {
  input: FormFilterInput
}

export type QuerySearchArgs = {
  options: ProductListInput
  query: Scalars['String']['input']
  skipRedirects?: InputMaybe<Scalars['Boolean']['input']>
}

export type QuerySitePropertiesArgs = {
  previewId?: InputMaybe<Scalars['String']['input']>
  propertyBucket?: InputMaybe<Scalars['String']['input']>
}

export type QuerySitemapArgs = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type QueryVideoArgs = {
  id: Scalars['ID']['input']
}

export type QueryVoucherCodesArgs = {
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
}

export type RangedFacet = Facet & {
  __typename?: 'RangedFacet'
  facetHeader: Scalars['DisplayString']['output']
  facetName: Scalars['String']['output']
  options: Array<RangedFacetOption>
}

export type RangedFacetOption = {
  __typename?: 'RangedFacetOption'
  displayName: Scalars['DisplayString']['output']
  /** May be null if the range is unbounded (e.g. "Up to £5") */
  from?: Maybe<Scalars['Float']['output']>
  matchedProductCount: Scalars['Int']['output']
  /** May be null if the range is unbounded (e.g. "£50 and up") */
  to?: Maybe<Scalars['Float']['output']>
}

export enum RateLimitingBucket {
  AddReview = 'ADD_REVIEW',
  ForgotPassword = 'FORGOT_PASSWORD',
  Login = 'LOGIN',
  MarketingMaterialSignUp = 'MARKETING_MATERIAL_SIGN_UP',
  MarketingPreferences = 'MARKETING_PREFERENCES',
  PushNotifications = 'PUSH_NOTIFICATIONS',
  ReferralEmail = 'REFERRAL_EMAIL',
  Registration = 'REGISTRATION',
  ResetPassword = 'RESET_PASSWORD',
  Waitlist = 'WAITLIST',
}

/** Represents a rating (for example out of 5 stars) */
export type RatingReviewElement = ReviewElement & {
  __typename?: 'RatingReviewElement'
  key: Scalars['String']['output']
  maxScore: Scalars['Int']['output']
  score: Scalars['Int']['output']
}

export enum RedemptionRateSubmissionStatus {
  /** Failed to redeem the points */
  ErrorRedeeming = 'ERROR_REDEEMING',
  /** Insufficient balance or invalid request */
  InsufficientFundsOrBadRequest = 'INSUFFICIENT_FUNDS_OR_BAD_REQUEST',
  /** Redemption rate was not found */
  RedemptionRateNotFound = 'REDEMPTION_RATE_NOT_FOUND',
  /** Success, Points were redeemed. */
  Success = 'SUCCESS',
}

export type ReferralCount = {
  __typename?: 'ReferralCount'
  count: Scalars['Int']['output']
  siteKey: Scalars['String']['output']
}

/**  Any additions to this should also be added to SocialLoginMissingInformation and maybe to the customer object */
export type RegistrationInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  email: Scalars['String']['input']
  fullName: Scalars['String']['input']
  loyaltyOptIn?: InputMaybe<Scalars['Boolean']['input']>
  marketingConsent: Scalars['String']['input']
  marketingConsentAuditData: MarketingConsentAuditData
  password: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  referralCode?: InputMaybe<Scalars['String']['input']>
}

export enum Relationship {
  EqualTo = 'EQUAL_TO',
  NotEqualTo = 'NOT_EQUAL_TO',
}

export type RemoveSocialLinkInput = {
  socialLinkId: Scalars['SocialLinkID']['input']
}

export type RenderCondition = {
  __typename?: 'RenderCondition'
  /**
   * This condition is a requirement for a field to be rendered, eg: card_details may have a condition:
   * referenceFieldName: "age"
   * relationship: "GREATER_THAN"
   * value: "18"
   */
  referenceFieldName: Scalars['String']['output']
  relationship: Relationship
  value: Scalars['String']['output']
}

/** The role of this is to represent the rendering map in HmacAppliedPlacement */
export type RenderingElement = {
  __typename?: 'RenderingElement'
  key?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type ReplaceAddressInput = {
  addressInput: AddressInput
  id: Scalars['ID']['input']
}

export type RequestSetPasswordEmailInput = {
  username: Scalars['String']['input']
}

export type RequestSetPasswordEmailResponse = {
  __typename?: 'RequestSetPasswordEmailResponse'
  /** Check to make sure error is not set to confirm success */
  error?: Maybe<ForgottenPasswordError>
}

export enum RequestSocialLinkVerificationEmailError {
  InvalidToken = 'INVALID_TOKEN',
}

export type RequestSocialLinkVerificationEmailInput = {
  socialAuthenticationToken: Scalars['SocialAuthenticationToken']['input']
}

/** The response will either contain the error field or the socialAuthenticationToken field */
export type RequestSocialLinkVerificationEmailResponse = {
  __typename?: 'RequestSocialLinkVerificationEmailResponse'
  error?: Maybe<RequestSocialLinkVerificationEmailError>
  /** A new token that can be used to resend the email */
  socialAuthenticationToken?: Maybe<
    Scalars['SocialAuthenticationToken']['output']
  >
}

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input']
  token: Scalars['PasswordResetToken']['input']
  username: Scalars['String']['input']
}

export type ResponsiveBuildYourOwnBundle = Widget & {
  __typename?: 'ResponsiveBuildYourOwnBundle'
  banners?: Maybe<Array<Maybe<Widget>>>
  bundleSubTitle?: Maybe<Scalars['String']['output']>
  expandAllCategories?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  maxProducts?: Maybe<Scalars['Int']['output']>
  query: Query
  showBundlePageTitle?: Maybe<Scalars['String']['output']>
  showCategoriesList?: Maybe<Scalars['String']['output']>
  showInfoButton?: Maybe<Scalars['String']['output']>
  showPrices?: Maybe<Scalars['String']['output']>
  showProductRRP?: Maybe<Scalars['String']['output']>
  showProductReviews?: Maybe<Scalars['String']['output']>
  summaryAmount?: Maybe<Scalars['String']['output']>
  summaryDisplayType?: Maybe<Scalars['String']['output']>
  unlimitedProducts?: Maybe<Scalars['String']['output']>
}

export type ResponsiveBuildYourOwnBundleProducts = Widget & {
  __typename?: 'ResponsiveBuildYourOwnBundleProducts'
  categoryTitle?: Maybe<Scalars['String']['output']>
  enableQuantitySelector?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  maxProducts?: Maybe<Scalars['Int']['output']>
  productList?: Maybe<ProductList>
  productListChildProducts?: Maybe<ProductVariantList>
  productsToShow?: Maybe<Scalars['Int']['output']>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuList?: Maybe<Array<Maybe<ProductVariant>>>
  skuListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
}

export type ResponsiveBuildYourOwnBundleProductsProductListArgs = {
  input: ProductListInput
}

export type ResponsiveBuildYourOwnBundleProductsProductListChildProductsArgs = {
  input: ProductListInput
}

export type ResponsiveComparisonTable = Widget & {
  __typename?: 'ResponsiveComparisonTable'
  buttonText?: Maybe<Scalars['String']['output']>
  buttonUrl?: Maybe<Scalars['String']['output']>
  comparisonList?: Maybe<Scalars['String']['output']>
  disableFeatureTag?: Maybe<Scalars['String']['output']>
  featureTag?: Maybe<Scalars['URL']['output']>
  id: Scalars['ID']['output']
  imageUrl?: Maybe<Scalars['URL']['output']>
  moreInfo?: Maybe<Scalars['String']['output']>
  query: Query
  subTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ResponsiveComparisonTableContainer = Widget & {
  __typename?: 'ResponsiveComparisonTableContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type ResponsiveProductBlockCarousel = Widget & {
  __typename?: 'ResponsiveProductBlockCarousel'
  displayStandaloneTitle?: Maybe<Scalars['String']['output']>
  enableAsiaStyles?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  productList?: Maybe<ProductList>
  query: Query
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  skuList?: Maybe<Array<Maybe<ProductVariant>>>
  skuListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
  title?: Maybe<Scalars['String']['output']>
  viewAllButtonStyle?: Maybe<Scalars['String']['output']>
  viewAllButtonText?: Maybe<Scalars['String']['output']>
  viewAllButtonUrl?: Maybe<Scalars['URL']['output']>
}

export type ResponsiveProductBlockCarouselProductListArgs = {
  input: ProductListInput
}

export type ResponsiveProductTabs = Widget & {
  __typename?: 'ResponsiveProductTabs'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  responsiveProductTabsTitle?: Maybe<Scalars['String']['output']>
  tabTitle_1?: Maybe<Scalars['String']['output']>
  tabTitle_2?: Maybe<Scalars['String']['output']>
  tabTitle_3?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type ResponsiveSetSalon = Widget & {
  __typename?: 'ResponsiveSetSalon'
  banners?: Maybe<Array<Maybe<Widget>>>
  beauty?: Maybe<Scalars['String']['output']>
  extensions?: Maybe<Scalars['String']['output']>
  fridayHours?: Maybe<Scalars['String']['output']>
  hair?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mondayHours?: Maybe<Scalars['String']['output']>
  query: Query
  salonBookUrl?: Maybe<Scalars['URL']['output']>
  salonDescription?: Maybe<Scalars['String']['output']>
  salonEmail?: Maybe<Scalars['String']['output']>
  salonTel?: Maybe<Scalars['String']['output']>
  salonTitle?: Maybe<Scalars['String']['output']>
  saturdayHours?: Maybe<Scalars['String']['output']>
  socialUrl?: Maybe<Scalars['String']['output']>
  sprayTan?: Maybe<Scalars['String']['output']>
  sundayHours?: Maybe<Scalars['String']['output']>
  thursdayHours?: Maybe<Scalars['String']['output']>
  tuesdayHours?: Maybe<Scalars['String']['output']>
  wednesdayHours?: Maybe<Scalars['String']['output']>
}

export type ResponsiveSetSalonWithSlides = Widget & {
  __typename?: 'ResponsiveSetSalonWithSlides'
  beauty?: Maybe<Scalars['String']['output']>
  extensions?: Maybe<Scalars['String']['output']>
  fridayHours?: Maybe<Scalars['String']['output']>
  hair?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mondayHours?: Maybe<Scalars['String']['output']>
  query: Query
  salonBookUrl?: Maybe<Scalars['URL']['output']>
  salonDescription?: Maybe<Scalars['String']['output']>
  salonEmail?: Maybe<Scalars['String']['output']>
  salonTel?: Maybe<Scalars['String']['output']>
  salonTitle?: Maybe<Scalars['String']['output']>
  saturdayHours?: Maybe<Scalars['String']['output']>
  sliderImage1?: Maybe<Scalars['URL']['output']>
  sliderImage1Alt?: Maybe<Scalars['String']['output']>
  sliderImage2?: Maybe<Scalars['URL']['output']>
  sliderImage2Alt?: Maybe<Scalars['String']['output']>
  sliderImage3?: Maybe<Scalars['URL']['output']>
  sliderImage3Alt?: Maybe<Scalars['String']['output']>
  socialUrl?: Maybe<Scalars['URL']['output']>
  sprayTan?: Maybe<Scalars['String']['output']>
  sundayHours?: Maybe<Scalars['String']['output']>
  thursdayHours?: Maybe<Scalars['String']['output']>
  tuesdayHours?: Maybe<Scalars['String']['output']>
  wednesdayHours?: Maybe<Scalars['String']['output']>
}

export type ResponsiveSliderSet = Widget & {
  __typename?: 'ResponsiveSliderSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  isAutoSlideOn?: Maybe<Scalars['String']['output']>
  isBulletNavigationHidden?: Maybe<Scalars['String']['output']>
  isHeightVariable?: Maybe<Scalars['String']['output']>
  query: Query
  slideDuration?: Maybe<Scalars['Int']['output']>
}

export type ResponsiveSuccessStoriesWidgetContainer = Widget & {
  __typename?: 'ResponsiveSuccessStoriesWidgetContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type ResponsiveSuccessStoryWidget = Widget & {
  __typename?: 'ResponsiveSuccessStoryWidget'
  caption?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageAfterAltText?: Maybe<Scalars['String']['output']>
  imageAfterUrl?: Maybe<Scalars['URL']['output']>
  imageAltText?: Maybe<Scalars['String']['output']>
  imageUrl?: Maybe<Scalars['URL']['output']>
  linkUrl?: Maybe<Scalars['String']['output']>
  profileCta?: Maybe<Scalars['String']['output']>
  profileName?: Maybe<Scalars['String']['output']>
  query: Query
  roundelTitle?: Maybe<Scalars['String']['output']>
  weightLossStyle?: Maybe<Scalars['String']['output']>
  weightLost?: Maybe<Scalars['String']['output']>
}

export type ResponsiveTwoItemComparisonContainer = Widget & {
  __typename?: 'ResponsiveTwoItemComparisonContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  title1?: Maybe<Scalars['String']['output']>
  title2?: Maybe<Scalars['String']['output']>
}

export type ResponsiveTwoItemComparisonRow = Widget & {
  __typename?: 'ResponsiveTwoItemComparisonRow'
  firstItem?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  secondItem?: Maybe<Scalars['String']['output']>
  valueToCompare?: Maybe<Scalars['String']['output']>
  valueToCopmare?: Maybe<Scalars['String']['output']>
}

export type ResponsiveUspBar = Widget & {
  __typename?: 'ResponsiveUSPBar'
  callIcon?: Maybe<Scalars['String']['output']>
  callLink?: Maybe<Scalars['String']['output']>
  callText?: Maybe<Scalars['String']['output']>
  checkmarkLink?: Maybe<Scalars['String']['output']>
  checkmarkText?: Maybe<Scalars['String']['output']>
  clickAndCollectLink?: Maybe<Scalars['String']['output']>
  clickAndCollectText?: Maybe<Scalars['String']['output']>
  countDownLink?: Maybe<Scalars['String']['output']>
  countDownText?: Maybe<Scalars['String']['output']>
  deliveryLink?: Maybe<Scalars['String']['output']>
  deliveryText?: Maybe<Scalars['String']['output']>
  emailLink?: Maybe<Scalars['String']['output']>
  emailText?: Maybe<Scalars['String']['output']>
  fastTrackLink?: Maybe<Scalars['String']['output']>
  fastTrackText?: Maybe<Scalars['String']['output']>
  fridayCutoff?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internationalDeliveryLink?: Maybe<Scalars['String']['output']>
  internationalDeliveryText?: Maybe<Scalars['String']['output']>
  mondayCutoff?: Maybe<Scalars['String']['output']>
  qualityLink?: Maybe<Scalars['String']['output']>
  qualityText?: Maybe<Scalars['String']['output']>
  query: Query
  returnsLink?: Maybe<Scalars['String']['output']>
  returnsText?: Maybe<Scalars['String']['output']>
  rewardPointsLink?: Maybe<Scalars['String']['output']>
  rewardPointsText?: Maybe<Scalars['String']['output']>
  saturdayCutoff?: Maybe<Scalars['String']['output']>
  storeLink?: Maybe<Scalars['String']['output']>
  storeText?: Maybe<Scalars['String']['output']>
  sundayCutoff?: Maybe<Scalars['String']['output']>
  thursdayCutoff?: Maybe<Scalars['String']['output']>
  timezoneOffset?: Maybe<Scalars['String']['output']>
  trustPilotLink?: Maybe<Scalars['String']['output']>
  trustPilotText?: Maybe<Scalars['String']['output']>
  tuesdayCutoff?: Maybe<Scalars['String']['output']>
  wednesdayCutoff?: Maybe<Scalars['String']['output']>
}

export type ResponsiveUspBarCustomisable = Widget & {
  __typename?: 'ResponsiveUSPBarCustomisable'
  calendarLink?: Maybe<Scalars['String']['output']>
  calendarPosition?: Maybe<Scalars['Int']['output']>
  calendarText?: Maybe<Scalars['String']['output']>
  callIcon?: Maybe<Scalars['String']['output']>
  callLink?: Maybe<Scalars['String']['output']>
  callPosition?: Maybe<Scalars['Int']['output']>
  callText?: Maybe<Scalars['String']['output']>
  checkmarkLink?: Maybe<Scalars['String']['output']>
  checkmarkPosition?: Maybe<Scalars['Int']['output']>
  checkmarkText?: Maybe<Scalars['String']['output']>
  clickAndCollectLink?: Maybe<Scalars['String']['output']>
  clickAndCollectPosition?: Maybe<Scalars['Int']['output']>
  clickAndCollectText?: Maybe<Scalars['String']['output']>
  countdownLink?: Maybe<Scalars['String']['output']>
  countdownPosition?: Maybe<Scalars['Int']['output']>
  countdownText?: Maybe<Scalars['String']['output']>
  deliveryLink?: Maybe<Scalars['String']['output']>
  deliveryPosition?: Maybe<Scalars['Int']['output']>
  deliveryText?: Maybe<Scalars['String']['output']>
  emailLink?: Maybe<Scalars['String']['output']>
  emailPosition?: Maybe<Scalars['Int']['output']>
  emailText?: Maybe<Scalars['String']['output']>
  fastTrackLink?: Maybe<Scalars['String']['output']>
  fastTrackPosition?: Maybe<Scalars['Int']['output']>
  fastTrackText?: Maybe<Scalars['String']['output']>
  fridayCutoff?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  internationalDeliveryLink?: Maybe<Scalars['String']['output']>
  internationalDeliveryPosition?: Maybe<Scalars['Int']['output']>
  internationalDeliveryText?: Maybe<Scalars['String']['output']>
  mondayCutoff?: Maybe<Scalars['String']['output']>
  qualityLink?: Maybe<Scalars['String']['output']>
  qualityPosition?: Maybe<Scalars['Int']['output']>
  qualityText?: Maybe<Scalars['String']['output']>
  query: Query
  rewardPointsLink?: Maybe<Scalars['String']['output']>
  rewardPointsPosition?: Maybe<Scalars['Int']['output']>
  rewardPointsText?: Maybe<Scalars['String']['output']>
  saturdayCutoff?: Maybe<Scalars['String']['output']>
  storeLink?: Maybe<Scalars['String']['output']>
  storePosition?: Maybe<Scalars['Int']['output']>
  storeText?: Maybe<Scalars['String']['output']>
  sundayCutoff?: Maybe<Scalars['String']['output']>
  thursdayCutoff?: Maybe<Scalars['String']['output']>
  timezoneOffset?: Maybe<Scalars['String']['output']>
  trustPilotLink?: Maybe<Scalars['String']['output']>
  trustPilotPosition?: Maybe<Scalars['Int']['output']>
  trustPilotText?: Maybe<Scalars['String']['output']>
  tuesdayCutoff?: Maybe<Scalars['String']['output']>
  wednesdayCutoff?: Maybe<Scalars['String']['output']>
  backgroundColor?: Maybe<Scalars['String']['output']>
}

export type ReturnUrlInput = {
  skus: Array<Scalars['SKU']['input']>
}

export type ReturnsEligibilityInput = {
  skus: Array<Scalars['SKU']['input']>
}

export enum ReturnsEligibilityOrderError {
  /** The provided address is not valid */
  InvalidAddress = 'INVALID_ADDRESS',
  /** The selected products are invalid for return */
  OrderProductsInvalid = 'ORDER_PRODUCTS_INVALID',
  /** Unrecognised error found */
  UnknownError = 'UNKNOWN_ERROR',
  /** The value of the selected products is too high */
  ValueTooHigh = 'VALUE_TOO_HIGH',
}

export enum ReturnsEligibilityProductError {
  /** The product is a dropship */
  DropshipOrderProduct = 'DROPSHIP_ORDER_PRODUCT',
  /** The product's delivery date is unknown */
  ItemDeliveryDateUnknown = 'ITEM_DELIVERY_DATE_UNKNOWN',
  /** The product's return date has expired */
  ItemExpired = 'ITEM_EXPIRED',
  /** The product has not been dispatched yet */
  ItemNotDispatched = 'ITEM_NOT_DISPATCHED',
  /** The product is not available for return */
  OrderProductUnavailable = 'ORDER_PRODUCT_UNAVAILABLE',
  /** The product is not in the order */
  ProductNotInOrder = 'PRODUCT_NOT_IN_ORDER',
  /** The product type is not valid */
  ProductTypeInvalid = 'PRODUCT_TYPE_INVALID',
  /** Unrecognised error found */
  UnknownError = 'UNKNOWN_ERROR',
}

export type ReturnsEligibilityResult = {
  __typename?: 'ReturnsEligibilityResult'
  orderError?: Maybe<ReturnsEligibilityOrderError>
  productErrors?: Maybe<Array<ReturnsEligibilitySkuAndProductError>>
  success: Scalars['Boolean']['output']
}

export type ReturnsEligibilitySkuAndProductError = {
  __typename?: 'ReturnsEligibilitySkuAndProductError'
  error?: Maybe<ReturnsEligibilityProductError>
  sku: Scalars['SKU']['output']
}

export type Review = {
  __typename?: 'Review'
  authorName: Scalars['DisplayString']['output']
  elements: Array<ReviewElement>
  id: Scalars['ID']['output']
  negativeVotes?: Maybe<Scalars['Int']['output']>
  positiveVotes?: Maybe<Scalars['Int']['output']>
  posted: Scalars['Date']['output']
  title: Scalars['DisplayString']['output']
  verifiedPurchase: Scalars['Boolean']['output']
}

export enum ReviewActionResult {
  AlreadyVoted = 'ALREADY_VOTED',
  InvalidReviewId = 'INVALID_REVIEW_ID',
  ReviewNotFound = 'REVIEW_NOT_FOUND',
  Successful = 'SUCCESSFUL',
}

export type ReviewElement = {
  key: Scalars['String']['output']
}

export type ReviewFieldInput = {
  isProductSpecific?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type ReviewMeasurementValue = {
  __typename?: 'ReviewMeasurementValue'
  label: Scalars['DisplayString']['output']
  mappingValue: Scalars['Int']['output']
  order: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export enum ReviewSort {
  HighestToLowestRating = 'HIGHEST_TO_LOWEST_RATING',
  MostToLeastPositiveVotes = 'MOST_TO_LEAST_POSITIVE_VOTES',
  NewestToOldest = 'NEWEST_TO_OLDEST',
}

export type ReviewTemplate = {
  __typename?: 'ReviewTemplate'
  features: Array<ReviewTemplateFeature>
  name: Scalars['ID']['output']
}

export type ReviewTemplateFeature = {
  __typename?: 'ReviewTemplateFeature'
  hasDefaultMeasurement: Scalars['Boolean']['output']
  label: Scalars['DisplayString']['output']
  measurementValues: Array<ReviewMeasurementValue>
  name: Scalars['ID']['output']
}

export type ReviewVotingInput = {
  reviewId: Scalars['ID']['input']
  sku: Scalars['SKU']['input']
}

export type Reviews = {
  __typename?: 'Reviews'
  hasMore: Scalars['Boolean']['output']
  reviews: Array<Review>
  total: Scalars['Int']['output']
}

export type ReviewsFilterInput = {
  scores: Array<Scalars['Int']['input']>
}

export type RichContent = {
  __typename?: 'RichContent'
  content: Array<RichContentItem>
}

export type RichContentContentArgs = {
  filter?: InputMaybe<Array<RichContentType>>
}

export type RichContentItem = {
  __typename?: 'RichContentItem'
  content: Scalars['DisplayString']['output']
  type: RichContentType
}

export enum RichContentType {
  Html = 'HTML',
  Markdown = 'MARKDOWN',
}

export type SearchResult = {
  __typename?: 'SearchResult'
  /**
   * If this is non-null, 0 results were found, but results were found for a similar query,
   * which was returned instead.
   * @deprecated pulling in ProductList -> clients will need to migrate to new query
   */
  correctedQuery?: Maybe<Scalars['DisplayString']['output']>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  facets: Array<Facet>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  hasMore: Scalars['Boolean']['output']
  productList?: Maybe<ProductList>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  products: Array<Product>
  searchRedirect?: Maybe<Scalars['URL']['output']>
  /** @deprecated pulling in ProductList -> clients will need to migrate to new query */
  total: Scalars['Int']['output']
}

/** One of our offer types allows the customer to select a gift at the basket. These can also be tiered to allow for multiple gifts at different thresholds. */
export type SelectYourSample = {
  __typename?: 'SelectYourSample'
  /** Total amount currently spent on products meeting the requirements for this Select Your Sample */
  currentAmountSpent: MoneyValue
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['DisplayString']['output']>
  tiers: Array<SelectYourSampleTier>
  title?: Maybe<Scalars['DisplayString']['output']>
}

export type SelectYourSampleTier = {
  __typename?: 'SelectYourSampleTier'
  /**
   * The amount of additional money the customer must spend on qualifying products to reach this
   * tier, or null if the tier is already reached.
   */
  additionalSpendToReach?: Maybe<MoneyValue>
  id: Scalars['ID']['output']
  maxSelectedProducts: Scalars['Int']['output']
  /** @deprecated Use sysProducts instead */
  products: Array<ProductVariant>
  /** @deprecated Use selectedSysProducts instead */
  selectedProducts: Array<ProductVariant>
  selectedSysProducts: SysProducts
  sysProducts: SysProducts
  thresholdAmountSpent: MoneyValue
}

export type SeoProductCategory = Widget & {
  __typename?: 'SeoProductCategory'
  buttonStyle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  productCategoryName?: Maybe<Scalars['String']['output']>
  productCategoryUrl?: Maybe<Scalars['String']['output']>
  query: Query
}

export type SeoProductCategorySet = Widget & {
  __typename?: 'SeoProductCategorySet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  showDiscoverTag?: Maybe<Scalars['String']['output']>
  showMore?: Maybe<Scalars['String']['output']>
}

/** Currency and Shipping Destination are passed in as this can affect the price of products after offer evaluation */
export type SessionSettings = {
  currency: Currency
  shippingDestination: Country
}

export type SettingsFieldInput = {
  fieldName: Scalars['String']['input']
  /** If the value is passed as null, it means it existed before and needs deleting. */
  value?: InputMaybe<Scalars['String']['input']>
}

export type ShopTheLookHotSpot = Widget & {
  __typename?: 'ShopTheLookHotSpot'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  SKU?: Maybe<ProductVariant>
  SKUNew?: Maybe<WidgetProductItem>
  hiddenMd?: Maybe<Scalars['String']['output']>
  hiddenSm?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemLeft?: Maybe<Scalars['String']['output']>
  itemLeftMd?: Maybe<Scalars['String']['output']>
  itemLeftSm?: Maybe<Scalars['String']['output']>
  itemTop?: Maybe<Scalars['String']['output']>
  itemTopMd?: Maybe<Scalars['String']['output']>
  itemTopSm?: Maybe<Scalars['String']['output']>
  productDescription?: Maybe<Scalars['String']['output']>
  query: Query
  rangeList?: Maybe<Scalars['String']['output']>
}

export type ShopTheRoom = Widget & {
  __typename?: 'ShopTheRoom'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  SKUList?: Maybe<Array<Maybe<ProductVariant>>>
  SKUListNew?: Maybe<Array<Maybe<WidgetProductItem>>>
  description?: Maybe<Scalars['String']['output']>
  displayAsGrid?: Maybe<Scalars['Boolean']['output']>
  displayQuickbuy?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  imageAlt?: Maybe<Scalars['String']['output']>
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  orientation?: Maybe<Scalars['String']['output']>
  query: Query
  quickBuyLabel?: Maybe<Scalars['String']['output']>
  shopAllLabel?: Maybe<Scalars['String']['output']>
  shopAllLink?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SignUpForEmailMarketingCampaignInput = {
  auditData: MarketingConsentAuditData
  /** The ID of the marketing campaign being signed up to. Currently must be an Integer */
  campaignId: Scalars['ID']['input']
  email: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type SignUpForMarketingInput = {
  auditData: MarketingConsentAuditData
  /** Interpreted as a phone number or email address depending on the provided MarketingType */
  contactDetails: Scalars['String']['input']
  type: MarketingType
}

export enum SignUpResult {
  Duplicate = 'DUPLICATE',
  NotFound = 'NOT_FOUND',
  Ok = 'OK',
  RequiresVerification = 'REQUIRES_VERIFICATION',
  UnknownError = 'UNKNOWN_ERROR',
}

export type SimpleFacet = Facet & {
  __typename?: 'SimpleFacet'
  facetHeader: Scalars['DisplayString']['output']
  facetName: Scalars['String']['output']
  options: Array<SimpleFacetOption>
}

export type SimpleFacetOption = {
  __typename?: 'SimpleFacetOption'
  displayName: Scalars['DisplayString']['output']
  matchedProductCount: Scalars['Int']['output']
  optionName: Scalars['String']['output']
}

export type SimpleTextReadMore = Widget & {
  __typename?: 'SimpleTextReadMore'
  id: Scalars['ID']['output']
  query: Query
  simpleTextReadMore_maxHeight?: Maybe<Scalars['Int']['output']>
  simpleTextReadMore_text?: Maybe<RichContent>
  simpleTextReadMore_textAlign?: Maybe<Scalars['String']['output']>
  simpleTextReadMore_title?: Maybe<Scalars['String']['output']>
  simpleTextReadMore_titleIsH1?: Maybe<Scalars['Boolean']['output']>
}

export type SiteProperties = {
  __typename?: 'SiteProperties'
  /**
   * If keys is provided, the result will be limited to only the given property keys,
   * otherwise, all properties will be returned
   */
  properties?: Maybe<Array<Maybe<LocalizedString>>>
}

export type SitePropertiesPropertiesArgs = {
  keys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type Sitemap = {
  __typename?: 'Sitemap'
  hasMore: Scalars['Boolean']['output']
  paths: Array<Scalars['PagePath']['output']>
  total: Scalars['Int']['output']
}

export type SliderFacet = Facet & {
  __typename?: 'SliderFacet'
  facetHeader: Scalars['DisplayString']['output']
  facetName: Scalars['String']['output']
  maxValue: Scalars['Float']['output']
  minValue: Scalars['Float']['output']
}

export type SocialAccount = {
  __typename?: 'SocialAccount'
  socialNetwork: SocialNetwork
  url: Scalars['URL']['output']
}

/**
 * This will be used on generated widgets with a UGC-type parameter.
 *
 * The field will look as follows:
 *
 * userGeneratedContent(offset: Int! = 0, limit: Int! = 5): SocialAssets
 */
export type SocialAsset = {
  __typename?: 'SocialAsset'
  caption: Scalars['String']['output']
  hashtags: Array<Scalars['String']['output']>
  largeImage: Scalars['URL']['output']
  mediumImage: Scalars['URL']['output']
  postUrl: Scalars['URL']['output']
  providerName: Scalars['String']['output']
  smallImage: Scalars['URL']['output']
  thumbnailImage: Scalars['URL']['output']
  user: SocialAssetUser
}

export type SocialAssetUser = {
  __typename?: 'SocialAssetUser'
  /** Full Name may or may not be provided based on the social network */
  fullName?: Maybe<Scalars['String']['output']>
  profileImage: Scalars['URL']['output']
  profileUrl: Scalars['URL']['output']
  username: Scalars['String']['output']
}

export type SocialAssets = {
  __typename?: 'SocialAssets'
  hasMore: Scalars['Boolean']['output']
  socialAssets: Array<SocialAsset>
  total: Scalars['Int']['output']
}

export type SocialAuthenticationResponse = {
  __typename?: 'SocialAuthenticationResponse'
  authenticationResponse: AuthenticationResponse
  /**
   * In the case that the social login failed due to an INVALID_DATA error, the 'form' field will be non-null,
   * and will contain a form containing only the missing or invalid fields required.
   */
  form?: Maybe<Form>
  /**
   * In the case that only Marketing Opt-In is required for Account Creation, when a social login is attempted
   * then the Account will be created successfully and this object will be non-null.
   * In which case you should render an opt-in page and submit the result with the updateMarketingPreferences mutation
   */
  optInForm: OptInForm
  socialIdentity?: Maybe<SocialIdentity>
  socialLinkId?: Maybe<Scalars['SocialLinkID']['output']>
  /**
   * In the case that the social login failed, the 'socialLoginToken' field will be non-null, and will contain
   * a token which can be used in the socialLogin mutation to retry social login with additional or different data
   */
  socialLoginToken?: Maybe<Scalars['SocialAuthenticationToken']['output']>
  /** @deprecated Use socialLinkID instead */
  socialUserId?: Maybe<Scalars['SocialLinkID']['output']>
}

export type SocialIdentity = {
  __typename?: 'SocialIdentity'
  email?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
}

export type SocialLink = {
  __typename?: 'SocialLink'
  socialLinkId: Scalars['SocialLinkID']['output']
  socialLoginProvider?: Maybe<SocialLoginProvider>
  status: SocialLinkStatus
  username?: Maybe<Scalars['String']['output']>
}

export enum SocialLinkStatus {
  Linked = 'LINKED',
  Pending = 'PENDING',
}

export type SocialLoginInput = {
  missingInformation?: InputMaybe<SocialLoginMissingInformation>
  /** Should we offer opt-in before or after Account Creation */
  optInAfterAccountCreation?: Scalars['Boolean']['input']
  socialAuthenticationToken: Scalars['SocialAuthenticationToken']['input']
}

/**
 * All input fields for social login are optional.  Which ones are actually needed depends on the information provided
 * by the social provider.
 *
 * The required fields can be determined using the Form from the SocialAuthenticationResponse type.
 */
export type SocialLoginMissingInformation = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  loyaltyOptIn?: InputMaybe<Scalars['Boolean']['input']>
  marketingConsent?: InputMaybe<Scalars['String']['input']>
  marketingConsentAuditData?: InputMaybe<MarketingConsentAuditData>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  referralCode?: InputMaybe<Scalars['String']['input']>
}

export type SocialLoginProvider = {
  __typename?: 'SocialLoginProvider'
  /** A unique identifier for this social provider. */
  code: Scalars['String']['output']
  colour?: Maybe<Scalars['String']['output']>
  iconUrl?: Maybe<Scalars['String']['output']>
  /**
   * A URL for the social login button to link to.  This URL comes with some already existing parameters,
   * but must be updated to add the following query parameters:
   *
   * clientState:  A string which will be returned once the user returns from the social login provider.  This can be
   *   used to store information that needs to be remembered from the start of their journey when they return, such as
   *   a CSRF token.
   *
   * returnUrl: A full URL (Including protocol) which the user will return to when they return from the social login
   *   provider (e.g. https://www.mydomain.com/socialLogin)
   *
   * The user will return from the social provider via a POST request to the returnUrl.  Their request body will be
   * form encoded, and contain the following data:
   *
   * token: A SocialAuthenticationToken, which can be used in this API via the exchangeSocialAuthenticationToken and
   * socialLogin fields.
   *
   * clientState: The same client state provided as a parameter to the URL.
   */
  loginUrl?: Maybe<Scalars['String']['output']>
  /** The text to display on the social login button, if applicable */
  name?: Maybe<Scalars['DisplayString']['output']>
}

export enum SocialNetwork {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  KakaoTalk = 'KAKAO_TALK',
  Line = 'LINE',
  Pinterest = 'PINTEREST',
  Tiktok = 'TIKTOK',
  TwentyoneButtons = 'TWENTYONE_BUTTONS',
  Twitter = 'TWITTER',
  Vk = 'VK',
  Weibo = 'WEIBO',
  Whatsapp = 'WHATSAPP',
  Youtube = 'YOUTUBE',
}

export type SocialReferralMethod = {
  __typename?: 'SocialReferralMethod'
  type: SocialReferralMethodType
  url?: Maybe<Scalars['String']['output']>
}

export enum SocialReferralMethodType {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Messenger = 'MESSENGER',
  Sms = 'SMS',
  Twitter = 'TWITTER',
  Vk = 'VK',
  Weibo = 'WEIBO',
  Whatsapp = 'WHATSAPP',
}

/**
 * [ISO_3166-2](https://en.wikipedia.org/wiki/ISO_3166-2) state code.
 * The non-standard codes `US_AA`, `US_AE` and `US_AP` represent Armed Forces locations, similar to BFPO.
 * Puerto Rico, US Virgin Islands etc. are not listed here as we treat them as separate countries.
 */
export enum State {
  /** Armed Forces Americas */
  UsAa = 'US_AA',
  /** Armed Forces Europe */
  UsAe = 'US_AE',
  UsAk = 'US_AK',
  UsAl = 'US_AL',
  /** Armed Forces Pacific */
  UsAp = 'US_AP',
  UsAr = 'US_AR',
  UsAz = 'US_AZ',
  UsCa = 'US_CA',
  UsCo = 'US_CO',
  UsCt = 'US_CT',
  UsDc = 'US_DC',
  UsDe = 'US_DE',
  UsFl = 'US_FL',
  UsGa = 'US_GA',
  UsHi = 'US_HI',
  UsIa = 'US_IA',
  UsId = 'US_ID',
  UsIl = 'US_IL',
  UsIn = 'US_IN',
  UsKs = 'US_KS',
  UsKy = 'US_KY',
  UsLa = 'US_LA',
  UsMa = 'US_MA',
  UsMd = 'US_MD',
  UsMe = 'US_ME',
  UsMi = 'US_MI',
  UsMn = 'US_MN',
  UsMo = 'US_MO',
  UsMs = 'US_MS',
  UsMt = 'US_MT',
  UsNc = 'US_NC',
  UsNd = 'US_ND',
  UsNe = 'US_NE',
  UsNh = 'US_NH',
  UsNj = 'US_NJ',
  UsNm = 'US_NM',
  UsNv = 'US_NV',
  UsNy = 'US_NY',
  UsOh = 'US_OH',
  UsOk = 'US_OK',
  UsOr = 'US_OR',
  UsPa = 'US_PA',
  UsRi = 'US_RI',
  UsSc = 'US_SC',
  UsSd = 'US_SD',
  UsTn = 'US_TN',
  UsTx = 'US_TX',
  UsUt = 'US_UT',
  UsVa = 'US_VA',
  UsVt = 'US_VT',
  UsWa = 'US_WA',
  UsWi = 'US_WI',
  UsWv = 'US_WV',
  UsWy = 'US_WY',
}

export enum StatusType {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
}

export type SubscriptionAutoRenewal = {
  __typename?: 'SubscriptionAutoRenewal'
  autoRenewalOverride?: Maybe<SubscriptionAutoRenewalOverride>
  nextContractRenewalDate?: Maybe<Scalars['Date']['output']>
}

export enum SubscriptionAutoRenewalOptInType {
  ExistingContract = 'EXISTING_CONTRACT',
  MonthlyRolling = 'MONTHLY_ROLLING',
  NotSelected = 'NOT_SELECTED',
}

export type SubscriptionAutoRenewalOptInTypeInput = {
  optInType: SubscriptionAutoRenewalOptInType
}

export type SubscriptionAutoRenewalOverride = {
  __typename?: 'SubscriptionAutoRenewalOverride'
  optInType: SubscriptionAutoRenewalOptInType
  shouldContinueWithMonthlyRollingContractUnlessOptedIn: Scalars['Boolean']['output']
}

export type SubscriptionContract = {
  __typename?: 'SubscriptionContract'
  /** @deprecated Use frequencyDuration instead */
  frequency: SubscriptionFrequency
  frequencyDuration: SubscriptionFrequencyDuration
  id: Scalars['ID']['output']
  initialDiscountPercentage: Scalars['Float']['output']
  initialPrice?: Maybe<ProductPrice>
  /** This is the recommended contract and should probably be pre-selected in the client implementation */
  recommended: Scalars['Boolean']['output']
  recurringDiscountPercentage: Scalars['Float']['output']
  recurringPrice?: Maybe<ProductPrice>
  upsellMessage: Scalars['DisplayString']['output']
}

export type SubscriptionContractInitialPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export type SubscriptionContractRecurringPriceArgs = {
  currency: Currency
  shippingDestination: Country
}

export enum SubscriptionDelay {
  DelayedOneMonth = 'DELAYED_ONE_MONTH',
  DelayedTwoMonths = 'DELAYED_TWO_MONTHS',
  NotDelayed = 'NOT_DELAYED',
  ProcessingDelayRequest = 'PROCESSING_DELAY_REQUEST',
}

/** The same day of the period each time (e.g. the nth day of each week/month/year) */
export enum SubscriptionFrequency {
  Annually = 'ANNUALLY',
  EveryFourMonths = 'EVERY_FOUR_MONTHS',
  EverySixMonths = 'EVERY_SIX_MONTHS',
  EveryThreeWeeks = 'EVERY_THREE_WEEKS',
  EveryTwoMonths = 'EVERY_TWO_MONTHS',
  EveryTwoWeeks = 'EVERY_TWO_WEEKS',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
}

export type SubscriptionFrequencyDuration = {
  __typename?: 'SubscriptionFrequencyDuration'
  duration: Scalars['Int']['output']
  unit: SubscriptionFrequencyUnit
}

export type SubscriptionFrequencyDurationInput = {
  duration: Scalars['Int']['input']
  unit: SubscriptionFrequencyUnit
}

export enum SubscriptionFrequencyUnit {
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem'
  product: Product
  quantity: Scalars['Int']['output']
  selectedVariant?: Maybe<ProductVariant>
}

export enum SubscriptionPaymentType {
  OnDispatch = 'ON_DISPATCH',
  OnOrder = 'ON_ORDER',
  Upfront = 'UPFRONT',
}

export type SubscriptionSchedule = {
  __typename?: 'SubscriptionSchedule'
  autoRenew: Scalars['Boolean']['output']
  /** @deprecated Use dispatchFrequencyDuration instead */
  dispatchFrequency: SubscriptionFrequency
  dispatchFrequencyDuration: SubscriptionFrequencyDuration
  /** @deprecated Use paymentFrequencyDuration instead */
  paymentFrequency?: Maybe<SubscriptionFrequency>
  paymentFrequencyDuration?: Maybe<SubscriptionFrequencyDuration>
  paymentType: SubscriptionPaymentType
  /** The number of orders to be placed in total. */
  totalDeliveries: Scalars['Int']['output']
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  FailedPayment = 'FAILED_PAYMENT',
  New = 'NEW',
  NoticePeriod = 'NOTICE_PERIOD',
  OnHold = 'ON_HOLD',
  /** The subscription order has been placed, but has yet to be processed. */
  Preprocessed = 'PREPROCESSED',
}

export type Subscriptions = {
  __typename?: 'Subscriptions'
  hasMore: Scalars['Boolean']['output']
  subscriptions: Array<ProductSubscription>
  total: Scalars['Int']['output']
}

export type SubscriptionsFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<SubscriptionStatus>
}

export type Supersize = {
  __typename?: 'Supersize'
  saving: SupersizeSaving
  variant: ProductVariant
}

export type SupersizeSaving = {
  __typename?: 'SupersizeSaving'
  /** E.g. next supersize option is 10 kg */
  nextSupersizeOption: ProductUnit
  /** Saving for per unit, e.g. "£2" per 1 kg or per 20 servings */
  savingPerUnit: MoneyValue
  /** The unit for the saving, e.g. "1 kg" in saving £2 per 1 kg */
  savingUnit: ProductUnit
}

export type SysProducts = {
  __typename?: 'SysProducts'
  productVariants: Array<ProductVariant>
  products: Array<Product>
}

export type TestCheckoutStartInput = {
  checkoutStartInput: CheckoutStartInput
  elysiumCheckoutOrder?: InputMaybe<Scalars['String']['input']>
}

export type TestGuestCheckoutStartInput = {
  guestCheckoutEmailInput: GuestCheckoutEmailInput
  testCheckoutStartInput: TestCheckoutStartInput
}

export type TestWidgetN = Widget & {
  __typename?: 'TestWidgetN'
  PersonalisedRecommendations?: Maybe<Array<Maybe<Product>>>
  id: Scalars['ID']['output']
  query: Query
}

export type TextReviewElement = ReviewElement & {
  __typename?: 'TextReviewElement'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type PersonalisedRecommendations = Widget & {
  __typename?: 'PersonalisedRecommendations'
  PersonalisedRecommendations?: Maybe<Array<Maybe<Product>>>
  id: Scalars['ID']['output']
  query: Query
}

export type TokenMarketingConsentAuditData = {
  landingUrl: Scalars['URL']['input']
}

export type TokenMarketingInput = {
  auditData: TokenMarketingConsentAuditData
  /** This is actually a hash of the email address but is in the unsubscribe link as the email parameter */
  email: Scalars['String']['input']
  /** This is the uuid parameter in the standard unsubscribe link */
  token: Scalars['MarketingToken']['input']
}

export type TopBrandsWidget = Widget & {
  __typename?: 'TopBrandsWidget'
  BrandEightLogoPath?: Maybe<Scalars['URL']['output']>
  BrandEightName?: Maybe<Scalars['String']['output']>
  BrandFiveLogoPath?: Maybe<Scalars['URL']['output']>
  BrandFiveName?: Maybe<Scalars['String']['output']>
  BrandFourLogoPath?: Maybe<Scalars['URL']['output']>
  BrandFourName?: Maybe<Scalars['String']['output']>
  BrandOneLogoPath?: Maybe<Scalars['URL']['output']>
  BrandOneName?: Maybe<Scalars['String']['output']>
  BrandSevenLogoPath?: Maybe<Scalars['URL']['output']>
  BrandSevenName?: Maybe<Scalars['String']['output']>
  BrandSixLogoPath?: Maybe<Scalars['URL']['output']>
  BrandSixName?: Maybe<Scalars['String']['output']>
  BrandTabTitle?: Maybe<Scalars['String']['output']>
  BrandThreeLogoPath?: Maybe<Scalars['URL']['output']>
  BrandThreeName?: Maybe<Scalars['String']['output']>
  BrandTwoLogoPath?: Maybe<Scalars['URL']['output']>
  BrandTwoName?: Maybe<Scalars['String']['output']>
  altTextBrandEightLogo?: Maybe<Scalars['String']['output']>
  altTextBrandFiveLogo?: Maybe<Scalars['String']['output']>
  altTextBrandFourLogo?: Maybe<Scalars['String']['output']>
  altTextBrandOneLogo?: Maybe<Scalars['String']['output']>
  altTextBrandSevenLogo?: Maybe<Scalars['String']['output']>
  altTextBrandSixLogo?: Maybe<Scalars['String']['output']>
  altTextBrandThreeLogo?: Maybe<Scalars['String']['output']>
  altTextBrandTwoLogo?: Maybe<Scalars['String']['output']>
  altTextFeaturedImage?: Maybe<Scalars['String']['output']>
  altTextFeaturedLogo?: Maybe<Scalars['String']['output']>
  featuredBrandName?: Maybe<Scalars['String']['output']>
  featuredBrandPageLink?: Maybe<Scalars['String']['output']>
  featuredBrandPageLinkName?: Maybe<Scalars['String']['output']>
  featuredBrandText?: Maybe<Scalars['String']['output']>
  featuredImagePath?: Maybe<Scalars['URL']['output']>
  featuredLogoPath?: Maybe<Scalars['URL']['output']>
  id: Scalars['ID']['output']
  query: Query
}

export type TopProductCategory = Widget & {
  __typename?: 'TopProductCategory'
  buttonStyle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  topProductCategoryName?: Maybe<Scalars['String']['output']>
  topProductCategoryUrl?: Maybe<Scalars['String']['output']>
}

export type TopProductCategorySet = Widget & {
  __typename?: 'TopProductCategorySet'
  DiscoverTitle?: Maybe<Scalars['String']['output']>
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  showDiscoverTag?: Maybe<Scalars['String']['output']>
  showMore?: Maybe<Scalars['String']['output']>
}

export type TrustPilotCarouselWidget = Widget & {
  __typename?: 'TrustPilotCarouselWidget'
  businessId?: Maybe<Scalars['String']['output']>
  dataStars?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  languageCode?: Maybe<Scalars['String']['output']>
  locale?: Maybe<Scalars['String']['output']>
  query: Query
  templateId?: Maybe<Scalars['String']['output']>
  theme?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['String']['output']>
}

export type AdaChatbotBooking = Widget & {
  __typename?: 'AdaChatbotBooking'
  title?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  heading?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  backgroundColor?: Maybe<Scalars['String']['output']>
  textColor?: Maybe<Scalars['String']['output']>
  buttonLabel?: Maybe<Scalars['String']['output']>
  buttonEmphasis?: Maybe<Scalars['String']['output']>
}

export type TrustPilotWidget = Widget & {
  __typename?: 'TrustPilotWidget'
  businessId?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isCarousel?: Maybe<Scalars['String']['output']>
  languageCode?: Maybe<Scalars['String']['output']>
  locale?: Maybe<Scalars['String']['output']>
  query: Query
  templateId?: Maybe<Scalars['String']['output']>
  theme?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['String']['output']>
}

export type UpdateDateOfBirthInput = {
  /** New date of birth. Set to null to remove from account. */
  newDateOfBirth?: InputMaybe<Scalars['Date']['input']>
}

export type UpdateEmailAddressInput = {
  currentPassword: Scalars['String']['input']
  newEmailAddress: Scalars['String']['input']
}

export enum UpdateMarketingPreferencesError {
  /**
   * There was insufficient data to update this marketing preference, such as
   * attempting to sign up for SMS marketing when the customer has no phone number.
   */
  InsufficientData = 'INSUFFICIENT_DATA',
}

export type UpdateMarketingPreferencesInput = {
  auditData: MarketingConsentAuditData
  newValue: Scalars['Boolean']['input']
  type: MarketingType
}

export type UpdateMarketingPreferencesResponse = {
  __typename?: 'UpdateMarketingPreferencesResponse'
  error?: Maybe<UpdateMarketingPreferencesError>
}

export type UpdateMobilePhoneNumberInput = {
  /** New phone number. Set to null to remove from account. */
  newNumber?: InputMaybe<Scalars['String']['input']>
}

export type UpdateNameInput = {
  newName?: InputMaybe<Scalars['String']['input']>
}

export type UpdatePasswordInput = {
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type UsedPaymentMethod = {
  __typename?: 'UsedPaymentMethod'
  amountSpent: MoneyValue
  giftCard?: Maybe<GiftCard>
  paymentCard?: Maybe<PaymentCard>
  paymentType?: Maybe<Scalars['String']['output']>
}

export type Validator = {
  __typename?: 'Validator'
  argument?: Maybe<Scalars['String']['output']>
  name: ValidatorName
}

export enum ValidatorName {
  /** The argument is an email domain. The field value should be accepted if it follows the format {any prefix}@{given domain} */
  EmailDomain = 'EMAIL_DOMAIN',
  /** The argument is a password and the site enforces password history checks. A password is being reused when a new one is required. */
  FailedHistoryRequirements = 'FAILED_HISTORY_REQUIREMENTS',
  /** The argument is comparable with the expected date input in the context of the field type.  The date field value will be rejected if it is older than the date calculated with the argument. */
  MaxDaysOld = 'MAX_DAYS_OLD',
  /** The argument is an integer.  The field value should be rejected if its length is greater than the argument. */
  MaxLength = 'MAX_LENGTH',
  /**
   * The argument is an integer.  The field values will be rejected if there are more options selected than are allowed here.
   * This validator is mainly for use with multiselect and likert scale fields
   */
  MaxOptions = 'MAX_OPTIONS',
  MaxUploadSizeKb = 'MAX_UPLOAD_SIZE_KB',
  /** The argument is comparable with the expected input in the context of the field type.  The field value will be rejected if it is greater than the argument. */
  MaxValue = 'MAX_VALUE',
  /** The argument is comparable with the expected date input in the context of the field type.  The date field value will be rejected if it is newer than the date calculated with the argument. */
  MinDaysOld = 'MIN_DAYS_OLD',
  /** The argument is an integer.  The field value should be rejected if its length is less than the argument. */
  MinLength = 'MIN_LENGTH',
  /**
   * The argument is an integer.  The field value should be rejected the number of lowercase letters is less than the
   * argument.
   */
  MinLowercase = 'MIN_LOWERCASE',
  /** The argument is an integer.  The field value should be rejected the number of digits is less than the argument. */
  MinNumbers = 'MIN_NUMBERS',
  /**
   * The argument is an integer.  The field values will be rejected if there are fewer options selected than are allowed here.
   * This validator is mainly for use with multiselect and likert scale fields
   */
  MinOptions = 'MIN_OPTIONS',
  /**
   * The argument is an integer.  The field value should be rejected the number of special characters is less than the
   * argument.
   */
  MinSpecialCharacters = 'MIN_SPECIAL_CHARACTERS',
  /**
   * The argument is an integer.  The field value should be rejected the number of uppercase letters is less than the
   * argument.
   */
  MinUppercase = 'MIN_UPPERCASE',
  /** The argument is comparable with the expected input in the context of the field type.  The field value will be rejected if it is less than the argument. */
  MinValue = 'MIN_VALUE',
  /**
   * For example, the email field might have the MUST_BE_UNIQUE validator, as the user must input an email that does not
   * match any existing customer's email address
   */
  MustBeUnique = 'MUST_BE_UNIQUE',
  /**
   * The argument is a comma-separated list of field names.  The field value should be rejected if it is equal to the
   * value of any of the fields specified.
   */
  NotEqualTo = 'NOT_EQUAL_TO',
  /** The field value should be rejected if it contains any digits (0-9) */
  NoDigits = 'NO_DIGITS',
  /** The field value should be rejected if it contains any of the following special characters: (?, \, ;, :. <, >, ", %, $) */
  NoSpecialCharacters = 'NO_SPECIAL_CHARACTERS',
  /** The argument is a string containing a Regex.  The field value should be rejected if it matches the given regex. */
  RegexDoesNotMatch = 'REGEX_DOES_NOT_MATCH',
  /** The argument is a string containing a Regex.  The field value should be rejected if it does not match the given regex. */
  RegexMatches = 'REGEX_MATCHES',
  /** Signals that this argument may be rejected by the backend for any reason. */
  ServerSideCheck = 'SERVER_SIDE_CHECK',
  /**
   * The argument is not required.  The field value should be rejected if the value is not one of the provided options.
   * This will almost always be set when there is an options list provided.
   */
  SpecifiedAnswersOnly = 'SPECIFIED_ANSWERS_ONLY',
  SupportedUploadExtensions = 'SUPPORTED_UPLOAD_EXTENSIONS',
}

/**
 * An axis on which a product can vary, such as colour or flavour.
 * A Product may have 0 or more options.
 */
export type VariantOption = {
  __typename?: 'VariantOption'
  choices: Array<OptionChoice>
  key: Scalars['String']['output']
  localizedKey?: Maybe<Scalars['String']['output']>
}

export type VariantOptions = {
  currency: Currency
  shippingDestination: Country
}

export type Video = {
  __typename?: 'Video'
  assets: Array<VideoAsset>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  status: StatusType
  thumbnailUrl?: Maybe<Scalars['URL']['output']>
  title?: Maybe<Scalars['String']['output']>
  transcript: Scalars['String']['output']
  vttType: VttType
  vttUrl: Scalars['URL']['output']
}

export type VideoAsset = {
  __typename?: 'VideoAsset'
  status: StatusType
  type: VideoAssetType
  url: Scalars['URL']['output']
}

export enum VideoAssetType {
  Mp4 = 'MP4',
  Original = 'ORIGINAL',
  Webm = 'WEBM',
}

export type VoucherCode = {
  __typename?: 'VoucherCode'
  discountCode?: Maybe<Scalars['String']['output']>
  expiresAt?: Maybe<Scalars['Timestamp']['output']>
  link?: Maybe<Hyperlink>
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type VoucherCodes = {
  __typename?: 'VoucherCodes'
  hasMore: Scalars['Boolean']['output']
  offers: Array<VoucherCode>
  total: Scalars['Int']['output']
}

export type VoucherCodesWidget = Widget & {
  __typename?: 'VoucherCodesWidget'
  id: Scalars['ID']['output']
  query: Query
}

export enum VttType {
  BackgroundMusic = 'BACKGROUND_MUSIC',
  Custom = 'CUSTOM',
  NoAudio = 'NO_AUDIO',
}

export type Widget = {
  id: Scalars['ID']['output']
  query: Query
}

export type WidgetProductItem = {
  __typename?: 'WidgetProductItem'
  product?: Maybe<Product>
  productVariant?: Maybe<ProductVariant>
}

/**
 * There may be multiple wishlist items for the same product,
 * but none with the same product and selected variant
 */
export type WishlistItem = {
  __typename?: 'WishlistItem'
  product: Product
  selectedVariant?: Maybe<ProductVariant>
}

export type WishlistItems = {
  __typename?: 'WishlistItems'
  hasMore: Scalars['Boolean']['output']
  items: Array<WishlistItem>
  total: Scalars['Int']['output']
}

export enum WishlistSort {
  Availability = 'AVAILABILITY',
  Default = 'DEFAULT',
  DiscountHighestToLowest = 'DISCOUNT_HIGHEST_TO_LOWEST',
  PriceLowestToHighest = 'PRICE_LOWEST_TO_HIGHEST',
  Title = 'TITLE',
}

export type AsymmetricGrid = Widget & {
  __typename?: 'asymmetricGrid'
  altTextImgOne?: Maybe<Scalars['String']['output']>
  altTextImgTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageOnePath?: Maybe<Scalars['URL']['output']>
  imageOneSubtitle?: Maybe<Scalars['String']['output']>
  imageTwoPath?: Maybe<Scalars['URL']['output']>
  imageTwoSubtitle?: Maybe<Scalars['String']['output']>
  linkOne?: Maybe<Scalars['String']['output']>
  linkTwo?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
  widthImageOne?: Maybe<Scalars['Int']['output']>
  widthImageOneMobile?: Maybe<Scalars['Int']['output']>
  widthImageTwo?: Maybe<Scalars['Int']['output']>
  widthImageTwoMobile?: Maybe<Scalars['Int']['output']>
}

export type Buildyourownbundle = Widget & {
  __typename?: 'buildyourownbundle'
  banners?: Maybe<Array<Maybe<Widget>>>
  bundlePageTitle?: Maybe<Scalars['String']['output']>
  bundleSubTitle?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  maxProducts?: Maybe<Scalars['String']['output']>
  packSummary?: Maybe<Scalars['String']['output']>
  query: Query
  showPrices?: Maybe<Scalars['String']['output']>
  summaryAmount?: Maybe<Scalars['String']['output']>
}

export type ButtonLink = Widget & {
  __typename?: 'buttonLink'
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  query: Query
  style?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
}

export type CategoryItemCard = Widget & {
  __typename?: 'categoryItemCard'
  altTextitem?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemCTA?: Maybe<Scalars['String']['output']>
  itemDescription?: Maybe<Scalars['String']['output']>
  itemImagePath?: Maybe<Scalars['URL']['output']>
  itemTitle?: Maybe<Scalars['String']['output']>
  itemUrl?: Maybe<Scalars['String']['output']>
  query: Query
  textAlignment?: Maybe<Scalars['String']['output']>
}

export type CategoryItemCardWithTextOverlay = Widget & {
  __typename?: 'categoryItemCardWithTextOverlay'
  altTextitem?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemCTA?: Maybe<Scalars['String']['output']>
  itemDescription?: Maybe<Scalars['String']['output']>
  itemImagePath?: Maybe<Scalars['URL']['output']>
  itemTitle?: Maybe<Scalars['String']['output']>
  itemUrl?: Maybe<Scalars['String']['output']>
  query: Query
  textAlignment?: Maybe<Scalars['String']['output']>
}

export type CoverageCalculator = Widget & {
  __typename?: 'coverageCalculator'
  additionalInfoDisclaimerText?: Maybe<Scalars['String']['output']>
  additionalInfoText?: Maybe<RichContent>
  additionalInfoTitle?: Maybe<Scalars['String']['output']>
  buyButtonLink?: Maybe<Scalars['String']['output']>
  calculatorSubtitle?: Maybe<Scalars['String']['output']>
  calculatorTitle?: Maybe<Scalars['String']['output']>
  calculatorType?: Maybe<Scalars['String']['output']>
  excludeAreaHelpText?: Maybe<RichContent>
  id: Scalars['ID']['output']
  instructionsText?: Maybe<RichContent>
  instructionsTitle?: Maybe<Scalars['String']['output']>
  paintCoatsHelpText?: Maybe<RichContent>
  paintCoverage?: Maybe<Scalars['String']['output']>
  query: Query
  wallpaperRollLength?: Maybe<Scalars['String']['output']>
  wallpaperRollWidth?: Maybe<Scalars['String']['output']>
}

export type EightLinkButtonWidget = Widget & {
  __typename?: 'eightLinkButtonWidget'
  buttonTextEight?: Maybe<Scalars['String']['output']>
  buttonTextFive?: Maybe<Scalars['String']['output']>
  buttonTextFour?: Maybe<Scalars['String']['output']>
  buttonTextOne?: Maybe<Scalars['String']['output']>
  buttonTextSeven?: Maybe<Scalars['String']['output']>
  buttonTextSix?: Maybe<Scalars['String']['output']>
  buttonTextThree?: Maybe<Scalars['String']['output']>
  buttonTextTwo?: Maybe<Scalars['String']['output']>
  buttonUrlEight?: Maybe<Scalars['String']['output']>
  buttonUrlFive?: Maybe<Scalars['String']['output']>
  buttonUrlFour?: Maybe<Scalars['String']['output']>
  buttonUrlOne?: Maybe<Scalars['String']['output']>
  buttonUrlSeven?: Maybe<Scalars['String']['output']>
  buttonUrlSix?: Maybe<Scalars['String']['output']>
  buttonUrlThree?: Maybe<Scalars['String']['output']>
  buttonUrlTwo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  title?: Maybe<Scalars['String']['output']>
  titleAlign?: Maybe<Scalars['String']['output']>
}

export type Fouritemwindowproductslider = Widget & {
  __typename?: 'fouritemwindowproductslider'
  ctaLink?: Maybe<Scalars['String']['output']>
  ctaText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  productCount?: Maybe<Scalars['Int']['output']>
  productList?: Maybe<Scalars['String']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type GeneralIframe = Widget & {
  __typename?: 'generalIframe'
  id: Scalars['ID']['output']
  iframeSource?: Maybe<Scalars['String']['output']>
  query: Query
}

export type GeneralQuoteBanner = Widget & {
  __typename?: 'generalQuoteBanner'
  ctaText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  textLink?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ImageSelectCard = Widget & {
  __typename?: 'imageSelectCard'
  ImageInformationText?: Maybe<Scalars['String']['output']>
  altTextImage?: Maybe<Scalars['String']['output']>
  altTextThumbnail?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemImageLargePath?: Maybe<Scalars['URL']['output']>
  itemImagePath?: Maybe<Scalars['URL']['output']>
  itemImageSmallPath?: Maybe<Scalars['URL']['output']>
  query: Query
  thumbnailImagePath?: Maybe<Scalars['URL']['output']>
}

export type ImageSelectSlider = Widget & {
  __typename?: 'imageSelectSlider'
  altTextLogoImage?: Maybe<Scalars['String']['output']>
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  logoImagePath?: Maybe<Scalars['URL']['output']>
  query: Query
  titleText?: Maybe<Scalars['String']['output']>
}

export type ImprovedSearchBestSellers = Widget & {
  __typename?: 'improvedSearchBestSellers'
  id: Scalars['ID']['output']
  productId1?: Maybe<Scalars['String']['output']>
  productId2?: Maybe<Scalars['String']['output']>
  productId3?: Maybe<Scalars['String']['output']>
  productId4?: Maybe<Scalars['String']['output']>
  query: Query
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type KitBuilder = Widget & {
  __typename?: 'kitBuilder'
  id: Scalars['ID']['output']
  kitBuilderDistributorId?: Maybe<Scalars['String']['output']>
  kitBuilderKey?: Maybe<Scalars['String']['output']>
  query: Query
}

export type LoyaltyRewardsList = Widget & {
  __typename?: 'loyaltyRewardsList'
  id: Scalars['ID']['output']
  listItem1?: Maybe<Scalars['String']['output']>
  listItem2?: Maybe<Scalars['String']['output']>
  listItem3?: Maybe<Scalars['String']['output']>
  listItem4?: Maybe<Scalars['String']['output']>
  listItem5?: Maybe<Scalars['String']['output']>
  listItem6?: Maybe<Scalars['String']['output']>
  listItem7?: Maybe<Scalars['String']['output']>
  listItem8?: Maybe<Scalars['String']['output']>
  query: Query
  title?: Maybe<Scalars['String']['output']>
}

export type LoyaltyWelcomeMessage = Widget & {
  __typename?: 'loyaltyWelcomeMessage'
  accountCreationTextPart1?: Maybe<Scalars['String']['output']>
  accountCreationTextPart2?: Maybe<Scalars['String']['output']>
  heading?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageAltText?: Maybe<Scalars['String']['output']>
  imagePath?: Maybe<Scalars['URL']['output']>
  profileCompletionTextPart1?: Maybe<Scalars['String']['output']>
  profileCompletionTextPart2?: Maybe<Scalars['String']['output']>
  query: Query
  subHeading?: Maybe<Scalars['String']['output']>
}

export type MultiCategoryCardSet = Widget & {
  __typename?: 'multiCategoryCardSet'
  backgroundColour?: Maybe<Scalars['String']['output']>
  banners?: Maybe<Array<Maybe<Widget>>>
  columnsNumber?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  multiCategoryCardSet_CTAText?: Maybe<Scalars['String']['output']>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type MultiCategoryCardSetV2 = Widget & {
  __typename?: 'multiCategoryCardSetV2'
  alternativeItemCardDesign?: Maybe<Scalars['Boolean']['output']>
  backgroundColour?: Maybe<Scalars['String']['output']>
  banners?: Maybe<Array<Maybe<Widget>>>
  columnsNumber?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  marginStyle?: Maybe<Scalars['String']['output']>
  mobileColumns?: Maybe<Scalars['Int']['output']>
  query: Query
  scrollable?: Maybe<Scalars['Boolean']['output']>
  stackCardsOnMobile?: Maybe<Scalars['Boolean']['output']>
  theme?: Maybe<Scalars['String']['output']>
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type ObsessVr = Widget & {
  __typename?: 'obsessVR'
  id: Scalars['ID']['output']
  ingetgrityHash?: Maybe<Scalars['String']['output']>
  obsessVRclient?: Maybe<Scalars['String']['output']>
  obsessVRhideLogo?: Maybe<Scalars['Boolean']['output']>
  obsessVRstore?: Maybe<Scalars['String']['output']>
  query: Query
  storeId?: Maybe<Scalars['String']['output']>
}

export type Odicci = Widget & {
  __typename?: 'odicci'
  id: Scalars['ID']['output']
  odicciCampaignID?: Maybe<Scalars['String']['output']>
  query: Query
}

export type ParcelLabWidget = Widget & {
  __typename?: 'parcelLabWidget'
  id: Scalars['ID']['output']
  parcelLabUserId?: Maybe<Scalars['Int']['output']>
  query: Query
  showSearchForm?: Maybe<Scalars['String']['output']>
  showZipCodeInput?: Maybe<Scalars['String']['output']>
}

export type Promoproductslider = Widget & {
  __typename?: 'promoproductslider'
  featuredImage?: Maybe<Scalars['URL']['output']>
  id: Scalars['ID']['output']
  imageAltText?: Maybe<Scalars['String']['output']>
  imageURL?: Maybe<Scalars['String']['output']>
  productCount?: Maybe<Scalars['Int']['output']>
  productList?: Maybe<ProductList>
  query: Query
  widgetSubtitle?: Maybe<Scalars['String']['output']>
  widgetTitle?: Maybe<Scalars['String']['output']>
}

export type PromoproductsliderProductListArgs = {
  input: ProductListInput
}

export type PropositionBar = Widget & {
  __typename?: 'propositionBar'
  id: Scalars['ID']['output']
  query: Query
  textFive?: Maybe<Scalars['String']['output']>
  textFour?: Maybe<Scalars['String']['output']>
  textOne?: Maybe<Scalars['String']['output']>
  textSix?: Maybe<Scalars['String']['output']>
  textThree?: Maybe<Scalars['String']['output']>
  textTwo?: Maybe<Scalars['String']['output']>
}

export type PropositionBarIncorrect = Widget & {
  __typename?: 'propositionBarIncorrect'
  id: Scalars['ID']['output']
  query: Query
}

export type ProvenanceBrandHeader = Widget & {
  __typename?: 'provenanceBrandHeader'
  brand?: Maybe<Scalars['String']['output']>
  brandId?: Maybe<Scalars['String']['output']>
  button2Text?: Maybe<Scalars['String']['output']>
  button2Url?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  itemAlign?: Maybe<Scalars['String']['output']>
  itemAlt?: Maybe<Scalars['String']['output']>
  itemButton?: Maybe<Scalars['String']['output']>
  itemImage?: Maybe<Scalars['URL']['output']>
  itemImageSmall?: Maybe<Scalars['URL']['output']>
  itemText?: Maybe<RichContent>
  itemTitle?: Maybe<Scalars['String']['output']>
  itemURL?: Maybe<Scalars['String']['output']>
  query: Query
  textAlign?: Maybe<Scalars['String']['output']>
  transcript?: Maybe<RichContent>
  videoTitle?: Maybe<Scalars['String']['output']>
  videoUrl?: Maybe<Scalars['String']['output']>
}

export type QubitHomePageRecs = Widget & {
  __typename?: 'qubitHomePageRecs'
  id: Scalars['ID']['output']
  numberOfProducts?: Maybe<Scalars['Int']['output']>
  placementId?: Maybe<Scalars['String']['output']>
  productList?: Maybe<ProductList>
  query: Query
  theme?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  titleAlign?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type QubitHomePageRecsProductListArgs = {
  input: ProductListInput
}

export type RevieveWidget = Widget & {
  __typename?: 'revieveWidget'
  id: Scalars['ID']['output']
  query: Query
}

export type ShadeFinder = Widget & {
  __typename?: 'shadeFinder'
  buttonText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  openOnLoad?: Maybe<Scalars['String']['output']>
  query: Query
  text?: Maybe<RichContent>
  title?: Maybe<Scalars['String']['output']>
  widgetAlignment?: Maybe<Scalars['String']['output']>
}

export type ShopTheLook = Widget & {
  __typename?: 'shopTheLook'
  altImageLarge?: Maybe<Scalars['String']['output']>
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageMedium?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  itemDescription?: Maybe<Scalars['String']['output']>
  itemTitle?: Maybe<Scalars['String']['output']>
  query: Query
}

export type SimpleCta = Widget & {
  __typename?: 'simpleCTA'
  CTAStyle?: Maybe<Scalars['String']['output']>
  CTAText?: Maybe<Scalars['String']['output']>
  CTAUrl?: Maybe<Scalars['String']['output']>
  doNotUse?: Maybe<Scalars['String']['output']>
  hasRevieveButtons?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  query: Query
  revievePartnerId_1?: Maybe<Scalars['String']['output']>
  revievePartnerId_2?: Maybe<Scalars['String']['output']>
  secondCTAStyle?: Maybe<Scalars['String']['output']>
  secondCTAText?: Maybe<Scalars['String']['output']>
  secondCTAUrl?: Maybe<Scalars['String']['output']>
  simpleCTABackgroundColour?: Maybe<Scalars['String']['output']>
  simpleCTABackgroundIsFullWidth?: Maybe<Scalars['Boolean']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SimpleDivider = Widget & {
  __typename?: 'simpleDivider'
  id: Scalars['ID']['output']
  marginBottom?: Maybe<Scalars['Int']['output']>
  marginTop?: Maybe<Scalars['Int']['output']>
  maxWidth?: Maybe<Scalars['Int']['output']>
  overrideStyles?: Maybe<Scalars['Boolean']['output']>
  query: Query
}

export type SimpleTextWidgetSet = Widget & {
  __typename?: 'simpleTextWidgetSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type SimpleTitleWidget = Widget & {
  __typename?: 'simpleTitleWidget'
  additionalSubtitleStyles?: Maybe<Scalars['String']['output']>
  additionalTitleStyles?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
  textAlign?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type Simpletextwidget = Widget & {
  __typename?: 'simpletextwidget'
  displayOnDesktopOnly?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  query: Query
  text?: Maybe<RichContent>
  textAlign?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type Simpletextwidgetwithh1 = Widget & {
  __typename?: 'simpletextwidgetwithh1'
  displayOnDesktopOnly?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  query: Query
  text?: Maybe<RichContent>
  textAlign?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SponsoredProducts = Widget & {
  __typename?: 'sponsoredProducts'
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductOne?: Maybe<ProductVariant>
  ProductOneNew?: Maybe<WidgetProductItem>
  /** @deprecated Use the field with type WidgetProductItem, name should be 'paramName'+New */
  ProductTwo?: Maybe<ProductVariant>
  ProductTwoNew?: Maybe<WidgetProductItem>
  altLogoPng?: Maybe<Scalars['String']['output']>
  contentTheme?: Maybe<Scalars['String']['output']>
  ctaOne?: Maybe<Scalars['String']['output']>
  ctaOneAriaLabel?: Maybe<Scalars['String']['output']>
  ctaOneURL?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logopngImageBG?: Maybe<Scalars['URL']['output']>
  logopngWhiteBG?: Maybe<Scalars['URL']['output']>
  query: Query
  text?: Maybe<Scalars['String']['output']>
  useH1?: Maybe<Scalars['String']['output']>
}

export type SponsoredProductsCriteoHomepage = Widget & {
  __typename?: 'sponsoredProductsCriteoHomepage'
  id: Scalars['ID']['output']
  query: Query
}

export type SponsoredProductsNew = Widget & {
  __typename?: 'sponsoredProductsNew'
  id: Scalars['ID']['output']
  query: Query
  widgetVersion?: Maybe<Scalars['String']['output']>
}

export type StoryStreamWidget = Widget & {
  __typename?: 'storyStreamWidget'
  id: Scalars['ID']['output']
  integrityHash?: Maybe<Scalars['String']['output']>
  layoutClass?: Maybe<Scalars['String']['output']>
  query: Query
  storyStreamId?: Maybe<Scalars['String']['output']>
}

export type SubscribeAndSaveInformationModal = Widget & {
  __typename?: 'subscribeAndSaveInformationModal'
  id: Scalars['ID']['output']
  imageAltText?: Maybe<Scalars['String']['output']>
  imageLarge?: Maybe<Scalars['URL']['output']>
  imageSmall?: Maybe<Scalars['URL']['output']>
  modalContent?: Maybe<RichContent>
  modalTitle?: Maybe<Scalars['String']['output']>
  query: Query
}

export type SwatchSelectSlider = Widget & {
  __typename?: 'swatchSelectSlider'
  altTextImageFour?: Maybe<Scalars['String']['output']>
  altTextImageOne?: Maybe<Scalars['String']['output']>
  altTextImageThree?: Maybe<Scalars['String']['output']>
  altTextImageTwo?: Maybe<Scalars['String']['output']>
  altTextLogoImage?: Maybe<Scalars['String']['output']>
  altTextThumbnailImageFour?: Maybe<Scalars['String']['output']>
  altTextThumbnailImageOne?: Maybe<Scalars['String']['output']>
  altTextThumbnailImageThree?: Maybe<Scalars['String']['output']>
  altTextThumbnailImageTwo?: Maybe<Scalars['String']['output']>
  count?: Maybe<Scalars['String']['output']>
  cta?: Maybe<Scalars['String']['output']>
  ctaLink?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageFourLink?: Maybe<Scalars['String']['output']>
  imageFourPath?: Maybe<Scalars['URL']['output']>
  imageOneLink?: Maybe<Scalars['String']['output']>
  imageOnePath?: Maybe<Scalars['URL']['output']>
  imageThreeLink?: Maybe<Scalars['String']['output']>
  imageThreePath?: Maybe<Scalars['URL']['output']>
  imageTwoLink?: Maybe<Scalars['String']['output']>
  imageTwoPath?: Maybe<Scalars['URL']['output']>
  info?: Maybe<Scalars['String']['output']>
  link?: Maybe<Scalars['String']['output']>
  linkText?: Maybe<Scalars['String']['output']>
  logoImagePath?: Maybe<Scalars['URL']['output']>
  parentWidgetLink?: Maybe<Scalars['String']['output']>
  query: Query
  thumbnailImageFourPath?: Maybe<Scalars['URL']['output']>
  thumbnailImageOnePath?: Maybe<Scalars['URL']['output']>
  thumbnailImageThreePath?: Maybe<Scalars['URL']['output']>
  thumbnailImageTwoPath?: Maybe<Scalars['URL']['output']>
  titleFour?: Maybe<Scalars['String']['output']>
  titleOne?: Maybe<Scalars['String']['output']>
  titleThree?: Maybe<Scalars['String']['output']>
  titleTwo?: Maybe<Scalars['String']['output']>
}

export type SwatchSelectSliderSet = Widget & {
  __typename?: 'swatchSelectSliderSet'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
}

export type TrustReinforcementContainer = Widget & {
  __typename?: 'trustReinforcementContainer'
  banners?: Maybe<Array<Maybe<Widget>>>
  id: Scalars['ID']['output']
  query: Query
  showStockInformation?: Maybe<Scalars['Boolean']['output']>
}

export type TrustReinforcementSection = Widget & {
  __typename?: 'trustReinforcementSection'
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  modalBody?: Maybe<RichContent>
  modalTitle?: Maybe<Scalars['String']['output']>
  query: Query
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type VideoEditorial = Widget & {
  __typename?: 'videoEditorial'
  alignment?: Maybe<Scalars['String']['output']>
  ctaLink?: Maybe<Scalars['URL']['output']>
  ctaText?: Maybe<Scalars['String']['output']>
  desktopViewMode?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  query: Query
  subtitle?: Maybe<RichContent>
  thumbnail?: Maybe<Scalars['URL']['output']>
  title?: Maybe<Scalars['String']['output']>
  vpsVideoId?: Maybe<Video>
}

export type YotiAgeVerification = Widget & {
  __typename?: 'yotiAgeVerification'
  id: Scalars['ID']['output']
  query: Query
  yotiDisplayText?: Maybe<Scalars['String']['output']>
}

export type YotiCancelFrame = Widget & {
  __typename?: 'yotiCancelFrame'
  id: Scalars['ID']['output']
  query: Query
}

export type GlobalComponentWidgetsQueryVariables = Exact<{
  [key: string]: never
}>

export type GlobalComponentWidgetsQuery = {
  __typename?: 'Query'
  componentWidgets: Array<
    | { __typename: 'AccordionWidget'; id: string }
    | { __typename: 'AccordionWidgetContainer'; id: string }
    | { __typename: 'BMICalculator'; id: string }
    | { __typename: 'BMICalculatorV2'; id: string }
    | { __typename: 'BrandsPageWidget'; id: string }
    | { __typename: 'BuildYourOwnBundleProductList'; id: string }
    | { __typename: 'CriteoSponsoredBannerAds'; id: string }
    | { __typename: 'CriteoSponsoredBannerAdsFlagship'; id: string }
    | { __typename: 'DeliveryInfoWidget'; id: string }
    | { __typename: 'DeliveryInformationWidget'; id: string }
    | { __typename: 'DynamicReferralWidget'; id: string }
    | { __typename: 'Easiware'; id: string }
    | { __typename: 'EditorialWidget'; id: string }
    | {
        __typename: 'EmailReEngagementModal'
        imageAltText?: string | null
        imageMedium?: any | null
        emailReEngagementTitleText?: string | null
        emailReEngagementBulletText3?: string | null
        emailReEngagementBulletText2?: string | null
        emailReEngagementBulletText1?: string | null
        imageSmall?: any | null
        imageLarge?: any | null
        emailReEngagementOptInDiscount?: string | null
        id: string
      }
    | { __typename: 'FastTrackBanner'; id: string }
    | { __typename: 'FoundationFinderLandingPageWidget'; id: string }
    | { __typename: 'GlobalAboutUsVideo'; id: string }
    | { __typename: 'GlobalAccreditationIcon'; id: string }
    | { __typename: 'GlobalAccreditationIconCollection'; id: string }
    | { __typename: 'GlobalBrandLogos'; id: string }
    | { __typename: 'GlobalBuyingRightNow'; id: string }
    | { __typename: 'GlobalBuyingRightNowManualProductURL'; id: string }
    | { __typename: 'GlobalCardScrollerCard'; id: string }
    | { __typename: 'GlobalCardScrollerSet'; id: string }
    | { __typename: 'GlobalDispatchAndDateCountdownWidget'; id: string }
    | { __typename: 'GlobalEditorialWithFeature'; id: string }
    | { __typename: 'GlobalFooterAccreditationIcons'; id: string }
    | { __typename: 'GlobalFooterContactUs'; id: string }
    | { __typename: 'GlobalFourBestSellers'; id: string }
    | { __typename: 'GlobalFourButtonLink'; id: string }
    | { __typename: 'GlobalFourItemEditorial'; id: string }
    | { __typename: 'GlobalGeneralImageBanner'; id: string }
    | { __typename: 'GlobalHelpCentreAccordion'; id: string }
    | { __typename: 'GlobalHelpCentreAccordion2'; id: string }
    | { __typename: 'GlobalHelpCentreAccordionCollection'; id: string }
    | { __typename: 'GlobalHelpCentreCollection'; id: string }
    | { __typename: 'GlobalHeroCTABanner'; id: string }
    | { __typename: 'GlobalImageCard'; id: string }
    | { __typename: 'GlobalImageCardSet'; id: string }
    | { __typename: 'GlobalMultiButton'; id: string }
    | { __typename: 'GlobalPrimaryBanner'; id: string }
    | { __typename: 'GlobalPrimaryBannerWithList'; id: string }
    | { __typename: 'GlobalPrimaryBannerWithTextOverlay'; id: string }
    | { __typename: 'GlobalProductCardScroller'; id: string }
    | { __typename: 'GlobalReferral3StepGuide'; id: string }
    | { __typename: 'GlobalScalableLogos'; id: string }
    | { __typename: 'GlobalSectionPeek'; id: string }
    | { __typename: 'GlobalSetAndromeda'; id: string }
    | { __typename: 'GlobalSimpleTextCTAWidget'; id: string }
    | { __typename: 'GlobalSixItemCategories'; id: string }
    | { __typename: 'GlobalSocialIcon'; id: string }
    | { __typename: 'GlobalSocialIconCollection'; id: string }
    | { __typename: 'GlobalSocialIconCollectionv2'; id: string }
    | { __typename: 'GlobalSocialIconv2'; id: string }
    | { __typename: 'GlobalStripBanner'; id: string }
    | { __typename: 'GlobalSubscriptionOptions'; id: string }
    | { __typename: 'GlobalTabbedWidgetSet'; id: string }
    | { __typename: 'GlobalThreeItemEditorial'; id: string }
    | { __typename: 'GlobalThreeItemEditorialSubtitleBG'; id: string }
    | { __typename: 'GlobalTransformationSlider'; id: string }
    | { __typename: 'GlobalTrendingHashtagBlock'; id: string }
    | { __typename: 'GlobalTrendingHashtagCollection'; id: string }
    | { __typename: 'GlobalTwoBestSellers'; id: string }
    | { __typename: 'GlobalTwoItemEditorial'; id: string }
    | { __typename: 'GlobalTwoItemImageTextBlock'; id: string }
    | { __typename: 'GlobalTwoItemImageTextCTA'; id: string }
    | { __typename: 'GlobalTwoItemImageTextCTA3070'; id: string }
    | { __typename: 'GlobalUGCCarousel'; id: string }
    | { __typename: 'GlobalVideoGallery'; id: string }
    | { __typename: 'GlobalVideoHeroBannerWidget'; id: string }
    | { __typename: 'GlobalVideoTextImageCTA'; id: string }
    | { __typename: 'GlobalWaitListSignUpFormResponses'; id: string }
    | { __typename: 'GlobalWaitListSignUpWidget'; id: string }
    | { __typename: 'GlobalWidgetSirius'; id: string }
    | { __typename: 'GlobalWidgetVega'; id: string }
    | { __typename: 'GlobalYoutubeGalleryItem'; id: string }
    | { __typename: 'GlobalYoutubeGallerySet'; id: string }
    | { __typename: 'LiveChatWidget'; id: string }
    | { __typename: 'LoyaltyHubBirthdayGift'; id: string }
    | { __typename: 'LoyaltyHubTier'; id: string }
    | { __typename: 'LoyaltyRewardTier'; id: string }
    | { __typename: 'LoyaltyRewardTiers'; id: string }
    | { __typename: 'MailingList'; id: string }
    | { __typename: 'MultipleCTABanner'; id: string }
    | { __typename: 'ProductListWidget'; id: string }
    | { __typename: 'ProductRecommenderItem'; id: string }
    | { __typename: 'ProfileWidget'; id: string }
    | { __typename: 'ResponsiveBuildYourOwnBundle'; id: string }
    | { __typename: 'ResponsiveBuildYourOwnBundleProducts'; id: string }
    | { __typename: 'ResponsiveComparisonTable'; id: string }
    | { __typename: 'ResponsiveComparisonTableContainer'; id: string }
    | { __typename: 'ResponsiveProductBlockCarousel'; id: string }
    | { __typename: 'ResponsiveProductTabs'; id: string }
    | { __typename: 'ResponsiveSetSalon'; id: string }
    | { __typename: 'ResponsiveSetSalonWithSlides'; id: string }
    | { __typename: 'ResponsiveSliderSet'; id: string }
    | { __typename: 'ResponsiveSuccessStoriesWidgetContainer'; id: string }
    | { __typename: 'ResponsiveSuccessStoryWidget'; id: string }
    | { __typename: 'ResponsiveTwoItemComparisonContainer'; id: string }
    | { __typename: 'ResponsiveTwoItemComparisonRow'; id: string }
    | { __typename: 'ResponsiveUSPBar'; id: string }
    | { __typename: 'ResponsiveUSPBarCustomisable'; id: string }
    | { __typename: 'SeoProductCategory'; id: string }
    | { __typename: 'SeoProductCategorySet'; id: string }
    | { __typename: 'ShopTheLookHotSpot'; id: string }
    | { __typename: 'ShopTheRoom'; id: string }
    | { __typename: 'SimpleTextReadMore'; id: string }
    | { __typename: 'PersonalisedRecommendations'; id: string }
    | { __typename: 'TestWidgetN'; id: string }
    | { __typename: 'TopBrandsWidget'; id: string }
    | { __typename: 'TopProductCategory'; id: string }
    | { __typename: 'TopProductCategorySet'; id: string }
    | { __typename: 'TrustPilotCarouselWidget'; id: string }
    | { __typename: 'TrustPilotWidget'; id: string }
    | { __typename: 'VoucherCodesWidget'; id: string }
    | { __typename: 'asymmetricGrid'; id: string }
    | { __typename: 'buildyourownbundle'; id: string }
    | { __typename: 'buttonLink'; id: string }
    | { __typename: 'categoryItemCard'; id: string }
    | { __typename: 'categoryItemCardWithTextOverlay'; id: string }
    | { __typename: 'coverageCalculator'; id: string }
    | { __typename: 'eightLinkButtonWidget'; id: string }
    | { __typename: 'fouritemwindowproductslider'; id: string }
    | { __typename: 'generalIframe'; id: string }
    | { __typename: 'generalQuoteBanner'; id: string }
    | { __typename: 'imageSelectCard'; id: string }
    | { __typename: 'imageSelectSlider'; id: string }
    | { __typename: 'improvedSearchBestSellers'; id: string }
    | { __typename: 'kitBuilder'; id: string }
    | { __typename: 'loyaltyRewardsList'; id: string }
    | { __typename: 'loyaltyWelcomeMessage'; id: string }
    | { __typename: 'multiCategoryCardSet'; id: string }
    | { __typename: 'multiCategoryCardSetV2'; id: string }
    | { __typename: 'obsessVR'; id: string }
    | { __typename: 'odicci'; id: string }
    | { __typename: 'parcelLabWidget'; id: string }
    | { __typename: 'promoproductslider'; id: string }
    | { __typename: 'propositionBar'; id: string }
    | { __typename: 'propositionBarIncorrect'; id: string }
    | { __typename: 'provenanceBrandHeader'; id: string }
    | { __typename: 'qubitHomePageRecs'; id: string }
    | { __typename: 'revieveWidget'; id: string }
    | { __typename: 'shadeFinder'; id: string }
    | { __typename: 'shopTheLook'; id: string }
    | { __typename: 'simpleCTA'; id: string }
    | { __typename: 'simpleDivider'; id: string }
    | { __typename: 'simpleTextWidgetSet'; id: string }
    | { __typename: 'simpleTitleWidget'; id: string }
    | { __typename: 'simpletextwidget'; id: string }
    | { __typename: 'simpletextwidgetwithh1'; id: string }
    | { __typename: 'sponsoredProducts'; id: string }
    | { __typename: 'sponsoredProductsCriteoHomepage'; id: string }
    | { __typename: 'sponsoredProductsNew'; id: string }
    | { __typename: 'storyStreamWidget'; id: string }
    | { __typename: 'subscribeAndSaveInformationModal'; id: string }
    | { __typename: 'swatchSelectSlider'; id: string }
    | { __typename: 'swatchSelectSliderSet'; id: string }
    | { __typename: 'trustReinforcementContainer'; id: string }
    | { __typename: 'trustReinforcementSection'; id: string }
    | { __typename: 'videoEditorial'; id: string }
    | { __typename: 'yotiAgeVerification'; id: string }
    | { __typename: 'yotiCancelFrame'; id: string }
  >
}
