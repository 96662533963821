import * as React from 'react'

import { useTheme } from '@thg-commerce/enterprise-pages/src/Product/theme'
import { ChoiceSelectedVariantMap } from '@thg-commerce/enterprise-product-options/src/transformers/productOptionsMap'
import {
  ImageSwatch,
  RadioGroup,
  SquaredRadioInput,
  Swatch,
} from '@thg-commerce/gravity-elements'
import { IconPosition } from '@thg-commerce/gravity-elements/CustomDropdown/CustomDropdown'
import { LabelText } from '@thg-commerce/gravity-elements/FormItem/Label'
import { ColourChoice } from '@thg-commerce/gravity-elements/Swatch'
import { SwatchShape } from '@thg-commerce/gravity-elements/Swatch/types'
import { VariantOptions } from '@thg-commerce/leonardo/horizon-schema'

import {
  Container,
  OptionContainer,
  SkeletonProductOption,
  StyledDropdown,
} from './styles'

export interface Choice {
  image?: string
  key: string
  colour?: string | null
  title: string
  disabled?: boolean
  inStock?: boolean
  notifyWhenInStockEnabled?: boolean | null
  useDisabledStyling?: boolean
  icon?: { fill: string; path: string; iconPosition?: IconPosition }
  customLabel?: boolean
}

export interface Option {
  type: 'RADIO' | 'DROPDOWN' | 'IMAGE' | 'SWATCH'
  label: string
  attribute?: boolean | null
  key: string
  selectedChoice?: string
  customLabel?: boolean | null
  placeholder?: string
  choices: Choice[]
  localizedKey?: string
}

export interface SelectedOptions {
  [key: string]: string
}
export interface ProductOptionsProps {
  order?: number[]
  onOptionChange: (key: string, value: string) => void
  onQuantityChange?: (quantity: number) => void
  onDropdownClick?: () => void
  onColourSwatchClick?: () => void
  options: Option[]
  selectedOptions?: SelectedOptions
  hideProductOptionsSwatch?: boolean
  loading?: boolean
  forceDropDowns?: boolean
  hideDropDownsLabel?: boolean
  enableFBTProductOptionPlacement?: boolean | undefined
  isWishlistOption?: boolean
  required?: boolean
  swatchShape?: SwatchShape
  notifyWhenInStockInDropdown?: boolean
  swatch?: {
    show: boolean
  }
  i18nText: {
    swatch: {
      unavailableText: string
      closeButtonText: string
      showLessButtonText: string
      showMoreButtonText: string
    }
    imageSwatch: {
      showButtonText: string
      showMoreButtonText: string
      showLessButtonText: string
    }
    dropdown?: {
      unavailableText: string
      customLabelText?: string
    }
    wishlist?: {
      unavailableText: string
    }
  }
  labelCustomComponent?: React.ReactNode
  dropdownWidth?: string
  maxDropdownHeight?: string
  showOnlyTwoRows?: boolean
  displayColoursAsTextOnly?: boolean
  customErrorMessage?: {
    displayError?: boolean
    errorMessage?: string
  }
  showOutOfStockAsDisabled?: boolean
  showAllImageSwatches?: boolean
  disableColorDropdown?: boolean
  setPersonalisationImages?: (
    personalisationImages: ChoiceSelectedVariantMap | VariantOptions,
  ) => void
}

enum OptionType {
  Size = 'Size',
}

const DropdownOption = (props) => {
  const { theme, textStyle, dropdownOptions, choicesWithColour } = props
  const handleDropdownChange = (event) => {
    props.onOptionChange(props.option.key, event.key.toString() || '')
    props.onDropdownClick?.()
  }

  const renderLabelCustomComponent = () => {
    if (!props.option.attribute) return null
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {props.labelCustomComponent}
      </div>
    )
  }

  return (
    <StyledDropdown
      iconOverride={theme.icons.chevronDown}
      label={props.option.localizedKey || props.option.label}
      textStyle={textStyle}
      removeLabelMargin={true}
      labelCustomComponent={renderLabelCustomComponent()}
      labelHidden={props.hideDropDownsLabel || false}
      required={props.required ?? true}
      aria-label={props.option.label}
      placeholder={props.option.placeholder}
      options={dropdownOptions}
      onChange={handleDropdownChange}
      selectedIconPosition={
        props.notifyWhenInStockInDropdown
          ? IconPosition.LEFT
          : IconPosition.RIGHT
      }
      hasMarginBottom={choicesWithColour.length > 0 || props.index >= 0}
      selected={props.selectedOptions?.[props.option.key]}
      maxDropdownHeight={props.maxDropdownHeight || '146px'}
      width={
        theme.widget.productRecommendations?.ProductOptionMaxWidth
          ?.OptionWidth || props.dropdownWidth
      }
      {...(props.option.label === OptionType.Size && {
        customErrorMessage: props.customErrorMessage,
      })}
    />
  )
}

const SwatchComponent = (props) => {
  const { choicesWithColour } = props
  const handleColourChange = (value) => {
    props.onOptionChange(props.option.key, value)
    props.onColourSwatchClick?.()
  }

  if (
    choicesWithColour.length > 0 &&
    !props.forceDropDowns &&
    (props.swatch?.show ?? true)
  ) {
    return (
      <Swatch
        shape={props.swatchShape ?? SwatchShape.SQUARE}
        colours={choicesWithColour}
        selectedColour={props.selectedOptions?.[props.option.key]}
        onColourChange={handleColourChange}
        i18nText={props.i18nText.swatch}
        showOnlyTwoRows={props.showOnlyTwoRows}
        displayColoursAsTextOnly={props.displayColoursAsTextOnly}
      />
    )
  }
  return null
}

const RenderImageSwatch = (props) => {
  const { textStyle, choicesWithColour, displayingColorText } = props
  return (
    <ImageSwatch
      label={props.option.localizedKey || props.option.label}
      textStyle={textStyle}
      labelHidden={props.hideDropDownsLabel || false}
      required={false}
      ariaLabel={props.option.label}
      images={choicesWithColour}
      selectedImage={displayingColorText[0].title}
      onImageChange={(value: string) => {
        props.onOptionChange(props.option.key, value)
        props.onColourSwatchClick?.()
      }}
      i18nText={props.i18nText.imageSwatch}
      hideOptionalText={true}
      displayColoursAsTextOnly={props.displayColoursAsTextOnly}
      showAllImageSwatches={props.showAllImageSwatches}
    />
  )
}

const OptionElement = (
  props: {
    option: Option
    index: number
    required?: boolean
    labelCustomComponent?: React.ReactNode
    dropdownWidth?: string
    maxDropdownHeight?: string
    customErrorMessage?: {
      displayError?: boolean
      errorMessage?: string
    }
    disableColorDropdown?: boolean
  } & ProductOptionsProps,
) => {
  const theme = useTheme()

  const choicesWithColour = props.option.choices.filter(
    (choice: Choice) => choice.colour,
  ) as ColourChoice[]

  const getIcon = (choice) => {
    if (
      (props.notifyWhenInStockInDropdown &&
        choice.notifyWhenInStockEnabled &&
        !choice.inStock) ||
      (choice.customLabel && choice.disabled)
    ) {
      return theme.patterns.productBlock.components.options.icon
    }

    return choice.icon
  }

  const getDisabledStylingValue = (choice) => {
    const outOfStock = choice.inStock === false

    return (
      ((!props.isWishlistOption && outOfStock) ||
        (props.showOutOfStockAsDisabled && choice.disabled)) &&
      !choice.notifyWhenInStockEnabled
    )
  }

  const shouldDisplayCustomLabel = (choice) => {
    const outOfStock = choice.inStock === false

    return (
      props.isWishlistOption ||
      outOfStock ||
      (props.showOutOfStockAsDisabled && choice.disabled)
    )
  }

  const getCustomLabel = (choice) => {
    const outOfStock = choice.inStock === false

    if (
      (choice.notifyWhenInStockEnabled &&
        props.i18nText.dropdown?.customLabelText &&
        outOfStock) ||
      (choice.customLabel && props.i18nText.dropdown?.customLabelText)
    ) {
      return props.i18nText.dropdown.customLabelText
    }

    if (
      (choice.disabled || outOfStock) &&
      props.i18nText.dropdown?.unavailableText
    ) {
      return props.i18nText.dropdown.unavailableText
    }

    return null
  }

  const dropdownOptions = props.option.choices.map((choice) => {
    const customLabel = getCustomLabel(choice)
    const disabledStyling = getDisabledStylingValue(choice)
    const displayCustomLabel = shouldDisplayCustomLabel(choice)
    const icon = getIcon(choice)

    return {
      ...choice,
      icon,
      useDisabledStyling: disabledStyling,
      customLabel: displayCustomLabel ? customLabel : null,
      value: choice.key,
      displayText: choice.title,
    }
  })

  const displayingColorText = props.option.choices.filter(
    (choice: Choice) =>
      props.selectedOptions?.[props.option.key] === choice.key,
  )

  const textStyle =
    theme.pageTheme?.productOptions?.variantSelector?.textStyle
      ?.textTransform || 'none'

  switch (props.option.type) {
    case 'DROPDOWN':
      return (
        <React.Fragment>
          <DropdownOption
            {...props}
            theme={theme}
            textStyle={textStyle}
            dropdownOptions={dropdownOptions}
            choicesWithColour={choicesWithColour}
          />
          <SwatchComponent {...props} choicesWithColour={choicesWithColour} />
        </React.Fragment>
      )
    case 'IMAGE':
      return (
        <RenderImageSwatch
          {...props}
          textStyle={textStyle}
          displayingColorText={displayingColorText}
          choicesWithColour={choicesWithColour}
        />
      )
    case 'SWATCH':
      return (
        <React.Fragment>
          <LabelText required={true} textStyle={textStyle}>
            {props.option.localizedKey || props.option.label}
          </LabelText>
          <Swatch
            shape={props.swatchShape ?? SwatchShape.SQUARE}
            colours={choicesWithColour}
            selectedColour={props.selectedOptions?.[props.option.key]}
            onColourChange={(value: string) => {
              props.onOptionChange(props.option.key, value)
              props.onColourSwatchClick?.()
            }}
            i18nText={props.i18nText.swatch}
            showOnlyTwoRows={props.showOnlyTwoRows}
            displayColoursAsTextOnly={props.displayColoursAsTextOnly}
          />
        </React.Fragment>
      )
    default:
      return (
        <RadioGroup
          required={props.required ?? true}
          label={props.option.localizedKey || props.option.label}
          textStyle={textStyle}
          selectedValue={props.selectedOptions?.[props.option.key]}
          labelHidden={false}
          // @TODO REBUILD-6279 improve how this is sent and split size guide container
          labelCustomComponent={
            props.option.attribute ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {props.labelCustomComponent}
              </div>
            ) : null
          }
          columns={2}
          getState={(value: string) =>
            props.onOptionChange(props.option.key, value)
          }
          horizontal={true}
          defaultValue={props.selectedOptions?.[props.option.key]}
        >
          {props.option.choices.map((choice: Choice, index: number) => (
            <SquaredRadioInput
              name={props.option.key}
              title={choice.title}
              value={choice.key}
              key={index}
              disabled={choice.disabled}
              borderWidth="2px"
            />
          ))}
        </RadioGroup>
      )
  }
}

// @deprecated TODO REBUILD-8913 ProductOptions should not be used. Instead use SimpleProductOptions that queries aurora edge for product options.
export const ProductOptions = (props: ProductOptionsProps) => {
  const hasOptionOrder =
    props.order && props.order.length === props.options.length
  const theme = useTheme()

  const options = React.useMemo(
    () =>
      props.options.map((option: Option, index: number) => {
        if (option.choices.length === 0) return
        return (
          <OptionContainer
            key={index}
            order={hasOptionOrder ? props.order?.[index] || index : index}
            enableFBTProductOptionPlacement={
              props.enableFBTProductOptionPlacement
            }
          >
            <OptionElement
              option={
                props.forceDropDowns ? { ...option, type: 'DROPDOWN' } : option
              }
              {...props}
              index={index}
            />
          </OptionContainer>
        )
      }),
    [props, hasOptionOrder],
  )

  if (props.loading) {
    return <SkeletonProductOption />
  }
  if (!options.length) return null

  return (
    <Container
      productOptionPlacement={
        theme.widget.productRecommendations?.fbtProductOption?.flexDirection
      }
      enableFBTProductOptionPlacement={props.enableFBTProductOptionPlacement}
    >
      {options}
    </Container>
  )
}
